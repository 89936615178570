export const updateEquipmentPropertyValue = /* GraphQL */ `
  mutation updateEquipmentPropertyValue(
    $id: ID = ""
    $value: String = ""
    $propertyType: String = ""
    $buildingId: ID = ""
  ) {
    updateEquipmentProperty(
      input: {
        id: $id
        value: $value
        propertyType: $propertyType
        buildingId: $buildingId
      }
    ) {
      id
      value
    }
  }
`
