export const GET_USER_BY_ID = `
query GET_USER_BY_ID($id: ID!) {
  getUser(id: $id) {
    id
    email
    firstName
    lastName
    updatedAt
    defaultUniqueAssetId
    createdAt
    userTypeId
    jobTitle
    industry
    phoneNumber
    customView
    primarySalesOfficeId
    profileSettings
    type {
      name
      id
    }
  }
}
`