import { createSlice } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

const initialState: any = {
  applicationErrors: []
}

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplicationErrors: (state, action) => {
      const { heading = null, errorMessage = null, errorID, shouldNotExpire, ErrorBody } = action.payload
      if (errorID) {
        state.applicationErrors = state.applicationErrors.filter((errorObj) => errorObj?.id !== errorID)
      } else {
        const newErrorObj = {
          heading,
          errorMessage,
          shouldNotExpire,
          ErrorBody,
          id: uuidv4()
        }
        state.applicationErrors.push(newErrorObj)
      }
    },
  }
})

export const {
  setApplicationErrors
} = applicationSlice.actions

export const selectApplicationErrors = (state: any) => state.application.applicationErrors