import React from "react"
import { useState, useEffect } from "react"
import "./ResizeHandle.scss"

function ResizeHandle({ onResize, resizableProps, columnName }) {
  const [dragging, setDragging] = useState(false)
  const [startPosition, setStartPosition] = useState(null)
  const [offsetWidth, setOffsetWidth] = useState(null)

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (dragging) {
        const deltaX = event.clientX - startPosition
        const newWidth = offsetWidth + deltaX

        //2 columns widths can't be greater than 50% of the screen.
        const screenWidth1Per = window.innerWidth / 100
        const maxWidth = screenWidth1Per * parseInt(resizableProps?.maxWidth)
        const minWidth = screenWidth1Per * parseInt(resizableProps?.minWidth)
        if (resizableProps) {
          if (minWidth && newWidth >= minWidth && newWidth <= maxWidth) {
            onResize({ width: newWidth, colName: columnName })
          }
        } else {
          onResize({ width: newWidth, colName: columnName })
        }
      }
    }

    const handleMouseUp = () => {
      setStartPosition(null)
      setDragging(false)
    }

    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [dragging, startPosition, onResize, resizableProps, columnName])

  const handleMouseDown = (event) => {
    event.preventDefault()
    setStartPosition(event.clientX)
    setOffsetWidth(event?.nativeEvent?.srcElement?.offsetParent?.offsetWidth)
    setDragging(true)
  }

  return (
    <div className="resize-handle" onMouseDown={handleMouseDown}>
      {dragging && <div className="overlay" />}
    </div>
  )
}

export default React.memo(ResizeHandle)
