import { Link } from "react-router-dom"
import style from "./Header.module.scss"
import { MultiLevelDropdown, SkeltonLoader } from "src/blitz"
import clsx from "clsx"
import translate from "src/common/translations"

const customBreadcrumbComp = {
  default: (
    <>
      <div className={style.logo}>
        <div className={style.logoText} data-testid="test-default-breadcrumb">
          Command Center
        </div>
      </div>
      <span className={style.home}>
        <span className="icon-uniE000"></span>
      </span>
    </>
  )
}

type HeaderTypes = {
  userName: string
  options: any
  breadcrumbs?: Breadcrumb[]
  headerTitle?: string
  loading?: boolean
}

type Breadcrumb = {
  link: string
  label: string
  customComponent?: string
}

export default function Header({
  userName,
  options,
  breadcrumbs = [],
  headerTitle,
  loading = false
}: HeaderTypes) {


  return (
    <div className={clsx(style.navone, "print-mode hide-on-print")}>
      <div className={style.breadcrumbsWrapper}>
        <Link className={style.homeBreadcrumb} to={"/buildings?view=tiles"}>
          <div className={style.logo}>
            <div className={`${style.logoIcon} icon-uniE020`}></div>
          </div>
        </Link>
        {breadcrumbs.map((item: any, index: number) => {
          return (
            <Link
              className={style.homeBreadcrumb}
              to={item?.link || "/"}
              key={`breadcrumb-id-${index}`}
            >
              {item?.customComponent &&
                customBreadcrumbComp[item.customComponent] ? (
                customBreadcrumbComp[item.customComponent]
              ) : (
                <div className={style.breadcrumbLevel} key={index}>
                  <label data-testid="test-breadcrumb">{item.label}</label>
                  <span className={style.home} />
                </div>
              )}
            </Link>
          )
        })}
      </div>

      <div className={style.headerCenter}>
        <SkeltonLoader width={130} loading={loading}>
          <div
            className={`${style.pageTitle} ${style.pointer}`}
            data-testid="test-heading-1"
          >
            {headerTitle}
          </div>
        </SkeltonLoader>
      </div>
      <div className={style.accountWrapper}>
        <MultiLevelDropdown
          leftIcon="icon-welcome"
          title={`${translate("Welcome")} ${userName}`}
          options={options}
          noBorder
          actionClassName={style.profileDropdownBtn}
        />
      </div>
    </div>
  )
}
