import adminSettings from "./en/admin-settings.json"
import automatedTests from "./en/automated-tests.json"
import buildings from "./en/buildings.json"
import common from "./en/common.json"
import exceptions from "./en/exception-details.json"
import performance from "./en/performance.json"
import properties from "./en/properties.json"
import reports from "./en/reports.json"

export const translations = {
  adminSettings,
  automatedTests,
  buildings,
  common,
  exceptions,
  performance,
  properties,
  reports
}
