import { Checkbox, HighLightText, ModalDialog, Typography } from "src/blitz"
import styles from "./ChillerPerformanceReport.module.scss"
import clsx from "clsx"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SearchRegex } from "src/utils/CommonMethods"
import { getChillerProperties } from "./helper"
import { setSelectedChillerProperties } from "src/redux/slicers/reportSlice"
import { useDispatch, useSelector } from "react-redux"
import {
  CHILLER_LIFE,
  CHILLER_PROFILE,
  COMPRESSOR_RUN_HOURS_AND_STARTS,
  MISSING_TEXT
} from "./constant"
import { BUTTON } from "../../Charts/constant"
import { displayTextGenerator } from "./helper"
import Picker from "./Characteristics/Picker"
import { useMutation } from "src/hooks/APIHooks"
import { updateEquipmentPropertyValue } from "src/graphql/queries/cpr"
import translate, { TranslateComponent } from "src/common/translations"
import { useAppDispatch } from "src/redux/Store"
import { translateJSON } from "src/redux/slicers/translationCombinedSlice"
import { UNIT_CONVERSION, UOM_LABELS } from "src/constants/unitConversion"

const PropertiesList = (props) => {
  const {
    chillerCharacteristics = {},
    selectedProperties = [],
    searchText = "",
    handleChangeProperties = () => null,
    systemOfMeasure
  } = props

  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState<any>(false)
  const [showUpdateModal, setShowUpdateModal] = useState<any>(false)
  const { finalProps, defaultSelectedProperties, characteristicsValues } =
    useMemo(() => {
      const { finalProps, defaultSelectedProperties, characteristicsValues } =
        getChillerProperties(
          chillerCharacteristics?.data,
          chillerCharacteristics?.equipmentDetails,
          systemOfMeasure
        )
      return {
        finalProps,
        defaultSelectedProperties,
        characteristicsValues
      }
    }, [chillerCharacteristics, systemOfMeasure])
  const { onSubmit: updateCharacteristic } = useMutation({
    query: updateEquipmentPropertyValue,
    onError: (_, extraParamsPass) => {
      const newObj: any = {}
      const oldObj = extraParamsPass || {}
      Object.keys(oldObj)?.forEach((key) => {
        const obj = oldObj?.[key] || {}
        newObj[obj?.targetKey] = {
          ...(oldObj?.[key] || {}),
          value: obj?.value
        }
      })
      setCharacteristicsV((prvs) => {
        return {
          ...prvs,
          ...newObj
        }
      })
    }
  })
  const [characteristicsV, setCharacteristicsV] = useState(
    characteristicsValues
  )
  useEffect(() => {
    setCharacteristicsV(characteristicsValues)
  }, [characteristicsValues])

  useEffect(() => {
    dispatch(setSelectedChillerProperties(defaultSelectedProperties))
  }, [chillerCharacteristics?.equipmentId])

  const [translatedProperty, setTranslatedProperty] = useState(finalProps || [])
  const appDispatch = useAppDispatch()
  const { translatedJSON } = useSelector((state: any) => state?.translationCombinedSlice);

  useEffect(() => {
    const columnsToTranslate = finalProps.map((tRow) => ({ "name": tRow.name }))
    appDispatch<any>(translateJSON({ json: columnsToTranslate }));
  }, [finalProps])
  useEffect(() => {
    if (translatedJSON) {
      const translatedProperty = finalProps.map((tRow, i) => {
        return ({
          ...tRow,
          translatedPropertyName: translatedJSON[i]?.name,
        })
      })
      setTranslatedProperty(translatedProperty)
    }
  }, [translatedJSON])
  const filteredProps = useMemo(() => {
    if (searchText) {
      const newProps = translatedProperty.filter(({ translatedPropertyName }) =>
        SearchRegex(translatedPropertyName, searchText)
      )
      return newProps
    }
    return translatedProperty
  }, [translatedProperty, searchText])

  const { modalConfig, customStyles }: any = useMemo(() => {
    const modalDialog = {
      heading: showModal?.name,
      modal: Boolean(showModal),
      handleClose: function () {
        setShowModal(false)
      },
      buttons: [
        {
          text: "Close",
          type: BUTTON,
          variant: "blue",
          handleClick: () => {
            setShowModal(false)
          }
        }
      ]
    }
    const customStyles = showModal?.info?.dialogWidth && {
      maxWidth: showModal?.info?.dialogWidth
    }

    return {
      modalConfig: modalDialog,
      customStyles
    }
  }, [showModal])

  const handleUpdateModalClose = useCallback(() => {
    setShowUpdateModal(false)
  }, [])
  const { updateModalConfig, modalCharacteristics }: any = useMemo(() => {
    const updateModalConfig = {
      heading: showUpdateModal?.name,
      modal: Boolean(showUpdateModal),
      handleClose: function () {
        setShowUpdateModal(false)
      }
    }
    const otherProps = showUpdateModal?.otherProps || []

    const modalCharacteristics: any = {}
    otherProps?.forEach((obj) => {
      modalCharacteristics[obj?.targetKey] = characteristicsV?.[obj?.targetKey]
    })

    return {
      updateModalConfig: updateModalConfig,
      modalCharacteristics: modalCharacteristics
    }
  }, [showUpdateModal])

  const handleSave = useCallback(async (obj) => {
    const newUpdateObj = obj?.updated || {}
    const newObj: any = {}
    const updatePromises = Object.keys(newUpdateObj)?.map((key) => {
      const o = newUpdateObj?.[key] || {}
      const payload: any = {
        id: o?.id,
        value: key === "ChillerCapacityNominal" && systemOfMeasure === UOM_LABELS.SI ? (o?.value / UNIT_CONVERSION.VALUE_BTU_TO_KW) : o?.value,
        propertyType: o?.details?.propertyType,
        buildingId: o?.details?.buildingId
      }
      newObj[o?.targetKey] = {
        ...(newUpdateObj?.[key] || {}),
        value: "ChillerCapacityNominal" && systemOfMeasure === UOM_LABELS.SI ? Math.round(o?.value) : o?.value
      }
      return updateCharacteristic(payload, obj?.current)
    })
    setCharacteristicsV((prvs) => {
      return {
        ...prvs,
        ...newObj
      }
    })
    setShowUpdateModal(false)
    const updateFunction = async () => {
      try {
        await Promise.all(updatePromises)
      } catch (err) {
        console.log("Error Occured")
      }
    }
    await updateFunction()
  }, [])
  const theTranslate = translate("The")
  const translateTooltip = translate("View section description and example image")
  return (
    <>
      {filteredProps?.map((properties, index: number) => {
        const checkboxId = `${properties.value}-${index}`
        return (
          <div key={properties.value}>
            <div className={styles.propertiesLabel}>
              <Checkbox
                key={checkboxId}
                name={properties.value}
                label={
                  <HighLightText
                    name={properties?.translatedPropertyName}
                    searchText={searchText}
                    translateLabel={false}
                  />
                }
                disabled={properties?.disabled}
                onChange={() => {
                  handleChangeProperties(
                    properties.value,
                    properties?.otherProps
                  )
                }}
                checked={selectedProperties?.includes(properties.value)}
              />
              <div
                className={styles.infoBlock}
                title={translateTooltip}
                onClick={() => {
                  setShowModal({
                    name: properties?.name,
                    info: properties?.info
                  })
                }}
              >
                <span className="icon icon-info" />
              </div>
            </div>
            {properties?.otherProps?.map((prop: any, index: number) => {
              if (prop?.checkboxName) {
                return (
                  <div key={index} className={styles.editableSectionData}>
                    <Checkbox
                      name={prop.value}
                      label={
                        <HighLightText
                          name={prop?.checkboxName}
                          searchText={searchText}
                          translateLabel={true}
                        />
                      }
                      disabled={!selectedProperties?.includes(properties.value)}
                      onChange={() => {
                        handleChangeProperties(prop.value)
                      }}
                      checked={selectedProperties?.includes(prop.value)}
                    />
                    <div
                      className={styles.infoBlock}
                      title={translateTooltip}
                      onClick={() => {
                        setShowModal({
                          name: prop?.name,
                          info: prop?.info,
                          otherProp: true
                        })
                      }}
                    >
                      <span className="icon icon-info" />
                    </div>
                  </div>
                )
              }
              const value = characteristicsV?.[prop?.targetKey]?.value
              const displayText = displayTextGenerator(prop?.targetKey, value, systemOfMeasure)
              const isMissing = displayText === MISSING_TEXT
              const customClassName =
                properties?.value === CHILLER_PROFILE
                  ? styles?.chillerProfileWrapper
                  : properties?.value === COMPRESSOR_RUN_HOURS_AND_STARTS
                    ? styles?.compressorRunHoursAndStartsWrapper
                    : properties?.value === CHILLER_LIFE &&
                    styles?.chillerLifeWrapper
              return (
                <div key={index} className={clsx(styles.editableSectionData)}>
                  <Typography tagType="div" styleType="p4" color="graphite">
                    {prop?.label}
                  </Typography>
                  <Typography
                    tagType="div"
                    styleType="p4"
                    className={clsx(styles.underline, styles?.hoverC, {
                      [styles.yellowText]: isMissing
                    })}
                    onClick={() => {
                      if (!prop?.disabled) {
                        setShowUpdateModal({
                          name: properties?.updateDataHeading,
                          targetKey: properties?.value,
                          otherProps: properties?.otherProps,
                          customClassName: customClassName
                        })
                      }
                    }}
                  >
                    {displayText}
                  </Typography>
                  {isMissing && prop?.isRequired && (
                    <Typography
                      tagType="div"
                      styleType="p4"
                      className={clsx(styles.yellowText, styles?.hoverC)}
                    >
                      {"(required for section)"}
                    </Typography>
                  )}
                </div>
              )
            })}
          </div>
        )
      })}
      <ModalDialog
        modalConfig={modalConfig}
        customStyles={customStyles}
        bodyClassName={styles?.infoImageBody}
        footerClassName={styles?.infoFooter}
      >
        <>
          <div>
            <Typography className={styles?.description} tagType="h4">
              <b>
                <TranslateComponent>
                  Description:
                </TranslateComponent>
              </b>
            </Typography>
            <Typography tagType="div" styleType="p4">
              <>
                {showModal?.otherProp ? "" : theTranslate}{" "}
                {showModal?.otherProp ? (
                  ""
                ) : (
                  <b>{showModal?.name ? <TranslateComponent>{showModal?.name}</TranslateComponent> : ""}</b>
                )}{" "}
                <TranslateComponent>
                  {showModal?.info?.description}
                </TranslateComponent>
              </>
            </Typography>
          </div>
          {showModal?.info?.imageSrc && (
            <div>
              <Typography className={styles?.description} tagType="h4">
                <b>
                  <TranslateComponent>
                    Section Example:
                  </TranslateComponent>
                </b>
              </Typography>
              <div className={styles?.imageWrapperBorder}>
                <div
                  style={{
                    maxWidth: showModal?.info?.imageWidth,
                    maxHeight: showModal?.info?.imageHeight
                  }}
                  className={styles?.imageWrapper}
                >
                  <img src={showModal?.info?.imageSrc} alt={showModal?.name} />
                </div>
              </div>
            </div>
          )}
        </>
      </ModalDialog>
      {showUpdateModal?.targetKey && (
        <ModalDialog
          modalConfig={updateModalConfig}
          rootClassName={styles?.modalRoot}
          customClassName={clsx(
            styles?.updateContainer,
            showUpdateModal?.customClassName
          )}
          bodyClassName={styles?.updateBody}
          footerClassName={styles?.updateFooter}
        >
          <Picker
            targetKey={showUpdateModal?.targetKey}
            modalCharacteristics={modalCharacteristics}
            handleUpdateModalClose={handleUpdateModalClose}
            handleSave={handleSave}
          />
        </ModalDialog>
      )}
    </>
  )
}

export default PropertiesList
