export const searchPredicate = (item, searchStr, searchProps: string[]) => {
  let foundResult = false
  searchProps.forEach((prop) => {
    if (`${item[prop]}`?.toUpperCase().includes(searchStr)) {
      foundResult = true
    }
  })
  return foundResult
}

export const searchEntities = (
  searchText,
  autoMatedData,
  searchProps: string[] = ["name"]
) => {
  const searchStr = `${searchText}`?.toUpperCase()
  if (autoMatedData) {
    //Filter the top level elements.
    const filteredData = autoMatedData.filter((facility) =>
      searchPredicate(facility, searchStr, searchProps)
    )
    return filteredData
  }
}
