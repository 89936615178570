import { useEffect, useRef } from "react"
import { CheckOutSideClickType } from "./types"
import { hasAbsoluteAncestor } from "./helper"

const CheckOutsideClick = (props: CheckOutSideClickType) => {
  const { handleOutsideClick, disable, className, btnRef } = props

  const ref = useRef(null)

  const handler = (e: any) => {
    if (
      (!ref.current.contains(e.target) || !hasAbsoluteAncestor(e.target)) &&
      !(e?.target === btnRef?.current)
    ) {
      handleOutsideClick()
    }
  }

  useEffect(() => {
    if (!disable) {
      document.addEventListener("mouseup", handler)
    }
    return () => {
      if (!disable) {
        document.removeEventListener("mouseup", handler)
      }
    }
  }, [])

  if (!props.children) {
    return null
  }
  return (
    <div className={`d-flex flex-row ${className}`} ref={ref}>
      {props.children}
    </div>
  )
}

export default CheckOutsideClick
