import {
  assignAttributeStateToLegends,
  assignAttributeStateToTLLegends,
  convertTimeToMinutes,
  generateLegend,
  getChartObject,
  getTimeIndex,
  intervalToMinutes,
  legendIDGenerator,
  legendName
} from "../common"
import moment from "moment"
import { isArray, isString, toNumber } from "lodash"
import {
  chartDateFormat,
  constantValues,
  reduxStoreTimeStampFormat
} from "../../constant"
import { TIMELINE_PROPERTY_TYPE, transformTimeLinechartNew } from "./timeLineChart"
import { generateExceptionBars } from "../Exceptions/index"
import { generateSuppressionBars } from "../Suppressions"
import { generateLegendProps_Type } from "../../ChartContainer/Types/helper"
import { generateExceptionLegends } from "../Exceptions/helper"
import { generateSuppressionLegends } from "../Suppressions/helper"
export const transformScatterChart = ({
  tz,
  startDate,
  endDate,
  intervalMinutes = "15m",
  inputData,
  chartInfo,
  equipmentList,
  buildingId,
  equipmentId,
  oldLegends,
  oldTimeLineLegends = null,
  assignedAttributes = [],
  legendAttributesCombination
}) => {
  const calculatedMin = intervalToMinutes(intervalMinutes)
  const equipmentType = chartInfo?.equipmentType
  const legends = {}
  let timeLineLegends = {}
  const data = inputData?.data || {}
  const newData = {}
  const newDataTimeline = {}
  const timestamps = []

  const localTimeStamp = Boolean(tz)
    ? moment(
      moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
      "MM-DD-YYYY HH:mm"
    )
    : moment()
  const endTimestamp = moment(endDate, chartDateFormat).add(1, "days")
  let oneLoopCompleted = false
  for (const id in data) {
    const paramData = data[id]
    const equipmentName = equipmentList?.find((obj) => obj?.id === id)?.name
    const selectedEquipment = equipmentId === id
    const building = buildingId === id
    const legendEquipmentType = equipmentList?.find((obj) => obj?.id === id)?.type

    const properties = paramData?.properties

    for (const propertyKey in properties) {
      const property = properties[propertyKey]
      const propertyIndex = property?.index
      const propertyName = property?.name || ""

      const axis = chartInfo?.axisConfig?.find((obj) =>
        getChartObject(obj, propertyKey, equipmentType)
      )
      const name = legendName({ building, selectedEquipment, propertyName, equipmentName })
      const xType = axis?.axisType?.toLowerCase() === "x"
      let legendObj
      const legendId = legendIDGenerator(id, propertyKey)
      if (axis.axisName === "timeline") {
        legendObj = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, propertyKey, type: "timeline", oldLegends: oldTimeLineLegends, propertyType: TIMELINE_PROPERTY_TYPE })
        timeLineLegends[legendId] = legendObj
      } else {
        const obj: generateLegendProps_Type = { legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, legendEquipmentType, oldLegends }
        if (xType) {
          obj.additionalAttributes = {
            xAxis: true,
            disabled: true
          }
        }
        legendObj = generateLegend(obj)
        legends[legendObj?.id] = legendObj;
      }

      let currentTimestamp = moment(startDate, chartDateFormat)
      let prevValue = null
      while (
        currentTimestamp.isSameOrBefore(endTimestamp) &&
        currentTimestamp.isSameOrBefore(localTimeStamp)
      ) {
        const dateKey = currentTimestamp.format("YYYY-MM-DD")
        const extractTime = currentTimestamp.format("HH:mm")
        const timetoMin = convertTimeToMinutes(extractTime)
        const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)

        const dateAvailable = paramData?.values?.[dateKey]

        let finalValue
        if (dateAvailable) {
          const valueArray = paramData?.values?.[dateKey]?.[timeIndexPosition]
          const valueArrayIsArray = isArray(valueArray)
          const arrayValues = valueArrayIsArray ? valueArray : []
          const value = arrayValues[propertyIndex]

          const isNull =
            constantValues?.includes(value) ||
            value?.toString()?.startsWith("_")
          const isTimeline = axis?.axisName === "timeline"

          const checkedValue =
            isNull || (isTimeline ? false : !isFinite(toNumber(value)))
              ? null
              : isTimeline
                ? value
                : parseFloat(value)

          if ((isString(value) && value?.length === 0) || !valueArrayIsArray) {
            finalValue = prevValue
          } else {
            finalValue = checkedValue
          }

          if (timeIndexPosition === 95) {
            prevValue = null
          } else {
            prevValue = finalValue
          }
        } else {
          finalValue = null
          prevValue = null
        }

        const dateVal = currentTimestamp.format(reduxStoreTimeStampFormat)

        if (axis.axisName === "timeline") {
          if (!newDataTimeline?.[`${id}-${propertyKey}`]) {
            newDataTimeline[`${id}-${propertyKey}`] = {
              data: []
            }
          }
        } else {
          if (!newData?.[`${id}-${propertyKey}`]) {
            newData[`${id}-${propertyKey}`] = {
              data: []
            }
          }
        }

        if (axis?.axisName === "timeline") {
          newDataTimeline[`${id}-${propertyKey}`].data.push(finalValue)
        } else {
          newData[`${id}-${propertyKey}`].data.push(finalValue)
        }
        if (!oneLoopCompleted) {
          timestamps.push(dateVal)
        }
        currentTimestamp = currentTimestamp.add(calculatedMin, "minutes")
      }
      oneLoopCompleted = true
    }
  }

  const noDataArray = Object.keys(legends)?.map((key) => {
    const noData = (newData?.[key]?.data || [])?.every(
      (e) => !Boolean(e) && e !== 0
    )
    return noData
  })

  const isNoData = noDataArray?.every((e) => Boolean(e))

  assignAttributeStateToLegends(legends, oldLegends, assignedAttributes, legendAttributesCombination)


  // Create/update Exception Legends
  const exceptionData = inputData?.exceptions?.data || {}
  const exceptionLegends = generateExceptionLegends({ exceptionData, chartInfo, equipmentList, selectedEquipID: equipmentId, oldTimeLineLegends })

  // Create/update Suppression Legends
  const suppressionData = inputData?.exceptions?.suppressed?.data || {}
  const suppressionLegends = generateSuppressionLegends({ suppressionData, chartInfo, oldTimeLineLegends })

  // Assign Colors to new time line legends of all kinds
  assignAttributeStateToTLLegends(timeLineLegends, exceptionLegends, suppressionLegends, assignedAttributes, legendAttributesCombination, oldTimeLineLegends)


  const { timeLineBars, totalLanes } = transformTimeLinechartNew(
    timeLineLegends,
    timestamps,
    newDataTimeline
  )

  const exceptionSampling = inputData?.exceptions?.sampling || 15
  const {
    exceptionsBars,
    exceptionLanes
  } = generateExceptionBars(
    exceptionData,
    exceptionLegends,
    exceptionSampling,
    chartInfo,
    totalLanes,
    startDate,
    endTimestamp,
    localTimeStamp
  )

  const suppressed = inputData?.exceptions?.suppressed?.data && inputData?.exceptions?.suppressed?.data[equipmentId] || {}
  const { suppressionBars } =
    generateSuppressionBars(
      suppressed,
      suppressionLegends,
      exceptionLanes,
      startDate,
      endTimestamp.format("YYYY-MM-DD")
    )

  const bars = [
    ...timeLineBars,
    ...exceptionsBars,
    ...suppressionBars
  ]

  const noDataTimelineArray = Object.keys(timeLineLegends)?.map((key) => {
    const noData = (newDataTimeline?.[key]?.data || [])?.every(
      (e) => !Boolean(e) && e !== 0
    )
    return noData
  })

  const isNoDataTimeline = noDataTimelineArray?.every((e) => Boolean(e))

  const isNoTimeLineData = timeLineBars?.every((obj) => !Boolean(obj?.value))

  timeLineLegends = {
    ...timeLineLegends,
    ...exceptionLegends,
    ...suppressionLegends
  }

  return {
    chartData: {
      data: isNoData ? {} : newData,
      timelineData: isNoDataTimeline ? {} : newDataTimeline
    },
    chartProps: {
      timestamps: isNoData ? [] : timestamps,
      legends: Object.values(legends),
      axisList: chartInfo?.axisConfig,
      timeLineLegends: timeLineLegends,
      timeLineBars: isNoData && isNoDataTimeline && isNoTimeLineData ? [] : bars,
      noDataAvailable: isNoData && isNoTimeLineData ? true : false,
      isdataprocessed: true
    }
  }
}
