import { createSlice } from "@reduxjs/toolkit"
import { AppTypes } from "../types/AppTypes"
import { TStore, AppDispatch } from "../Store"
import { Auth, Amplify } from "aws-amplify"
import { State } from "../types"

const initialState: AppTypes = {
  breadcrumbs: [],
  selectedLanguage: "en",
  userInfo: {
    data: null,
    // pages: null
  },
  access: new Map([]),
  roles: [],
  userAccessibleBuildingIds: [],
  customView: {
    customViewId: "",
    views: "",
    loaded: false
  },
}

export const userData = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setUserAccess: (state, action) => {
      state.access = action.payload
    },
    setUserRoles: (state, action) => {
      state.roles = action.payload
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload
    },
    setCustomView: (state, action) => {
      state.customView.views = action.payload;
      state.customView.loaded = true
    },
    setsetCustomViewId: (state, action) => {
      state.customView.customViewId = action.payload
    },
    setUserAccessibleBuildingIds: (state, action) => {
      // This holds ids based on user selection in pages dropdown to get new token,
      //  doesn't mean user has access to only these buildings
      state.userAccessibleBuildingIds = action.payload
    }
  }
})

export const getSession = async (isJWT = false) => {
  const session = await Auth.currentSession()
  if (!isJWT) return session;
  return session.getIdToken().getJwtToken()
}

export const newRefreshToken = (buildingIds, accountIds, salesOfficeIds) => {
  return async (dispatch: AppDispatch, getState: any) => {
    const state: State = getState()
    const { data: userInfo }: any = state.userData?.userInfo
    if (userInfo) {
      const user = await Auth.currentAuthenticatedUser()
      let update = false
      const newAccessControlObj = {
        b: buildingIds || undefined,
        a: accountIds || undefined,
        s: salesOfficeIds || undefined,
      }
      const existingAttributes = JSON.parse(user.attributes["custom:accessControl"] || "{}")
      Object.keys(newAccessControlObj).forEach(k => {
        newAccessControlObj[k]?.forEach(e => {
          if (!existingAttributes?.[k] || existingAttributes?.[k].indexOf(e) === -1) {
            update = true
          }
        })
      })

      if (update) {
        await Auth.updateUserAttributes(user, {
          "custom:accessControl": JSON.stringify(newAccessControlObj)
        }).then((res) => {
          if (res) {
            Amplify.configure({
              API: {
                graphql_headers: async () => {
                  const token = await getSession(true)
                  return {
                    Authorization: token
                  }
                }
              }
            })
          }
        })
      }
    }
  }
}

export const {
  setBreadcrumbs,
  setSelectedLanguage,
  setUserInfo,
  setUserAccess,
  setUserRoles,
  setUserAccessibleBuildingIds,
  setCustomView,
  setsetCustomViewId
} = userData.actions

export const selectLanguage = (state: TStore) => state.userData.selectedLanguage
export const selectUserInfo = (state: TStore) => state.userData.userInfo.data
export const selectUserAccess = (state: TStore) => state.userData.access
export const selectUserRoles = (state: TStore) => state.userData.roles
export const getCustomView = (state: TStore) => state.userData.customView.views
export const getCustomViewId = (state: TStore) => state.userData.customView.customViewId
export const isCustumViewLoaded = (state: TStore) => state.userData.customView.loaded

// export const selectUserPermissions = (state: TStore) =>
//   state.userData.userInfo.pages
export const selectUserAccessibleBuildingIds = (state: TStore) =>
  state.userData.userAccessibleBuildingIds

