export const localHost = "localhost"
export const ENVIRONMENT = {
  DEV: "dev",
  STG: "stg",
  PROD: "prod"
}

export const RETRY_COUNT = 48
export const RECHECK_INTERVAL = 5000

export const TRANE_CONNECT_BUILDING_SETUP_URL = "traneConnectBuildingSetupURL"
export const TRANE_CONNECT_EQUIPMENT_SETUP_URL = "traneConnectEquipmentSetupURL"
export const TRANE_CONNECT_REMOTE_ACCESS_URL = "traneConnectRemoteAccessURL"
export const TRANE_CONNECT_AUTOMATED_TEST_SETTINGS_URL =
  "traneConnectAutomatedTestSettingsURL"
export const TRANE_CONNECT_EQUIPMENT_AUTOMATED_TEST_SETTINGS_URL =
  "traneConnectEquipmentAutomatedTestSettingsURL"
export const TRANE_CONNECT_AUTOMATED_TEST_SUPPRESSIONS_URL =
  "traneConnectAutomatedTestSuppressionsURL"
export const TRANE_CONNECT_FINDING_URL = "traneConnectFindingURL"
export const TRANE_CONNECT_CREATE_NOTE_URL = "traneConnectCreatetNoteURL"
export const TRANE_CONNECT_NOTE_URL = "traneConnectNoteURL"
export const COMMAND_CENTER_CHART_EXPORT_URL = "commandCenterChartExportUrl"
export const TRANE_CONNECT_EQUIPMENT_SETUP_Details_URL =
  "traneConnectEquipmentSetupDeatilsURL"
export const COMMAND_CENTER_PARETO_CHART_REDIRECTION =
  "commandCenterParetoChartRedirection"
export const COMMAND_CENTER_GEN_4_REDIRECTION =
  "commandCenterGen4Redirection"
export const CHART_FINDING = "chartFinding"

export const TRANE_CONNECT_URL = "https://dev.connect.tis.trane.com"

export const COMMAND_CENTER_GEN_4_URL = "https://tcc.tis-dev.trane.com"