export const transformTimeLinechartNew = (
  timeLineLegends: any,
  timestamps: any,
  data: any
) => {
  const timeLineBars = []
  const timelines = []
  Object.keys(timeLineLegends)?.forEach((key: any, index: number) => {
    const obj = timeLineLegends[key]
    const newObj = { ...obj }
    if (obj?.show) {
      newObj.lane = index + 1
    }
    timelines.push(newObj)
  })
  for (let i = 0; i < timelines?.length; i++) {
    createBarObjects(timelines?.[i])
  }

  function createBarObjects(object) {
    let currentObject = null
    const objData = data?.[object?.id]?.data || []
    const colorByValue = {}
    for (let i = 0; i < timestamps.length; i++) {
      const timestamp = timestamps[i]
      const value = objData?.[i]

      if (currentObject === null || currentObject?.value !== value) {
        if (currentObject !== null) {
          currentObject.endTimestamp = timestamp
          timeLineBars.push(currentObject)
        }
        if (value && !(Object?.keys(colorByValue)?.includes(value))) {
          if (!(Object?.values(colorByValue)?.includes(object?.color?.[0]))) {
            colorByValue[value] = object?.color?.[0]
          } else if (!(Object?.values(colorByValue)?.includes(object?.color?.[1]))) {
            colorByValue[value] = object?.color?.[1]
          }
        }
        currentObject = {
          startTimestamp: timestamp,
          endTimestamp: null,
          value: value || null,
          lane: object?.lane,
          id: object?.id,
          name: object?.name,
          color: !value
            ? "#000"
            : colorByValue[value],
          show: object?.show,
          visible: object?.visible,
          propertyName: object?.propertyName
        }
      }

      if (i === timestamps?.length - 1) {
        currentObject.endTimestamp = timestamp
        timeLineBars.push(currentObject)
      }
    }
  }

  const totalLanes = Math.max(...(timeLineBars?.map((obj) => obj?.lane) || []))

  return { timeLineBars: timeLineBars, totalLanes: totalLanes }
}

export const TIMELINE_PROPERTY_TYPE = "timelinePropertyType"
