import clsx from "clsx"
import { ITable } from "../types"
import styles from "./Table.module.scss"

const Table = (props: ITable) => {
  const { className = "", children, stickyHeader = true } = props

  const table = (
    <table
      className={clsx(styles.table, className, {
        [styles.stickyHeader]: stickyHeader
      })}
    >
      {children}
    </table>
  )

  return <div className={styles.tableWrapper}>{table}</div>
}

export default Table
