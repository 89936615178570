import clsx from "clsx"
import { Typography } from "src/blitz"
import styles from "./EmptyReport.module.scss"

type MessageType = {
  message?: string
}
const EmptyReportMessage = ({ message }: MessageType) => {
  if (!message) return
  return (
    <div className={clsx(styles.emptyReport)}>
      <Typography tagType="h3" styleType="p1" translateText={true}>
        {message}
      </Typography>
    </div>
  )
}

export default EmptyReportMessage
