import {
  CHILLER,
  CHARACTERISTIC
} from "src/components/shared/ReportDialog/constants"
import {
  CHART_END_DATE,
  CHART_START_DATE,
  CHART_TYPE,
  FACILITY_CATEGORY,
  SELECTED_TYPE_EQUIPMENT
} from "../../constant"
import { isJsonString } from "src/utils/CommonMethods"
import { isArray, uniqBy } from "lodash"
import { API } from "aws-amplify"
import { getPropsByEquipmentType } from "src/components/shared/ReportDialog/graphql"
import { decodeURLSearchParams, formatToURLDate } from "../../Helper/common"
import { END_DATE, START_DATE } from "src/blitz/DateRange/constant"

// Function to check if all required Parameters are selected
export const checkAllParamsRequired = (
  isBuildingPerformance,
  chartFilter,
  buildingId
) => {
  let allParamsSelected =
    chartFilter?.[CHART_TYPE]?.value?.id &&
    chartFilter?.[CHART_END_DATE] &&
    chartFilter?.[CHART_START_DATE] &&
    buildingId
  if (isBuildingPerformance) {
    allParamsSelected =
      allParamsSelected && chartFilter?.[SELECTED_TYPE_EQUIPMENT]?.value
  } else {
    allParamsSelected =
      allParamsSelected && chartFilter?.[SELECTED_TYPE_EQUIPMENT]?.value?.id
  }
  return allParamsSelected
}

// Function to check if chart is a Pareto category
export const checkIsPareto = (isBuildingPerformance, selectedTypeEquipment) => {
  return (
    isBuildingPerformance &&
    selectedTypeEquipment &&
    selectedTypeEquipment !== FACILITY_CATEGORY
  )
}

// Function to get chiller related properties when chiller type equipment is selected
export const getChillerApplicableProperties = async (selectedEquipment) => {
  const selectedEquipmentObjects = [selectedEquipment]
  const chillerFilter = []
  let compCondTypeFilter = []
  let instanceFilter = []
  selectedEquipmentObjects?.forEach(({ compCondType, instance }) => {
    const selectedChillerFilter = []

    if (compCondType && isJsonString(compCondType)) {
      compCondTypeFilter = JSON.parse(compCondType?.replace(/'/g, "\""))?.map(
        (item) => ({ compCondType: { matchPhrasePrefix: item } })
      )
    } else if (compCondType && compCondType?.includes("[")) {
      compCondTypeFilter = [
        {
          compCondType: {
            matchPhrasePrefix: compCondType.replace(/[\[\]]/g, "")
          }
        }
      ]
    } else if (compCondType && compCondType !== " ") {
      compCondTypeFilter = [
        { compCondType: { matchPhrasePrefix: compCondType } }
      ]
    }
    if (instance && isJsonString(instance)) {
      instanceFilter = JSON.parse(instance?.replace(/'/g, "\""))?.map(
        (item) => ({ instance: { eq: item } })
      )
    } else if (instance && isArray(instance)) {
      instanceFilter = instance?.map((item) => ({
        instance: { eq: item }
      }))
    }

    if (compCondTypeFilter?.length > 0) {
      selectedChillerFilter?.push({ or: compCondTypeFilter })
    }
    if (instanceFilter?.length > 0) {
      selectedChillerFilter?.push({ or: instanceFilter })
    }
    if (selectedChillerFilter?.length > 0) {
      chillerFilter.push({ and: selectedChillerFilter })
    }
  })

  const properties = await fetchPropertiesByEquipmentId(
    chillerFilter,
    selectedEquipment?.type
  )
  return properties
}
const fetchPropertiesByEquipmentId = async (
  filterVariable = null,
  type = CHILLER
) => {
  try {
    const { data }: any = await API.graphql({
      query: getPropsByEquipmentType,
      variables: {
        filter:
          filterVariable && filterVariable?.length > 0
            ? {
              equipmentType: { eq: type },
              isRDR: { eq: 1 },
              sourceEquipmentType: { eq: "NA" },
              sourceFamilyType: { eq: "NA" },
              or: [...filterVariable]
            }
            : {
              equipmentType: { eq: type },
              isRDR: { eq: 1 },
              sourceEquipmentType: { eq: "NA" },
              sourceFamilyType: { eq: "NA" }
            }
      }
    })
    const uniqueProperties: any = uniqBy(
      data?.searchPropertyMetadata?.items,
      "tisDisplayName"
    )
    const newProperties = uniqueProperties?.map(
      ({ id, targetKey, propertyType, tisDisplayName }) => {
        let propName = targetKey
        try {
          propName =
            propertyType === CHARACTERISTIC
              ? `${tisDisplayName} ***`
              : tisDisplayName
        } catch (error) {
          console.warn(error)
        }
        return {
          name: propName ? propName : targetKey,
          targetKey,
          id: id + propName,
          value: id + propName,
          propertyType
        }
      }
    )
    return newProperties
  } catch (e) {
    console.error(e)
  }
}

export const handleDateChange = (pathString, val, searchParams, syncChart, position) => {
  const startDateString = val?.start?.toString()
  const endDateString = val?.end?.toString()

  const startDates = searchParams.get("startDate")
  const endDates = searchParams.get("endDate")

  let startDatesList: any = decodeURLSearchParams(startDates)
  let endDatesList: any = decodeURLSearchParams(endDates)

  const forStartDate = formatToURLDate(startDateString)
  const forEndDate = formatToURLDate(endDateString)

  if (syncChart) {
    startDatesList =
      startDatesList.length === 0
        ? [forStartDate]
        : startDatesList?.map(() => forStartDate)
    endDatesList =
      endDatesList.length === 0
        ? [forEndDate]
        : endDatesList?.map(() => forEndDate)
  } else {
    startDatesList[position] = forStartDate
    endDatesList[position] = forEndDate
  }

  const encodedStartDates = encodeURI(startDatesList)
  const encodedEndDates = encodeURI(endDatesList)
  searchParams.set(START_DATE, encodedStartDates)
  searchParams.set(END_DATE, encodedEndDates)

  pathString = pathString + "?" + searchParams.toString()

  return pathString
}