import {
  COMMAND_CENTER_GEN_4_REDIRECTION,
  COMMAND_CENTER_GEN_4_URL,
  COMMAND_CENTER_PARETO_CHART_REDIRECTION,
  TRANE_CONNECT_EQUIPMENT_AUTOMATED_TEST_SETTINGS_URL,
  TRANE_CONNECT_URL
} from "src/constants/common"

export const environmentHelper = {
  traneConnectURLList: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectFindingURL: {
        absoluteURL: absoluteURL,
        findingsPath: "findings/add",
        findingParameters: {
          accountId: "organization",
          buildingId: "location",
          equipmentId: "equipment",
          chartId: "chartId",
          chartLink: "chartLink",
          building: "building",
          performance: "performance",
          sid: "sid",
          chartImage: "chartImage",
          chartTitle: "chartTitle"
        }
      }
    }
  },
  createNoteLink: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectCreatetNoteURL: {
        absoluteURL: absoluteURL,
        findingsPath: "notes/add",
        findingParameters: {
          accountId: "organization",
          buildingId: "location",
          equipmentId: "equipment",
          selectedEquipmentId: "equipment"
        }
      }
    }
  },
  NoteLink: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectNoteURL: {
        absoluteURL: absoluteURL,
        findingsPath: "notes",
        findingParameters: {
          accountId: "organization",
          buildingId: "location",
          equipmentId: "equipment",
          selectedEquipmentId: "equipment"
        }
      }
    }
  },
  PerformanceChartExport: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.commandCenterBaseURL ||
      "https://dev.tcc.tis.trane.com"
    return {
      commandCenterChartExportUrl: {
        absoluteURL: absoluteURL
      }
    }
  },
  BuildingSetup: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectBuildingSetupURL: {
        absoluteURL: absoluteURL,
        findingsPath: "building-setup",
        pathParameters: {
          buildingId: "buildingId"
        },
        findingParameters: {
          accountId: "organization"
        }
      }
    }
  },
  EquipmentSetup: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectEquipmentSetupURL: {
        absoluteURL: absoluteURL,
        findingsPath: "equipment-setup",
        findingParameters: {
          accountId: "organization",
          buildingId: "location"
        }
      }
    }
  },
  RemoteAccess: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectRemoteAccessURL: {
        absoluteURL: absoluteURL,
        findingsPath: "connect-device",
        findingParameters: {
          accountId: "organization",
          buildingId: "location"
        }
      }
    }
  },
  AutomatedTestSettings: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectAutomatedTestSettingsURL: {
        absoluteURL: absoluteURL,
        findingsPath: "automated-test-settings",
        findingParameters: {
          accountId: "organization",
          buildingId: "location",
        }
      },
      [TRANE_CONNECT_EQUIPMENT_AUTOMATED_TEST_SETTINGS_URL]: {
        absoluteURL: absoluteURL,
        findingsPath: "automated-test-settings",
        findingParameters: {
          accountId: "organization",
          buildingId: "location",
          selectedEquipmentId: "equipment"
        }
      }
    }
  },
  AutomatedTestSuppressions: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectAutomatedTestSuppressionsURL: {
        absoluteURL: absoluteURL,
        findingsPath: "automated-test-suppressions",
        findingParameters: {
          accountId: "organization",
          buildingId: "location"
        }
      }
    }
  },
  EquipmentSetupDetails: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.traneConnectBaseURL ||
      TRANE_CONNECT_URL
    return {
      traneConnectEquipmentSetupDeatilsURL: {
        absoluteURL: absoluteURL,
        findingsPath: "equipment-setup/details",
        findingParameters: {
          buildingId: "location",
          selectedEquipmentId: "equipment"
        },
        additionalQuery: "tab=3"
      }
    }
  },
  ParetoChart: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.commandCenterBaseURL ||
      "https://dev.tcc.tis.trane.com"

    return {
      [COMMAND_CENTER_PARETO_CHART_REDIRECTION]: {
        absoluteURL: absoluteURL
      }
    }
  },
  GEN4Link: function () {
    const absoluteURL =
      this?.appEnvironment?.externalLinks?.commandCenterGen4BaseURL ||
      COMMAND_CENTER_GEN_4_URL
    return {
      [COMMAND_CENTER_GEN_4_REDIRECTION]: {
        absoluteURL: absoluteURL
      }
    }
  }
}
