import { filter, isArray, maxBy, uniq, uniqBy } from "lodash"
import { sortDataByFieldName } from "src/blitz/DataGridTable/helper"
import { ASC } from "src/blitz/DataGridTable/constants"
import { BUILDING_AGGREGATES } from "../buildingSummary/constants"
import { getExpiredStatus } from "src/utils/OfferingUtils"


export const removeSpaceInString = (string: string) =>
  string?.trim()?.replace(/\s\s+/g, " ")


export const formatsearchBuildingsData = (searchBuildingsData) => {
  return searchBuildingsData?.items?.map((item: any) => {
    item.offeringCodes =
      isArray(item?.offerings?.items) &&
        item?.offerings?.items?.length > 0
        ? uniq(item?.offerings?.items?.map((obj) => obj?.code))
        : []
    if (item?.offeringCodes?.length > 0) {
      const codesList = []
      item?.offeringCodes?.forEach((code: any) => {
        const filteredOfferings = filter(item?.offerings?.items, { code: code?.trim() })
        const offering = maxBy(filteredOfferings, obj => new Date(obj?.endDate)) || {}
        if (offering?.code) {
          const { isExpired, remainingDaysNumberInGracePeriod } =
            getExpiredStatus(offering, item?.tz)
          offering.isExpired = isExpired
          offering.remainingDaysNumberInGracePeriod =
            remainingDaysNumberInGracePeriod
        } else {
          offering.code = code
          offering.isExpired = false
        }
        codesList.push(offering)
      })
      item.offerings.items = uniqBy(
        sortDataByFieldName("code", ASC, codesList),
        "code"
      )
    } else {
      if (item?.offerings?.items) {
        item.offerings.items = []
      } else {
        item.offerings = { items: [] }
      }
    }
    item.deviceConnectivity = item?.deviceConnectivity?.split(",")
    return item
  })
}


export const groupToFilter = (groups) => {
  return groups.reduce((acc, item) => {
    if (!acc[item.outerCondition]) {
      acc[item.outerCondition] = []
    }
    acc[item.outerCondition] = [...acc[item.outerCondition], {
      [item.groupCondition]: item.query.map(q => {
        const type = BUILDING_AGGREGATES.find(fil => fil.name === q.type).field;
        if (q.operation === "contains") {
          return ({
            [type]: { wildcard: `*${q.value}*` }
          })
        } else if ((q.operation === "notContains")) {
          return ({
            not: {
              [type]: { wildcard: `${q.value}*` }
            }
          })
        }
        else if (type === "deviceConnectivity" || type === "offeringCodes") {
          return ({
            [type]: { matchPhrasePrefix: `*${q.value}*` }
          })
        }
        return ({
          [type]: {
            [q.operation]: q.value
          }
        })
      })
    }]
    return acc
  }, {})
}
export const UiFilterToGroup = (filter) => {
  const field = Object.keys(filter);
  const groups = field.reduce((acc, curr) => {
    const temp = filter[curr];

    const group = {
      groupCondition: "or",
      outerCondition: "and",
      query: temp.map(item => {

        const field = Object.keys(item)[0]
        const type = BUILDING_AGGREGATES.find(fil => fil.field === field).name
        const operation = Object.keys(item[field])[0]
        const value = item[field][operation]
        return ({
          type,
          operation,
          value
        })
      })
    }
    acc.push(group)
    return acc
  }, [])
  return {
    groups
  }
}

