import { filter, groupBy, concat, cloneDeep, map, isEmpty, isString } from "lodash"
// import moment from "moment"
import { getMoment } from "./MomentUtils"
import {
  AIR_HANDLER,
  AUTOMATED_TESTS,
  FAMILY_FIELD,
  NAME_FIELD,
  NO_DATA_KEY,
  ROOT,
  STAND_ALONE,
  VAV_AIR_SYS
} from "src/components/buildingSummary/constants"
import { ASC, DESC } from "src/blitz/DataGridTable/constants"
const moment = getMoment()
// import { indicatorsPreference } from "src/blitz/PIIndicator/constant"
export const unGroupSystemHeirarchy = (arr: any, result = []) => {
  arr.forEach((obj: any) => {
    const { sub, ...rest } = obj
    result.push(rest)
    if (sub) {
      unGroupSystemHeirarchy(sub, result)
    }
  })
  return result
}

export const findChildItems = (item, value, key) => {
  if (item[key] === value) {
    return true
  }
  if (item?.sub && item.sub?.length > 0) {
    return item.sub.find(s => findChildItems(s, value, key))
  }
  return false
}

export const validParentId = (el: any = {}) =>
  el?.parentId &&
  el?.parentId !== null &&
  el?.parentId !== "" &&
  el?.parentId !== "NA"

export const frameEquipmentsTree = (
  data: any,
  twoWeekRange: any,
  automatedResults: any,
  Standalone: any,
  translatedAutomatedTests: any,
  translatedAutomatedTestRow: any
): any => {
  if (data?.length === 0) return []
  const analyticResultItems = (el: any) =>
    automatedResults.filter((item) => item.equipmentId === el)

  const nullParentId = (el: any) =>
    el.parentId === "" ||
    el.parentId === "NA" ||
    !Boolean(el.parentId) ||
    el.parentId === "(null)"

  const totalEquipmentsList = data.filter(equipment => {
    if (nullParentId(equipment)) {
      return true
    }
    return !data.find(eq => isString(equipment?.parentId) && equipment?.parentId?.split("#")?.includes(eq?.id))
  }).map((el: any) => {
    return {
      ...el,
      equipmentType: el?.isParent ? ROOT : "StandAloneC"
    }
  })

  const EquipmentsWithParentIdList = data.filter(equipment => {
    if (validParentId(equipment)) {
      return !!data.find(eq => isString(equipment?.parentId) && equipment?.parentId?.split("#")?.includes(eq?.id))
    }
    return false
  }).map(
    (el: any) => {
      return {
        ...el,
        parentId: el?.parentId?.split("#")
      }
    }
  )
  const equipmentsList = totalEquipmentsList?.filter(
    (eq) => eq.equipmentType === ROOT
  )
  const standAlone = totalEquipmentsList?.filter(
    (eq) => eq.equipmentType === "StandAloneC"
  )

  const standAloneGroup = groupBy(standAlone, FAMILY_FIELD)
  const standAloneP = Object.entries(standAloneGroup).map((s) => {
    const [parentName, data] = [...s]
    const dataWithAnalytics = data.map((obj) => {
      const analyticsResultDisplay = includeAnalyticsStatus(
        twoWeekRange,
        analyticResultItems(obj?.id)
      )
      return {
        ...obj,
        analyticsResultDisplay
      }
    })
    return {
      name: `${Standalone} ${parentName}`,
      family: `${Standalone} ${parentName}`,
      isParent: true,
      equipmentType: STAND_ALONE,
      analyticsResult: {
        items: []
      },
      analyticsResultDisplay: includeAnalyticsStatus(twoWeekRange, []),
      sub: dataWithAnalytics
    }
  })

  const automatedTestRowGenerator = (parent: any) => {
    return [
      {
        analyticsResult: { items: analyticResultItems(parent?.id) },
        family: parent?.family,
        id: parent?.id + " - " + translatedAutomatedTestRow,
        atrID: parent?.id,
        parentId: parent?.id,
        isParent: false,
        name: translatedAutomatedTests + " - " + parent?.name,
        type: parent?.type,
        automatedTestRow: true
      }
    ]
  }

  const formEquipmentChildren = (parents: any, rootLevel: boolean) => {
    for (let i = 0; i < parents.length; i++) {
      const parent = parents[i]
      const parentId = Boolean(parent?.automatedTestRow)
        ? parent?.parentId
        : parent?.id
      const childrens = filter(EquipmentsWithParentIdList, (el: any) =>
        el.parentId.includes(parent.id)
      )
      const isParent = childrens?.length > 0
      parent.analyticsResultDisplay = includeAnalyticsStatus(
        twoWeekRange,
        analyticResultItems(parentId),
        isParent
      )
      if (isParent) {
        if (!rootLevel) {
          parent.equipmentType = "P"
        }
        parent.isParent = true
        const automatedTestRow = automatedTestRowGenerator(parent)
        parent.sub = [...automatedTestRow, ...childrens]
        parents[i] = parent
        formEquipmentChildren(parent.sub, false)
      }
    }
  }
  formEquipmentChildren(equipmentsList, true)

  return concat(equipmentsList, standAloneP || [])
}

const getAllIndexes = (arr, val) => {
  const indexes = []
  let i = -1
  while ((i = arr.indexOf(val, i + 1)) != -1) {
    indexes.push(i)
  }
  return indexes
}

export const includeAnalyticsStatus = (
  twoWeekRange,
  analyticsResult,
  isParent = false
) => {
  if (analyticsResult?.length === 0) return twoWeekRange
  let weekRange = cloneDeep(twoWeekRange)
  const testDateList = map(analyticsResult, "testDate").map((date) => date)
  weekRange = weekRange.map((day) => {
    const analyticIndexs = getAllIndexes(testDateList, day.date)
    if (analyticIndexs.length !== 0) {
      const thisDay = analyticIndexs.map((i) => analyticsResult[i])
      // to be deleted once the mockData isnsert is stopped
      const filtredResults = thisDay.filter(
        (analytic) => analytic?.pi || analytic?.rollUpResult
      )
      const parentIndicator = filtredResults?.[0]?.rollUpResult
        ? JSON.parse(filtredResults?.[0]?.rollUpResult)?.res
        : NO_DATA_KEY
      const piData = isParent ? parentIndicator : filtredResults?.[0]?.pi
      // if (Boolean(piData)) {
      //   const nestedResults = JSON.parse(piData)
      //   const parsedResults = Object.values(nestedResults)
      //   parsedResults?.map((tDOj: any) => {
      //     if (
      //       indicatorsPreference[tDOj]?.preference <
      //       indicatorsPreference[result]?.preference
      //     ) {
      //       result = tDOj
      //     }
      //   })
      // }
      day.performanceIndicator = piData || NO_DATA_KEY
      day.result = piData || NO_DATA_KEY
    }
    return day
  })

  return weekRange
}

export const mapAnalyticResult = (twoWeekRange, analyticName, results) => {
  if (isEmpty(results)) return twoWeekRange
  const weekRange = twoWeekRange.map((day) => {
    const resultKey = `${day.date}###${analyticName}`
    const performanceIndicator = results[resultKey]
    const resultObject = performanceIndicator
      ? {
        performanceIndicator,
        result: performanceIndicator
      }
      : {}
    return {
      ...day,
      ...resultObject
    }
  })
  return weekRange
}

export const sortFunction = (a, b, sortOrder, multiple = false) => {
  const sortOrderType = sortOrder === DESC ? -1 : 1
  if (multiple) {
    const nameA =
      a.name === null || a.name === undefined ? "" : a.name.toString()
    const nameB =
      b.name === null || b.name === undefined ? "" : b.name.toString()
    const familyA =
      a.family === null || a.family === undefined ? "" : a.family.toString()
    const familyB =
      b.family === null || b.family === undefined ? "" : b.family.toString()

    const familyComparison = familyA.localeCompare(familyB, "en", {
      numeric: true
    })
    if (familyComparison !== 0) {
      return sortOrderType * familyComparison
    }
    return (
      sortOrderType *
      nameA.localeCompare(nameB, "en", {
        numeric: true
      })
    )
  } else {
    const nameA =
      a.name === null || a.name === undefined ? "" : a.name.toString()
    const nameB =
      b.name === null || b.name === undefined ? "" : b.name.toString()
    return (
      nameA.localeCompare(nameB, "en", {
        numeric: true
      }) * sortOrderType
    )
  }
}

export const sortAnalyticsBuildingSummaryPage = (
  data: any,
  currentSortField: string,
  sortOrder: string
) => {
  if (currentSortField === NAME_FIELD) {
    const equipmentsList = data?.filter((eq: any) => eq.equipmentType === ROOT)
    const standAlone = data?.filter(
      (eq: any) => eq.equipmentType === STAND_ALONE
    )

    const sortEquipments = (arr: any, sortOrder: string, multiple = false) => {
      return [...arr]
        .sort((a, b) => sortFunction(a, b, sortOrder, multiple))
        .map((obj) => {
          if (obj.sub) {
            const automatedObjects = obj.sub.filter((c) =>
              c.name.includes(AUTOMATED_TESTS)
            )
            let airHandler = []
            let remaining = obj.sub.filter(
              (c) => !c.name.includes(AUTOMATED_TESTS)
            )
            if (obj?.family === VAV_AIR_SYS) {
              airHandler = sortEquipments(
                obj.sub.filter((c) => c.family.includes(AIR_HANDLER)),
                sortOrder
              )
              remaining = remaining.filter(
                (c) => !c.family.includes(AIR_HANDLER)
              )
            }
            obj.sub = sortEquipments(remaining, sortOrder)
            if (automatedObjects.length > 0) {
              if (obj?.family === VAV_AIR_SYS && airHandler.length > 0) {
                obj.sub =
                  sortOrder === ASC
                    ? automatedObjects.concat(airHandler, obj.sub)
                    : automatedObjects.concat(obj.sub, airHandler)
              } else {
                obj.sub = automatedObjects.concat(obj.sub)
              }
            }
          }
          return obj
        })
    }

    const finalSortedEquipments = sortEquipments(equipmentsList, sortOrder)
    const finalSortedStandalone = sortEquipments(standAlone, sortOrder)
    const final =
      sortOrder === ASC
        ? concat(finalSortedEquipments, finalSortedStandalone)
        : concat(finalSortedStandalone, finalSortedEquipments)
    return final
  }

  if (currentSortField === FAMILY_FIELD) {
    const filteredEquipments = unGroupSystemHeirarchy(data)
      ?.filter((obj) => !obj?.name?.includes(AUTOMATED_TESTS))
      ?.filter((obj) => !(obj?.equipmentType === STAND_ALONE))
    const finalArray = filteredEquipments.sort((a, b) =>
      sortFunction(a, b, sortOrder, true)
    )
    return finalArray
  }
}
const TWO_WEEK_LENGTH = 14
export const getDaysFromEndDate = (endDate: any) => {
  const weekRange = []
  for (let i = 0; i < TWO_WEEK_LENGTH; i++) {
    const d = moment(endDate).subtract(i, "days")
    weekRange.unshift({
      name: d.format("ddd"),
      title: d.format("dddd, MMMM D, YYYY"),
      date: d.format("YYYY-MM-DD"),
      result: NO_DATA_KEY
    })
  }
  return weekRange
}
