import React from "react"
import {
  Checkbox,
  RadioGroup,
  Typography,
  SkeltonLoader,
  SecondaryNav,
  LeftPanel,
  SearchInput,
  RightPanel
} from "src/blitz"
import { t } from "src/translations/help"
import styles from "./ReportList.module.scss"
import clsx from "clsx"
import ListWithSearch from "./ListWithSearch"
import translate, { TranslateComponent } from "src/common/translations"

const ListItem = ({ config = [] }) => {

  return (
    <div className={clsx("row g-0", styles.reportListContainer)}>
      {config.map(
        (
          {
            data,
            mainData,
            loading,
            title,
            handleChange,
            footerConfig,
            searchText,
            selectedItems,
            onChangeSearch,
            isAllChecked,
            showSelectedCount,
            characteristicsInfo = "",
            showInBlue = false,
            count,
            showEmptyMessage,
            emptyMessage,
            disableSelectAll,
            listType = "checkbox",
            translateValues = false
          },
          index: number
        ) => {
          return (
            <div
              key={`list-item-${index}`}
              className={clsx("col-sm", styles.reportListWrapper)}
            >
              <div className={clsx(styles.reportPanel)}>
                <SecondaryNav withBorder>
                  <LeftPanel loading={loading}>{translate(title)}</LeftPanel>
                  <RightPanel loading={loading}>
                    <SearchInput
                      onInputChange={onChangeSearch}
                      onInputClear={() => onChangeSearch("")}
                      searchText={searchText}
                      testId={
                        "rdr-" +
                        title?.split(" ")?.join("-")?.toLowerCase() +
                        "-search-input"
                      }
                    />
                  </RightPanel>
                </SecondaryNav>
                <div className={styles.panelList}>
                  {showEmptyMessage ? (
                    <Typography
                      tagType="div"
                      styleType="p1"
                      color="graphite"
                      className={styles.emptyMessage}
                    >
                      {emptyMessage}
                    </Typography>
                  ) : (
                    <div className={styles.panelListInner}>
                      <div className={styles.reportListHeader}>
                        <SkeltonLoader
                          theme="light-dark"
                          width={200}
                          loading={loading}
                        >
                          <Checkbox
                            name={"select-all-equipments"}
                            label={"Select / DeSelect All"}
                            checked={isAllChecked}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(e?.target?.checked, "all-check")}
                            disabled={disableSelectAll}
                            testId={
                              "rdr-" +
                              title?.split(" ")?.join("-")?.toLowerCase() +
                              "-select-all-check-box"
                            }
                          />
                        </SkeltonLoader>
                        <>
                          {showSelectedCount ? (
                            <Typography
                              tagType="span"
                              styleType="p4"
                              fontType="italicFont"
                              className={styles.countLabel}
                            >
                              <>
                                {selectedItems?.length} {"of"}{" "}
                                {mainData?.length}
                                <TranslateComponent>
                                  {"(Master List)"}
                                </TranslateComponent>
                              </>
                            </Typography>
                          ) : null}
                          <Typography
                            tagType="span"
                            styleType="p4"
                            fontType="italicFont"
                            className={styles.countLabel}
                          >
                            <>
                              <TranslateComponent>
                                {characteristicsInfo}
                              </TranslateComponent> {" "}
                              {showInBlue && (
                                <span className={styles.showInBlue}>blue.</span>
                              )}
                            </>
                          </Typography>
                        </>
                      </div>
                      <div className={styles.reportListBody}>
                        <ListWithSearch
                          data={data}
                          selectedItems={selectedItems}
                          handleChange={handleChange}
                          listType={listType}
                          searchText={searchText}
                          translateValues={translateValues}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {footerConfig ? (
                  <FooterActionBar config={footerConfig} />
                ) : null}
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

const FooterActionBar = ({ config }) => {
  return (
    <div className={clsx("row g-0", styles.reportActionsWrapper)}>
      {config?.type === "radio" ? (
        <div className={`col-sm d-inline-flex ${config?.className || ""}`}>
          <RadioGroup
            radioGroupClassName={styles.radioButtonGroup}
            options={config?.options || []}
            layout="row"
            handleChange={config.handleChange}
            selected={config?.selected || undefined}
          />
        </div>
      ) : (
        <div className={`col-sm d-inline-flex ${config?.className || ""}`}>
          {config?.options.map(
            ({ id, label, checked, name, disabled = false }: any, index) => {
              return (
                <Checkbox
                  key={index}
                  checkboxId={id}
                  name={name}
                  label={label}
                  checked={checked}
                  onChange={config?.handleChange}
                  disabled={disabled}
                  testId={"rdr-" + id?.toLowerCase() + "-check-box"}
                />
              )
            }
          )}
        </div>
      )}
    </div>
  )
}

export default ListItem
