import moment from "moment"
import { useMemo } from "react"
import { useLocation } from "react-router"
import {
  END_DATE,
  START_DATE,
  SUBTRACT,
  TWO_WEEKS
} from "src/blitz/DateRange/constant"
import {
  checkInValidDate,
  getCalculatedDay,
  getDateDifference
} from "src/blitz/DateRange/helper"

export const useQueryDateValues = (diableDateDiffCheck = false) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const queryStartDate = queryParams.get(START_DATE)?.split("-")?.join("/")
  const queryEndDate = queryParams.get(END_DATE)?.split("-")?.join("/")

  const defaultDates = useMemo(
    () =>
      diableDateDiffCheck
        ? checkInValidDate(queryStartDate) ||
          checkInValidDate(queryEndDate) ||
          new Date(queryStartDate) >= new Date(queryEndDate)
        : checkInValidDate(queryStartDate) ||
          checkInValidDate(queryEndDate) ||
          new Date(queryStartDate) >= new Date(queryEndDate) ||
          getDateDifference(queryEndDate, queryStartDate) !== 13,
    [queryStartDate, queryEndDate]
  )
  const startDate = useMemo(
    () =>
      defaultDates
        ? diableDateDiffCheck ? moment(new Date())?.subtract({months:1})?.add({days:1})?.toDate() : getCalculatedDay(SUBTRACT, TWO_WEEKS)
        : new Date(queryStartDate),
    [defaultDates, queryStartDate]
  )
  const endDate = useMemo(
    () => (defaultDates ? new Date() : new Date(queryEndDate)),
    [defaultDates, queryEndDate]
  )

  return {
    queryStartDate,
    queryEndDate,
    defaultDates,
    startDate,
    endDate
  }
}
