import { Suspense } from "react"
import LoadingBar from "react-top-loading-bar"
import { useEffect, useRef } from "react"
export const Loader = () => {
  const ref = useRef(null)
  useEffect(() => {
    ref.current.continuousStart()
  }, [ref])
  return <LoadingBar color="#f11946" ref={ref} height={3} />
}

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  )
}

export default Loadable
