import { SQUARE_SHAPE } from "src/blitz/Charts/constants"
import {
  convertTimeToMinutes,
  getChartObject,
  getTimeIndex,
  intervalToMinutes
} from "../common"
import {
  OCCUPANCY_ACTIVE,
  UNOCCUPIED,
  chartDateFormat,
  constantValues,
  possibleYAxis,
  reduxStoreTimeStampFormat
} from "../../constant"
import moment from "moment"
import { capitalize, isArray, isInteger, isString, toNumber } from "lodash"
import { STACKEDBAR_SORT_BY_PERCENT } from "../../ChartContainer/ChartLegends/Legends/StackedBar/constants"
export const occupancyActivePossibleValues = ["unoccupied", "occupied"]
export const defaultRangeColors = {
  unoccupied: "#646464",
  lower: "#87b1e1",
  upper: "#67d25b",
  above: "#ff001a"
}
export const transformStackedBarChart = ({
  tz,
  startDate,
  endDate,
  intervalMinutes = "15m",
  inputData,
  chartInfo,
  equipmentList
}) => {
  // default range values;
  const defaultBoundaryRange = { lower: 0, upper: 60, above: 95 }
  const equipmentType = chartInfo?.equipmentType
  const calculatedMin = intervalToMinutes(intervalMinutes)
  const data = inputData?.data || {}
  const settings = inputData?.settings || {}
  const additionalInformation: any = {}
  let legends = []
  const newData = {}
  const timestamps = []
  const occupancyActive = chartInfo?.axisConfig?.find((obj) =>
    getChartObject(obj, OCCUPANCY_ACTIVE, equipmentType)
  )

  let oneLoopCompleted = false
  for (const id in data) {
    const paramData = data?.[id]
    const paramName = equipmentList?.find((obj) => obj?.id === id)?.name
    const properties = paramData?.properties

    const legendId = id
    const legendObj = {
      id: legendId,
      name: paramName,
      axisName: "y1",
      show: true,
      visible: true,
      properties: properties,
      type: "bar"
    }
    legends.push(legendObj)

    if (!newData?.[legendId]) {
      newData[legendId] = {
        data: []
      }
    }

    const prevValue = {}
    for (const propertyKey in properties) {
      const property = properties[propertyKey]
      const propertyIndex = property?.index
      prevValue[propertyIndex] = null
    }
    let currentTimestamp = moment(startDate, chartDateFormat)
    const endTimestamp = moment(endDate, chartDateFormat).add(1, "days")
    const localTimeStamp = Boolean(tz)
      ? moment(
        moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
        "MM-DD-YYYY HH:mm"
      )
      : moment()

    while (
      currentTimestamp.isBefore(endTimestamp) &&
      currentTimestamp.isSameOrBefore(localTimeStamp)
    ) {
      const dateKey = currentTimestamp.format("YYYY-MM-DD")
      const extractTime = currentTimestamp.format("HH:mm")
      const timetoMin = convertTimeToMinutes(extractTime)
      const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)

      const dateAvailable = paramData?.values?.[dateKey]

      const valueArray = paramData?.values?.[dateKey]?.[timeIndexPosition]
      const valueArrayIsArray = isArray(valueArray)
      const arrayValues = valueArrayIsArray ? valueArray : []

      const finalValue: any = {
        ...prevValue
      }
      for (const propertyKey in properties) {
        const property = properties[propertyKey]
        const propertyIndex = property?.index
        const isOccupancy = propertyKey === OCCUPANCY_ACTIVE

        if (dateAvailable) {
          const value = arrayValues?.[propertyIndex]
          const checkedValue = isOccupancy
            ? occupancyActivePossibleValues?.includes(
              (value || "")?.toLowerCase()
            )
              ? value
              : null
            : constantValues?.includes(value) ||
              !isFinite(toNumber(value)) ||
              value?.toString()?.startsWith("_")
              ? null
              : parseFloat(value)

          if ((isString(value) && value?.length === 0) || !valueArrayIsArray) {
            finalValue[propertyIndex] = prevValue?.[propertyIndex]
          } else {
            finalValue[propertyIndex] = checkedValue
          }
          if (timeIndexPosition === 95) {
            prevValue[propertyIndex] = null
          } else {
            prevValue[propertyIndex] = finalValue?.[propertyIndex]
          }
        } else {
          finalValue[propertyIndex] = null
          prevValue[propertyIndex] = null
        }
      }

      newData[legendId]?.data.push(finalValue)

      if (timeIndexPosition === 95) {
        for (const propertyKey in properties) {
          const property = properties[propertyKey]
          const propertyIndex = property?.index
          prevValue[propertyIndex] = null
        }
      }

      const dateVal = currentTimestamp.format(reduxStoreTimeStampFormat)
      if (!oneLoopCompleted) {
        timestamps.push(dateVal)
      }

      currentTimestamp = currentTimestamp.add(calculatedMin, "minutes")
    }
    oneLoopCompleted = true
  }

  const propKeys = Object.keys(settings)
  const defaultPropKeys = Object.keys(defaultBoundaryRange)
  if (propKeys?.length > 0) {
    additionalInformation["settings"] = {}
    let boundaryIndex = 0
    while (boundaryIndex <= defaultPropKeys?.length - 1) {
      const key = propKeys[boundaryIndex - 1]?.toLowerCase()
      const initialIndex = boundaryIndex === 0
      const isLow = key?.includes("low")
      const settingsKey = initialIndex ? "lower" : isLow ? "upper" : "above"
      const value = isInteger(parseInt(settings?.[propKeys[boundaryIndex - 1]]))
        ? settings?.[propKeys[boundaryIndex - 1]]
        : defaultBoundaryRange?.[settingsKey]
      const settingsValue = initialIndex ? 0 : value
      additionalInformation["settings"][settingsKey] = toNumber(settingsValue)
      const legendObj = {
        id: settingsKey,
        excelName: initialIndex
          ? "Lower Control Range"
          : isLow
            ? "Upper Control Range"
            : "Above Control Range",
        color: initialIndex ? "#87b1e1" : isLow ? "#67d25b" : "#ff001a",
        settingsKey: settingsKey,
        axisName: "y1",
        show: true,
        visible: true,
        disabled: true,
        type: "boundary",
        shape: SQUARE_SHAPE,
        boundaryPosition: initialIndex ? 0 : isLow ? 1 : 2
      }
      legends.unshift(legendObj)
      boundaryIndex++
    }
  } else {
    additionalInformation["settings"] = defaultBoundaryRange
  }

  const calculatedValues = {}
  const boundaryObj = additionalInformation["settings"]
  legends
    ?.filter((obj: any) => obj?.type === "bar")
    ?.forEach((obj: any) => {
      const data = newData?.[obj?.id]?.data || []
      const oaIndex = obj?.properties?.[OCCUPANCY_ACTIVE]?.index?.toString()
      const paramIndex = oaIndex === "0" ? "1" : "0"
      const paramObj = calculatedValues?.[obj?.id]

      if (!paramObj) {
        calculatedValues[obj?.id] = {
          lower: 0,
          upper: 0,
          above: 0
        }
        if (occupancyActive) {
          calculatedValues[obj?.id].unoccupied = 0
        }
      }
      data?.forEach((value) => {
        const paramValue = parseInt(value[paramIndex]?.toString())
        if (value?.[oaIndex]?.toLowerCase() === UNOCCUPIED || isFinite(paramValue)) {
          if (value?.[oaIndex]?.toLowerCase() === UNOCCUPIED) {
            calculatedValues[obj?.id].unoccupied++
          } else if (paramValue < boundaryObj.upper) {
            calculatedValues[obj?.id].lower++
          } else if (paramValue < boundaryObj.above) {
            calculatedValues[obj?.id].upper++
          } else {
            calculatedValues[obj?.id].above++
          }
        }
      })
    })

  if (occupancyActive) {
    const legendObj = {
      id: UNOCCUPIED,
      name: capitalize(UNOCCUPIED),
      color: "#646464",
      settingsKey: UNOCCUPIED,
      axisName: "y1",
      show: true,
      visible: true,
      type: "boundary",
      shape: SQUARE_SHAPE,
      boundaryPosition: -1
    }
    legends.unshift(legendObj)
  }

  const axisList = chartInfo?.axisConfig?.map((obj) => {
    const isY = possibleYAxis?.includes(obj?.axisName)
    if (isY) {
      return {
        ...obj,
        range: [0, 100]
      }
    } else {
      return obj
    }
  })
  const aggregated = occupancyActive
    ? "VAV Air System"
    : "Chilled Water Distribution Valve"

  legends = legends?.map((obj) => {
    if (obj?.type === "boundary") {
      const name =
        obj?.id === "lower"
          ? obj?.excelName +
          " " +
          `damper < ${additionalInformation["settings"]?.upper}% open`
          : obj?.id === "upper"
            ? obj?.excelName +
            " " +
            `damper between ${additionalInformation["settings"]?.upper}% and ${additionalInformation["settings"]?.above}% open`
            : obj?.id === "above"
              ? obj?.excelName +
              " " +
              `damper > ${additionalInformation["settings"]?.above}% open`
              : obj?.name

      return {
        ...obj,
        name: name
      }
    } else {
      return obj
    }
  })

  const noDataArray = legends?.map((obj) => {
    const noData = (newData?.[obj?.id]?.data || [])?.every((e) =>
      Object.values(e || {})?.every((e) => !Boolean(e))
    )
    return noData
  })

  const isNoData = noDataArray?.every((e) => Boolean(e))

  return {
    chartData: {
      data: isNoData ? {} : newData
    },
    chartProps: {
      barData: isNoData ? {} : calculatedValues,
      legends: legends,
      aggregated: aggregated,
      axisList: axisList,
      sortBehaviour: STACKEDBAR_SORT_BY_PERCENT,
      settings: additionalInformation?.settings || defaultBoundaryRange,
      noDataAvailable : isNoData ? true : false,
      isdataprocessed : true
    }
  }
}
