import React, { useState, useMemo } from "react"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import styles from "./ConnectivityPopup.module.scss"
import translate, { TranslateComponent } from "src/common/translations"
import {
  getTimeStringByTZ,
  availableOptions,
  TILE_DATE_FORMAT,
  CONNECTED,
  NOT_CONNECTED,
  NA,
  NOT_AVAILABLE,
  AVAILABLE,
  CONNECTIVITY,
  REMOTE,
  NOT_SUPPORTED
} from "./helper"

function ConnectivityPopup({
  type,
  status,
  icon,
  building,
  testId,
  filteredDevices
}) {
  const { tz } = building
  const [show, setShow] = useState(false)
  const legendStatus = useMemo(() => {
    return `${type} : ${availableOptions[status]}`
  }, [type, availableOptions, status])

  const popover = (
    <Popover
      show={show}
      className={`${styles.connectivityPopoverPopover} `}
      data-testid={testId}
    >
      <Popover.Header className={styles.popoverHeader}>
        <span
          title={"Close"}
          onClick={() => setShow(!show)}
          className={`home-legend-icon icon-close ${styles.closeIcon}`}
        ></span>
        <div className={styles.headerTitle}>
          <strong>
            <TranslateComponent>{type}</TranslateComponent>
          </strong>:&nbsp; 
            <TranslateComponent>{availableOptions[status]}</TranslateComponent>
        </div>
      </Popover.Header>
      <Popover.Body className={styles.popoverBody}>
        {filteredDevices?.map((value: any, index: number) => (
          <div
            key={value.id}
            className={`${styles.connectionBody} ${styles.popoverBody} ${filteredDevices?.length != index + 1
                ? [`${styles.borderBottom}`]
                : ""
              }`}
          >
            <div>
              <strong>
                 <TranslateComponent>Device Name</TranslateComponent>
              </strong>:&nbsp; {value?.name || NA}
            </div>
            <div>
              <strong>
                <TranslateComponent>Type</TranslateComponent>
              </strong>: &nbsp;{value?.type || NA}
            </div>
            <div>
              <strong>
                <TranslateComponent>Serial number</TranslateComponent>
              </strong>:&nbsp; {value?.serial || NA}
            </div>
            {type === CONNECTIVITY && (
              <div>
                <strong>
                  <TranslateComponent>Last Connection</TranslateComponent>
                </strong>:&nbsp;{" "}
                {value?.deviceConnection?.connectionTimeUTC && tz
                  ? getTimeStringByTZ(
                    value?.deviceConnection?.connectionTimeUTC,
                    tz,
                    TILE_DATE_FORMAT
                  )
                  : NA}
              </div>
            )}
            {type === CONNECTIVITY && (
              <div>
                <strong>
                  <TranslateComponent>Status</TranslateComponent>
                </strong>:&nbsp;
                <span
                  className={`${value?.connectionStatus === CONNECTED
                      ? [`${styles.deviceConnected}`]
                      : [`${styles.deviceDisconnected}`]
                    }`}
                >
                 <TranslateComponent>{value?.connectionStatus}</TranslateComponent>
                </span>
              </div>
            )}
            {type === REMOTE && (
              <div>
                <strong>
                  <TranslateComponent>Status</TranslateComponent>
                </strong>:&nbsp;
                <span
                  className={`${value?.remoteStatus === NOT_SUPPORTED
                      ? [`${styles.deviceNotSupported}`]
                      : value?.remoteStatus === AVAILABLE
                        ? [`${styles.deviceConnected}`]
                        : [`${styles.deviceDisconnected}`]
                    }`}
                >
                  <TranslateComponent>{value?.remoteStatus}</TranslateComponent>
                </span>
              </div>
            )}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  )

  const OverlayPopover = () => (
    <OverlayTrigger
      rootClose
      trigger={"click"}
      placement="bottom"
      overlay={popover}
      flip
    >
      <span title={translate(legendStatus)} className={`home-legend-icon ${icon}`}>
        {[NOT_AVAILABLE, NOT_CONNECTED].includes(availableOptions[status]) ? (
          <span className={"icon-close"}></span>
        ) : null}
      </span>
    </OverlayTrigger>
  )

  return <OverlayPopover />
}
export default ConnectivityPopup
