import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { skeltonLightDark } from "src/constants/skelton"
import { generateArray } from "src/utils/CommonMethods"
import { TableCell, TableRow } from "src/blitz/Table"
import { ILoadingRows } from "../types"

const LoadingRows = (props: ILoadingRows) => {
  const { columnsLength, loadingRowsLength = 5 } = props

  const dummy = generateArray(loadingRowsLength, {})
  const colData = generateArray(columnsLength, {})
  return (
    <>
      {dummy?.map((item: any, index: number) => {
        return (
          <TableRow key={"rowIndex_" + index}>
            {colData.map((columnObj: any, columnIndex: number) => {
              return (
                <TableCell key={"columnIndex_" + columnIndex}>
                  <SkeletonTheme
                    height={"100%"}
                    width={"100%"}
                    {...skeltonLightDark}
                  >
                    <Skeleton />
                  </SkeletonTheme>
                </TableCell>
              )
            })}
          </TableRow>
        )
      })}
    </>
  )
}

export default LoadingRows
