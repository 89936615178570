import clsx from "clsx"
import modulestyles from "./SecondaryNav.module.scss"
import { SkeltonLoader } from "src/blitz"

export const SecondaryNav = ({
  children,
  withBorder = false,
  showTopBorder = true,
  className = "",
  onlyCenterPanel = false
}: any) => {
  return (
    <div
      className={clsx(modulestyles.secondaryNav, className, {
        [modulestyles.withBorder]: withBorder,
        [modulestyles.topBorder]: showTopBorder,
        [modulestyles.onlyCenterWrapper]: onlyCenterPanel
      })}
    >
      {children}
    </div>
  )
}

export const LeftPanel = ({ children, loading = false }) => {
  return (
    <div className={modulestyles.leftWrapper}>
      <SkeltonLoader theme="light-dark" width={200} loading={loading}>
        {children}
      </SkeltonLoader>
    </div>
  )
}
export const CenterPanel = ({ children, loading = false }) => {
  return (
    <div className={modulestyles.centerWrapper}>
      <SkeltonLoader theme="light-dark" width={200} loading={loading}>
        {children}
      </SkeltonLoader>
    </div>
  )
}
export const RightPanel = ({
  children,
  loading = false,
  isHorizontal
}: any) => {
  return (
    <div
      className={clsx(modulestyles.rightWrapper, {
        [modulestyles.horizontal]: isHorizontal
      })}
    >
      <SkeltonLoader theme="light-dark" width={200} loading={loading}>
        {children}
      </SkeltonLoader>
    </div>
  )
}
