import { createSlice } from "@reduxjs/toolkit"
import { HomeTypes } from "../types/homeTypes"
import { TStore } from "../Store"
import { ACTIONS } from "src/constants"
import { LOADING } from "src/components/shared/Charts/constant"

const initialState: HomeTypes = {
  buildings: {
    searchText: "",
    loading: true,
    data: [],
    nextToken: null,
    responseTime: 0,
    total: 0,
    hasMore: false
  },
  hasMore: false,
  scollLoading: false,
  selectedBuildings: [],
  selectAll: false,
  singleSelect: false,
  pageView: "tiles",
  breadcrumbs: [],
  headerTitle: "default",
  selectedLanguage: { language: "English", value: "en-US" },
  access: new Map([]),
  accessLoading: false,
  buildingsAggregates: {
    loading: true,
    data: [],
    error: false
  },
  downloadBuildingVariables: {},
  buildingFilter: {},
  status: {
    error: false,
    completed: false
  },
  currentView: "All",
  selectedBuildingsEquipmentTypeList: [],
  generateBuildingsReport: false,
}

export const homeSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    setUserAccess: (state, action) => {
      if (action.payload.type === LOADING) {
        state.accessLoading = true
      } else {
        state.accessLoading = false
        state.access = action.payload?.data
      }
    },
    setSearch: (state, action) => {
      state.buildings.searchText = action.payload
    },
    setBuildings: (state, action) => {
      const {
        type = "Sucesss",
        data = [],
        nextToken = null,
        responseTime = 0,
        total = 0,
        hasMore = false
      } = action.payload
      switch (type) {
        case "Loading":
          state.buildings = {
            loading: true,
            data: [],
            nextToken,
            responseTime,
            searchText: state.buildings.searchText,
            hasMore: false,
            error: undefined,
            total: 0
          }
          break
        case "Success":
          if (state.selectAll) {
            state.selectedBuildings = data?.map(({ id }) => id)
          }
          state.buildings = {
            loading: false,
            data,
            responseTime,
            nextToken,
            searchText: state.buildings.searchText,
            total,
            hasMore,
            error: false
          }
          break
        case "Failure":
          state.buildings = {
            loading: false,
            data,
            responseTime,
            nextToken,
            searchText: state.buildings.searchText,
            error: true,
            hasMore: false,
            total: 0
          }
          break
        case "UpdatingResponseTime":
          state.buildings.responseTime = responseTime
          break
      }
    },
    selectAllBuildingsAction: (state, action) => {
      state.selectAll = action.payload
    },
    selectBuildingAction: (state, action) => {
      state.selectedBuildings = action.payload
    },
    setPageView: (state, action) => {
      state.pageView = action.payload
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
    setHeaderTitle: (state, action) => {
      state.headerTitle = action.payload
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload
    },
    setInfinitLoading: (state, action) => {
      state.scollLoading = action.payload
    },
    setCurrView: (state, action) => {
      state.currentView = action.payload
    },
    setBuildingAggregates: (state, action) => {
      const { type = "Sucesss", data = [] } = action.payload
      switch (type) {
        case "Loading":
          state.buildingsAggregates = {
            loading: true,
            data: [],
            error: undefined
          }
          break
        case "Success":
          state.buildingsAggregates = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.buildingsAggregates = {
            loading: false,
            data,
            error: true
          }
          break
      }
    },
    setBuildingFilter: (state, action) => {
      state.buildingFilter = { ...action.payload }
    },
    setDownloadBuildingVariables: (state, action) => {
      state.downloadBuildingVariables = { ...action.payload }
    },
    setGenerateBuildingsReport: (state, action) => {
      state.generateBuildingsReport = action.payload
    },
    setSelectedBuildingsEquipmentTypeList: (state, action) => {
      state.selectedBuildingsEquipmentTypeList = action.payload
    },
    setReportStatus: (state, { payload }) => {
      switch (payload) {
        case ACTIONS.COMPLETE:
          state.status = {
            ...initialState.status,
            completed: true
          }
          break
        case ACTIONS.ERROR:
          state.status = {
            ...initialState.status,
            error: true
          }
          break
        case ACTIONS.RESET:
          state.status = {
            ...initialState.status
          }
          break
      }
      return state
    },

  }
})

export const {
  setSearch,
  setBuildings,
  selectAllBuildingsAction,
  selectBuildingAction,
  setPageView,
  setBreadcrumbs,
  setHeaderTitle,
  setSelectedLanguage,
  setInfinitLoading,
  setBuildingAggregates,
  setBuildingFilter,
  setDownloadBuildingVariables,
  setReportStatus,
  setGenerateBuildingsReport,
  setUserAccess,
  setCurrView,
  setSelectedBuildingsEquipmentTypeList
} = homeSlice.actions

export const selectBuildingsList = (state: TStore) => state.home.buildings.data
export const selectBuildings = (state: TStore) => state.home.buildings
export const selectBuildingsNextToken = (state: TStore) =>
  state.home.buildings.nextToken
export const selectHasMore = (state: TStore) => state.home.buildings.hasMore
export const selectBuildingsChecked = (state: TStore) =>
  state.home.selectedBuildings
export const selectBuildingsSlice = (state: TStore) => state.home
export const selectPageView = (state: TStore) => state.home.pageView
export const selectSearchText = (state: TStore) =>
  state.home.buildings.searchText
export const selectBuidingsTotal = (state: TStore) => state.home.buildings.total
export const selectLanguage = (state: TStore) => state.home.selectedLanguage
export const selectScollLoading = (state: TStore) => state.home.scollLoading
export const selectBuildingAggregates = (state: any) => state.home.buildingsAggregates
export const selectBuildingFilter = (state: any) => state.home.buildingFilter
export const selectDownloadBuildingVariables = (state: any) => state.home.downloadBuildingVariables
export const selectReportStatus = (state: any) => state.building.status
export const selectGenerateBuildingsReport = (state: any) => state.building.generateBuildingsReport
export const selectUserAccess = (state: TStore) => state.home.access
export const selectUserAccessLoading = (state: TStore) => state.home.accessLoading
export const selectCurrentView = (state: TStore) => state.home.currentView
export const selectSelectedBuildingsEquipmentTypeList = (state: TStore) => state.home.selectedBuildingsEquipmentTypeList