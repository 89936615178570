import { useState, Fragment, useMemo } from "react"
import { Dropdown } from "react-bootstrap"
import { debounce } from "lodash"
import clsx from "clsx"
import styles from "./MultiLevelDropdown.module.scss"
import { Link } from "react-router-dom"
import Typography from "../Typography"
import { useTranslation } from "react-i18next"
import DOMPurify from "dompurify"
import translate, { TranslateComponent } from "src/common/translations"

type ActionTypes = {
  noBorder?: boolean
  title?: string
  actionClassName?: string
  leftIcon?: string
  setting?: boolean
  enableScroll?: boolean
  options?: Option[]
  handler?: any
  testId?: string
  icon?: string
  iconColor?: string
  search?: boolean
  searchPlaceHolder?: string
  disabled?: boolean
  onSearch?: any
  translateCurrentViewOff?: boolean,
  customTranslationOn?: boolean,
}

type Option = {
  type: "text" | "divider" | "link" | "button" | "iconButton"
  isExternalLink?: boolean
  disabled?: boolean
  iconLeft?: boolean
  icon?: string
  iconColor?: string
  title?: string
  link?: string
  id?: string
  value?: string
  // eslint-disable-next-line @typescript-eslint/no-empty-function, no-unused-vars
  handler?: any
  options?: Option[]
  hidden?: boolean
  iconClassname?: string
  thicknessClassname?: string,
  active?: any,
  customTranslationOn?: boolean,
  translateCurrentViewOff?: boolean,
}
type DropDownTree = {
  item: Option
  key?: number
  handlerFunction?: any
  translateCurrentViewOff?: boolean,
  customTranslationOn?: boolean,
}
const MultiLevelDropdown = ({
  title = "",
  setting = false,
  enableScroll = false,
  icon = " ",
  iconColor = "",
  options = [],
  noBorder = false,
  actionClassName = "",
  handler,
  leftIcon = "",
  testId = "",
  search = false,
  searchPlaceHolder = "",
  disabled = false,
  onSearch,
  translateCurrentViewOff = false,
  customTranslationOn = false
}: ActionTypes) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState("")

  const filterSubOptions = (options) => {
    return options?.filter((item) =>
      item?.options
        ? filterSubOptions(item?.options)
        : item?.title?.toUpperCase()?.includes(searchText?.toUpperCase())
    )
  }

  const filteredOptions = useMemo(
    () => (searchText ? filterSubOptions(options) : options),
    [searchText, options]
  )
  const getTranslatedTitle = (title: string) => {
    if (customTranslationOn && title.includes(":"))  {
      // Split at the colon and trim parts
      const parts = title.split(":").map((part) => part.trim());
    
      // Assign variables for before and after the colon
      const equipmentType = parts[0];
      const equipmentName = parts.length > 1 ? parts.slice(1).join(":") : "";
    
      return (
        <>
          {/* Translate the equipmentType (before the colon) */}
          <TranslateComponent>{equipmentType}</TranslateComponent>
          {": "}
    
          {/* If the equipmentName matches "Standalone", translate it */}
          {equipmentName.includes("Standalone") ? (
            <TranslateComponent>{equipmentName}</TranslateComponent>
          ) : (
            equipmentName
          )}
        </>
      );
    }
    return <TranslateComponent>{title}</TranslateComponent>
  };
  
   
  return (
    <>
      <Dropdown
        autoClose
        className={`${clsx(
          styles.dropdown,
          disabled ? styles.dropdown_disabled : ""
        )}`}
        data-testid={testId}
      >
        <div>
          <Dropdown.Toggle
            className={`${styles.dropdownTtoggle} ${clsx(
              { [styles.noBorder]: noBorder },
              actionClassName
            )}`}
          >
            {setting && (
              <span
                className={`${styles.btnIconRight} icon icon-actions`}
              ></span>
            )}
            {leftIcon && (
              <span
                className={`${styles.leftDropdownIcon} ${DOMPurify.sanitize(
                  leftIcon
                )}`}
              ></span>
            )}
            {options && (
              <span style={{ color: iconColor }} className={`btn-icon ${icon}`}>
                &nbsp;
              </span>
            )}
            {translateCurrentViewOff ? title : getTranslatedTitle(title || "-- Select One --")}
            <span className={styles.caret}></span>
          </Dropdown.Toggle>
        </div>
        <Dropdown.Menu
          className={clsx(
            styles.dropdownMenu,
            enableScroll ? styles.dropdownScroll : ""
          )}
        >
          {search && (
            <input
              type="text"
              placeholder={searchPlaceHolder}
              onChange={(e) => {
                if (onSearch) {
                  onSearch(e.target.value)
                } else {
                  setSearchText(e.target.value)
                }
              }}
            />
          )}
          {filteredOptions?.map((item: Option, index) => (
            <Fragment key={index}>
              <DropdownItemTree item={item} handlerFunction={handler} translateCurrentViewOff={translateCurrentViewOff} />
            </Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

const DropdownItemTree = ({ item, handlerFunction, translateCurrentViewOff }: DropDownTree) => {
  const {
    type = "text",
    disabled = false,
    hidden = false,
    title = "",
    options = [],
    iconLeft = false,
    icon = "",
    iconColor = "",
    link = "title",
    handler,
    value = "",
    iconClassname = "",
    thicknessClassname = "",
    active,
    customTranslationOn = false,
  } = item

  const handlerFunc = handler ? handler : handlerFunction

  const translateValueOff = customTranslationOn || translateCurrentViewOff

  const [showDropdown, setShowDropdown] = useState(false)
  const isChildPresent = useMemo(() => options?.length > 0, [options])
  const closeSubNav = debounce(() => setShowDropdown(false), 500)
  const openSubNav = (e: any) => {
    if (e?.type === "click") e.stopPropagation()
    setShowDropdown(true)
    closeSubNav.cancel()
  }
  const getTitle = (title: string) => {
    if (customTranslationOn && title.includes(":")) {
      const [equipmentType, equipmentName] = title.split(":").map((part) => part.trim());
  
      return (
        <>
          <TranslateComponent>{equipmentType || title}</TranslateComponent>
          {": "}
          {equipmentName.includes("Standalone") ? (
            <TranslateComponent>{equipmentName}</TranslateComponent>
          ) : (
            equipmentName
          )}
        </>
      );
    }
  
    return title; // Return the full title if not a language string
  };

  if (type === "divider")
    return <Dropdown.Divider className={styles.dropdownDivider} />
  if (type === "link")
    return (
      <Dropdown.Item
        as="div"
        className={`${styles.dropdownItem} ${clsx({
          [styles.disabled]: disabled
        })}`}
        onClick={handlerFunc}
      >
        <Link to={link}>{title}</Link>
      </Dropdown.Item>
    )
  if (type === "button")
    return (
      <Dropdown.Item
        as="button"
        onClick={() => handlerFunc(value, item)}
        className={clsx(styles.dropdownItem, styles.flex, {
          [styles.disabled]: disabled,
          [styles.hidden]: hidden,
          [styles.iconLeft]: iconLeft
        })}
      >
        <div>{!translateValueOff ? <TranslateComponent>{title}</TranslateComponent> : getTitle(title)}</div>
        {active && (<span
          style={{ color: "green" }}
          className={`${styles.iconFontSize} btn-icon icon-checkmark`}
        ></span>)}
        {options && (
          <span
            style={{ color: iconColor ? iconColor : "" }}
            className={`${styles.iconFontSize} btn-icon ${icon}`}
          ></span>
        )}

      </Dropdown.Item>
    )
  if (type === "iconButton")
    return (
      <Dropdown.Item
        as="button"
        onClick={() => handlerFunc(value, item)}
        style={{ height: "20px" }}
        className={`${styles?.dropdownItem} ${styles?.dropdownIconItem} ${styles?.flex} ${clsx({
          [styles?.disabled]: disabled
        })} ${clsx({ [styles?.hidden]: hidden })} `}
      >

        {active && <span className={styles?.enlarged}>&#x2713;</span>}
        {title && <span>{title}</span>}
        <div style={{ flex: "1" }} className={clsx(iconClassname, thicknessClassname)}>{""}</div>
      </Dropdown.Item>
    )
  return (
    <Fragment>
      {!isChildPresent && type == "text" && (
        <Dropdown.Item
          as="div"
          className={clsx(styles.dropdownItem, styles.flex, {
            [styles.disabled]: disabled,
            [styles.iconLeft]: iconLeft
          })}
        >
          {title}{" "}
          {options && <span className={`btn-icon ${icon}`}>&nbsp;</span>}
        </Dropdown.Item>
      )}
      {isChildPresent && (
        <Dropdown.Item
          as="a"
          onClick={openSubNav}
          onMouseLeave={closeSubNav}
          onMouseEnter={openSubNav}
          className={styles.dropdownItem}
        >
          <Dropdown
            autoClose
            className={styles.subMenu}
            show={showDropdown}
            drop={"end"}
            align={"start"}
          >
            <Dropdown.Toggle className={styles.dropdownToggle}>
              <Typography
                className={styles.buttonText}
                tagType="span"
                styleType="p4"
                translateText={true}
              >
                {title}
              </Typography>
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.dropdownMenu}>
              {options?.map((sub: Option, i) => (
                <DropdownItemTree
                  key={i}
                  item={sub}
                  handlerFunction={handlerFunc}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Dropdown.Item>
      )}
    </Fragment>
  )
}
export default MultiLevelDropdown
