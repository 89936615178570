import { USER_EVENTS as EQUIPMENT_SUMMARY, PROPERTIES_TO_SEND_BY_EVENT as EQUIPMENT_SUMMARY_PROPERTIES_TO_SEND_BY_EVENT } from "./findings/user-events"
import { USER_EVENTS as LOGIN} from "./findings/user-events"
import { USER_EVENTS as SERVICE_ADVISORY} from "./findings/user-events"
import { USER_EVENTS as BUILDING_SUMMARY} from "./findings/user-events"
import { USER_EVENTS as PERFORMANCE_CHART} from "./findings/user-events"
import { USER_EVENTS as TRANECONNECT_REDIRECT} from "./findings/user-events"

export const USER_EVENTS = {
	...LOGIN,
	...EQUIPMENT_SUMMARY,
	...SERVICE_ADVISORY,
	...BUILDING_SUMMARY,
	...PERFORMANCE_CHART,
	...TRANECONNECT_REDIRECT,
}

export const PROPERTIES_TO_SEND_BY_EVENT = {
	...EQUIPMENT_SUMMARY_PROPERTIES_TO_SEND_BY_EVENT,
}
