export const PRIMARY_OFFERINGS = {
	EP: "EP",
	BP: "BP",
	GRIDFLEX: "GF",
	TS: "TS",
	RA: "RA",
	ES: "ES",
	IS: "IS",
	CAP: "CAP",
	WOM: "WOM",
	MST: "MST",
	SHARED: "SHARED"
}

export const CLIENTS = {
    CLIENTTAG: "TCUI"
}
