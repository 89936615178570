import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

// Check whether the code is running on localhost, such as localhost:3000, to enable React Query Developer tool for development purpose
const isLocalHost = window?.location?.host?.includes("localhost:") ?? false

//query client instance
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retryOnMount: false,
      staleTime: Infinity
      // gcTime: Infinity
    }
  }
})

/**
 * Provider Component
 *
 * React Query setup
 *
 * @export
 * @param {*} {children}
 * @return {*}
 */
export default function QueryProvider({ children }: any): any {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* For Developers - Enable this while developing */}
      {isLocalHost && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}
