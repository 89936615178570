export const fetchLocalConfigFile = async (fileName: string) => {
  let isSuccess: any = false
  try {
    const response = await fetch(`${window.location.origin}/${fileName}`)
    const data = await response.json()
    if (data) {
      isSuccess = data
    } else {
      isSuccess = false
    }
  } catch (err) {
    isSuccess = false
  }
  return isSuccess
}
