import { SearchInput } from "src/blitz/SearchInput"
import styles from "./TextFilter.module.scss"
import Checkbox from "src/blitz/Checkbox/Checkbox"
import { SELECT_ALL_EQUIPMENTS } from "../../constants"
import { t } from "src/translations/help"
import ListWithSearch from "src/components/shared/ReportDialog/RawDataReport/ReportList/ListWithSearch"
import Typography from "src/blitz/Typography"
import { Spinner } from "src/blitz/Spinner"
import { TranslateComponent } from "src/common/translations"
//import InfiniteScroll from "react-infinite-scroll-component";

const TextFilter = (props) => {
  const {
    options,
    selected,
    searchFilterValue,
    allChecked,
    handleSelection = () => null,
    onInputClear = () => null,
    onInputChange = () => null,
    aggregatesLoading = false,
    translateValues,
  } = props


  return (
    <>
      <div className={styles.searchWrapper}>
        <SearchInput
          onInputChange={(value: string) => onInputChange(value)}
          onInputClear={onInputClear}
          searchText={searchFilterValue}
        />
      </div>
      <div>
        {aggregatesLoading && (
          <div className={styles.spinner}>
            <Spinner loading={aggregatesLoading} />
          </div>
        )}
        {options?.length ? (
          <>
            {!aggregatesLoading && (
              <div className={styles.checkBoxStyles}>
                <Checkbox
                  name={SELECT_ALL_EQUIPMENTS}
                  label={t("common.selectandDeSelectAll")}
                  checked={allChecked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleSelection(e?.target?.checked)
                  }
                />
              </div>
            )}
            {/* <div id="facility-scroll-advisory-filter" className={styles.optionsWrapperLone}></div> */}
            {
              !aggregatesLoading && (
                //     <InfiniteScroll
                //     dataLength={options.length}
                //     //next={() => null}
                //     //hasMore={hasMoreAggregates}
                //     style={{ overflow: "hidden" }}
                //     loader={<Spinner loading={aggregatesLoading} />}
                //     scrollableTarget="facility-scroll-advisory-filter"
                //     inverse={false}
                // >
                <div className={styles.filterbodyWrapper}>
                  <ListWithSearch
                    data={options}
                    selectedItems={selected}
                    handleChange={handleSelection}
                    listType={"checkbox"}
                    searchText={searchFilterValue}
                    translateValues={translateValues}
                  />
                </div>
              ) //</></InfiniteScroll>
            }
          </>
        ) : (
          !aggregatesLoading && (
            <Typography tagType="span" styleType="p4">
              <TranslateComponent>No Records Found</TranslateComponent>
            </Typography>
          )
        )}
      </div>
    </>
  )
}

export default TextFilter
