import { useMemo } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import {
  skeltonDark,
  skeltonWhite,
  skeltonLightDark
} from "src/constants/skelton"
type SkeltonType = {
  loading?: boolean
  children?: React.ReactNode
  width?: string | number
  height?: number
  theme?: "dark" | "white" | "light-dark"
  className?: string
}
const SkeletonLoader = ({
  loading,
  children,
  width = 100,
  height = 15,
  theme = "dark",
  className = ""
}: SkeltonType) => {
  const getTheme = (theme: string) => {
    switch (theme) {
      case "dark":
        return skeltonDark
      case "light-dark":
        return skeltonLightDark
      case "white":
        return skeltonWhite
    }
  }
  const themeObj = useMemo(() => getTheme(theme), [theme])
  return (
    <SkeletonTheme width={width} height={height} {...themeObj}>
      {loading ? <Skeleton className={className} /> : children}
    </SkeletonTheme>
  )
}

export default SkeletonLoader
