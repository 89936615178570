// import React, {  Fragment } from "react";
// import DOMPurify from 'dompurify';
import styles from "./Button.module.scss"
import { IButtonVariant, IDefaultButton, ISizeVariant } from "./types"
import clsx from "clsx"
const NewButton = (props: IDefaultButton) => {
  const {
    variant = "dark",
    children,
    btnSize = "large",
    hoverVariant,
    // eslint-disable-next-line no-unused-vars
    // icon = "",
    // iconPlacement = "",
    className = "",
    activeClass = false,
    testId = "",
    ...args
  } = props
  // const getIcon = (icon: any, text: any) => {
  //   if (icon) {
  //     if (iconPlacement && iconPlacement === "after") {
  //       return <Fragment>{text}<span style={{ margin: 3 }} className={`btn-icon ${icon}`}></span></Fragment>
  //     } else {
  //       return <Fragment><span style={{ margin: 3 }} className={`btn-icon ${icon}`}></span>{" "}{text}</Fragment>
  //     }
  //   } else {
  //     return
  //   }
  // }
  return (
    <button
      data-testid={testId}
      className={clsx(
        className,
        getButtonSize(btnSize),
        getElementTag(variant),
        getElementHover(hoverVariant),
        {
          [styles.buttonDarkActive]: activeClass && variant === "dark"
          // [styles.buttonGreen]: variant === "green",
          // [styles.buttonMini]: btnSize === "mini",
        }
      )}
      {...args}
    >
      {children}
    </button>
  )
}

const getElementTag = (styleType: IButtonVariant) => {
  switch (styleType) {
    case "blue":
      return styles.buttonBlue
    case "green":
      return styles.buttonGreen
    case "red":
      return styles.buttonRed
    case "transparent":
      return styles.buttonTransparent
    default:
      return styles.buttonDark
  }
}

const getElementHover = (styleType: IButtonVariant) => {
  switch (styleType) {
    case "blue":
      return styles.buttonBlue
    case "green":
      return styles.buttonGreen
  }
}

const getButtonSize = (size: ISizeVariant) => {
  switch (size) {
    case "mini":
      return styles.buttonMini
    case "icon-button":
      return styles.iconButton
    default:
      return styles.button
  }
}

export default NewButton
