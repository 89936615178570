import {
  RadioGroup,
  SecondaryNav,
  LeftPanel,
  SearchInput,
  RightPanel
} from "src/blitz"
import { useSelector, useDispatch } from "react-redux"
import styles from "./DigitalInspectionReport.module.scss"
import {
  getEquipmentLoader,
  getEquipments,
  getSelectedEquipments,
  setSelectedEquipments
} from "src/redux/slicers/reportSlice"
import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import { SearchRegex } from "src/utils/CommonMethods"
import { sortByAlphanumericSpecialChars } from "src/blitz/DataGridTable/helper"
import { selectEquipmentSummary } from "src/redux/slicers/equipmentSummarySlice"
import {TranslateComponent} from "src/common/translations"
const ALLOW_MULTISELECT_EQUIPMENTS = [
  "VRF-System"
]

const EquipmentList = (props: any) => {
  const { selectedEquipmentType } = props
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const equipLoading = useSelector(getEquipmentLoader)
  const equipments = useSelector(getEquipments)
  const selectedItem = useSelector(getSelectedEquipments)
  const { data: selectedEquipment } = useSelector(selectEquipmentSummary)
  const handleSearch = (value: string) => {
    setSearchText(value)
  }

  // const handleEquipmentChange = (equipmentId) => {
  //   // Find the equipment by ID or default to an empty object
  //   const selectedEquipment = equipments.find(item => item?.id === equipmentId) || {};

  //   // Function to update the selected equipments state
  //   const updateSelectedEquipments = (newEquipments) => {
  //     dispatch(setSelectedEquipments(newEquipments));
  //   };

  //   // Check if the equipment type allows multiple selections
  //   if (ALLOW_MULTISELECT_EQUIPMENTS.includes(selectedEquipmentType)) {
  //     // Determine if the equipment is already selected
  //     const isEquipmentSelected = selectedItem.some(item => item.id === selectedEquipment.id);

  //     // Update the state based on whether the equipment is already selected
  //     isEquipmentSelected
  //       ? updateSelectedEquipments(selectedItem.filter(item => item.id !== selectedEquipment.id))
  //       : updateSelectedEquipments([...selectedItem, selectedEquipment]);
  //   } else {
  //     // For single selection, update the state with the new equipment
  //     updateSelectedEquipments([selectedEquipment]);
  //   }
  // };

  const handleEquipmentChange = (ev) => {
    const selectedEquipment = equipments.find((item) => item?.id === ev) || {}
    if (ALLOW_MULTISELECT_EQUIPMENTS.includes(selectedEquipmentType.value)) {
      if (selectedItem.find(item => item.id === selectedEquipment.id)) {
        dispatch(setSelectedEquipments(selectedItem.filter(item => item.id !== selectedEquipment.id)))
      } else {
        dispatch(setSelectedEquipments([...selectedItem, selectedEquipment]))
      }
    } else {
      dispatch(setSelectedEquipments([selectedEquipment]))
    }
  }

  const filteredEquipments = useMemo(() => {
    let tempEquipments = equipments
    if (searchText) {
      tempEquipments = equipments.filter(({ name }) =>
        SearchRegex(name, searchText)
      )
    }
    tempEquipments = sortByAlphanumericSpecialChars(
      "name",
      "Asc",
      tempEquipments
    )
    return tempEquipments?.map((equipment: any) => ({
      name: equipment.name,
      value: equipment.id
    }))
  }, [equipments, searchText])

  useEffect(() => {
    if (selectedEquipment && selectedEquipment?.type === selectedEquipmentType?.value) {
      const preSelectedEquipment = equipments.find((item) => item?.id === selectedEquipment?.id) || {}
      dispatch(setSelectedEquipments([preSelectedEquipment]))
    }
  }, [selectedEquipmentType, equipments])
  return (
    <div className={clsx("col-sm", styles.reportListWrapper)}>
      <div className={clsx(styles.reportPanel)}>
        <SecondaryNav withBorder>
          <LeftPanel loading={equipLoading}>
            <TranslateComponent>Select System or Equipment</TranslateComponent>
          </LeftPanel>
          <RightPanel loading={equipLoading}>
            <SearchInput
              onInputChange={handleSearch}
              onInputClear={() => setSearchText("")}
              searchText={searchText}
            />
          </RightPanel>
        </SecondaryNav>
        <div className={styles.panelList}>
          <div className={styles.panelListInner}>
            <div className={styles.reportListBody}>
              <RadioGroup
                radioGroupClassName={styles.radioButtonGroup}
                options={filteredEquipments}
                layout="column"
                handleChange={handleEquipmentChange}
                selected={selectedItem}
                highLightText={searchText}
                translateCurrentValueOff={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EquipmentList
