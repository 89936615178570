import { I18nextProvider } from "react-i18next"
import i18n from "./i18nInit"
import AppRoutes from "./Routes"
import HelpAIButton from "./components/HelpAI"
import { AccessControl } from "./components/accessControl"
import { selectUserAccess, selectUserAccessLoading } from "src/redux/slicers/homeSlice"
import { useSelector } from "react-redux"
import Error from "./components/shared/ErrorModal/Error"

const App = ({ errorMessage }) => {
  const userAccess = useSelector(selectUserAccess)
  const userAccessLoaing = useSelector(selectUserAccessLoading)
  return (
    <I18nextProvider i18n={i18n}>
      <AppRoutes errorMessage={errorMessage} userAccess={userAccess} userAccessLoaing={userAccessLoaing} />
      <Error />
      <AccessControl id={["cc.poc"]} >
        <HelpAIButton theme="dark" />
      </AccessControl>
    </I18nextProvider>
  )
}
export default App
