import { API } from "aws-amplify"
import { getDataFromStack, saveToStack } from "../utils"
import { get } from "lodash"

export const fetchAllList = async ({ query, variables = {}, isHeadLess = false, stackKey = undefined, path, count = 10, stackExpiry = 900000 }) => {
    try {
        let all = []
        let nextToken = null
        let currentCount = 1
        const stackData = stackKey && getDataFromStack(stackKey)
        if (!stackData || !stackKey) {
            do {
                const gVariables: any = {
                    ...variables,
                }
                if (nextToken) {
                    gVariables.nextToken = nextToken
                }
                const graphQLParams: any = {
                    query,
                    variables: gVariables
                }
                if (!isHeadLess) {
                    graphQLParams.authMode = "AMAZON_COGNITO_USER_POOLS"
                }
                const response = await API.graphql(graphQLParams)
                const data = get(response, path, null)
                currentCount++
                nextToken = data?.nextToken
                all = all.concat(data.items)
            } while (nextToken !== null && currentCount <= count)
            if (stackKey) {
                saveToStack(stackKey, all, {}, stackExpiry)
            }
        } else {
            const listAll = getDataFromStack(stackKey)
            all = all.concat(listAll)
        }
        return {
            list: all
        }
    } catch (err) {
        return {
            err,
            error: true
        }
    }
}