import { QueryKey } from "@tanstack/react-query"
import { queryClient } from "src/providers/QueryProvider"

const timeouts = {}
export const saveToStack = (
  key: any,
  data: any,
  options: any = {},
  clearDataAfter = 0
) => {
  queryClient.setQueryData(key, data, options)
  if (timeouts?.[key]) {
    clearTimeout(timeouts?.[key])
  }
  if (clearDataAfter > 0) {
    timeouts[key] = setTimeout(() => {
      queryClient.removeQueries(key)
      delete timeouts?.[key]
    }, clearDataAfter)
  }
}

export const getDataFromStack = (key: QueryKey) => {
  return queryClient.getQueryData(key)
}
