import clsx from "clsx"
import { ITableBody } from "../types"
import styles from "./TableBody.module.scss"

const TableBody = (props: ITableBody) => {
  const { className = "", children, style = {} } = props

  return (
    <tbody
      style={{ ...style }}
      className={clsx(styles.tbody, styles.tbodyHover, className)}
    >
      {children}
    </tbody>
  )
}

export default TableBody
