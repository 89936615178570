export const skeltonLightDark = {
  baseColor: "#333",
  highlightColor: "#656565"
}
export const skeltonDark = {
  baseColor: "#707070",
  highlightColor: "#656565"
}
export const skeltonWhite = {
  baseColor: "#fff",
  highlightColor: "#dedede"
}
