import { uniqBy } from "lodash"
import { ExceptionInputs, SortableInput } from "./types"

export const updateExceptions = (
    exceptionInputs: ExceptionInputs,
    componentRelatedProperties: any,
    selectedEquipmentType: any,
    isAll = false
) => {
    let data = exceptionInputs?.reduce(
        (exceptionList: any, analyticItem: any) => {
            const { equipmentType, equipmentComponent, exceptionInputs } =
                analyticItem
            const { circuitInstances, compressorInstances } =
                componentRelatedProperties
            const parsedExceptions = exceptionInputs?.flatMap(
                (exceptionInput: any) => {
                    const parsedException = JSON.parse(exceptionInput)
                    const modifierRegex = new RegExp(/[X$#@!%^*&*[\]]{2}/)
                    const circuitRegex = new RegExp(/([Cc][kK][tT])[a-zA-z]*/)
                    const compressorRegex = new RegExp(/([Cc][Oo][mM][pP])[a-zA-z]*/)
                    if (parsedException?.uiExpList) {
                        const expListParsedException = Object?.values(
                            parsedException?.uiExpList
                        )?.flatMap((uiExpListName: string) => {
                            if (
                                circuitRegex?.test(uiExpListName) &&
                                modifierRegex?.test(uiExpListName)
                            ) {
                                return circuitInstances?.map((instance) => {
                                    const newExceptionName = uiExpListName?.replace(
                                        modifierRegex,
                                        instance
                                    )
                                    return {
                                        exceptionName: `${newExceptionName} ${equipmentComponent && isAll
                                            ? `(${equipmentComponent})`
                                            : equipmentType && isAll
                                                ? `(${equipmentType})`
                                                : ""
                                            }`,
                                        exceptionKey: newExceptionName,
                                        equipmentType:
                                            equipmentType || selectedEquipmentType?.value
                                    }
                                })
                            } else if (
                                compressorRegex?.test(uiExpListName) &&
                                modifierRegex?.test(uiExpListName)
                            ) {
                                return compressorInstances?.map((instance) => {
                                    const newExceptionName = uiExpListName?.replace(
                                        modifierRegex,
                                        instance
                                    )
                                    return {
                                        exceptionName: `${newExceptionName} ${equipmentComponent && isAll
                                            ? `(${equipmentComponent})`
                                            : equipmentType && isAll
                                                ? `(${equipmentType})`
                                                : ""
                                            }`,
                                        exceptionKey: newExceptionName,
                                        equipmentType:
                                            equipmentType || selectedEquipmentType?.value
                                    }
                                })
                            } else {
                                return [
                                    {
                                        exceptionName: `${uiExpListName} ${equipmentComponent && isAll
                                            ? `(${equipmentComponent})`
                                            : equipmentType && isAll
                                                ? `(${equipmentType})`
                                                : ""
                                            }`,
                                        exceptionKey: uiExpListName,
                                        equipmentType:
                                            equipmentType || selectedEquipmentType?.value
                                    }
                                ]
                            }
                        })
                        return [...expListParsedException]
                    } else {
                        if (
                            circuitRegex?.test(parsedException?.uiExpName) &&
                            modifierRegex?.test(parsedException?.uiExpName)
                        ) {
                            return circuitInstances?.map((instance) => {
                                const newExceptionName = parsedException?.uiExpName?.replace(
                                    modifierRegex,
                                    instance
                                )
                                return {
                                    exceptionName: `${newExceptionName} ${equipmentComponent && isAll
                                        ? `(${equipmentComponent})`
                                        : equipmentType && isAll
                                            ? `(${equipmentType})`
                                            : ""
                                        }`,
                                    exceptionKey: newExceptionName,
                                    equipmentType: equipmentType || selectedEquipmentType?.value
                                }
                            })
                        } else if (
                            compressorRegex?.test(parsedException?.uiExpName) &&
                            modifierRegex?.test(parsedException?.uiExpName)
                        ) {
                            return compressorInstances?.map((instance) => {
                                const newExceptionName = parsedException?.uiExpName?.replace(
                                    modifierRegex,
                                    instance
                                )
                                return {
                                    exceptionName: `${newExceptionName} ${equipmentComponent && isAll
                                        ? `(${equipmentComponent})`
                                        : equipmentType && isAll
                                            ? `(${equipmentType})`
                                            : ""
                                        }`,
                                    exceptionKey: newExceptionName,
                                    equipmentType: equipmentType || selectedEquipmentType?.value
                                }
                            })
                        } else {
                            return [
                                {
                                    exceptionName: `${parsedException?.uiExpName} ${equipmentComponent && isAll
                                        ? `(${equipmentComponent})`
                                        : equipmentType && isAll
                                            ? `(${equipmentType})`
                                            : ""
                                        }`,
                                    exceptionKey: parsedException?.uiExpName,
                                    equipmentType: equipmentType || selectedEquipmentType?.value
                                }
                            ]
                        }
                    }
                }
            )
            return [...exceptionList, ...parsedExceptions]
        },
        []
    )
    data = uniqBy(data, (item: any) =>
        item?.exceptionName?.toLowerCase()
    ).filter((val: any) => val.exceptionKey)
    data = data.sort((a: SortableInput, b: SortableInput) =>
        a?.exceptionName > b?.exceptionName
            ? 1
            : b?.exceptionName > a?.exceptionName
                ? -1
                : 0
    )

    return data

}