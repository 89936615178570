export const listAllUsers = /* GraphQL - Gets all the user list from schema */ `
query ListAllUsers(
    $aggregates: [SearchableUserAggregationInput]
    $filter: SearchableUserFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: [SearchableUserSortInput]
) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        email
        type
        status
        groups
      }
    }
  }
`

export const getUser = /* GraphQL - Creates new user */ `
    query getUser(
        $id: String!
     
        getUser(input: {id: $id}) {
            id
            name
            email
            type
            status
            groups
            customView
        }
    }
`

export const createUser = /* GraphQL - Creates new user */ `
    mutation CreateUser(
        $name: String!
        $email: String
        $type: String
        $status: String
    ) {
        createUser(input: {name: $name, email: $email, type: $type, status: $status}) {
            id
            name
            email
            type
            status
            groups
        }
    }
`

export const updateUser = /* GraphQL - Updates the user details */ `
    mutation  UpdateUser(
        $id: ID!
        $name: String
        $email: String
        $type: String
        $status: String
    ) {
        updateUser(input: {id: $id, name: $name, email: $email, type: $type, status: $status}) {
            id
            name
            email
            type
            status
            groups
        }
    }
`

export const deleteUser = /* GraphQL - Delete the user */ `
    mutation  DeleteUser(
        $id: ID!
    ) {
        deleteUser(input: {id: $id}) {
            id
        }
    }
`

export const updateUserGroup = /* GraphQL - Updates the user details */ `
    mutation  UpdateUser(
        $id: ID!
        $groups: [String]
    ) {
        updateUser(input: {id: $id, groups: $groups}) {
            id
            name
            email
            type
            status
            groups
        }
    }
`

// export const listAllGroups = /* GraphQL - Gets all the groups list from schema */ `
// query SearchGroups(
//     $aggregates: [SearchableGroupAggregationInput]
//     $filter: SearchableGroupFilterInput
//     $from: Int
//     $limit: Int
//     $nextToken: String
//     $sort: [SearchableGroupSortInput]
// ) {
//     searchGroups(
//         aggregates: $aggregates
//         filter: $filter
//         from: $from
//         limit: $limit
//         nextToken: $nextToken
//         sort: $sort
//         ) {
//       items {
//         id
//         name
//         description
//         status
//         subCategory
//         category
//       }
//     }
//   }
// `
export const listAllGroups = /* GraphQL - Gets all the groups list from schema */ `
query SearchGroups(
    $limit: Int 
) {
    listGroups(limit: $limit) {
        items {
          accessType
          description
          isActive
          name
          resourceType
        }
      }
  }
`

export const createGroup = /* GraphQL - Creates new group */ `
    mutation CreateGroup(
        $name: ID!
        $description: String!
        $accessType: AccessType!
        $isActive: Int!
        $resourceType: ResourceType!
    ) {
        createGroup(input: {accessType: $accessType, description: $description, isActive: $isActive, name: $name, resourceType: $resourceType}) {
            name
          }
    }
`
export const updateGroup = /* GraphQL - Updates the group details */ `
    mutation UpdateGroup(
        $name: ID!
        $description: String!
        $accessType: AccessType!
        $isActive: Int!
        $resourceType: ResourceType!
    ) {
        updateGroup(input: {accessType: $accessType, description: $description, isActive: $isActive, name: $name, resourceType: $resourceType}) {
            name
        }
    }
`

export const deleteGroup = /* GraphQL - Delete the group */ `
    mutation  DeleteGroup(
        $id: ID!
    ) {
        deleteGroup(input: {id: $id}) {
            id
        }
    }
`

export const listAllPermissions = /* GraphQL - Gets all the GroupPermission list from schema */ `
    query SearchPermission(
        $aggregates: [SearchablePermissionAggregationInput]
        $filter: SearchablePermissionFilterInput
        $from: Int
        $limit: Int
        $nextToken: String
        $sort: [SearchablePermissionSortInput]
    ) {
        searchPermissions(
            aggregates: $aggregates
            filter: $filter
            from: $from
            limit: $limit
            nextToken: $nextToken
            sort: $sort
        ) {
        items {
            id
            isActive
            groupPermissionsId
            description
            resourceId
            type
            group {
                name
              }
            }
        }
    }
`
export const createPermission = /* GraphQL - Creates Permission group */ `
    mutation CreatePermission(
        $type: String
        $resourceId: String
        $isActive: Boolean
        $description: String
        $groupPermissionsId: ID
    ) {
        createPermission(input: {type: $type, resourceId: $resourceId, isActive: $isActive, description: $description, groupPermissionsId: $groupPermissionsId }) {
            id
            type
            resourceId
            isActive
            description
            groupPermissionsId
        }
    }
`

export const updatePermission = /* GraphQL - Updates the Permission details */ `
    mutation UpdatePermission(
        $id: ID!
        $type: String
        $resourceId: String
        $isActive: Boolean
        $description: String
        $groupPermissionsId: ID
    ) {
        updatePermission(input: {id: $id, type: $type, resourceId: $resourceId, isActive: $isActive, description: $description, groupPermissionsId: $groupPermissionsId }) {
            id
            type
            resourceId
            isActive
            description
            groupPermissionsId
        }
    }
`

export const deletePermission = /* GraphQL - Delete the Permission */ `
    mutation  DeleteGroupPermission(
        $id: ID!
    ) {
        deletePermission(input: {id: $id}) {
            id
        }
    }
`

export const listAllBuildings = /* GraphQL - Gets all the Buildings list from schema */ `
query ListAllBuildings(
    $aggregates: [SearchableBuildingAggregationInput]
    $filter: SearchableBuildingFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: [SearchableBuildingSortInput]
) {
    searchBuildings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        address
        city
        state
        country
        groups
      }
    }
  }
`
export const updateBuildingGroup = /* GraphQL - Updates the buildings group */ `
    mutation  UpdateBuilding(
        $id: ID!
        $groups: [String]
    ) {
        updateBuilding(input: {id: $id, groups: $groups}) {
            id
            name
            address
            city
            state
            country
            groups
        }
    }
`

export const listAllApplications = /* GraphQL - Gets all the Applications list from schema */ `
query ListApplications{
    listApplications{
        items {
            id
            name
            description
            clientIds
        }
    }
}
`
export const createApplication = /* GraphQL - Create the Application */`
mutation CreateApplication(
    $name: String!
    $description: String!
    $id: ID!
    $clientIds: [ID]!
) {
    createApplication(input: {id: $id, appId: $id, clientIds: $clientIds, name: $name, description: $description}) {
        id
      }
}
`
export const updateApplication = /* GraphQL - Updates the Existing Application */`
mutation UpdateApplication(
    $name: String!
    $description: String!
    $id: ID!
    $clientIds: [ID]!
) {
    updateApplication(input: {id: $id, appId: $id, clientIds: $clientIds, name: $name, description: $description}) {
        id
      }
}
`
export const deleteApplication = /* GraphQL - Delete the Application */ `
    mutation DeleteApplication($id: ID!) {
        deleteApplication(input: {id: $id}) {
          id
        }
      }
`

export const listAllUserTypes = /* GraphQL - Gets all the UserTypes list from schema */ `
query ListApplications($limit: Int!){
    listUserTypes(limit: $limit) {
        items {
          authUserTypes
          description
          id
          name
          userTypeId
        }
      }
}
`
export const createUserType = /* GraphQL - Create the UserTypes */`
mutation CreateUserType(
    $userTypeId: ID!
    $name: ID!
    $description: String!
    $authUserTypes: [ID]!
) {
    createUserType(input: {id: $userTypeId, description: $description, authUserTypes: $authUserTypes, name: $name, userTypeId: $userTypeId}) {
        id
      }
}
`
export const updateUserType = /* GraphQL - Updates the Existing UserTypes */`
mutation UpdateUserType(
    $name: ID!
    $description: String!
    $userTypeId: ID!
    $authUserTypes: [ID]!
) {
    updateUserType(input: {id: $userTypeId, description: $description, authUserTypes: $authUserTypes, name: $name, userTypeId: $userTypeId}) {
        id
      }
}
`
export const deleteUserType = /* GraphQL - Delete the UserTypes */ `
    mutation DeleteUserType($id: ID!) {
        deleteUserType(input: {id: $id}) {
          id
        }
      }
`



export const listAllOfferings = /* GraphQL - Gets all the offerings list from schema */ `
    query ListOfferings(
        $filter: ModelOfferingFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listOfferings(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
        items {
            id
            name
            endDate
            code
            description
            startDate
        }
        }
    }
`
export const createOffering = /* GraphQL - update the offering from schema */ `
    mutation createOffering(
        $id: ID
        $name: String!
        $code: String!
        $description: String
        $startDate: AWSDateTime!
        $endDate: AWSDateTime!
        $owners: [String]
        $groups: [String]
    ) {
        createOffering(input :{
            id: $id
            name: $name
            code: $code
            description: $description
            startDate: $startDate
            endDate: $endDate
            owners: $owners
            groups: $groups
        }) {
            id
            name
            code
            description
            startDate
            endDate
        }
    }
`

export const updateOffering = /* GraphQL - update the offering from schema */ `
    mutation UpdateOffering(
        $id: ID!
        $name: String
        $code: String
        $description: String
        $startDate: AWSDateTime
        $endDate: AWSDateTime
        $owners: [String]
        $groups: [String]
    ) {
        updateOffering(input: {
            id: $id
            name: $name
            code: $code
            description: $description
            startDate: $startDate
            endDate: $endDate
            owners: $owners
            groups: $groups
        }) {
            id
            name
            code
            description
            startDate
            endDate
        }
    }
`

export const deleteOffering = /* GraphQL - Delete the Offering */ `
    mutation  DeleteOffering(
        $id: ID!
    ) {
        deleteOffering(input: {id: $id}) {
            id
        }
    }
`

export const listBuildingSubscriptions = /* GraphQL - Gets all the Subscription list from schema */ `
    query ListBuildingSubscriptions(
        $filter: ModelBuildingSubscriptionFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listBuildingSubscriptions(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
        items {
            id
            buildingSubscriptionsId
            offeringSubscriptionsId
            startDate
            endDate
        }
        }
    }
`
export const createSubscription = /* GraphQL - update the Subscription from schema */ `
    mutation createSubscription(
        $id: ID
        $startDate: AWSDateTime!
        $endDate: AWSDateTime!
        $owners: [String]
        $groups: [String]
        $buildingSubscriptionsId: ID
        $offeringSubscriptionsId: ID
    ) {
        createBuildingSubscription(input :{
            id: $id
            startDate: $startDate
            endDate: $endDate
            owners: $owners
            groups: $groups
            buildingSubscriptionsId: $buildingSubscriptionsId
            offeringSubscriptionsId: $offeringSubscriptionsId
        }) {
            id
            buildingSubscriptionsId
            offeringSubscriptionsId
            startDate
            endDate
        }
    }
`

export const updateSubscription = /* GraphQL - update the Subscription from schema */ `
    mutation UpdateSubscription(
        $id: ID!
        $startDate: AWSDateTime
        $endDate: AWSDateTime
        $owners: [String]
        $groups: [String]
        $buildingSubscriptionsId: ID
        $offeringSubscriptionsId: ID
    ) {
        updateBuildingSubscription(input: {
            id: $id
            startDate: $startDate
            endDate: $endDate
            owners: $owners
            groups: $groups
            buildingSubscriptionsId: $buildingSubscriptionsId
            offeringSubscriptionsId: $offeringSubscriptionsId
        }) {
            id
            buildingSubscriptionsId
            offeringSubscriptionsId
            startDate
            endDate
        }
    }
`

export const deleteSubscription = /* GraphQL - Delete the Subscription */ `
    mutation  DeleteSubscription(
        $id: ID!
    ) {
        deleteBuildingSubscription(input: {id: $id}) {
            id
        }
    }
`
export const GetUIAccessToken = /* GraphQL - get the UIAccessTokens */`
query GetUIAccessToken {
    getUIAccessTokens
  }
  `
