import { legendIDGenerator } from "../common"
import { generateExceptionsTimestampObjects } from "../exceptions"

export const generateExceptionBars = (
  exceptionData: any = {},
  exceptionLegends: any = {},
  exceptionSampling = 0,
  chartInfo: any = {},
  totalLanes = 0,
  startDate,
  endTimestamp,
  localTimeStamp
) => {
  const exceptionsBars = []
  let exceptionLanes = totalLanes
  Object.keys(exceptionData)?.forEach((equipmentID: string) => {
    const exceptionsObj = exceptionData?.[equipmentID] || {}
    Object.keys(exceptionsObj)?.forEach((exceptionName: string) => {
      const exceptionArray = exceptionsObj?.[exceptionName] || []
      const axis = chartInfo?.axisConfig?.find(
        (obj) => obj?.axisName?.toLowerCase() === "exceptions"
      )
      const exceptionDetails = (axis?.exceptionList || [])?.find((obj) => {
        // Ends with A -> Name from config
        // Ends with R -> Name from response
        const excepA = obj?.name?.toLowerCase()
        const excepR = exceptionName?.toLowerCase()
        if (excepA?.includes("xx")) {
          const excepAXXRemoved = excepA.replace("xx", "");
          if (excepAXXRemoved?.length === excepR?.length) {
            return excepAXXRemoved === excepR
          } else {
            const position = excepA?.indexOf("xx");
            const extraLength = excepR?.length - excepAXXRemoved?.length
            const excepRXXRemoved = excepR.slice(0, position) + excepR.slice(position + extraLength)
            return excepAXXRemoved === excepRXXRemoved
          }
        } else {
          return obj?.name === exceptionName
        }
      })
      const interval = exceptionDetails?.interval
      const legendId = legendIDGenerator(equipmentID, exceptionName)
      const legend = exceptionLegends?.[legendId]
      const otherProps = {
        color: legend?.color,
        id: legend?.id,
        lane: exceptionLanes,
        name: legend?.name,
        propertyName: legend?.propertyName,
        show: legend?.show,
        visible: legend?.visible
      }

      const exceptionTimeLineBars = generateExceptionsTimestampObjects(
        exceptionArray,
        interval,
        exceptionSampling,
        startDate,
        endTimestamp,
        localTimeStamp,
        otherProps
      )

      exceptionsBars.push(...exceptionTimeLineBars)
      exceptionLanes++
    })
  })

  return {
    exceptionsBars,
    exceptionLanes
  }
}
