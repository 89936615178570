import React, { useCallback, useEffect, useState, useRef, useMemo } from "react"
import classnames from "classnames"
import styles from "./MultiRangeSlider.module.scss"
import { debounce } from "lodash"

type MultiRangeSliderInput = {
  min: number
  max: number
  actualMin: number
  actualMax: number
  handleChange: any
}
const MultiRangeSlider = ({
  min,
  max,
  actualMin,
  actualMax,
  handleChange
}: MultiRangeSliderInput) => {
  const [minVal, setMinVal] = useState(actualMin)
  const [maxVal, setMaxVal] = useState(actualMax)
  const [slider1Val, setSlider1Val] = useState(actualMin)
  const [slider2Val, setSlider2Val] = useState(actualMax)
  const slider1Ref = useRef(null)
  const slider2Ref = useRef(null)
  const [slider1Step, setSlider1Step] = useState(null)
  const [slider2Step, setSlider2Step] = useState(null)

  // Get min and max values when their state changes
  useEffect(() => {
    handleChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal])

  useEffect(() => {
    setMinVal(actualMin)
    setMaxVal(actualMax)
    setSlider1Val(actualMin)
    setSlider2Val(actualMax)

  }, [actualMin, actualMax])

  useEffect(() => {
    const rangeLinePadding = 16
    let calcStep1 =
      (slider1Ref.current.offsetWidth - rangeLinePadding) /
      (slider1Ref.current.max - slider1Ref.current.min)
    let calcStep2 =
      (slider2Ref.current.offsetWidth - rangeLinePadding) /
      (slider2Ref.current.max - slider2Ref.current.min)
    calcStep1 = (calcStep1 && calcStep1 !== Infinity && calcStep1 !== -Infinity) ? calcStep1 : 1
    calcStep2 = (calcStep2 && calcStep2 !== Infinity && calcStep2 !== -Infinity) ? calcStep2 : 1
    setSlider1Step(calcStep1)
    setSlider2Step(calcStep2)
  }, [])

  const InputOnChangeHandler = useCallback(
    (value: number, type: string) => {
      const minLimit = slider1Val <= slider2Val ? slider1Val : slider2Val
      const maxLimit = slider1Val >= slider2Val ? slider1Val : slider2Val
      if (value >= min && value <= max) {
        if (type == "min" && value <= maxLimit) {
          setMinVal(value)
          slider1Val <= slider2Val ? setSlider1Val(value) : setSlider2Val(value)
        } else if (type == "min" && value > maxLimit) {
          setMinVal(maxLimit)
          setSlider1Val(maxLimit)
          setMaxVal(value)
          setSlider2Val(value)
        } else if (type == "max" && value > minLimit) {
          setMaxVal(value)
          slider1Val >= slider2Val ? setSlider1Val(value) : setSlider2Val(value)
        } else if (type == "max" && value <= minLimit) {
          setMinVal(value)
          setSlider1Val(value)
          setMaxVal(minLimit)
          setSlider2Val(minLimit)
        }
      } else {
        if (type == "min") {
          setMinVal(min)
          slider1Val <= slider2Val ? setSlider1Val(min) : setSlider2Val(min)
        } else {
          setMaxVal(max)
          slider1Val >= slider2Val ? setSlider1Val(max) : setSlider2Val(max)
        }
      }
    },
    [slider1Val, slider2Val]
  )

  const ArrowDisableHandler = (event: any) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault()
    }
  }

  const debouncedOnchange = useMemo(
    () => debounce((value, type) => InputOnChangeHandler(value, type), 1000),
    [InputOnChangeHandler]
  )

  return (
    <div className={styles.container}>
      <div className={styles.rangeInput}>
        <input
          type="number"
          min={min}
          max={max}
          className={styles.numberInput}
          value={minVal}
          onKeyDown={ArrowDisableHandler}
          onChange={(e) => {
            debouncedOnchange(parseInt(e.target.value), "min")
            setMinVal(+e.target.value)
          }}
        />
        <span className={styles.range_span}>Range</span>
        <input
          type="number"
          className={styles.numberInput}
          value={maxVal}
          onKeyDown={ArrowDisableHandler}
          onChange={(e) => {
            debouncedOnchange(parseInt(e.target.value), "max")
            setMaxVal(+e.target.value)
          }}
        />
      </div>
      <div className={styles.container_slider}>
        <input
          name="slider1"
          type="range"
          min={min}
          max={max}
          value={slider1Val}
          ref={slider1Ref}
          onChange={(event) => {
            const value = +event.target.value
            value <= slider2Val && setMinVal(value)
            value >= slider2Val && setMaxVal(value)
            setSlider1Val(value)
            event.target.value = value.toString()
          }}
          className={classnames(styles.thumb, styles.thumb__zindex_3, {
            [styles.thumb__zindex_5]: minVal > max - 100
          })}
        />
        <input
          name="slider2"
          type="range"
          min={min}
          max={max}
          value={slider2Val}
          ref={slider2Ref}
          onChange={(event) => {
            const value = +event.target.value
            value <= slider1Val && setMinVal(value)
            value >= slider1Val && setMaxVal(value)
            setSlider2Val(value)
            event.target.value = value.toString()
          }}
          className={classnames(styles.thumb, styles.thumb__zindex_4, {
            [styles.thumb__zindex_5]: minVal > max - 100
          })}
        />
        <output
          htmlFor="slider1"
          className={styles.bubble}
          style={{
            transform: `translateX(${(slider1Val - min) * slider1Step}px)`,
            opacity:
              Math.abs(
                (slider2Val - min) * slider2Step -
                  (slider1Val - min) * slider1Step
              ) < 20
                ? 0
                : 1
          }}
        >
          {slider1Val}
        </output>
        <output
          htmlFor="slider2"
          className={styles.bubble}
          style={{
            transform: `translateX(${(slider2Val - min) * slider2Step}px)`,
            opacity:
              Math.abs(
                (slider2Val - min) * slider2Step -
                  (slider1Val - min) * slider1Step
              ) < 20
                ? 0
                : 1
          }}
        >
          {slider2Val}
        </output>
        <output
          htmlFor="slider2"
          className={styles.bubble}
          style={{
            transform: `translateX(${(slider2Val - min) * slider2Step - 10}px)`,
            opacity:
              Math.abs(
                (slider2Val - min) * slider2Step -
                  (slider1Val - min) * slider1Step
              ) < 20
                ? 1
                : 0
          }}
        >
          {minVal + "-" + maxVal}
        </output>
        <div className={styles.slider}>
          <div
            className={styles.slider__left_value}
            style={{
              opacity:
                (slider1Val - min) * slider1Step <= 30 ||
                (slider2Val - min) * slider2Step <= 30
                  ? 0
                  : 1
            }}
          >
            {min}
          </div>
          <div
            className={styles.slider__right_value}
            style={{
              opacity:
                (max - min) * slider1Step - (slider1Val - min) * slider1Step <=
                  30 ||
                (max - min) * slider2Step - (slider2Val - min) * slider2Step <=
                  30
                  ? 0
                  : 1
            }}
          >
            {max}
          </div>
          <div className={styles.slider__track} />
          <div className={styles.slider__range} />
        </div>
      </div>
    </div>
  )
}

export default MultiRangeSlider
