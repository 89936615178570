import {
  CHILLER_LIFE,
  CHILLER_PROFILE,
  COMPRESSOR_RUN_HOURS_AND_STARTS
} from "../constant"
import ChillerLife from "./ChillerLife"
import ChillerProfile from "./ChillerProfile"
import CompressorRunHoursAndStarts from "./CompressorRunHoursAndStarts"

const componentMapping = {
  [CHILLER_PROFILE]: ChillerProfile,
  [CHILLER_LIFE]: ChillerLife,
  [COMPRESSOR_RUN_HOURS_AND_STARTS]: CompressorRunHoursAndStarts
}
const Picker = (props) => {
  const { targetKey, ...otherProps } = props
  const Component = componentMapping[targetKey]
  if (!Component) {
    return
  }
  return <Component {...otherProps} />
}

export default Picker
