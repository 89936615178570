import { IDefaultCheckbox } from "./types"
import styles from "./Checkbox.module.scss"
import clsx from "clsx"
import { v4 as uuidv4 } from "uuid"
import Typography from "../Typography"
import translate from "src/common/translations"
const Checkbox = (props: IDefaultCheckbox) => {
  const id = uuidv4()
  const {
    name,
    checked = false,
    className = "",
    labelDisabled = false,
    inputProps = {},
    disabled,
    onChange = () => null,
    onClick = () => null,
    label,
    checkboxId = id,
    testId = "",
    textColor,
  } = props
  //Provide true/false for checked property insteadof null/undefined.
  const checkBoxValue = checked ?? false
  return (
    <div className={clsx(styles.tisccCheckbox, className)}>
      <input
        id={checkboxId}
        name={name}
        className={clsx(styles.tisccCheckboxInput, {
          [styles.indeterminate]: checked === null,
          [styles.labelDisabled]: labelDisabled
        })}
        title={typeof label === "string" ? translate(label) : null}
        checked={checkBoxValue}
        onChange={onChange}
        onClick={onClick}
        type="checkbox"
        disabled={disabled}
        {...inputProps}
        data-testid={testId}
      />
      {label && !labelDisabled && (
        <Typography
          tagType="label"
          styleType="p4"
          className={styles.tisccCheckboxlabel}
          htmlFor={checkboxId}
          color={textColor}
          title={typeof label === "string" ? translate(label) : undefined}
        >
          {typeof label === "string" ? translate(label) : label}
        </Typography>
      )}
    </div>
  )
}
export default Checkbox
