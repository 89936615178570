import { ITableRow } from "../types"

const TableRow = (props: ITableRow) => {
  const { className = "", children, style, onClick, testId = "" } = props

  return (
    <tr
      onClick={onClick}
      data-testid={testId}
      style={{ ...style }}
      className={className}
    >
      {children}
    </tr>
  )
}

export default TableRow
