import { isArray, isString, sum, toInteger, toNumber } from "lodash"
import {
  chartDateFormat,
  constantValues,
  reduxStoreTimeStampFormat
} from "../../constant"
import {
  convertTimeToMinutes,
  getChartObject,
  getTimeIndex,
  intervalToMinutes,
  legendIDGenerator
} from "../common"
import moment from "moment"

export const PIE_LABEL = "Percent of Above Control Range Measurements"
export const transformPieChart = (props) => {
  const {
    tz,
    startDate,
    endDate,
    intervalMinutes = "15m",
    inputData,
    chartInfo,
    equipmentList,
    buildingId,
    equipmentId
  } = props
  const timestamps = []
  const defaultBoundaryRange = {
    above: 95,
    upper: 65,
    lower: 0
  }
  const equipmentType = chartInfo?.equipmentType
  const calculatedMin = intervalToMinutes(intervalMinutes)
  const data = inputData?.data || {}
  const settings = inputData?.settings || {}
  const legends = []

  const newData = {}
  let oneLoopCompleted = false

  for (const id in data) {
    const paramData = data[id]
    const equipmentName = equipmentList?.find((obj) => obj?.id === id)?.name
    const selectedEquipment = equipmentId === id
    const building = buildingId === id

    const properties = paramData?.properties

    for (const propertyKey in properties) {
      const property = properties[propertyKey]
      const propertyIndex = property?.index
      const propertyName = property?.name || ""

      const axis = chartInfo?.axisConfig?.find((obj) =>
        getChartObject(obj, propertyKey, equipmentType)
      )

      const name = building || selectedEquipment ? propertyName : equipmentName

      const legendId = legendIDGenerator(id, propertyKey)
      const legendObj = {
        id: legendId,
        name: name,
        axisName: axis?.axisName,
        show: true,
        visible: true,
        type: "pie"
      }
      legends.push(legendObj)
      let currentTimestamp = moment(startDate, chartDateFormat)
      const endTimestamp = moment(endDate, chartDateFormat).add(1, "days")
      const localTimeStamp = Boolean(tz)
        ? moment(
          moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
          "MM-DD-YYYY HH:mm"
        )
        : moment()
      let prevValue = null

      while (
        currentTimestamp.isSameOrBefore(endTimestamp) &&
        currentTimestamp.isSameOrBefore(localTimeStamp)
      ) {
        const dateKey = currentTimestamp.format("YYYY-MM-DD")
        const extractTime = currentTimestamp.format("HH:mm")
        const timetoMin = convertTimeToMinutes(extractTime)
        const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)

        const dateAvailable = paramData?.values?.[dateKey]

        let finalValue
        if (dateAvailable) {
          const valueArray = paramData?.values?.[dateKey]?.[timeIndexPosition]
          const valueArrayIsArray = isArray(valueArray)
          const arrayValues = valueArrayIsArray ? valueArray : []
          const value = arrayValues[propertyIndex]

          const checkedValue =
            constantValues?.includes(value) ||
              !isFinite(toNumber(value)) ||
              value?.toString()?.startsWith("_")
              ? null
              : parseFloat(value)

          if ((isString(value) && value?.length === 0) || !valueArrayIsArray) {
            finalValue = prevValue
          } else {
            finalValue = checkedValue
          }

          if (timeIndexPosition === 95) {
            prevValue = null
          } else {
            prevValue = finalValue
          }
        } else {
          finalValue = null
          prevValue = null
        }
        const dateVal = currentTimestamp.format(reduxStoreTimeStampFormat)
        if (!newData?.[legendId]) {
          newData[legendId] = {
            data: []
          }
        }
        newData[legendId]?.data.push(finalValue)
        if (!oneLoopCompleted) {
          timestamps.push(dateVal)
        }
        currentTimestamp = currentTimestamp.add(calculatedMin, "minutes")
      }
      oneLoopCompleted = true
    }
  }

  // This logic might be modified based on config and axis info
  const highRangeProperty = Object.keys(settings)?.find((key) =>
    key?.toLowerCase()?.includes("high")
  )
  const lowRangeProperty = Object.keys(settings)?.find((key) =>
    key?.toLowerCase()?.includes("low")
  )
  const aboveControRangeValueResponse = toInteger(settings?.[highRangeProperty])
  const upperControRangeValueResponse = toInteger(settings?.[lowRangeProperty])

  const aboveControlRangeValue = aboveControRangeValueResponse
    ? aboveControRangeValueResponse
    : defaultBoundaryRange?.above
  const upperControlRangeValue = upperControRangeValueResponse
    ? upperControRangeValueResponse
    : defaultBoundaryRange?.upper

  const calculatedValues = {}

  legends?.forEach((obj: any) => {
    const data = newData?.[obj?.id]?.data || []

    const paramObj = calculatedValues?.[obj?.id]

    if (!paramObj) {
      calculatedValues[obj?.id] = {
        lower: 0,
        upper: 0,
        above: 0
      }
    }

    data?.forEach((value) => {
      if (value < upperControlRangeValue) {
        calculatedValues[obj?.id].lower++
      } else if (value < aboveControlRangeValue) {
        calculatedValues[obj?.id].upper++
      } else if (value >= aboveControlRangeValue && value <= 100) {
        calculatedValues[obj?.id].above++
      }
    })
  })

  const values = Object.values(calculatedValues)?.map((obj: any) => obj?.above)
  const totalValue = sum(values)

  const percentageData = legends?.map((obj) => {
    const calculatedValue = calculatedValues[obj?.id]?.above
    const value = (calculatedValue / totalValue) * 100
    const finalObject = {
      id: obj?.id,
      name: obj?.name,
      color: obj?.color,
      label: PIE_LABEL,
      value
    }
    return finalObject
  })

  const isNoData = percentageData?.every((e) => !(e?.value >= 0))
  Object.keys(defaultBoundaryRange)?.forEach((key) => {
    const legendObj = {
      id: key,
      excelName:
        key === "lower"
          ? "Lower Control Range"
          : key === "upper"
            ? "Upper Control Range"
            : "Above Control Range",
      color:
        key === "lower" ? "#87b1e1" : key === "upper" ? "#67d25b" : "#ff001a",
      settingsKey: key,
      axisName: "y1",
      show: true,
      visible: true,
      disabled: true,
      type: "boundary",
      boundaryPosition: key === "lower" ? 0 : key === "upper" ? 1 : 2
    }
    legends.unshift(legendObj)
  })
  const legendsObj = {}

  legends?.forEach((obj) => {
    legendsObj[obj?.id] = obj
  })

  return {
    chartData: {
      data: isNoData ? {} : newData
    },
    chartProps: {
      slices: isNoData ? [] : percentageData,
      legends: legendsObj,
      calculatedValues: isNoData ? {} : calculatedValues,
      noDataAvailable: isNoData ? true : false,
      isdataprocessed: true
    }
  }
}
