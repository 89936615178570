import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Predictions } from "aws-amplify";

export interface TranslationState {
    originalJSON: Record<string, any>;
    translatedJSON: Record<string, any>;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null;
    translationsMap: Record<string, string>; // Stores translation by language
    // translationsMap: Record<string, Record<string, string>>; // Stores translation by language
    currentLanguage: string;
}

const initialState: TranslationState = {
    originalJSON: {},
    translatedJSON: {},
    status: "idle",
    error: null,
    translationsMap: {}, // Stores only the current language translations
    currentLanguage: "en", // Default to English
}

const collectTexts = (obj: Record<string, any>, path = ""): [string, string][] => {
    let texts: [string, string][] = []

    for (const key in obj) {
        const fullPath = path ? `${path}.${key}` : key
        if (typeof obj[key] === "object") {
            texts = texts.concat(collectTexts(obj[key], fullPath))
        } else {
            if (!texts.some(([_, value]) => value === obj[key]))
                texts.push([fullPath, obj[key]])
        }
    }
    return texts
}

// setTexts based on the original JSON path and translated values
const setTexts = (obj: Record<string, any>, texts: [string, string][]) => {
    const textMap = new Map(texts)
    const setNestedTexts = (obj: Record<string, any>, path = "") => {
        for (const key in obj) {
            const fullPath = path ? `${path}.${key}` : key
            if (typeof obj[key] === "object") {
                setNestedTexts(obj[key], fullPath)
            } else if (textMap.has(fullPath)) {
                const translatedValue = textMap.get(fullPath)
                if (translatedValue) {
                    obj[key] = translatedValue
                }
            }
        }
    }
    setNestedTexts(obj)
}

export const translateJSON = createAsyncThunk(
    "translation/translateJSON",
    async ({ json }: { json: Record<string, any> }, thunkAPI) => {
        const state = thunkAPI.getState() as any;
        const targetLanguage = state.userData?.selectedLanguage || "en"
        const { translationCombinedSlice: { translationsMap } } = state;

        if (!json.length) {
            return json
        }

        // Return cached translations if available
        const cacheKey = JSON.stringify(json); // Create a unique cache key based on the input JSON
        if (targetLanguage !== "en" && translationsMap[targetLanguage]?.[cacheKey]) {
            return translationsMap[targetLanguage][cacheKey];  // Return cached translation if available
        }

        if (targetLanguage === "en") {
            return json;
        }

        const texts = collectTexts(json)
        const uniqueValues = Array.from(new Set(texts.map(([, value]) => value))); // Get unique values for translation

        try {
            const result = await Predictions.convert({
                translateText: {
                    source: {
                        text: uniqueValues.join("\n"), // Prepare unique values for translation
                        language: "en",
                    },
                    targetLanguage: targetLanguage,
                }
            })
            const transResult = result.text.split("\n")

            // Save new translations in the state while keeping the original structure
            const newTranslations: Record<string, string> = {}

            // Map unique values to their translations
            uniqueValues.forEach((value, index) => {
                newTranslations[value] = transResult[index];
            });

            // Create the final output with original length
            const finalOutput = texts.map(([, value]) => newTranslations[value]);

            // Populate translated object with original structure
            const translatedTexts = texts.map(([path], index) => [path, finalOutput[index]] as [string, string]);
            const translatedObj = JSON.parse(JSON.stringify(json)); // Deep copy original object
            setTexts(translatedObj, translatedTexts);

            // Reset translationsMap to store only the current language translations
            const updatedTranslationsMap = {
                ...translationsMap,
                [targetLanguage]: {
                    ...translationsMap[targetLanguage],
                    [cacheKey]: translatedObj // Cache the translated object
                }
            };

            // Update the state with the new translations and current language
            thunkAPI.dispatch(updateTranslationsMap(updatedTranslationsMap));
            thunkAPI.dispatch(setCurrentLanguage(targetLanguage));

            return translatedObj;
        } catch (error) {
            console.log(error)
            const message = error.message;
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const translationCombinedSlice = createSlice({
    name: "translation",
    initialState,
    reducers: {
        setOriginalJSON: (state, action: PayloadAction<Record<string, any>>) => {
            state.originalJSON = action.payload;
        },
        updateTranslationsMap: (state, action: PayloadAction<Record<string, string>>) => {
            state.translationsMap = action.payload // Overwrite with the new language translations
        },
        setCurrentLanguage: (state, action: PayloadAction<string>) => {
            state.currentLanguage = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(translateJSON.pending, (state) => {
                state.status = "loading";
            })
            .addCase(translateJSON.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.translatedJSON = action.payload;
            })
            .addCase(translateJSON.rejected, (state, action: PayloadAction<any>) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { setOriginalJSON, updateTranslationsMap, setCurrentLanguage } = translationCombinedSlice.actions;
export default translationCombinedSlice.reducer;
