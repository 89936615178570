import {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useMemo
} from "react"
import { SearchRegex } from "src/utils/CommonMethods"
import { useSelector, useDispatch } from "react-redux"
import { t } from "src/translations/help"
import { getDateFormatWithEnglishLocale } from "src/utils/CommonMethods"
import {
  selectBuildingSummary,
  selectBuildingId
} from "src/redux/slicers/buildingSummarySlice"
import { useMutation } from "src/hooks/APIHooks"
import { generateEHRDataReport } from "./../graphql"
import {
  getFileFormatDetails,
  getSelectedEquipments,
  getEquipmentLoader,
  setEquipmentSearchText,
  getEquipments,
  setIsValid,
  setReportFormat,
  setSelectedEquipments,
  getFilter,
  setIsDataExceeded,
  setReportStatus,
  getReportStatus
} from "src/redux/slicers/reportSlice"
import { sortByAlphanumericSpecialChars } from "src/blitz/DataGridTable/helper"
import { getMinutesDifference } from "src/blitz/DateRange/helper"
import {
  DATA_INTERVAL_MINUTES,
  MAX_DATA_CELLS_QUANTITY,
  REPORT_STATE
} from "../constants"
import styles from "./AlarmsAndEventsReport.module.scss"
import clsx from "clsx"
import { ACTIONS } from "src/constants"
import { downloadFileUsingS3 } from "src/utils/CommonMethods"
import { ReportList } from "../RawDataReport/ReportList"
import { RECHECK_INTERVAL, RETRY_COUNT } from "src/constants/common"

const AlarmsAndEventsReport = forwardRef((props: any, ref: any) => {
  const { selectedLanguage } = props
  useImperativeHandle(ref, () => ({
    onClickGenerate: generateReportHandler
  }))
  const dispatch = useDispatch()
  const [selectAllEquip, setSelectAllEquip] = useState(false)
  const buildingId = useSelector(selectBuildingId)
  const { data: buildingSummary } = useSelector(selectBuildingSummary)

  const equipLoading = useSelector(getEquipmentLoader)
  const equipments = useSelector(getEquipments)
  const selectedEquipments: any = useSelector(getSelectedEquipments)
  const [reportInfo, setReportInfo] = useState(REPORT_STATE)
  const { cancelled, error } = useSelector(getReportStatus)
  const { startDate, endDate, language } = useSelector(getFilter)

  const { equipmentSearchText, viewFormat } = useSelector(getFileFormatDetails)

  const isValid = useMemo(
    () => selectedEquipments?.length > 0,
    [selectedEquipments]
  )

  useEffect(() => {
    dispatch(setIsValid(isValid))
  }, [isValid])

  const isDataExceeded = useMemo(() => {
    return (
      MAX_DATA_CELLS_QUANTITY <
      selectedEquipments.length *
      (getMinutesDifference(startDate, endDate) / DATA_INTERVAL_MINUTES)
    )
  }, [selectedEquipments, startDate, endDate])

  useEffect(() => {
    dispatch(setIsDataExceeded(isDataExceeded))
  }, [isDataExceeded])

  const { onSubmit: generateReportQuery } = useMutation({
    query: generateEHRDataReport,
    onSuccess: (response: any) => {
      if (response?.data?.generateEHRDataReport) {
        const { body } = JSON.parse(response?.data?.generateEHRDataReport)
        setReportInfo({
          url: body,
          retryCount: 0
        })
      }
    },
    onError: () => {
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  })

  useEffect(() => {
    dispatch(setReportStatus(ACTIONS.RESET))
  }, [])

  const filteredEquipments = useMemo(() => {
    let tempEquipment = []
    if (equipmentSearchText) {
      tempEquipment = equipments.filter(({ name }) =>
        SearchRegex(name, equipmentSearchText)
      )
      tempEquipment = sortByAlphanumericSpecialChars(
        "name",
        "Asc",
        tempEquipment
      )
    } else {
      tempEquipment = sortByAlphanumericSpecialChars("name", "Asc", equipments)
    }
    return tempEquipment
  }, [equipments, equipmentSearchText])

  // **** Below method is invoked by ref **** /
  const generateReportHandler = () => {
    if (isValid && !isDataExceeded) {
      dispatch(setReportStatus(ACTIONS.LOADING))
      const selectedDevicesObj = {}
      equipments?.forEach(({ id, name }) => {
        if (selectedEquipments?.includes(id)) {
          selectedDevicesObj[id] = name
        }
      })
      const formattedStartDate = getDateFormatWithEnglishLocale(startDate)
      const formattedEndDate = getDateFormatWithEnglishLocale(endDate)

      generateReportQuery({
        requestBody: JSON.stringify({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          format: viewFormat,
          buildingId: { [buildingId]: buildingSummary?.name },
          deviceIds: selectedDevicesObj,
          report: "aer",
          language: selectedLanguage?.langCode,
        })
      })
    }
  }

  useEffect(() => {
    let timeout = null
    if (cancelled || !reportInfo.url || error || reportInfo.retryCount >= 26) {
      clearTimeout(timeout)
    } else if (reportInfo.retryCount === 0) {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, 0)
    } else {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, RECHECK_INTERVAL)
    }
    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout)
    }
  }, [cancelled, reportInfo])

  const checkReportIsFound = async () => {
    try {
      const response = await fetch(reportInfo.url)
      if (response?.status === 404) {
        setReportInfo({
          ...reportInfo,
          retryCount: reportInfo.retryCount + 1
        })
        if (reportInfo.retryCount + 1 >= RETRY_COUNT) {
          dispatch(setReportStatus(ACTIONS.ERROR))
        }
      } else if (response?.status === 200) {
        dispatch(setReportStatus(ACTIONS.COMPLETE))
        downloadFileUsingS3(reportInfo.url, buildingSummary?.name)
      } else {
        dispatch(setReportStatus(ACTIONS.ERROR))
      }
    } catch (e) {
      console.log(e)
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  }

  const onEquipmentInputSearch = (value) => {
    dispatch(setEquipmentSearchText(value))
  }

  const onViewFormatChange = (format: string) => {
    dispatch(setReportFormat(format))
    dispatch(setReportStatus(ACTIONS.RESET))
  }

  const handleChangeEquipment = (checked, equipId: string) => {
    dispatch(setReportStatus(ACTIONS.RESET))
    let updateSelectedEquipments = []
    if (equipId === "all-check") {
      updateSelectedEquipments = checked
        ? filteredEquipments?.map(({ id }) => id)
        : []
      dispatch(setSelectedEquipments(updateSelectedEquipments))
      setSelectAllEquip((prev) => !prev)
    } else {
      updateSelectedEquipments = checked
        ? [...selectedEquipments, equipId]
        : selectedEquipments.filter((id: string) => id !== equipId)
      dispatch(setSelectedEquipments(updateSelectedEquipments))
      const isSelected = updateSelectedEquipments.length
        ? filteredEquipments?.length === updateSelectedEquipments?.length
          ? true
          : null
        : false
      setSelectAllEquip(isSelected)
    }
  }

  const leftOptions = [
    {
      label: t("reports.standardXlsx"),
      name: "report format",
      value: "xlsx",
      radioId: "standard",
      testId: "aer-standard-xlsx-radio-input"
    },
    {
      label: t("reports.standardCsv"),
      name: "report format",
      value: "csv",
      radioId: "standardCsv",
      testId: "aer-standard-csv-radio-input"
    }
  ]
  const config = [
    {
      title: "Select Device",
      data: filteredEquipments,
      mainData: equipments,
      loading: equipLoading,
      handleChange: handleChangeEquipment,
      footerConfig: {
        type: "radio",
        options: leftOptions,
        selected: viewFormat,
        handleChange: onViewFormatChange
      },
      searchText: equipmentSearchText,
      selectedItems: selectedEquipments,
      onChangeSearch: onEquipmentInputSearch,
      isAllChecked: selectAllEquip
    }
  ]

  return (
    <div className={clsx(styles.alarmsAndEventsWrapper)}>
      <>
        <ReportList config={config} />
      </>
    </div>
  )
})

AlarmsAndEventsReport.displayName = "Alarms And Events Report"
export default AlarmsAndEventsReport
