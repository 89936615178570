import { intersection, uniq } from "lodash"
import { VAS_TYPE, VAV_BOX } from "../../common"
import {
  ASC,
  BUTTON,
  PARETO_CATEGORY,
  defaultFacilityObject
} from "../../../constant"
import { sortByAlphanumericSpecialChars } from "src/blitz/DataGridTable/helper"
import { FAMILY_FIELD, STAND_ALONE } from "src/components/buildingSummary/constants"
import { frameEquipmentsTree, sortAnalyticsBuildingSummaryPage } from "src/utils/analyticsHelper"

export const formTypeDropDown = (data, configList, isBuildingPerformance) => {
  let typeDropDown = []
  if (isBuildingPerformance) {
    typeDropDown = formTypeDDBP(data, configList)
  } else {
    typeDropDown = formTypeDDEP(data, configList)
  }
  return typeDropDown
}

// -------------------- Type Drop Down of Building Performance ----------------------- //

export const formTypeDDBP = (
  equipmentList: any = [],
  chartAxisConfigList: any = []
) => {
  const equipmentHierarchy = frameEquipmentsTree(equipmentList, [], [], "", "", "")
  const equipmentListFramed = sortAnalyticsBuildingSummaryPage(equipmentHierarchy, FAMILY_FIELD, ASC)
  // List of Equipment Types which has charts
  const equipmentTypeNames = chartAxisConfigList
    ?.filter((obj: any) => obj?.value?.type === PARETO_CATEGORY)
    ?.map((obj) => obj?.value?.equipmentType)
  const equipTypesHasCharts = uniq(equipmentTypeNames)

  const { familyTypes = {} } = equipmentListFramed?.reduce(
    (acc, item: any) => {
      if (item?.type !== "None") {
        if (!acc.familyTypes[item.type]) {
          const familyType: any = {
            title: item.family,
            value: item.type,
            type: BUTTON
          }
          acc.familyTypes[item.type] = familyType
        }
      }
      return acc
    },
    {
      familyTypes: {}
    }
  )

  const equipListTypesList = Object.keys(familyTypes)
  const intersectionEquipmentTypes = intersection(equipTypesHasCharts, equipListTypesList);

  let typeDropDown = []
  intersectionEquipmentTypes?.forEach((type: any) => {
    if (familyTypes?.[type]) {
      typeDropDown.push(familyTypes?.[type])
    }
  })

  typeDropDown = sortByAlphanumericSpecialChars("title", "Asc", typeDropDown)
  typeDropDown.push(defaultFacilityObject)
  return typeDropDown
}

// -------------------- Type Drop Down of Building Performance ----------------------- //

// #########################################################################################

// -------------------- Type Drop Down of Equipment Performance ----------------------- //

export const formTypeDDEP = (
  equipmentList: any = [],
  chartAxisConfigList: any = []
) => {
  // List of Equipment Types which has charts
  const equipmentTypeNames = chartAxisConfigList
    ?.filter((obj: any) => {
      return obj?.value?.type !== PARETO_CATEGORY
    })
    ?.map((obj) => obj?.value?.equipmentType)
  const equipTypesHasCharts = uniq(equipmentTypeNames)
  if (equipmentList.length === 0) return []
  const {
    familyTypes = {},
    rootEquipments = {},
    filteredEquipments = []
  } = equipmentList?.reduce(
    (acc, item: any) => {
      if (item?.type !== "None") {
        const buttonObj = {
          title: item.name,
          value: item,
          type: "button",
          childList: [],
          siblingList: []
        }
        acc.filteredEquipments.push(buttonObj)

        if (!acc.familyTypes[item.type]) {
          const familyType: any = {
            title: item.family,
            value: item.type,
            options: []
          }
          if (VAS_TYPE.includes(item.type) && item?.isParent) {
            const buttonObj = {
              title: item.name,
              value: item
            }
            acc.rootEquipments[VAV_BOX].push(buttonObj)
          }
          acc.familyTypes[item.type] = familyType
        } else {
          if (VAS_TYPE.includes(item.type) && item?.isParent) {
            const buttonObj = {
              title: item.name,
              value: item
            }
            acc.rootEquipments[VAV_BOX].push(buttonObj)
          }
        }
      }
      return acc
    },
    {
      filteredEquipments: [],
      familyTypes: {},
      rootEquipments: {
        [VAV_BOX]: []
      }
    }
  )

  const everyLevel = formChildrenEveryLevelSibling(equipmentList)
  filteredEquipments.forEach((item) => {
    const equipmentObj =
      everyLevel?.find((obj) => obj?.id === item?.value?.id) || {}
    const children = equipmentObj?.childList || []
    const siblings = equipmentObj?.siblings || []
    if (children.length > 0) {
      item.childList.push(...children)
    }
    if (siblings.length > 0) {
      item.siblingList.push(...siblings)
    }
  })

  for (const key in rootEquipments) {
    if (rootEquipments[key]?.length > 0) {
      rootEquipments[key] = sortByAlphanumericSpecialChars(
        "title",
        "Asc",
        rootEquipments[key]
      )
      if (familyTypes[key]) {
        familyTypes[key].options = rootEquipments[key]
      }
    }
  }

  for (const key in familyTypes) {
    if (VAV_BOX === key) {
      const subEquipments = filteredEquipments.filter(
        (item) => item.value.type === key
      )
      familyTypes[key].options = familyTypes[key].options?.map((parentObj) => {
        const options = subEquipments?.filter((childObj) => {
          if (childObj.value?.parentId) {
            const parentIds = childObj.value?.parentId?.split("#")
            return (
              parentIds.includes(parentObj.value.id) && !childObj.value.isParent
            )
          } else {
            return false
          }
        })
        const optionsAvailable = options?.length > 0
        const returnObj = {
          ...parentObj
        }
        if (optionsAvailable) {
          returnObj.options = sortByAlphanumericSpecialChars(
            "title",
            "Asc",
            options
          )
        }
        return returnObj
      })
      // ?.filter((obj) => obj?.options?.length > 0)
      const StandAloneEquipments = subEquipments?.filter(
        (childObj) =>
          !childObj?.value?.parentId || childObj?.value?.parentId === "NA"
      )
      const standaloneObj = {
        title: STAND_ALONE,
        options: sortByAlphanumericSpecialChars(
          "title",
          "Asc",
          StandAloneEquipments
        )
      }
      if (standaloneObj?.options?.length > 0) {
        familyTypes[key].options = [...familyTypes[key].options, standaloneObj]
      }
    } else {
      familyTypes[key].options = sortByAlphanumericSpecialChars(
        "title",
        "Asc",
        filteredEquipments.filter((item) => item.value.type === key)
      )
    }
  }

  const equipmentDropDownOptions: any = Object.values(familyTypes) || []
  const filteredEquipmentTypes = sortByAlphanumericSpecialChars(
    "title",
    "Asc",
    equipmentDropDownOptions?.filter((obj) =>
      equipTypesHasCharts?.includes(obj?.value)
    )
  )
  return filteredEquipmentTypes
}

const inValidParentIds = ["", "NA", null, undefined, "(null)"]

const formChildrenEveryLevelSibling = (data: any = []) => {
  const equipmentArray = JSON.parse(JSON.stringify(data))

  const equipmentMap = {}
  equipmentArray.forEach((equipment) => {
    equipmentMap[equipment.id] = equipment
    equipmentMap[equipment.id].children = []
    equipmentMap[equipment.id].childList = []
    equipmentMap[equipment.id].siblings = []
  })

  equipmentArray.forEach((equipment) => {
    const parentIds = (equipment?.parentId?.split("#") || [])?.filter(
      (id) => !inValidParentIds?.includes(id)
    )

    const inEquipmentMap = parentIds?.some((id) => id in equipmentMap)
    if (inEquipmentMap) {
      parentIds?.map((id) => {
        const invalid = inValidParentIds?.includes(id)
        if (invalid) return
        const parent = equipmentMap[id]
        if (parent) {
          parent.children.push(equipment)
        }
      })
    }
  })

  equipmentArray.forEach((equipment) => {
    const parentIds = (equipment?.parentId?.split("#") || [])?.filter(
      (id) => !inValidParentIds?.includes(id)
    )

    const parent = equipmentMap[equipment.id]
    if (parent) {
      parentIds.forEach((parentId) => {
        const parentMap = equipmentMap[parentId]
        const siblings =
          parentMap?.children?.filter((item) => item.id !== equipment.id) || []
        equipment.siblings.push(...siblings)
      })
    }
  })

  const equipmentWithChildren = equipmentArray?.filter(
    (obj) => obj?.children?.length > 0 || obj?.siblings?.length > 0
  )

  equipmentWithChildren.forEach((equipment) => {
    if (equipment?.children) {
      equipment.children.forEach((child) => {
        assignChildren(child, equipment)
      })
    }
  })

  const finalArray = equipmentWithChildren?.map((obj) => {
    const childList = obj?.childList?.map((obj) => {
      return {
        ...obj,
        childList: [],
        siblings: [],
        children: []
      }
    })
    const siblings = obj?.siblings?.map((obj) => {
      return {
        ...obj,
        childList: [],
        siblings: [],
        children: []
      }
    })

    return {
      ...obj,
      childList: childList,
      siblings: siblings,
      children: []
    }
  })

  return finalArray
}

function assignChildren(child, parent) {
  if (child?.children) {
    child?.children?.forEach((child) => {
      assignChildren(child, parent)
    })
  }
  parent.childList.push(child)
}

// -------------------- Type Drop Down of Equipment Performance ----------------------- //

// #########################################################################################

// -------------------- Get Default value for Type Drop Down -------------------------- //

// To get default selected Equipment/Type information from Type Drop Down List

export const getDefaultTypeEquipment = (
  isBuildingPerformance,
  typeDropDownOptions = [],
  equipmentId: any = {},
  plainEquipmentList = [],
  chartId,
  configList = []
) => {
  let typeItem = null
  if (isBuildingPerformance) {
    const defaultChartType = configList?.find(
      (obj) => obj?.value?.id === chartId
    )
    const isPareto = defaultChartType?.value?.type === "Pareto"
    if (defaultChartType) {
      if (isPareto) {
        typeItem =
          typeDropDownOptions?.find(
            (obj) => obj?.value === defaultChartType?.value?.equipmentType
          ) || {}
      } else {
        typeItem =
          typeDropDownOptions?.find(
            (obj) => obj?.value === defaultChartType?.value?.category
          ) || {}
      }
    }
  } else {
    const equipmentInfo =
      plainEquipmentList?.find((obj) => obj?.id === equipmentId) || {}
    const equipmentTypeOptions =
      typeDropDownOptions?.find((obj) => obj?.value === equipmentInfo?.type)
        ?.options || []
    if (VAV_BOX === equipmentInfo?.type) {
      for (const obj of equipmentTypeOptions) {
        if (!typeItem) {
          for (const item of obj?.options || []) {
            if (item.value?.id === equipmentInfo?.id) {
              typeItem = item
              break
            }
          }
        } else {
          break
        }
      }
    } else {
      typeItem = equipmentTypeOptions?.find(
        (obj) => obj?.value?.id === equipmentInfo?.id
      )
    }
  }
  return typeItem
}
