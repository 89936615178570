import { useCallback, useRef, useEffect, useState, useMemo } from "react"
import {
  ModalDialog,
  SecondaryNav,
  MultiLevelDropdown,
  Typography,
  Button,
  Spinner
} from "src/blitz"
import { useSelector, useDispatch } from "react-redux"
import { t } from "src/translations/help"
import { useParams, useLocation } from "react-router-dom"
import { API } from "aws-amplify"
import DateRangePicker from "src/blitz/DateRange/DateRangePicker"
import {
  getDevicesByBuilding,
  getEquipmentTypesByBuilding,
  getEquipmentsByBuilding
} from "./graphql"
import {
  ADD,
  CUSTOM_RANGE,
  DATE,
  END,
  FOUR_WEEKS,
  MONTH,
  NONE,
  ONE_DAY,
  ONE_MONTH,
  ONE_WEEK,
  START,
  SUBTRACT
} from "src/blitz/DateRange/constant"
import {
  displayDateFormatter,
  getCalculatedDay
} from "src/blitz/DateRange/helper"
import { RawDataReport } from "./RawDataReport"
import { ExceptionReport } from "./ExceptionReport"
import { ChillerReport } from "./ChillerReport"
import { AlarmsAndEventsReport } from "./AlarmsAndEventsReport"
import { DigitalInspectionReport } from "./DigitalInspectionReport"
import {
  selectBuildingSummary,
  selectEquipmentTypes,
  selectExplicitEquipmentTypes
} from "src/redux/slicers/buildingSummarySlice"
import {
  setReportStatus,
  getReportStatus,
  setReportDialog,
  selectReportDialog,
  getIsValid,
  getIsDataExceeded,
  setFilter,
  getFilter,
  setRawDataEquipments,
  getReportType,
  setReportType,
  getBuildingList,
  setMultiBuildingsDialog,
  selectAppliedBuildings,
  setBuildingEquipmentTypes,
  getEquipments,
  setSelectedEquipments
} from "src/redux/slicers/reportSlice"
import {
  offeringDIAvailableEquipmentTypes,
  frameEquipmentsTree,
  iSDiReportDisabled,
  transformChillerProperties,
  DI_TAB_ALLOWED_EQUIP_TYPES
} from "./helper"
import {
  RAW_DATA,
  CHILLER_REPORT,
  EXCEPTION_REPORT,
  SELECT_ALL,
  LANG_ENGLISH,
  IMPERIAL_MEASURE,
  METRIC_MEASURE,
  CHILLER,
  ALARMS_AND_EVENTS_REPORT,
  DIGITAL_INSPECTION_REPORT,
  reportTimePeriodOptions,
  defaultTimePeriodOptions,
  systemOfMeasureOptions,
  languageOptions
} from "./constants"
import style from "./ReportDialog.module.scss"
import LoadingSpinner from "src/blitz/Loading/Loading"
import { ACTIONS } from "src/constants"
import { useQueryDateValues } from "src/hooks"
import { useQuery } from "src/hooks/APIHooks"
import { ReportErrorMessage } from "src/components/shared"
import moment from "moment"
import { reportOfferingCodeCheck } from "src/components/buildingSummary/helper"
import {
  ENABLING_CPR_REPORT_OFFERINGS,
  ENABLING_EHR_REPORT_OFFERINGS
} from "src/components/buildingSummary/constants"
import { getPropertyMappingByEquipmentID } from "src/components/equipmentSummary/graphql"
import { cloneDeep, get } from "lodash"
import { selectSelectedBuildingsEquipmentTypeList } from "src/redux/slicers/homeSlice"
import { selectEquipmentSummary } from "src/redux/slicers/equipmentSummarySlice"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import momentTimeZone from "moment-timezone"
import { fetchAllList } from "src/stack/Queries/fetchAll"
import translate, { TranslateComponent } from "src/common/translations"


const ReportDialog = () => {
  const { data: buildingSummary } = useSelector(selectBuildingSummary)
  const hasChillerEquipment = useSelector((state: any) =>
    selectExplicitEquipmentTypes(state, "Chiller")
  )
  const { buildingId } = buildingSummary?.buildingId
    ? buildingSummary
    : useParams()
  const [openCalendar, setOpenCalendar] = useState(false)
  const [noAvailableEquipmentTypes, setAvailableEquipmentTypes] =
    useState(false)
  const { loading, completed, error } = useSelector(getReportStatus)
  const appliedBuildings = useSelector(selectAppliedBuildings)
  const isValid = useSelector(getIsValid)
  const { loading: buildingListLoading } = useSelector(getBuildingList)
  const isDataExceeded = useSelector(getIsDataExceeded)
  const { queryStartDate, queryEndDate } = useQueryDateValues()
  const EquipmentTypesMapping = useSelector(selectEquipmentTypes)
  const equipments = useSelector(getEquipments)
  const selectedBuildingsEquipmentTypeList = useSelector(selectSelectedBuildingsEquipmentTypeList)
  const route = useLocation();
  const { loading: equipmentLoading, data: equipment } = useSelector(
    selectEquipmentSummary
  )
  const completedMessage = completed ? "Completed" : ""

  // RDR and DI Report
  const processEquipmentList = async (data: any = []) => {
    dispatch(setRawDataEquipments({ type: "Loading" }))
    setAvailableEquipmentTypes(!(data?.length > 0))
    const equipmentTypes = frameEquipmentsTree(data, false)
    const newEquipmentTypes = await addMissingChillerPropertyTypes(equipmentTypes)
    setEquipmentTypes(newEquipmentTypes)
    if (buildingId && selectedEquipmentType?.value) {
      const selectedEquipmentTypeObject = newEquipmentTypes?.filter(
        ({ value }) => value === selectedEquipmentType?.value
      )?.[0]
      if (selectedEquipmentTypeObject) {
        if (
          !selectedEquipmentTypeObject?.sub &&
          selectedEquipmentTypeObject?.options
        ) {
          selectedEquipmentTypeObject?.options?.forEach((optionObj) => {
            if (
              optionObj?.sub?.find(
                (subObj) => subObj?.id === equipment?.id
              )
            ) {
              dispatch(
                setRawDataEquipments({
                  type: "Success",
                  data: optionObj?.sub || []
                })
              )
              dispatch(
                setFilter({
                  equipmentType: {
                    label:
                      reportType?.value === CHILLER_REPORT
                        ? "Chiller"
                        : optionObj?.title,
                    value:
                      reportType?.value === CHILLER_REPORT
                        ? "Chiller"
                        : optionObj?.value
                  },
                  error: false,
                  isCompleted: false
                })
              )
            }
          })
        } else {
          dispatch(
            setRawDataEquipments({
              type: "Success",
              data: selectedEquipmentTypeObject?.sub || []
            })
          )
        }
      } else {
        dispatch(setRawDataEquipments({ type: "Failure" }))
      }
    }
    dispatch(setBuildingEquipmentTypes(newEquipmentTypes))
  }

  const fetchEquipmentPropertiesByEquipmentID = async (equipmentId) => {
    try {
      const response = await API.graphql({
        query: getPropertyMappingByEquipmentID,
        variables: { id: equipmentId },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      })
      const data = get(
        response,
        "data.getPropertyMappingByEquipmentID.items",
        null
      )
      return data
    } catch (err) {
      throw err
    }
  }
  const addMissingChillerPropertyTypes = async (equipmentTypes) => {
    const chillerType = equipmentTypes?.filter(
      (item) => item?.value === CHILLER
    )
    const chillerEquipments = chillerType?.[0]?.sub
    if (chillerEquipments?.length > 0) {
      const ChillerEquipmentObject = {}
      const ChillerEquipIdwithMissingProperties = []
      chillerEquipments?.forEach((item: any) => {
        const { compCondType, instance, id } = item
        if (
          !compCondType ||
          compCondType === " " ||
          compCondType === "" ||
          !instance ||
          instance === " " ||
          instance === ""
        ) {
          ChillerEquipIdwithMissingProperties?.push(id)
        }
        ChillerEquipmentObject[id] = item
      })
      if (ChillerEquipIdwithMissingProperties?.length > 0) {
        const fetchallEquipmentMissingProperties = async () => {
          try {
            const analyticsPromises = ChillerEquipIdwithMissingProperties?.map(
              async (equipId) => {
                const response = await fetchEquipmentPropertiesByEquipmentID(
                  equipId
                )
                if (response) {
                  const { instance, compCondType } = transformChillerProperties(
                    response || []
                  )
                  if (compCondType) {
                    ChillerEquipmentObject[equipId].compCondType = compCondType
                  }
                  if (instance) {
                    ChillerEquipmentObject[equipId].instance = instance
                  }
                }
              }
            )
            await Promise.all(analyticsPromises)
          } catch (err) {
            console.warn("Error in adding missing properties to chillers")
          }
        }
        await fetchallEquipmentMissingProperties()
        const subArray = Object?.values(ChillerEquipmentObject)
        const newEquipmentTypes = equipmentTypes
        const index = newEquipmentTypes?.indexOf(chillerType[0])
        chillerType[0].sub = subArray
        if (index !== -1) {
          newEquipmentTypes[index] = chillerType[0]
        }
        return newEquipmentTypes
      } else {
        return equipmentTypes
      }
    } else {
      return equipmentTypes
    }
  }

  const { refetch: refetchEquipmentsByEquipmentId } = useQuery({
    query: getEquipmentsByBuilding,
    dataPath: "data.listEquipmentsByBuildingAndActiveAndType.items",
    disableInitialLoad: true,
    onSuccess: (data: any) => {
      dispatch(
        setRawDataEquipments({
          type: "Success",
          data: data || []
        })
      )
    },
    onError: () => {
      dispatch(setRawDataEquipments({ type: "Failure" }))
    }
  })
  const { refetch: refetchDevicesByBuildingId } = useQuery({
    query: getDevicesByBuilding,
    dataPath: "data.listDevicesByBuilding.items",
    disableInitialLoad: true,
    onSuccess: (data: any) => {
      const responseData = data?.map((item: any) => {
        if (!item.name) {
          item.name = item?.serial
        }
        return item
      })
      dispatch(
        setRawDataEquipments({
          type: "Success",
          data: responseData || []
        })
      )
    },
    onError: () => {
      dispatch(setRawDataEquipments({ type: "Failure" }))
    }
  })

  const {
    equipmentType: selectedEquipmentType,
    timePeriod,
    startDate,
    endDate,
    language,
    systemOfMeasure
  } = useSelector(getFilter)
  const [selectedDate, setSelectedDate] = useState({
    start: new Date(startDate),
    end: new Date(endDate)
  })
  const [calendarView, setCalendarView] = useState({
    start: DATE,
    end: DATE
  })

  const getLanguageDetails = (langOptions, language) => {
    const selectedLanguageOption = langOptions?.find((obj) => obj?.value === language)
    if (selectedLanguageOption?.title) {
      return { title: selectedLanguageOption?.title, langCode: language }
    }
    dispatch(
      setFilter({
        language: LANG_ENGLISH
      })
    )
    return { title: langOptions?.find((obj) => obj?.value === LANG_ENGLISH).title, langCode: language }
  }

  const languageTitleSelected = useMemo(
    () => {
      return getLanguageDetails(languageOptions, language)
    },
    [language]
  )

  const systemOfMeasureSelected = useMemo(
    () =>
    ({
      title: systemOfMeasureOptions?.find((obj) => obj?.value === systemOfMeasure)?.title,
      metricCode: systemOfMeasure
    }),
    [systemOfMeasure]
  )

  const [selectedLanguage, setSelectedLanguage] = useState(languageTitleSelected)
  const [selectedMetric, setSelectedMetric] = useState(systemOfMeasureSelected)

  const DIReportDisabled = useMemo(() => {
    let availableEquipmentTypes = []
    if (route.pathname === "/buildings") {
      availableEquipmentTypes = selectedBuildingsEquipmentTypeList;
    } else {
      availableEquipmentTypes = Object.keys(EquipmentTypesMapping).filter(equipmentType => EquipmentTypesMapping[equipmentType]);
    }
    return iSDiReportDisabled(availableEquipmentTypes, buildingSummary)
  }, [buildingSummary, EquipmentTypesMapping, equipments])

  const rawDataCompRef = useRef(null)
  const exceptionCompRef = useRef(null)
  const chillerCompRef = useRef(null)
  const alarmAndEventCompRef = useRef(null)
  const digitalInspCompRef = useRef(null)
  const dispatch = useDispatch()
  const showReportDialog = useSelector(selectReportDialog)
  const reportType = useSelector(getReportType)
  const [equipmentTypes, setEquipmentTypes] = useState<any>([])
  const reportTypes: any = useMemo(
    () => [
      {
        title: "Alarms and Events",
        type: "button",
        value: ALARMS_AND_EVENTS_REPORT,
        id: ALARMS_AND_EVENTS_REPORT,
        testId: "reports-dropdown-select-alarmsandevents"
      },
      {
        title: "Chiller Performance",
        disabled: hasChillerEquipment
          ? reportOfferingCodeCheck(
            ENABLING_CPR_REPORT_OFFERINGS,
            buildingSummary?.offerings,
            buildingSummary
          )
          : true,
        type: "button",
        value: CHILLER_REPORT,
        id: CHILLER_REPORT,
        testId: "reports-dropdown-select-chillerReport"
      },
      {
        title: "Digital Inspection",
        type: "button",
        value: DIGITAL_INSPECTION_REPORT,
        id: DIGITAL_INSPECTION_REPORT,
        disabled: DIReportDisabled,
        testId: "reports-dropdown-select-digitalInspectionReport"
      },
      {
        title: "Exception History",
        type: "button",
        disabled: reportOfferingCodeCheck(
          ENABLING_EHR_REPORT_OFFERINGS,
          buildingSummary?.offerings,
          buildingSummary
        ),
        value: EXCEPTION_REPORT,
        id: EXCEPTION_REPORT,
        testId: "reports-dropdown-select-exceptionRreport"
      },
      {
        title: "Raw Data",
        type: "button",
        value: RAW_DATA,
        id: RAW_DATA,
        testId: "reports-dropdown-select-rawData"
      }
    ],
    [t]
  )

  useEffect(() => {
    dispatch(setReportStatus(ACTIONS.RESET))
    if (buildingId) fetchEquipments()
  }, [buildingId, reportTypes, reportType, appliedBuildings])

  useEffect(() => {
    if (reportType?.value === CHILLER_REPORT) {
      const qDate = queryEndDate ? new Date(queryEndDate) : new Date()
      const maxStartDate = moment(qDate).startOf("month").toDate()
      const monthEndDate = moment(qDate).endOf("month").toDate()
      const maxEndDate = buildingSummary?.tz
        ? momentTimeZone().tz(buildingSummary?.tz)?.toDate() : momentTimeZone().tz("UTC")?.toDate()
      setSelectedDate({
        start: maxStartDate,
        end: moment(monthEndDate)?.isAfter(maxEndDate) ? maxEndDate : monthEndDate
      })
      dispatch(
        setFilter({
          timePeriod: ONE_MONTH,
          startDate: maxStartDate.toISOString(),
          endDate: moment(monthEndDate)?.isAfter(maxEndDate) ? maxEndDate?.toISOString() : monthEndDate?.toISOString()
        })
      )
      setCalendarView({
        start: MONTH,
        end: DATE
      })
    } else {
      setSelectedDate({
        start: queryStartDate ? new Date(queryStartDate) : new Date(),
        end: queryEndDate ? new Date(queryEndDate) : new Date()
      })
      dispatch(
        setFilter({
          startDate: queryStartDate
            ? new Date(queryStartDate)?.toISOString()
            : new Date().toISOString(),
          endDate: queryEndDate
            ? new Date(queryEndDate)?.toISOString()
            : new Date().toISOString()
        })
      )
    }
  }, [])

  const onChangeReportType = (_: string, selectedReport: any) => {
    const { title, value } = selectedReport
    if (reportType?.value === value) return
    const props = {
      reportType: { title, value },
      switch: true
    }
    dispatch(setReportType(props))
    if (value === CHILLER_REPORT) {
      setCalendarView({
        start: MONTH,
        end: DATE
      })
      const maxStartDate = moment(new Date()).startOf("month").toDate()
      const monthEndDate = moment(queryEndDate || "").endOf("month").toDate()
      const maxEndDate = buildingSummary?.tz
        ? momentTimeZone().tz(buildingSummary?.tz)?.toDate() : momentTimeZone().tz("UTC")?.toDate()
      setSelectedDate({
        start: maxStartDate,
        end: moment(monthEndDate)?.isAfter(maxEndDate) ? maxEndDate : monthEndDate
      })
      dispatch(
        setFilter({
          equipmentType: {
            label: "Chiller",
            value: "Chiller"
          },
          timePeriod: ONE_MONTH,
          startDate: maxStartDate.toISOString(),
          endDate: moment(monthEndDate)?.isAfter(maxEndDate) ? maxEndDate?.toISOString() : monthEndDate?.toISOString()
        })
      )
    } else {
      setCalendarView({
        start: DATE,
        end: DATE
      })
    }

  }

  const onClickGenerate = useCallback(() => {
    if (reportType?.value === RAW_DATA) {
      {
        rawDataCompRef?.current?.onClickGenerate()
        trackEvent(USER_EVENTS.REPORTS.events.CREATE_RAW_DATA_REPORT)
      }
    } else if (reportType?.value === EXCEPTION_REPORT) {
      {
        exceptionCompRef?.current?.onClickGenerate()
        trackEvent(USER_EVENTS.REPORTS.events.CREATE_EXCEPTION_HISTORY_REPORT)
      }
    } else if (reportType?.value === CHILLER_REPORT) {
      {
        chillerCompRef?.current?.onClickGenerate()
        trackEvent(USER_EVENTS.REPORTS.events.CREATE_CHILLER_PERFORMANCE_REPORT)
      }
    } else if (reportType?.value === DIGITAL_INSPECTION_REPORT) {
      {
        digitalInspCompRef?.current?.onClickGenerate()
        trackEvent(USER_EVENTS.REPORTS.events.CREATE_DIGITAL_INSPECTION_REPORT)
      }
    } else if (reportType?.value === ALARMS_AND_EVENTS_REPORT) {
      {
        alarmAndEventCompRef?.current?.onClickGenerate()
        trackEvent(USER_EVENTS.REPORTS.events.CREATE_ALARMS_REPORT)
      }
    }
  }, [
    chillerCompRef,
    rawDataCompRef,
    digitalInspCompRef,
    showReportDialog,
    reportType
  ])

  const closeReportModal = useCallback(() => {
    dispatch(setReportDialog(false))
  }, [])

  const modalConfig = useMemo(
    () => ({
      modal: showReportDialog,
      heading: "Reports",
      fullScreen: true,
      buttons: [
        {
          type: "button",
          variant: "blue",
          handleClick: () => {
            onClickGenerate()
          },
          text: t("reports.generateReport"),
          disabled: !isValid || loading || isDataExceeded
        },
        {
          type: "button",
          variant: "dark",
          handleClick: closeReportModal,
          text: t("common.close")
        }
      ],
      handleClose: closeReportModal
    }),
    [showReportDialog, loading, isValid, isDataExceeded]
  )

  const errorModalConfig = useMemo(
    () => ({
      modal: error,
      heading: t("reports.errorModal.retryLoadError"),
      isShowCloseIcon: false,
      buttons: [
        {
          type: "button",
          variant: "dark",
          handleClick: () => dispatch(setReportStatus(ACTIONS.RESET)),
          text: t("common.close")
        }
      ]
    }),
    [error]
  )

  const fetchEquipments = async () => {
    setEquipmentTypes([])
    let equipmentTypes = []
    if (reportType?.value === EXCEPTION_REPORT) {
      let buildingIds = []
      if (appliedBuildings?.length >= 1) {
        buildingIds = appliedBuildings?.map((building) => {
          return building?.id
        })
      } else {
        buildingIds = [buildingSummary?.id]
      }
      const finalPayload = { buildingIds: buildingIds, filter: ["analytics"] }
      const apiData: any = await API.graphql({
        query: getEquipmentTypesByBuilding,
        variables: { requestBody: JSON.stringify(finalPayload) }
      })
      const parsedData = JSON.parse(apiData?.data?.getBuildingEquipmentTypes)
        ?.body?.EquipmentType
      Object.keys(parsedData)?.forEach((item) => {
        if (item !== NONE) {
          equipmentTypes = [
            ...equipmentTypes,
            { title: item, value: parsedData[item], type: "button" }
          ]
        }
      })
      setEquipmentTypes([...SELECT_ALL, ...equipmentTypes])
      dispatch(setBuildingEquipmentTypes(equipmentTypes))
    } else if (reportType?.value === RAW_DATA) {
      // fetchEquipmentTypes({ buildingId })
      const response = await fetchAllList({ query: getEquipmentsByBuilding, variables: { buildingId }, isHeadLess: false, path: "data.listEquipmentsByBuildingAndActiveAndType" });
      const data = cloneDeep(response?.list || [])
      await processEquipmentList(data)
    } else if (reportType?.value === CHILLER_REPORT) {
      fetchEquipmentsByEquipmentId("Chiller")
    } else if (reportType?.value === ALARMS_AND_EVENTS_REPORT) {
      fetchDevicesByBuildingId({ id: buildingId })
    } else if (reportType?.value === DIGITAL_INSPECTION_REPORT) {
      const availableEquipmentTypes = [
        ...offeringDIAvailableEquipmentTypes(buildingSummary?.offerings),
        ...DI_TAB_ALLOWED_EQUIP_TYPES
      ]
      if (availableEquipmentTypes?.length > 0) {
        const DIEquipmentTypeFilter = availableEquipmentTypes?.map((item) => ({
          type: { eq: item }
        }))
        const response = await fetchAllList({ query: getEquipmentsByBuilding, variables: { buildingId, filter: { or: DIEquipmentTypeFilter } }, isHeadLess: false, path: "data.listEquipmentsByBuildingAndActiveAndType" });
        const data = cloneDeep(response?.list || [])
        await processEquipmentList(data)
        setAvailableEquipmentTypes(false)
      } else {
        setAvailableEquipmentTypes(true)
      }
    }
  }

  const handleDateRangeSubmit = (selectedDate: any) => {
    if (selectedDate?.start && selectedDate?.end) {
      dispatch(
        setFilter({
          startDate: selectedDate.start.toISOString(),
          endDate: selectedDate.end.toISOString()
        })
      )
    }
  }

  const handleTimePeriodChange = (val: any) => {
    dispatch(setFilter({ timePeriod: val }))
    if (val === ONE_WEEK) {
      const maxStartDate = getCalculatedDay(SUBTRACT, ONE_WEEK)
      if (selectedDate?.start > maxStartDate) {
        setSelectedDate({
          start: maxStartDate,
          end: new Date()
        })
        dispatch(
          setFilter({
            startDate: maxStartDate.toISOString(),
            endDate: new Date().toISOString()
          })
        )
      } else {
        setSelectedDate((prvsVal) => ({
          start: prvsVal?.start,
          end: getCalculatedDay(ADD, ONE_WEEK, prvsVal?.start)
        }))
        if (selectedDate?.start) {
          const newEndDate = getCalculatedDay(ADD, ONE_WEEK, selectedDate.start)
          dispatch(
            setFilter({
              startDate: selectedDate.start.toISOString(),
              endDate: newEndDate.toISOString()
            })
          )
        }
      }
    } else if (val === FOUR_WEEKS) {
      const maxStartDate = getCalculatedDay(SUBTRACT, FOUR_WEEKS)
      setSelectedDate({
        start: maxStartDate,
        end: new Date()
      })
      dispatch(
        setFilter({
          startDate: maxStartDate.toISOString(),
          endDate: new Date().toISOString()
        })
      )
    } else if (val === CUSTOM_RANGE) {
      setSelectedDate((prvsVal) => ({
        start: prvsVal?.start,
        end: prvsVal?.start > prvsVal?.end ? prvsVal?.start : prvsVal?.end
      }))
      dispatch(
        setFilter({
          startDate: selectedDate.start.toISOString(),
          endDate:
            selectedDate.start > selectedDate.end
              ? selectedDate.start.toISOString()
              : selectedDate.end.toISOString()
        })
      )
    } else {
      const today = new Date()
      setSelectedDate({
        start: today,
        end: today
      })
      dispatch(
        setFilter({
          startDate: today.toISOString(),
          endDate: today.toISOString()
        })
      )
    }
  }
  const handleLanguageChange = (val: any) => {
    const languageDetails = languageOptions?.find((obj) => obj?.value === val)
    if (languageDetails?.title) {
      setSelectedLanguage({ title: languageDetails?.title, langCode: languageDetails?.value })
    } else {
      dispatch(
        setFilter({
          language: LANG_ENGLISH
        })
      )
      const enLanguage = languageOptions?.find((obj) => obj?.value === LANG_ENGLISH)
      setSelectedLanguage({ title: enLanguage?.title, langCode: enLanguage?.value })
    }
  }

  const handleSystemOfMeasureChange = (val: any) => {
    const metricDetails = systemOfMeasureOptions?.find((obj) => obj?.value === val)
    setSelectedMetric({ title: metricDetails?.title, metricCode: metricDetails?.value })
  }

  const handleEquipmentType = (_: any, objectValue: any) => {
    dispatch(setReportStatus(ACTIONS.RESET))
    if (
      reportType?.value === RAW_DATA ||
      reportType?.value === DIGITAL_INSPECTION_REPORT
    ) {
      dispatch(
        setRawDataEquipments({
          type: "Success",
          data: objectValue?.sub || []
        })
      )
    }
    dispatch(
      setFilter({
        equipmentType: {
          label:
            reportType?.value === CHILLER_REPORT
              ? "Chiller"
              : objectValue?.title,
          value:
            reportType?.value === CHILLER_REPORT
              ? "Chiller"
              : objectValue?.value
        },
        error: false,
        isCompleted: false
      })
    )
  }

  const fetchEquipmentsByEquipmentId = async (value: any) => {
    dispatch(setRawDataEquipments({ type: "Loading" }))
    refetchEquipmentsByEquipmentId({
      buildingId: buildingId,
      type: value
    })
  }
  const fetchDevicesByBuildingId = async (value: any) => {
    dispatch(setRawDataEquipments({ type: "Loading" }))
    refetchDevicesByBuildingId(value)
  }
  const timePeriodsOptions: any = useMemo(
    () => reportTimePeriodOptions?.[reportType?.value] || defaultTimePeriodOptions,
    [reportType?.value]
  )

  const facilityNameText = useMemo(() => {
    if (appliedBuildings?.length === 0 || reportType?.value === RAW_DATA) {
      return buildingSummary?.name
    } else if (appliedBuildings?.length === 1) {
      return appliedBuildings[0]?.name
    } else {
      return t("reports.multipleFacilities")
    }
  }, [appliedBuildings, buildingSummary?.name, reportType?.value])

  const disableEditBtn = useMemo(
    () =>
      reportType?.value === RAW_DATA ||
      reportType?.value === CHILLER_REPORT ||
      reportType?.value === ALARMS_AND_EVENTS_REPORT ||
      reportType?.value === DIGITAL_INSPECTION_REPORT ||
      buildingListLoading,
    [reportType?.value, buildingListLoading]
  )

  const timePeriodTitleSelected = useMemo(
    () => timePeriodsOptions?.find((obj) => obj?.value === timePeriod)?.title,
    [timePeriod]
  )
  const startDateD = useMemo(
    () => displayDateFormatter(new Date(startDate)),
    [startDate]
  )
  const endDateD = useMemo(
    () => displayDateFormatter(new Date(endDate)),
    [endDate]
  )
  const submittedDateDis =
    startDateD + (timePeriod !== ONE_DAY ? " - " + endDateD : "")

  const getModalComponent = () => {
    switch (reportType?.value) {
      case RAW_DATA:
        return (
          <RawDataReport
            ref={rawDataCompRef}
            selectedEquipmentType={selectedEquipmentType}
            selectedLanguage={selectedLanguage}
            selectedMetric={selectedMetric}
          />
        )
      case EXCEPTION_REPORT:
        return <ExceptionReport ref={exceptionCompRef} />
      case CHILLER_REPORT:
        return <ChillerReport ref={chillerCompRef} selectedLanguage={selectedLanguage} selectedMetric={selectedMetric} />
      case ALARMS_AND_EVENTS_REPORT:
        return <AlarmsAndEventsReport ref={alarmAndEventCompRef} selectedLanguage={selectedLanguage} />
      case DIGITAL_INSPECTION_REPORT:
        return (
          <DigitalInspectionReport
            ref={digitalInspCompRef}
            selectedEquipmentType={selectedEquipmentType}
            selectedLanguage={selectedLanguage}
          />
        )
    }
  }

  const cancelReport = () => {
    dispatch(setReportStatus(ACTIONS.CANCELLED))
    trackEvent(USER_EVENTS.REPORTS.events.CANCEL_GENERATE_REPORT)
  }

  const handleCustomDateSelection = (
    calendarType: any,
    date: any,
    pickerCategory: any
  ) => {
    if (
      calendarType === START &&
      moment(selectedDate?.end).isSameOrBefore(moment(date), "day")
    ) {
      const end = new Date(date)
      end.setDate(date.getDate() + 1)
      setSelectedDate({
        start: date,
        end: end
      })
    } else if (
      calendarType === END &&
      moment(selectedDate?.start).isSameOrAfter(moment(date), "day")
    ) {
      const start = new Date(date)
      start.setDate(date.getDate() - 1)
      setSelectedDate({
        start: start,
        end: date
      })
    } else {
      setSelectedDate((prvsVal) => ({
        start:
          calendarType === START
            ? date
            : pickerCategory === ONE_DAY
              ? prvsVal?.start
              : pickerCategory === CUSTOM_RANGE
                ? date < prvsVal?.start
                  ? date
                  : prvsVal?.start
                : getCalculatedDay(SUBTRACT, pickerCategory, date),
        end:
          calendarType === END
            ? date
            : pickerCategory === ONE_DAY
              ? date
              : pickerCategory === CUSTOM_RANGE
                ? date > prvsVal?.end
                  ? date
                  : prvsVal?.end
                : getCalculatedDay(ADD, pickerCategory, date)
      }))
    }
  }

  const getMaxStartDateProps = () => {
    if (reportType?.value === DIGITAL_INSPECTION_REPORT && timePeriod === CUSTOM_RANGE) {
      return new Date(buildingSummary?.localTime).setDate(
        new Date(buildingSummary?.localTime).getDate() - 1
      )
    } else {
      return null
    }
  }

  return (
    <>
      <ModalDialog
        modalConfig={modalConfig}
        titleClassName={style.reportDialogHeader}
        bodyClassName={style.reportDialogBody}
        footerClassName={style.reportDialogFooter}
        showSpinner={loading}
        showCompleted={completed}
        showDataExceeded={isDataExceeded}
        completedMessage={completedMessage}
      >
        <SecondaryNav className={style.textAlign}>
          <Typography
            tagType="span"
            styleType="p4"
            data-testid="report-dialog-facility-name-label"
            className={style.fontStyle}
          >
            <>
              {translate("Facility Name")}
              : {facilityNameText}
            </>
          </Typography>
          <Button
            type="button"
            onClick={() => dispatch(setMultiBuildingsDialog(true))}
            disabled={disableEditBtn}
            testId="report-dialog-edit-button"
          >
            {buildingListLoading ? (
              <div className={style.spinner}>
                <Spinner loading={buildingListLoading} />
              </div>
            ) : (
              <span>
                <TranslateComponent>
                  Edit
                </TranslateComponent>
              </span>
            )}
          </Button>
          <span className={style.divider} />
          <Typography
            tagType="span"
            styleType="p4"
            data-testid="report-dialog-report-label"
          >
            <TranslateComponent>Report:</TranslateComponent>
          </Typography>
          <MultiLevelDropdown
            translateCurrentViewOff={false}
            title={reportType?.title}
            options={reportTypes}
            handler={onChangeReportType}
            testId="report-dialog-report-types-dropdown-field"
            actionClassName={style.dropdownFontStyle}
          />
          <span className={style.divider} />
          <Typography
            tagType="span"
            styleType="p4"
            data-testid="report-dialog-time-period-label"
            className={style.fontStyle}
          >
            <TranslateComponent>Time Period:</TranslateComponent>
          </Typography>
          <MultiLevelDropdown
            title={timePeriodTitleSelected}
            options={timePeriodsOptions}
            handler={handleTimePeriodChange}
            testId="report-dialog-time-period-dropdown-field"
            actionClassName={style.DropdownFontStyle}
            disabled={reportType?.value === CHILLER_REPORT}
          />
          <DateRangePicker
            openCalendar={openCalendar}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            calendarView={calendarView}
            setCalendarView={setCalendarView}
            pickerCategory={timePeriod}
            setOpenCalendar={setOpenCalendar}
            maxStartDateProps={getMaxStartDateProps()}
            maxEndDateProps={new Date(buildingSummary?.localTime)}
            submitHandler={handleDateRangeSubmit}
            testId={"report-dialog--range-date-picker-field"}
            handleCustomDateSelection={
              reportType?.value === DIGITAL_INSPECTION_REPORT &&
                timePeriod === CUSTOM_RANGE
                ? handleCustomDateSelection
                : null
            }
          />
          <Typography tagType="span" styleType="p4" className={style.fontStyle}>
            <TranslateComponent>{submittedDateDis}</TranslateComponent>
          </Typography>

          {reportType?.value !== ALARMS_AND_EVENTS_REPORT && (
            <>
              <span className={style.divider} />
              <Typography
                tagType="span"
                styleType="p4"
                className={style.fontStyle}
              >
                <TranslateComponent>Select System or Equipment Type:</TranslateComponent>
              </Typography>
              <MultiLevelDropdown
                translateCurrentViewOff={false}
                title={selectedEquipmentType?.label}
                customTranslationOn={true}
                options={equipmentTypes}
                handler={handleEquipmentType}
                testId="report-dialog-equipment-types-dropdown-field"
                disabled={
                  reportType?.value === CHILLER_REPORT ||
                  noAvailableEquipmentTypes
                }
                enableScroll={reportType?.value === EXCEPTION_REPORT}
                actionClassName={style.dropdownFontStyle}
              />
            </>
          )}

          {(reportType?.value === CHILLER_REPORT ||
            reportType?.value === RAW_DATA ||
            reportType?.value === DIGITAL_INSPECTION_REPORT ||
            reportType?.value === ALARMS_AND_EVENTS_REPORT) && (
              <>
                <span className={style.divider} />
                <Typography
                  tagType="span"
                  styleType="p4"
                  className={style.fontStyle}
                >
                  <TranslateComponent>Select Language:</TranslateComponent>
                </Typography>
                <MultiLevelDropdown
                  translateCurrentViewOff={true}
                  title={selectedLanguage?.title}
                  options={languageOptions}
                  handler={handleLanguageChange}
                  testId="report-dialog-language-types-dropdown-field"
                  actionClassName={style.dropdownFontStyle}
                // disabled={reportType?.value === DIGITAL_INSPECTION_REPORT}
                />
              </>
            )}

          {(reportType?.value === CHILLER_REPORT ||
            // reportType?.value === DIGITAL_INSPECTION_REPORT ||
            reportType?.value === RAW_DATA) && (
              <>
                <span className={style.divider} />
                <Typography
                  tagType="span"
                  styleType="p4"
                  className={style.fontStyle}
                >
                  <TranslateComponent>Select System Of Measure:</TranslateComponent>
                </Typography>
                <MultiLevelDropdown
                  title={selectedMetric?.title}
                  options={systemOfMeasureOptions}
                  handler={handleSystemOfMeasureChange}
                  testId="report-dialog-system-of-measure-type-dropdown-field"
                  actionClassName={style.dropdownFontStyle}
                // disabled={reportType?.value === DIGITAL_INSPECTION_REPORT}
                />
              </>
            )}
        </SecondaryNav>
        {getModalComponent()}
        {loading && (
          <LoadingSpinner
            progressMessage={t("reports.export_started")}
            cancelText={t("common.cancel_loading")}
            handleCancel={cancelReport}
            showCancel
            showProgress
          />
        )}
      </ModalDialog>

      {error && (
        <ReportErrorMessage modalConfig={errorModalConfig}></ReportErrorMessage>
      )}
    </>
  )
}

export default ReportDialog
