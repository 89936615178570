import { Scatter_CHART_CATEGORY,ScatterWithLimits_CHART_CATEGORY,LineWithControlRangeAndMean_CHART_CATEGORY, LineWithBinaryStates_CHART_CATEGORY,Line_CHART_CATEGORY,ScatterWithPerformanceCurve_CHART_CATEGORY } from "src/blitz/Charts/constants"

export const addPropertyCharts = [Line_CHART_CATEGORY ,LineWithControlRangeAndMean_CHART_CATEGORY]
export const FormatEnabledCharts = [Line_CHART_CATEGORY,LineWithControlRangeAndMean_CHART_CATEGORY,Scatter_CHART_CATEGORY,
  ScatterWithPerformanceCurve_CHART_CATEGORY]
export const refereshYApplicableCharts = [
  Line_CHART_CATEGORY,
  LineWithBinaryStates_CHART_CATEGORY,
  LineWithControlRangeAndMean_CHART_CATEGORY,
  Scatter_CHART_CATEGORY,
  ScatterWithLimits_CHART_CATEGORY
]
export const possibleYAxesNames = ["y1", "y2", "y3", "y4"]
