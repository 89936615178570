import moment from "moment"
import {
  chartDateFormat,
  customHpathEquipmentTypes,
  CHILLER_COMPRESSOR_COUNT,
  CHILLER_COMPRESSOR_TYPE,
  CHILLER_CONDENSOR_TYPE,
  CHILLER_CIRCUIT_COUNT,
  CHARACTERISTIC_CURVE,
  CHILLER_COMPRESSOR_COUNT_ARRAY,
  ITEM_SEPERATION
} from "../../../constant"
import {
  LineWithControlRangeAndMean_CHART_CATEGORY,
  StackedBar_CHART_CATEGORY
} from "src/blitz/Charts/constants"
import { has, isArray, isEmpty, isString, uniq } from "lodash"
import { getCircuitAndCompressorInstances } from "../../exceptions"

export const generateChartPayloadBody = (
  chartConfig: any = {},
  equipmentSelected,
  buildingId,
  sDate,
  eDate,
  tz = "",
  added = false,
  addedAxisList: any = [],
  chillerApplicableProperties: any = [],
  selectedEquipmentProperties: any = [],
  systemOfMeasure: any = ""
) => {
  const startDate = moment(sDate, chartDateFormat).format("YYYY-MM-DD")
  const endDate = moment(eDate, chartDateFormat).format("YYYY-MM-DD")
  const buildingDateTime = moment.tz.zone(tz)
    ? moment.tz(tz)?.format("YYYY-MM-DD HH:mm:ss")
    : moment().format("YYYY-MM-DD HH:mm:ss")
  const chartType =
    chartConfig?.type === StackedBar_CHART_CATEGORY
      ? "Bar"
      : chartConfig?.type === LineWithControlRangeAndMean_CHART_CATEGORY
        ? "TimeLine"
        : chartConfig?.type
  let payload: any = {
    startDate: startDate,
    endDate: endDate,
    category: chartConfig?.category,
    chartType: chartType,
    equipmentType: chartConfig?.equipmentType,
    buildingDateTime,
    building: {
      id: buildingId,
      pId: [],
      type: chartConfig?.equipmentType
    },
    weather: [],
    settings: []
  }

  if (!added) {
    const payloadObject = structureFromConfig(
      chartConfig,
      equipmentSelected,
      buildingId,
      chillerApplicableProperties,
      selectedEquipmentProperties
    )

    const equipmentTypeObject: any = {}
    const configEquipmentsPropertiesObjects = payloadObject?.equipment || []

    addedAxisList?.forEach((obj) => {
      const added = obj?.added || {}
      Object.keys(added)?.forEach((equipType) => {
        if (!equipmentTypeObject?.[equipType]) {
          equipmentTypeObject[equipType] = []
        }
        let addedLegends = added?.[equipType] || []
        addedLegends = addedLegends?.map((item) => {
          const sep = item?.split(ITEM_SEPERATION);
          return [sep?.[0], sep?.[1]]
        })
        equipmentTypeObject[equipType].push(...addedLegends)
      })
    })

    configEquipmentsPropertiesObjects?.forEach((obj) => {
      if (!equipmentTypeObject?.[obj?.type]) {
        equipmentTypeObject[obj?.type] = []
      }
      const equipType = obj?.type || ""
      const pIDs = obj?.pId?.map((i) => i) || []
      const eIDs = obj?.eId?.map((i) => i?.id) || []
      const combinationArray = []

      pIDs?.forEach((pid) => {
        eIDs?.forEach((eid) => {
          combinationArray.push([eid, pid])
        })
      })
      equipmentTypeObject[equipType].push(...combinationArray)
    })


    const equipments = []
    Object.keys(equipmentTypeObject)?.forEach((equipmentType) => {
      const equipmentPropertyObjects = equipmentTypeObject?.[equipmentType] || []

      let consolidatedData = consolidateData(equipmentPropertyObjects)
      consolidatedData = consolidatedData?.map((obj) => {
        return {
          ...obj,
          type: equipmentType
        }
      })
      equipments.push(...consolidatedData)

    })

    payload = {
      ...payload,
      systemOfMeasure: systemOfMeasure,
      building: payloadObject?.building,
      equipment: equipments, // new equipment array with uom is included
      weather: payloadObject?.weather,
      settings: payloadObject?.settings,
      characteristicCurve: payloadObject?.characteristicCurve
    }
     
    if (chartConfig?.category?.toLowerCase() === "equipment") {
      payload.exceptions = payloadObject?.exceptions
    }
  } else {
    const newAdded = added ? added : {}
    payload = {
      ...payload,
      ...newAdded
    }
  }
  return payload
}

export const structureFromConfig = (
  chartConfig: any = {},
  equipmentSelected: any = {},
  buildingId,
  chillerApplicableProperties: any = [],
  selectedEquipmentProperties: any = []
) => {
  const selectedEquipmentChillerProperties = extractChartChillerProperties(
    equipmentSelected?.value?.compCondType,
    equipmentSelected?.value?.instance
  )
  const axisConfigList = chartConfig?.axisConfig || []
  const structuredObject: any = {
    building: {
      id: buildingId,
      pId: [],
      type: chartConfig?.equipmentType
    },
    equipment: [],
    weather: [],
    settings: [],
    axes: [],
    characteristicCurve: {
      parameters: [],
      instances: [],
      id: equipmentSelected?.value?.id
    }
  }

  if (chartConfig?.category?.toLowerCase() === "equipment") {
    structuredObject.exceptions = {
      eId: equipmentSelected?.value?.id || "",
      exName: []
    }
  }

  const allChild = {}
  const allSibling = {}
  const allSiblingChillers = {}
  const parentObject = {
    type: chartConfig?.equipmentType,
    eId: [{ id: equipmentSelected?.value?.id }],
    pId: []
  }

  axisConfigList.forEach((obj) => {
    const axisObject = {
      axisName: obj?.axisName,
      axisType: obj?.axisType,
      displayName: obj?.displayName,
      symbol: obj?.symbol,
      UoM: obj?.UoM
    }
    structuredObject.axes.push(axisObject)

    if (obj?.axisName === CHARACTERISTIC_CURVE && obj?.curveList) {
      const circuitCount =
        selectedEquipmentChillerProperties?.[CHILLER_CIRCUIT_COUNT]
      const instanceArray = []
      if (circuitCount) {
        for (let i = 1; i <= circuitCount; i++) {
          instanceArray.push(`CKT${i}`)
        }
      }
      structuredObject.characteristicCurve.parameters = obj?.curveList
      structuredObject.characteristicCurve.instances = instanceArray
    }

    if (obj?.parameterList && isArray(obj?.parameterList?.parent)) {
      if (chartConfig?.category === "Equipment") {
        parentObject.pId.push(...obj?.parameterList?.parent)
      } else {
        structuredObject.building.pId.push(...obj?.parameterList?.parent)
        structuredObject.building.pId = uniq(structuredObject?.building?.pId)
      }
    }

    if (isArray(obj?.weatherList)) {
      structuredObject.weather.push(...obj?.weatherList)
    }
    if (isArray(obj?.settingList)) {
      structuredObject.settings.push(...obj?.settingList)
    }

    if (obj?.parameterList && !isEmpty(obj?.parameterList?.child)) {
      const child = obj?.parameterList?.child || {}
      if (
        customHpathEquipmentTypes?.includes(parentObject?.type?.toLowerCase())
      ) {
        Object.keys(child)?.map((key) => {
          if (key?.includes("CustomHpath")) {
            const requiredTargetKeys = child[key]
            const availableTargetKeys = chillerApplicableProperties?.map(
              (item) => item?.targetKey
            )
            const compatibleTargetKeys = requiredTargetKeys?.filter(
              (targetKey) =>
                availableTargetKeys?.some((availableTargetKey) =>
                  availableTargetKey?.includes(targetKey)
                )
            )
            if (key.includes("child")) {
              const childPIds = chillerCircuitCompressorLogic(
                key,
                selectedEquipmentChillerProperties?.[CHILLER_CIRCUIT_COUNT],
                selectedEquipmentChillerProperties?.[
                CHILLER_COMPRESSOR_COUNT_ARRAY
                ],
                compatibleTargetKeys
              )
              parentObject.pId.push(...childPIds)
            } else if (key.includes("weather")) {
              structuredObject.weather.push(...compatibleTargetKeys)
            } else {
              parentObject.pId.push(...compatibleTargetKeys)
            }
          } else {
            const childPIds = chillerCircuitCompressorLogic(
              key,
              selectedEquipmentChillerProperties?.[CHILLER_CIRCUIT_COUNT],
              selectedEquipmentChillerProperties?.[
              CHILLER_COMPRESSOR_COUNT_ARRAY
              ],
              child[key]
            )
            parentObject.pId.push(...childPIds)
          }
        })
      } else {
        Object.keys(child)?.map((key) => {
          if (!has(allChild, key)) {
            allChild[key] = []
          }
          allChild[key].push(...child[key])
        })
      }
    }
    if (obj.parameterList && !isEmpty(obj.parameterList.sibling)) {
      const sibling = obj.parameterList.sibling || {}

      Object.keys(sibling)?.map((key) => {
        if (key === "Chiller") {
          const silblingChillers = equipmentSelected?.siblingList?.filter(
            (obj) => obj?.type === key
          )
          silblingChillers?.forEach((chiller) => {
            const siblingChillerProperties = extractChartChillerProperties(
              chiller?.compCondType,
              chiller?.instance
            )
            const siblingObj = sibling[key]
            let siblingPIDs = []
            Object?.keys(sibling[key])?.forEach((chillerKey) => {
              siblingPIDs = chillerCircuitCompressorLogic(
                chillerKey,
                siblingChillerProperties[CHILLER_CIRCUIT_COUNT],
                siblingChillerProperties[CHILLER_COMPRESSOR_COUNT_ARRAY],
                siblingObj[chillerKey]
              )
            })
            allSiblingChillers[chiller?.id] = siblingPIDs
          })
        } else {
          if (!has(allSibling, key)) {
            allSibling[key] = []
          }
          allSibling[key].push(...sibling[key])
        }
      })
    }

    if (chartConfig?.category?.toLowerCase() === "equipment") {
      if (obj?.axisName?.toLowerCase() === "exceptions") {
        if (
          Array.isArray(obj?.exceptionList) &&
          obj?.exceptionList?.length > 0
        ) {
          const childExceptions = obj?.exceptionList && obj?.exceptionList.filter(list => !!list.child)
          if (childExceptions.length > 0) {
            const formattedChildExceptions = childExceptions.reduce((acc, curr) => {
              if (acc[curr.child]) {
                acc[curr.child].push(curr.name)
              } else {
                acc[curr.child] = [curr.name]
              }
              return acc;
            }, {})
            structuredObject.exceptions = equipmentSelected.childList
              .filter(child => Object.keys(formattedChildExceptions).includes(child.type))
              .map(item => ({
                "eId": item.id, "exName": formattedChildExceptions[item.type]
              }))
          } else {

            const { circuitInstances, compressorInstances } =
              getCircuitAndCompressorInstances(selectedEquipmentProperties)
            obj?.exceptionList?.forEach((exceptionObj: any) => {
              const exception = exceptionObj?.name
              if (isString(exception)) {
                const modifierRegex = new RegExp(/[X$#@!%^*&*[\]]{2}/)
                const circuitRegex = new RegExp(
                  /[a-zA-z]*([Cc][kK][tT])[a-zA-z]*/
                )
                const compressorRegex = new RegExp(
                  /[a-zA-z]*([Cc][Oo][mM][pP])[a-zA-z]*/
                )
                const compressorKeywordRegex =
                  /([Cc][Oo][mM][pP][rR][eE][sS][sS][oO][rR])/
                if (
                  circuitRegex?.test(exception) &&
                  modifierRegex?.test(exception)
                ) {
                  circuitInstances?.forEach((instance) => {
                    if (
                      !structuredObject?.exceptions?.exName?.includes(exception)
                    ) {
                      structuredObject.exceptions.exName.push(
                        exception?.replace(modifierRegex, instance)
                      )
                    }
                  })
                } else if (
                  (compressorRegex?.test(exception) ||
                    compressorKeywordRegex?.test(exception)) &&
                  modifierRegex?.test(exception)
                ) {
                  compressorInstances?.forEach((instance) => {
                    if (
                      !structuredObject?.exceptions?.exName?.includes(exception)
                    ) {
                      structuredObject.exceptions.exName.push(
                        exception?.replace(modifierRegex, instance)
                      )
                    }
                  })
                } else {
                  if (
                    !structuredObject?.exceptions?.exName?.includes(exception)
                  ) {
                    structuredObject.exceptions.exName.push(exception)
                  }
                }
              }
            })
          }
        }
      }
    }
  })

  parentObject.pId = uniq(parentObject.pId)

  if (parentObject?.pId?.length > 0) {
    structuredObject.equipment.push(parentObject)
  }

  Object.keys(allChild)?.map((key) => {
    const eIds =
      equipmentSelected?.childList
        ?.filter((obj) => obj?.type === key)
        ?.map((obj) => {
          return {
            id: obj?.id
          }
        }) || []
    const uniqPIds = uniq(allChild[key]) || []
    const equipmentObject: any = {
      type: key,
      eId: eIds,
      pId: uniqPIds
    }


    if (equipmentObject?.pId?.length > 0 && equipmentObject?.eId?.length > 0) {
      structuredObject.equipment.push(equipmentObject)
    }
  })

  Object.keys(allSibling)?.map((key) => {
    const eIds =
      equipmentSelected?.siblingList
        ?.filter((obj) => obj?.type === key)
        ?.map((obj) => {
          return {
            id: obj?.id
          }
        }) || []
    const uniqPIds = uniq(allSibling?.[key]) || []
    const equipmentObject: any = {
      type: key,
      eId: eIds,
      pId: uniqPIds
    }


    if (equipmentObject?.pId?.length > 0 && equipmentObject?.eId?.length > 0) {
      structuredObject.equipment.push(equipmentObject)
    }
  })
  Object.keys(allSiblingChillers)?.map((key) => {
    const uniqPIds = uniq(allSiblingChillers?.[key]) || []
    const equipmentObject: any = {
      type: "Chiller",
      eId: [{ id: key }],
      pId: uniqPIds
    }

    if (equipmentObject?.pId?.length > 0 && equipmentObject?.eId?.length > 0) {
      structuredObject.equipment.push(equipmentObject)
    }
  })

  return structuredObject
}

export const chillerCircuitCompressorLogic = (
  property,
  circuitCount,
  compressorCountArray,
  childArray
) => {
  const childPIds = []
  if (property.includes("Circuit") && circuitCount && childArray?.length > 0) {
    for (const item of childArray) {
      const propertyName = item.endsWith("1") ? item.slice(0, -1) : item
      for (let i = 1; i <= circuitCount; i++) {
        childPIds.push(`${propertyName}${i}`)
      }
    }
  } else if (
    property.includes("Compressor") &&
    circuitCount &&
    compressorCountArray?.length > 0 &&
    childArray?.length > 0
  ) {
    for (const item of childArray) {
      const propertyName = item.endsWith("1A") ? item.slice(0, -2) : item
      for (const index in compressorCountArray) {
        for (let j = 1; j <= compressorCountArray[index]; j++) {
          childPIds.push(
            `${propertyName}${parseInt(index) + 1}${String.fromCharCode(
              64 + j
            )}`
          )
        }
      }
    }
  } else if (property.includes("generic")) {
    childPIds.push(...childArray)
  }
  return childPIds
}

export const parseInstanceString = (instance) => {
  try {
    if (Array.isArray(instance)) {
      return instance
    } else {
      const instanceArray = instance
        .replace(/\[|\]|\"|\'|\\|\{|\}| /g, "")
        .split(",")
      return instanceArray
    }
  } catch (error) {
    return []
  }
}

export const parseCompCondTypeString = (compCondType) => {
  try {
    const regex = /"([^"\\]*(?:\\.[^"\\]*)*)"|\[([^\]]+)\]|([^[\s"\\]+)/g
    const matches = Array.from(compCondType.matchAll(regex)) || []
    const values = matches?.map((match) => {
      const value = (
        match?.[1] !== undefined
          ? match?.[1]
          : match?.[2] !== undefined
            ? match?.[2]
            : match?.[3]
      )?.trim()
      return value?.replace(/^"(.*)"$/, "$1")
    })
    return values
  } catch (error) {
    return []
  }
}

export const extractCountValuesFromInstance = (instance = "") => {
  let circuitCount = 0
  const compressorCountArray = []
  const instanceArray = parseInstanceString(instance)
  instanceArray?.forEach((inst) => {
    if (inst?.toLowerCase()?.includes("ckt")) {
      circuitCount++
    }
  })
  if (instanceArray?.includes("Comp")) {
    for (let i = 0; i < circuitCount; i++) {
      compressorCountArray?.push(1)
    }
  } else {
    for (let i = 1; i <= circuitCount; i++) {
      let compressorCount = 0
      instanceArray?.forEach((inst) => {
        if (inst?.toLowerCase()?.startsWith(i?.toString())) {
          compressorCount++
        }
      })
      compressorCountArray.push(compressorCount)
    }
  }
  return {
    circuitCount,
    compressorCountArray
  }
}

export const extractChartChillerProperties = (
  compCondType = "",
  instance = ""
) => {
  const compCondTypeArray = parseCompCondTypeString(compCondType)
  const condensorType =
    compCondTypeArray?.length >= 1 ? compCondTypeArray[0]?.split("#")[0] : null
  const compressorType =
    compCondTypeArray?.length >= 1 ? compCondTypeArray[0]?.split("#")[1] : null
  const { circuitCount, compressorCountArray } =
    extractCountValuesFromInstance(instance)
  const averageCompressorCount =
    compressorCountArray?.reduce((a, b) => parseInt(a) + parseInt(b), 0) /
    circuitCount

  return {
    [CHILLER_CONDENSOR_TYPE]: condensorType,
    [CHILLER_COMPRESSOR_TYPE]: compressorType,
    [CHILLER_CIRCUIT_COUNT]: circuitCount,
    [CHILLER_COMPRESSOR_COUNT]: averageCompressorCount,
    [CHILLER_COMPRESSOR_COUNT_ARRAY]: compressorCountArray
  }
}

const mergeUnique = (arr) => {
  const newSet: any = new Set(arr)
  return [...newSet]
};

// Function to consolidate the data to minise the combinations repeatability
const consolidateData = (data) => {
  const eidToPidMap = new Map();

  data.forEach(([eId, pId]) => {
    if (!eidToPidMap.has(eId)) {
      eidToPidMap.set(eId, new Set());
    }
    eidToPidMap.get(eId).add(pId);
  });

  const combined = {};
  eidToPidMap.forEach((pids, id) => {
    const key = Array.from(pids).sort().join(",");
    if (!combined[key]) {
      combined[key] = new Set();
    }
    combined[key].add(id);
  });

  const result = Object.entries(combined).map(([pids, ids]) => {
    const cds: any = ids;
    return { eId: Array.from(cds).map(id => ({ id })), pId: mergeUnique(pids.split(",")) };
  });

  return result;
};
