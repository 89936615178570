// All Equipment List of a building
export const BUILDING_EQUIPMENT_LIST_ALL_ET = 900000 // In Milli Seconds
export const BUILDING_EQUIPMENT_LIST_ALL_KEY = "buildingEquipmentList"
export const getBuildingEquipListAllKey = (buildingID: any) =>
  `${BUILDING_EQUIPMENT_LIST_ALL_KEY}_${buildingID}`

// All Configured Performance tab chart list
export const CHART_LIST_ET = 900000
export const CHART_LIST_ALL_KEY: any = "performanceChartList"

// Properties Based on Equipment Type
export const EQUIPMENTTYPE_PROPERTIES_ET = 900000
export const EQUIPMENTTYPE_PROPERTIES_KEY: any = "equipmentTypeProperties"
export const getEquipmentTypeProperties = (equipmentType: any) =>
  `${EQUIPMENTTYPE_PROPERTIES_KEY}_${equipmentType}`

// Config Dimension information
export const LIST_CONFIG_AXES_ET = 1800000
export const LIST_CONFIG_AXES_KEY: any = "listConfigAxes"

// All Equipment List of a building by type
export const BUILDING_TYPE_EQUIPMENT_PROPERTIES_ALL_ET = 90000000 // In Milli Seconds
export const BUILDING_TYPE_EQUIPMENT_PROPERTIES_ALL_KEY = "buildingTypeEquipmentpropertiesList"
export const getBuildingTypeEquipPropertiesListAllKey = (buildingID: any, type: any) =>
  `${BUILDING_TYPE_EQUIPMENT_PROPERTIES_ALL_KEY}_${buildingID}_${type}`

// All Equipment List of a building by type
export const BUILDING_EQUIPMENT_PROPERTIES_ALL_ET = 90000000 // In Milli Seconds
export const BUILDING_EQUIPMENT_PROPERTIES_ALL_KEY = "buildingEquipmentpropertiesList"
export const getBuildingEquipPropertiesListAllKey = (buildingID: any) =>
  `${BUILDING_EQUIPMENT_PROPERTIES_ALL_KEY}_${buildingID}`

// All Equipment List of a building by type
export const LIST_EXCEPTIONS_ALL_ET = 90000000 // In Milli Seconds
export const LIST_EXCEPTIONS_ALL_KEY = "buildingEquipmentpropertiesList"
export const getListExceptionsAllKey = () =>
  `${BUILDING_EQUIPMENT_PROPERTIES_ALL_KEY}`