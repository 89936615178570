import moment from "moment"
import { reduxStoreTimeStampFormat } from "../constant"
import { EquipmentProperties } from "../../ReportDialog/ExceptionReport/types"

export const generateExceptionsTimestampObjects = (
  timestamps: string[],
  interval: string,
  sampling: number,
  cStartDate: any,
  cEndDate: any,
  buildingLocalTime: any,
  otherProps: any = {}
) => {
  const correctedEndDate =
    moment(new Date(buildingLocalTime)).isSameOrBefore(
      moment(new Date(cEndDate))
    ) && buildingLocalTime
      ? moment(new Date(buildingLocalTime)).format(reduxStoreTimeStampFormat)
      : moment(new Date(cEndDate)).format(reduxStoreTimeStampFormat)
  const intervalMinutes = parseInterval(interval)
  const samplingMinutes = sampling
  const timestampObjects: { startTimestamp: string; endTimestamp: string }[] =
    []
  let timeLineBars = []
  if (timestamps?.length > 0) {
    timestamps.forEach((timestamp) => {
      const timestampDate = moment(timestamp, reduxStoreTimeStampFormat)
      const endTimestamp = moment(timestampDate).add(samplingMinutes, "minutes")
      const startTimestamp = moment(endTimestamp).subtract(
        intervalMinutes,
        "minutes"
      )
      const timestampObject = {
        startTimestamp: startTimestamp.format(reduxStoreTimeStampFormat),
        endTimestamp: endTimestamp.format(reduxStoreTimeStampFormat),
        ...otherProps,
        value: true
      }

      timestampObjects.push(timestampObject)
    })

    // Sort timestamp objects based on startTimestamp
    timestampObjects.sort((a, b) =>
      moment(a.startTimestamp).diff(moment(b.startTimestamp))
    )

    // Fill in the gaps
    const filledTimestampObjects: {
      startTimestamp: string
      endTimestamp: string
    }[] = []

    const firstObjectStartDate = moment(timestampObjects[0].startTimestamp)
    if (
      firstObjectStartDate.diff(moment(cStartDate, reduxStoreTimeStampFormat)) >
      0
    ) {
      filledTimestampObjects.push({
        startTimestamp: cStartDate,
        endTimestamp: timestampObjects?.[0]?.startTimestamp,
        ...otherProps,
        color: "#525252",
        value: ""
      })
    }

    for (let i = 0; i < timestampObjects.length - 1; i++) {
      const currentObject = timestampObjects?.[i]
      const nextObject = timestampObjects?.[i + 1]

      const currentEndDate = moment(currentObject.endTimestamp)
      const nextStartDate = moment(nextObject.startTimestamp)

      if (nextStartDate.diff(currentEndDate) > 0) {
        // There is a gap, fill it
        const gapStartDate = moment(currentEndDate)
        const gapEndDate = moment(nextStartDate)

        filledTimestampObjects.push({
          startTimestamp: gapStartDate.format(reduxStoreTimeStampFormat),
          endTimestamp: gapEndDate.format(reduxStoreTimeStampFormat),
          ...otherProps,
          color: "#525252",
          value: ""
        })
      }
    }

    const lastObjectEndDate = moment(
      timestampObjects[timestampObjects.length - 1].endTimestamp
    )
    if (
      moment(correctedEndDate, reduxStoreTimeStampFormat).diff(
        lastObjectEndDate
      ) > 0
    ) {
      filledTimestampObjects.push({
        startTimestamp: lastObjectEndDate.format(reduxStoreTimeStampFormat),
        endTimestamp: correctedEndDate,
        ...otherProps,
        color: "#525252",
        value: ""
      })
    }

    timeLineBars = [...filledTimestampObjects, ...timestampObjects]
    timeLineBars.sort((a, b) =>
      moment(a.startTimestamp).diff(moment(b.startTimestamp))
    )
  } else {
    timeLineBars.push({
      startTimestamp: moment(cStartDate).format(reduxStoreTimeStampFormat),
      endTimestamp: correctedEndDate,
      ...otherProps,
      color: "#525252",
      value: "No Data"
    })
  }

  return timeLineBars
}

export const parseInterval = (interval: string): number => {
  const timeValue = parseInt(interval?.slice(0, -1))
  const timeUnit = interval?.slice(-1)

  if (timeUnit === "m") {
    return timeValue
  } else if (timeUnit === "h") {
    return timeValue * 60
  } else if (timeUnit === "d") {
    return timeValue * 24 * 60
  } else {
    return 0
  }
}

export const getCircuitAndCompressorInstances = (
  propertyArray: EquipmentProperties
) => {
  let maxCircuitCount = 1
  let singleCircuitInstanceExists = false
  let circuitInstances = []

  let singleCompressorInstanceExists = false
  let multipleCompressorInstanceExists = false
  const compressorInstances = []

  propertyArray?.forEach(({ targetKey, value }) => {
    if (targetKey?.toLowerCase() === "circuitcount" && (value === "1" || value === "")) {
      singleCircuitInstanceExists = true
    }
    if (
      targetKey?.toLowerCase()?.includes("circuitcount") &&
      value &&
      parseInt(value) > maxCircuitCount
    ) {
      maxCircuitCount = parseInt(value)
    }
    if (targetKey?.toLowerCase() === "compressorcount" && (value === "1" || value === "")) {
      singleCompressorInstanceExists = true
    }
    if (
      targetKey?.toLowerCase()?.includes("compressorcount") &&
      value &&
      parseInt(value) > 1
    ) {
      multipleCompressorInstanceExists = true
    }
  })

  circuitInstances =
    maxCircuitCount <= 1
      ? [""]
      : Array.from({ length: maxCircuitCount }, (_, i) => ` ${i + 1}`)

  if (singleCircuitInstanceExists && !circuitInstances?.includes("")) {
    circuitInstances?.push("")
  }

  const componentPropertyArray = propertyArray?.filter(
    ({ targetKey, value }) =>
      targetKey?.toLowerCase()?.includes("compressorcountckt")
  )
  const filteredArray = Array?.from(
    new Set(componentPropertyArray?.map((item) => item?.targetKey))
  ).map((targetKey) => ({
    targetKey,
    value: Math?.max(
      ...componentPropertyArray
        ?.filter((item) => item?.targetKey === targetKey)
        ?.map((item) => parseInt(item?.value) || 1)
    )
  }))

  if (filteredArray?.length <= 1 && filteredArray[0]?.value <= 1) {
    compressorInstances?.push("")
  } else if (multipleCompressorInstanceExists) {
    filteredArray.forEach((item: any) => {
      const { targetKey, value } = item
      for (let i = 1; i <= value; i++) {
        const suffix = String?.fromCharCode(i + 96)?.toUpperCase()
        compressorInstances?.push(
          ` ${targetKey?.slice(targetKey?.length - 1)}${suffix}`
        )
      }
    })
  } else {
    compressorInstances?.push("")
  }

  if (singleCompressorInstanceExists && !compressorInstances?.includes("")) {
    compressorInstances?.push("")
  }

  return ({
    circuitInstances: circuitInstances,
    compressorInstances: compressorInstances
  })
}