import { Fragment } from "react"
import ConnectivityPopup from "src/blitz/ConnectivityPopup/ConnectivityPopup"
import { EVENTS } from "src/constants"
import styles from "./BuildingTile.module.scss"
const ConnectivityModal = ({ type, status, building, testId }) => {
  const CONNECTIVITY = "Connectivity"
  const REMOTE = "Remote Access"

  return (
    <Fragment>
      {type === CONNECTIVITY && (
        <ConnectivityPopup
          type={type}
          building={building}
          status={status}
          icon={`${styles?.connectivityStatus} ${"icon-" + status}`}
          testId={testId}
          filteredDevices={building?.connectionDevices}
        />
      )}
      {type === REMOTE && (
        <ConnectivityPopup
          type={type}
          building={building}
          status={status}
          icon={`${styles?.remoteAccessStatus} icon-remote-access  ${status}`}
          testId={testId}
          filteredDevices={building?.remoteDevices}
        />
      )}
      {type === EVENTS.CRITICAL && (
        <span
          data-testid={testId}
          title={EVENTS.CRITICAL}
          className={"home-legend-icon material-symbols-outlined"}
        >
          error_med
        </span>
      )}
      {type === EVENTS.INFORMATION && (
        <span
          data-testid={testId}
          title={EVENTS.INFORMATION}
          className={"home-legend-icon material-symbols-outlined"}
        >
          help_clinic
        </span>
      )}
      {type === EVENTS.SERVICE && (
        <span
          data-testid={testId}
          title={EVENTS.SERVICE}
          className={"home-legend-icon material-symbols-outlined"}
        >
          settings
        </span>
      )}
    </Fragment>
  )
}
export default ConnectivityModal
