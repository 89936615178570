import {
  assignAttributeStateToLegends,
  assignAttributeStateToTLLegends,
  convertTimeToMinutes,
  generateLegend,
  getChartObject,
  getTimeIndex,
  intervalToMinutes,
  legendIDGenerator,
  legendName
} from "../common"
import moment from "moment"
import { isArray, isString, toNumber, uniq } from "lodash"
import {
  chartDateFormat,
  CHILLER_CIRCUIT_COUNT,
  constantValues,
  reduxStoreTimeStampFormat
} from "../../constant"
import { TIMELINE_PROPERTY_TYPE, transformTimeLinechartNew } from "./timeLineChart"

import { extractChartChillerProperties } from "../Payload/Performance/generateChartPayload"
import { generateLegendProps_Type } from "../../ChartContainer/Types/helper"
import { generateExceptionLegends } from "../Exceptions/helper"
import { generateSuppressionLegends } from "../Suppressions/helper"
import { generateSuppressionBars } from "../Suppressions"
import { generateExceptionBars } from "../Exceptions/index"

export const STRAIGHT_CURVE = "straightCurve"
export const transformScatterWithPerformanceCurvechart = ({
  tz,
  startDate,
  endDate,
  intervalMinutes = "15m",
  inputData,
  chartInfo,
  equipmentList,
  buildingId,
  equipmentId,
  selectedTypeEquipment = {},
  oldLegends,
  oldTimeLineLegends = null,
  assignedAttributes = [],
  legendAttributesCombination
}: any) => {
  const calculatedMin = intervalToMinutes(intervalMinutes)
  const equipmentType = chartInfo?.equipmentType
  let legends: any = {}
  let timeLineLegends = {}
  const data = inputData?.data || {}
  const curveData = inputData?.curveData || [];
  const newData = {}
  const newDataTimeline = {}
  const newCurveData = {}
  const timestamps = []
  let oneLoopCompleted = false
  const endTimestamp = moment(endDate, chartDateFormat).add(1, "days")
  const localTimeStamp = Boolean(tz)
    ? moment(
      moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
      "MM-DD-YYYY HH:mm"
    )
    : moment()
  for (const id in data) {
    const paramData = data[id]
    const equipmentName = equipmentList?.find((obj) => obj?.id === id)?.name
    const selectedEquipment = equipmentId === id
    const building = buildingId === id

    const properties = paramData?.properties

    for (const propertyKey in properties) {
      const property = properties[propertyKey]
      const propertyIndex = property?.index
      const propertyName = property?.name || ""

      const axis = chartInfo?.axisConfig?.find((obj) =>
        getChartObject(obj, propertyKey, equipmentType)
      )
      const name = legendName({ building, selectedEquipment, propertyName, equipmentName })
      const xType = axis?.axisType?.toLowerCase() === "x"
      let legendObj: any
      const legendId = legendIDGenerator(id, propertyKey)
      if (axis?.axisName === "timeline") {
        legendObj = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, propertyKey, type: "timeline", oldLegends: oldTimeLineLegends, propertyType: TIMELINE_PROPERTY_TYPE })
        timeLineLegends[legendId] = legendObj
      } else {
        const obj: generateLegendProps_Type = { legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, oldLegends }
        if (xType) {
          obj.additionalAttributes = {
            xAxis: true,
            disabled: true
          }
        }
        obj.additionalAttributes = {
          ...(obj.additionalAttributes || {}),
          specificProperties: {
            equipID: id,
            propertyKey: propertyKey
          }
        }
        legendObj = generateLegend(obj)
        legends[legendObj?.id] = legendObj;
      }

      let currentTimestamp = moment(startDate, chartDateFormat)
      let prevValue = null
      while (
        currentTimestamp.isSameOrBefore(endTimestamp) &&
        currentTimestamp.isSameOrBefore(localTimeStamp)
      ) {
        const dateKey = currentTimestamp.format("YYYY-MM-DD")
        const extractTime = currentTimestamp.format("HH:mm")
        const timetoMin = convertTimeToMinutes(extractTime)
        const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)

        const dateAvailable = paramData?.values?.[dateKey]

        let finalValue
        if (dateAvailable) {
          const valueArray = paramData?.values?.[dateKey]?.[timeIndexPosition]
          const valueArrayIsArray = isArray(valueArray)
          const arrayValues = valueArrayIsArray ? valueArray : []
          const value = arrayValues[propertyIndex]

          const isNull =
            constantValues?.includes(value) ||
            value?.toString()?.startsWith("_")
          const isTimeline = axis?.axisName === "timeline"

          const checkedValue =
            isNull || (isTimeline ? false : !isFinite(toNumber(value)))
              ? null
              : isTimeline
                ? value
                : parseFloat(value)

          if ((isString(value) && value?.length === 0) || !valueArrayIsArray) {
            finalValue = prevValue
          } else {
            finalValue = checkedValue
          }

          if (timeIndexPosition === 95) {
            prevValue = null
          } else {
            prevValue = finalValue
          }
        } else {
          finalValue = null
          prevValue = null
        }

        const dateVal = currentTimestamp.format(reduxStoreTimeStampFormat)

        if (axis?.axisName === "timeline") {
          if (!newDataTimeline?.[legendId]) {
            newDataTimeline[legendId] = {
              data: []
            }
          }
        } else {
          if (!newData?.[legendId]) {
            newData[legendId] = {
              data: []
            }
          }
        }

        if (axis?.axisName === "timeline") {
          newDataTimeline[legendId].data.push(finalValue)
        } else {
          newData[legendId].data.push(finalValue)
        }
        if (!oneLoopCompleted) {
          timestamps.push(dateVal)
        }
        currentTimestamp = currentTimestamp.add(calculatedMin, "minutes")
      }
      oneLoopCompleted = true
    }
  }


  const instancesCount = uniq(curveData?.map((obj) => (obj?.instance || "")?.toLowerCase()))?.length
  const selectedEquipmentChillerProperties = extractChartChillerProperties(
    selectedTypeEquipment?.compCondType,
    selectedTypeEquipment?.instance
  )
  const circuitCount = selectedEquipmentChillerProperties?.[CHILLER_CIRCUIT_COUNT]
  curveData?.forEach((curve) => {
    const equipmentName = equipmentList?.find((obj) => obj?.id === curve?.equipmentId)?.name
    const selectedEquipment = curve?.equipmentId === equipmentId
    const instanceName = instancesCount > 1 ? "Ckt " + curve?.instance?.slice(-1) : "Ckt"
    const legendId = legendIDGenerator(equipmentId, curve?.curveType, curve?.instance?.toLowerCase())
    const name = `${selectedEquipment ? "" : equipmentName + " "}${instanceName} ${curve?.curveType}`
    const legendArray: any = Object.values(legends)
    const xNamePropName: any = legendArray?.find((obj: any) => {
      if (circuitCount === 1) {
        return obj?.axisName?.toLowerCase() === "x" && !((obj?.name?.toLowerCase() || "")?.includes(("Ckt")?.toLowerCase()))
      } else if (circuitCount > 1) {
        return obj?.axisName?.toLowerCase() === "x" && (obj?.name?.toLowerCase() || "")?.includes((instanceName || "")?.toLowerCase())
      } else {
        return false
      }
    })?.name || ""
    const xName = `${selectedEquipment ? "" : equipmentName + " "}${xNamePropName}`
    const obj: generateLegendProps_Type = { legendId, name, axisName: "y", oldLegends, type: STRAIGHT_CURVE }
    obj.additionalAttributes = {
      xName
    }
    const legendObj: any = generateLegend(obj)
    legends[legendObj?.id] = legendObj;
    newCurveData[legendId] = curve?.characteristicCurveDatas
  })

  const noDataArray = Object.values(legends)?.map((obj: any) => {
    if (obj?.type === STRAIGHT_CURVE) {
      const curveArray = newCurveData?.[obj?.id] || []
      const data = []
      curveArray?.forEach((obj) => {
        data.push(toNumber(obj?.curveXAxisValue))
        data.push(toNumber(obj?.curveYAxisValue))
      })
      const noData = (data)?.every(
        (e) => !Boolean(e) && e !== 0
      )
      return noData
    } else {
      const noData = (newData?.[obj?.id]?.data || [])?.every(
        (e) => !Boolean(e) && e !== 0
      )
      return noData
    }
  })

  const isNoData = noDataArray?.every((e) => Boolean(e))

  assignAttributeStateToLegends(legends, oldLegends, assignedAttributes, legendAttributesCombination)


  // Create/update Exception Legends
  const exceptionData = inputData?.exceptions?.data || {}
  const exceptionLegends = generateExceptionLegends({ exceptionData, chartInfo, equipmentList, selectedEquipID: equipmentId, oldTimeLineLegends })

  // Create/update Suppression Legends
  const suppressionData = inputData?.exceptions?.suppressed?.data || {}
  const suppressionLegends = generateSuppressionLegends({ suppressionData, chartInfo, oldTimeLineLegends })

  // Assign Colors to new time line legends of all kinds
  assignAttributeStateToTLLegends(timeLineLegends, exceptionLegends, suppressionLegends, assignedAttributes, legendAttributesCombination, oldTimeLineLegends)


  const { timeLineBars, totalLanes } = transformTimeLinechartNew(
    timeLineLegends,
    timestamps,
    newDataTimeline
  )

  const exceptionSampling = inputData?.exceptions?.sampling || 15
  const {
    exceptionsBars,
    exceptionLanes
  } = generateExceptionBars(
    exceptionData,
    exceptionLegends,
    exceptionSampling,
    chartInfo,
    totalLanes,
    startDate,
    endTimestamp,
    localTimeStamp
  )

  const suppressed = inputData?.exceptions?.suppressed?.data && inputData?.exceptions?.suppressed?.data[equipmentId] || {}
  const { suppressionBars } =
    generateSuppressionBars(
      suppressed,
      suppressionLegends,
      exceptionLanes,
      startDate,
      endTimestamp.format("YYYY-MM-DD")
    )

  const bars = [
    ...timeLineBars,
    ...exceptionsBars,
    ...suppressionBars
  ]

  const axisList = chartInfo?.axisConfig

  legends = Object.values(legends)
  legends = legends?.filter((obj) => {
    if (circuitCount === 1) {
      return !(obj?.specificProperties?.propertyKey || "")?.includes(PROPERTY_CIRCUIT_CURRENT_DRAW_RLACALC);
    } else if (circuitCount > 1) {
      return obj?.specificProperties?.propertyKey !== PROPERTY_CHILLER_LOAD_NORMALIZED;
    } else {
      return obj
    }
  })
  legends = legends?.map((obj) => {
    if (obj?.axisName?.toLowerCase() === "y") {
      if (circuitCount === 1) {
        return eq1CircuitCountXRef(obj, legends)
      } else if (circuitCount > 1) {
        return gt1CircuitCountXRef(obj, legends)
      } else {
        return obj
      }
    } else {
      return obj
    }
  })

  const noDataTimelineArray = Object.keys(timeLineLegends)?.map((key) => {
    const noData = (newDataTimeline?.[key]?.data || [])?.every(
      (e) => !Boolean(e) && e !== 0
    )
    return noData
  })

  const isNoDataTimeline = noDataTimelineArray?.every((e) => Boolean(e))

  const isNoTimeLineData = timeLineBars?.every((obj) => !Boolean(obj?.value))

  timeLineLegends = {
    ...timeLineLegends,
    ...exceptionLegends,
    ...suppressionLegends
  }

  return {
    chartData: {
      data: isNoData ? {} : newData,
      timelineData: isNoDataTimeline ? {} : newDataTimeline
    },
    chartProps: {
      timestamps: isNoData ? [] : timestamps,
      legends: legends,
      axisList: axisList,
      timeLineLegends: timeLineLegends,
      timeLineBars:
        isNoData && isNoDataTimeline && isNoTimeLineData ? [] : timeLineBars,
      curveData: newCurveData || {},
      noDataAvailable: isNoData && isNoTimeLineData ? true : false,
      isdataprocessed: true
    }
  }
}

export const eq1CircuitCountXRef = (obj, legends) => {
  const xRefLegend = legends?.find(refObj => refObj?.specificProperties?.equipID === obj?.specificProperties?.equipID && refObj?.specificProperties?.propertyKey === PROPERTY_CHILLER_LOAD_NORMALIZED)
  const newObj = {
    ...obj,
    xRef: xRefLegend?.id
  }
  return newObj
}

export const gt1CircuitCountXRef = (obj, legends) => {
  const ycktIndex = obj?.specificProperties?.propertyKey?.indexOf("CKT")
  const ycktNumberString = obj?.specificProperties?.propertyKey?.slice(ycktIndex, obj?.specificProperties?.propertyKey?.length);
  const yCurrentDrawProp = PROPERTY_CIRCUIT_CURRENT_DRAW_RLACALC + ycktNumberString
  const xRefLegend = legends?.find(refObj => {
    return refObj?.specificProperties?.equipID === obj?.specificProperties?.equipID && refObj?.specificProperties?.propertyKey === yCurrentDrawProp
  })
  const newObj = {
    ...obj,
    xRef: xRefLegend?.id
  }
  return newObj
}

export const PROPERTY_CHILLER_LOAD_NORMALIZED = "ChillerLoadNormalized"
export const PROPERTY_CIRCUIT_CURRENT_DRAW_RLACALC = "CircuitCurrentDrawRLACalc"