import { Line_CHART_CATEGORY, LineWithBinaryStates_CHART_CATEGORY, LineWithControlRangeAndMean_CHART_CATEGORY } from "src/blitz/Charts/constants"

// Performance Charts Categories
export const FACILITY_CATEGORY = "Facility"
export const EQUIPMENT_CATEGORY = "Equipment"
export const PARETO_CATEGORY = "Pareto"

// Performance chart state attributes
export const SELECTED_TYPE_EQUIPMENT = "selectedTypeEquipment"
export const CHART_TYPE = "chartType"
export const CHART_TYPE_LOADING = "chartTypeLoading"
export const CHART_CLIENT_ID = "chartClientId"
export const CHART_START_DATE = "startDate"
export const CHART_END_DATE = "endDate"
export const CHART_PARETO_SORT = "paretoSort"
export const SYSTEM_OF_MEASURE = "systemOfMeasure"

export const defaultFacilityObject = {
  title: "Facility",
  value: FACILITY_CATEGORY,
  type: "button"
}

export const CHART_LEGEND = "legends"
export const CHART_TIMELINE_LEGEND = "timeLineLegends"
export const CHART_EXPORT_ID = "chartExportId"
export const NAME = "name"
export const ASC = "Asc"
export const LOADING = "Loading"
export const NA = "NA"
export const TARGETKEY = "targetKey"
export const SUCCESS = "Success"
export const FAILURE = "Failure"
export const APPLY = "Apply"
export const BUTTON = "button"
export const ICON_BUTTON = "iconButton"
export const RADIO = "radio"
export const BLUE = "blue"
export const ALL_CHECK = "all-check"
export const CLOSE = "Close"
export const CHECKBOX = "checkbox"
export const EDIT_CHART_PROPERTIES = "EditChartproperties"
export const REMOVE_ALL_ADDED_PROPERTIES = "RemoveAllAddedProperties"
export const ADD_PROPERTY = "AddProperty"
export const Y2 = "y2"
export const RTU08_SUPPLY_AIRPRESS = "RTU08SupplyAirPress"
export const LEFT = "Left"
export const RIGHT = "Right"
export const TIME_PERIOD_SLIDER = "Time Period Slider"
export const LEFT_HANDLE_TYPE = "w"
export const RIGHT_HANDLE_TYPE = "e"
export const SCATTER_WITH_PERFORMANCE_CURVE = "scatterWithPerformanceCurve"
export const DEFAULT_LOWER_RANGE_END = 60
export const DEFAULT_UPPER_RANGE_END = 95
export const CHILLED_WATER_VALVE_DISTRIBUTION =
  "CHILLED WATER VALVE DISTRIBUTION"
export const VAV_AIR_SYSTEM = "VAV AIR SYSTEM"
export const MEAN_OF_ALL = "Mean of All"
export const keys = ["unoccupied", "lower", "upper", "above"]
export const ABOVE = "above"
export const UPPER = "upper"
export const LOWER = "lower"
export const Load_Valve_Position = "Load Valve Position:"
export const Legend_Name = "legendName"
export const VALUE_UNOCCUPIED = "Unoccupied"
export const BAR_CHART_LEFT_AXIS_VALUES = [0, 0.2, 0.4, 0.6, 0.8, 1.0]

export const ALL_OPTION = "ALL"
export const MIN_VAL = "minVal"
export const MAX_VAL = "maxVal"
export const COLOR = "color"
export const LINE_TYPE = "lineType"
export const STROKE_WIDTH = "strokeWidth"
export const SHAPE = "shape"
export const SHAPE_SIZE = "shapeSize"

// Chiller Properties

export const CHILLER_COMPRESSOR_COUNT = "Chiller Comp Count"
export const CHILLER_COMPRESSOR_TYPE = "Comp Type"
export const CHILLER_CONDENSOR_TYPE = "Cond Type"
export const CHILLER_CIRCUIT_COUNT = "Ckt Count"
export const CHILLER_TYPE = "Chiller Type"
export const CHILLER_COMPRESSOR_COUNT_ARRAY = "Chiller Comp Count Array"
export const CHARACTERISTIC_CURVE = "characteristicCurve"

//Pallete Colors in Color picker
export const PALLETTE = [
  "#61AE34",
  "#FFF10B",
  "#D52B1E",
  "#00B9E4",
  "#FB9A09",
  "#DAFFA7",
  "#FFF787",
  "#FEA7AA",
  "#CFD5E8",
  "#FFEEC1",
  "#B7FF5B",
  "#E1D47B",
  "#FD575D",
  "#6C97BF",
  "#FEDE87",
  "#89C506",
  "#AB9A1F",
  "#E10018",
  "#326089",
  "#FECF54"
]

export const chartDateFormat = "YYYY/MM/DD"
export const pdfDateFormat = "MMMM DD, YYYY hh:mm A"
export const CURRENT_FETCH = "currentFetch"
export const TIMEPERIOD_TYPE = "timePeriod"
export const DATE_SUBMIT_TYPE = "dateSubmit"
export const CHART_CATEGORY_LIST_TYPE = "chartCategoryList"
export const CHART_EQUIPMENT_TYPE_LIST_TYPE = "chartTypeEquipmentList"
export const CHART_EQUIPMENT_PROPERTIES_LIST_ALL = "listBuildingEquipmentProperties"
// Performance Query Params
export const CHART_ID_PARAM = "chartId"
export const CLIENT_ID_PARAM = "clientId"
export const CHART_WIDTH_PARAM = "width"
export const CHART_HEIGHT_PARAM = "height"
export const CHART_BC_PARAM = "bc"
export const CHART_UOM_PARAM = "uom"
export const CHART_PARETO_SORT_PARAM = "paretoSort"

export const svgPadding = { top: 30, right: 30, bottom: 50, left: 50 }

// 2023/08/25 Constant values recieved from response which will be treated as null/gap
export const constantValues = [
  "--",
  null,
  "_2.55000000E+02",
  "-",
  "null",
  "",
  "-99999",
  undefined
]
export const reduxStoreTimeStampFormat = "YYYY-MM-DDTHH:mm:ss"
export const tooltipTimeStampFormat = "MM/DD/YY h:mm A"

export const OCCUPANCY_ACTIVE = "OccupancyActive"
export const UNOCCUPIED = "unoccupied"

export const CHART_DATE_FORMAT = {
  RANGE_DATE_FORMAT: "MM-DD-YYYY"
}

export const possibleYAxis = [
  "y",
  "y1",
  "y2",
  "y3",
  "y4",
  "Y",
  "Y1",
  "Y2",
  "Y3",
  "Y4"
]

export const customHpathEquipmentTypes = ["chiller"]

export const settingsReferenceLinesSuffix = {
  ChOverDBSetPt: "Changeover Setpoint",
  CoEntSetPt: "Changeover Setpoint"
}
export const settingsReferenceLines = (settingKey = "", prefix = "") => {
  return (
    (prefix?.replaceAll("-", "") || "") +
    " " +
    (settingsReferenceLinesSuffix?.[settingKey] || "")
  )
}

export const PARETO_HIGH_TO_LOW = "HighToLow"
export const PARETO_LOW_TO_HIGH = "LowToHigh"
export const PARETO_EQUIPMENT_BY_DATE = "EquipmentByDate"

export const availableParetoSorts = [PARETO_LOW_TO_HIGH, PARETO_EQUIPMENT_BY_DATE, PARETO_HIGH_TO_LOW]

export const paretoSortTitles = {
  [PARETO_HIGH_TO_LOW]: "h2L",
  [PARETO_LOW_TO_HIGH]: "l2H",
  [PARETO_EQUIPMENT_BY_DATE]: "equipByDt"
}


export const editableProperties = ["lineType", "color", "shape", "shapeSize", "strokeWidth"]

export const editablePropertiesTimeLine = ["show", "visible"]

export const ITEM_SEPERATION = "_&&_"

export const chartsCategoriesWithLines = [Line_CHART_CATEGORY, LineWithBinaryStates_CHART_CATEGORY, LineWithControlRangeAndMean_CHART_CATEGORY]