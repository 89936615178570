import { t } from "src/translations/help"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Typography, Button } from "src/blitz"
import styles from "./ErrorMessage.module.scss"
type ErrorProps = {
  title?: string
  BodyComponent?: FC
}
const errorPage = ({ title = "404", BodyComponent = null }: ErrorProps) => {
  const navigate = useNavigate()
  return (
    <div className={styles.errorPageWrapper}>
      <div className={styles.companyLogo}>
        <Typography tagType="h3" styleType="h3">
          <span className="icon-uniE020"></span>
        </Typography>
      </div>
      <div className={styles.errorBlock}>
        <Typography
          tagType="h3"
          className={styles.errorCode}
          styleType="h3"
          fontType="oswaldFont"
        >
          {title}
        </Typography>
        <Typography
          tagType="p"
          className={styles.errorDescription}
          styleType="p3"
        >
          {BodyComponent ? <BodyComponent /> : <>
            <Typography tagType="p">{t("common.page_not_found")}</Typography>
            <Typography tagType="p">
              <>
                {t("common.please_go")}
                <Button
                  type="button"
                  variant="transparent"
                  onClick={() => navigate(-1)}
                >
                  <Typography
                    tagType="span"
                    styleType="p3"
                    fontType="normalFont"
                  >
                    back
                  </Typography>
                </Button>{" "}
                or{" "}
                <Button
                  type="button"
                  variant="transparent"
                  onClick={() => navigate("/buildings?view=tiles")}
                >
                  <Typography
                    tagType="span"
                    styleType="p3"
                    fontType="normalFont"
                  >
                    navigate home
                  </Typography>
                </Button>
              </>
            </Typography>
          </>}
        </Typography>
      </div>
    </div>
  )
}

export default errorPage
