import moment from "moment"
import { intersection, uniq } from "lodash"
import { getExpiredStatus } from "src/utils/OfferingUtils"

export const buildTableFormat = (equipments: any[], propertyName: string) => {
  if (!equipments || !propertyName) return []
  const items = equipments?.reduce((accumulatedItems, equipment: any) => {
    const equipmentName = equipment?.name
    const advisories = equipment[propertyName]?.items?.map((params: any) => {
      params.equipmentName = equipmentName
      return params
    })
    accumulatedItems = [...accumulatedItems, ...advisories]
    return accumulatedItems
  }, [])
  return items || []
}

export const generateDateRangesForQuery = (st, ed, datesPerCall: number) => {
  let currentDate = moment(st)
  const endDate = moment(ed)
  const dateRanges = []

  while (currentDate?.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
    let rangeEndDate = moment(currentDate).add(datesPerCall - 1, "days")
    if (rangeEndDate.isAfter(endDate)) {
      rangeEndDate = moment(endDate)
    }
    dateRanges.push({
      startDate: currentDate.format("YYYY-MM-DD"),
      endDate: rangeEndDate.format("YYYY-MM-DD")
    })
    currentDate = moment(rangeEndDate).add(1, "days")
  }

  return dateRanges
}

export const reportOfferingCodeCheck = (
  offerings: any[],
  offeringsItems: any = [],
  building: any
) => {
  if (!offeringsItems || offeringsItems?.length === 0) return true
  const offeringsCodes = uniq(offeringsItems?.map((obj) => obj?.code)) || []
  const commonOfferings = intersection(offeringsCodes, offerings)
  if (commonOfferings?.length > 0) {
    const expiredOfferings = commonOfferings?.map((offering) => {
      const offeringObj = building?.offerings?.find(
        (val) => val.code === offering
      )
      const { disableAutomatedTestResults } = getExpiredStatus(
        offeringObj,
        building?.tz)
      return disableAutomatedTestResults
    })
    const allExpired = expiredOfferings?.every((val) => val === true)
    return allExpired
  } else {
    return true
  }
}
