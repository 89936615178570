import {
  RadioGroup,
  SecondaryNav,
  LeftPanel,
  SearchInput,
  RightPanel
} from "src/blitz"
import { useSelector, useDispatch } from "react-redux"
import styles from "./ChillerPerformanceReport.module.scss"
import {
  getEquipmentLoader,
  getEquipments,
  getFilter,
  getSelectedEquipments,
  setChillerCharacteristics,
  setSelectedChillerProperties,
  setSelectedEquipments
} from "src/redux/slicers/reportSlice"
import clsx from "clsx"
import { useCallback, useEffect, useMemo, useState } from "react"
import { SearchRegex } from "src/utils/CommonMethods"
import { sortByAlphanumericSpecialChars } from "src/blitz/DataGridTable/helper"
import { getChillerProperties } from "src/graphql/queries/chart"
import { API } from "aws-amplify"
import { getEquipmentSummaryById } from "src/components/equipmentSummary/graphql"
import { v4 as uuidv4 } from "uuid"
import { CURRENT_FETCH } from "../../Charts/constant"
import { selectEquipmentSummary } from "src/redux/slicers/equipmentSummarySlice"
import { TranslateComponent } from "src/common/translations"

const EquipmentList = () => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("")
  const equipLoading = useSelector(getEquipmentLoader)
  const equipments = useSelector(getEquipments)
  const selectedItem = useSelector(getSelectedEquipments)
  const { data: selectedEquipment } = useSelector(selectEquipmentSummary)
  const { equipmentType: selectedEquipmentType } = useSelector(getFilter)
  const handleSearch = (value: string) => {
    setSearchText(value)
  }
  const handleEquipmentChange = (ev) => {
    const selectedItem = equipments.find((item) => item?.id === ev) || {}
    dispatch(setSelectedEquipments(selectedItem))
  }

  const fetchChillerProperties = useCallback(async (equipmentId) => {
    try {
      const newFetch = uuidv4()
      dispatch(
        setChillerCharacteristics({
          type: "Loading",
          [CURRENT_FETCH]: newFetch
        })
      )
      const graphQLParams: any = {
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: getChillerProperties,
        variables: { id: equipmentId }
      }
      const apiData: any = await API.graphql(graphQLParams)
      if (apiData && !apiData.errors) {
        const characteristics =
          apiData?.data?.getPropertyMappingByEquipmentID?.items
        return { characteristics, newFetch }
      }
    } catch (err) {
      dispatch(setChillerCharacteristics({ type: "Failure" }))
    }
  }, [])

  const fetchEquipmentDetails = useCallback(async (equipmentId) => {
    try {
      const graphQLParams: any = {
        authMode: "AMAZON_COGNITO_USER_POOLS",
        query: getEquipmentSummaryById,
        variables: { equipmentId: equipmentId }
      }
      const chillerEquipmentDetails: any = await API.graphql(graphQLParams)
      if (chillerEquipmentDetails && !chillerEquipmentDetails.errors) {
        if (chillerEquipmentDetails?.data?.getEquipment) {
          return chillerEquipmentDetails?.data?.getEquipment || {}
        }
      }
    } catch (err) {
      dispatch(setChillerCharacteristics({ type: "Failure" }))
    }
  }, [])

  useEffect(() => {
    if (selectedItem?.id) {
      dispatch(setSelectedChillerProperties([]))
      const fetchCharacteristics = async () => {
        const [response, equipmentDetails] = await Promise.all([
          fetchChillerProperties(selectedItem?.id),
          fetchEquipmentDetails(selectedItem?.id)
        ])
        dispatch(
          setChillerCharacteristics({
            type: "Success",
            data: response?.characteristics || [],
            [CURRENT_FETCH]: response?.newFetch,
            equipmentId: selectedItem?.id,
            equipmentDetails: equipmentDetails
          })
        )
      }
      fetchCharacteristics()
    }
  }, [selectedItem])

  const filteredEquipments = useMemo(() => {
    let tempEquipments = equipments
    if (searchText) {
      tempEquipments = equipments.filter(({ name }) =>
        SearchRegex(name, searchText)
      )
    }
    tempEquipments = sortByAlphanumericSpecialChars(
      "name",
      "Asc",
      tempEquipments
    )
    return tempEquipments?.map((equipment: any) => ({
      name: equipment.name,
      value: equipment.id
    }))
  }, [equipments, searchText, selectedItem])

  useEffect(() => {
    if (
      selectedEquipment &&
      selectedEquipment?.type === selectedEquipmentType?.value
    ) {
      const preSelectedEquipment =
        equipments.find((item) => item?.id === selectedEquipment?.id) || {}
      dispatch(setSelectedEquipments(preSelectedEquipment))
    }
  }, [selectedEquipment, equipments])
  return (
    <div className={clsx("col-sm", styles.reportListWrapper)}>
      <div className={clsx(styles.reportPanel)}>
        <SecondaryNav withBorder>
          <LeftPanel loading={equipLoading}>
            <TranslateComponent>
              Select System or Equipment
            </TranslateComponent>
          </LeftPanel>
          <RightPanel loading={equipLoading}>
            <SearchInput
              onInputChange={handleSearch}
              onInputClear={() => setSearchText("")}
              searchText={searchText}
            />
          </RightPanel>
        </SecondaryNav>
        <div className={styles.panelList}>
          <div className={styles.panelListInner}>
            <div className={styles.reportListBody}>
              <RadioGroup
                radioGroupClassName={styles.radioButtonGroup}
                options={filteredEquipments}
                layout="column"
                handleChange={handleEquipmentChange}
                selected={selectedItem?.id}
                highLightText={searchText}
                translateCurrentValueOff={true}                
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EquipmentList
