import { useImperativeHandle, forwardRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  getFilter,
  getIsValid,
  getSelectedEquipments,
  setIsValid,
  setReportStatus
} from "src/redux/slicers/reportSlice"
import styles from "./DigitalInspectionReport.module.scss"
import clsx from "clsx"
import { ACTIONS } from "src/constants"
import EquipmentList from "./EquipmentList"
import DIReportSelectionSection from "./DIReportSelectionSection"
import { useMutation } from "src/hooks/APIHooks"
import { downloadFileUsingS3, getDateFormatWithEnglishLocale } from "src/utils/CommonMethods"
import { EmptyReport } from "../EmptyReport"
import { t } from "src/translations/help"
import { getConfig } from "src/config"
import { COMMAND_CENTER_CHART_EXPORT_URL } from "src/constants/common"
import { generatePerformanceChartExport } from "src/graphql/queries/chart"
import { selectEquipmentSummary } from "src/redux/slicers/equipmentSummarySlice"
import { useParams } from "react-router"
import { selectBuildingSummary } from "src/redux/slicers/buildingSummarySlice"

const DigitalInspectionReport = forwardRef((props: any, ref: any) => {
  const { selectedEquipmentType, selectedLanguage } = props
  const { buildingId } = useParams()
  const isValid = useSelector(getIsValid)
  useImperativeHandle(ref, () => ({
    onClickGenerate: generateReportHandler
  }))

  const dispatch = useDispatch()
  const { data: equipmentSummary } = useSelector(selectEquipmentSummary)
  const { data: buildingSummary } = useSelector(selectBuildingSummary)
  const selectedEquipments: any = useSelector(getSelectedEquipments)
  const { startDate, endDate } =
    useSelector(getFilter)
  useEffect(() => {
    dispatch(setIsValid(Object?.keys(selectedEquipments)?.length > 0))
  }, [selectedEquipments])

  const { onSubmit: generatePerformanceChartReport } = useMutation({
    query: generatePerformanceChartExport,
    requireReturnValue: true,
    onSuccess: (data: any) => {
      if (data?.data?.generateChartPDF) {
        const response = JSON.parse(data?.data?.generateChartPDF)
        const srcType = "data:application/octet-stream;base64,"

        const linkSource = srcType + response?.body
        const fileName =
          (equipmentSummary?.building?.name || buildingSummary?.name || "") +
          "_" +
          getDateFormatWithEnglishLocale(startDate) +
          "_" +
          (selectedEquipments?.length > 0 ? selectedEquipments[0]?.name : "") +
          "_" +
          "Digital Inspection.pdf"
        downloadFileUsingS3(linkSource, fileName)
        dispatch(setReportStatus(ACTIONS.COMPLETE))
      }
    },
    onError: () => {
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  })

  // **** Below method is invoked by ref **** /
  const generateReportHandler = () => {
    if (isValid) {
      dispatch(setReportStatus(ACTIONS.LOADING))
      const { externalLinks } = getConfig()
      const type = "pdf"
      if (Boolean(externalLinks[COMMAND_CENTER_CHART_EXPORT_URL])) {
        const width = 820
        const height = 1024
        const viewPortObj = {
          deviceScaleFactor: 1,
          width: width,
          height: height
        }
        const extraParamsObj = {
          landscape: false,
          omitBackground: false
        }
        const viewport = JSON.stringify(viewPortObj)
        const extraParams = JSON.stringify(extraParamsObj)
        const exportParams = `?startDate=${getDateFormatWithEnglishLocale(startDate, "MM-DD-YYYY")}&endDate=${getDateFormatWithEnglishLocale(endDate, "MM-DD-YYYY")}&bc=white&equipmentType=${selectedEquipmentType?.value}&lang=${selectedLanguage?.langCode}`
        const selectedEquipmentsEncode = encodeURIComponent(selectedEquipments.map(equip => equip?.id).join(","))
        const url = `${externalLinks[COMMAND_CENTER_CHART_EXPORT_URL]?.absoluteURL}/DIreport/building/${buildingId}/equipment/${selectedEquipmentsEncode}${exportParams}`
        const paramsObj = {
          url: url,
          type: type,
          viewport,
          extraParams,
          buildingId: "",
          returnType: ""
        }

        generatePerformanceChartReport(paramsObj)
      } else {
        console.log("Please check Command Center URL Configurable JSON file")
      }
    }
  }

  return (
    <div className={clsx(styles.digitalInspectionWrapper)}>
      {selectedEquipmentType?.value !== "" && selectedEquipmentType?.value ? (
        <>
          <EquipmentList selectedEquipmentType={selectedEquipmentType} />
          <DIReportSelectionSection />
        </>
      ) : (
        <EmptyReport message={t("reports.selectreportOptionsabove")} />
      )}
    </div>
  )
})

DigitalInspectionReport.displayName = "Digital Inspection Report"
export default DigitalInspectionReport
