import { useCallback, useMemo, useState } from "react"
import styles from "./Characteristics.module.scss"
import { Button, Typography } from "src/blitz"
import clsx from "clsx"
import { COMPRESSOR_RUN_HOURS, COMPRESSOR_STARTS_BEFORE } from "../constant"
import { TranslateComponent } from "src/common/translations"

const CompressorRunHoursAndStarts = (props) => {
  const {
    modalCharacteristics,
    handleUpdateModalClose,
    handleSave = () => null
  } = props
  const [inputs, setInputs] = useState(modalCharacteristics)

  const onSaveClick = () => {
    const savedObject: any = {
      current: modalCharacteristics,
      updated: {}
    }
    const characteristics = modalCharacteristics || {}
    Object.keys(characteristics)?.forEach((targetKey) => {
      const crnt = modalCharacteristics?.[targetKey] || {}
      const crntValue = crnt?.value || ""
      const uptdValue: any = inputs?.[targetKey]?.value || ""
      const passValue: any = inputs?.[targetKey]?.value || ""
      if (!Boolean(uptdValue) && uptdValue !== 0) {
        return
      } else {
        if (crntValue !== uptdValue && crnt?.id) {
          savedObject.updated[targetKey] = {
            ...crnt,
            value: passValue
          }
        }
      }
    })
    handleSave(savedObject)
  }
  const handleChange = useCallback((e, cKey) => {
    const value = e?.target?.value
    setInputs((prvs) => {
      const obj = { ...prvs?.[cKey] }
      obj.value = value
      return {
        ...prvs,
        [cKey]: {
          ...obj
        }
      }
    })
  }, [])

  const disableSave = useMemo(() => {
    const isSameCRH =
      inputs?.[COMPRESSOR_RUN_HOURS]?.value ===
      modalCharacteristics?.[COMPRESSOR_RUN_HOURS]?.value
    const isSameCSB =
      inputs?.[COMPRESSOR_STARTS_BEFORE]?.value ===
      modalCharacteristics?.[COMPRESSOR_STARTS_BEFORE]?.value
    const isNull =
      (!Boolean(inputs?.[COMPRESSOR_RUN_HOURS]?.value) &&
        inputs?.[COMPRESSOR_RUN_HOURS]?.value !== 0) ||
      (!Boolean(inputs?.[COMPRESSOR_STARTS_BEFORE]?.value) &&
        inputs?.[COMPRESSOR_STARTS_BEFORE]?.value !== 0)
    return (isSameCRH && isSameCSB) || isNull
  }, [modalCharacteristics, inputs])

  return (
    <>
      <div
        className={clsx(
          styles?.wrapper,
          styles?.compressorRunHoursAndStartsWrapper
        )}
      >
        <div className={clsx(styles?.italic, styles?.unselectable)}>
          <TranslateComponent>
            {
              "To ensure accurate data in the Total Life of Equipment section in Compressor Run Hours and Starts, the following information must be entered. (If this chiller was acquired after TIS data collection started, enter 0 for both items.)"
            }
          </TranslateComponent>
        </div>
        <div className={styles?.inputFieldWrapper}>
          <Typography tagType="div" styleType="p4" className={styles?.label}>
            Run Hours Before TIS Data Collection Start Date:
          </Typography>
          <input
            type="number"
            id={COMPRESSOR_RUN_HOURS}
            name="Run Hours Before TIS Data Collection Start Date"
            value={inputs?.[COMPRESSOR_RUN_HOURS]?.value}
            onChange={(e) => handleChange(e, COMPRESSOR_RUN_HOURS)}
          ></input>
        </div>
        <div className={styles?.inputFieldWrapper}>
          <Typography tagType="div" styleType="p4" className={styles?.label}>
            Starts Before TIS Data Collection Start Date:
          </Typography>
          <input
            type="number"
            id={COMPRESSOR_STARTS_BEFORE}
            name="Starts Before TIS Data Collection Start Date"
            value={inputs?.[COMPRESSOR_STARTS_BEFORE]?.value}
            onChange={(e) => handleChange(e, COMPRESSOR_STARTS_BEFORE)}
          ></input>
        </div>
      </div>
      <div className={styles?.footer}>
        <Button
          type="button"
          variant="blue"
          onClick={onSaveClick}
          disabled={disableSave}
        >
          <span>
            <TranslateComponent>
              Save
            </TranslateComponent>
          </span>
        </Button>
        <Button type="button" onClick={handleUpdateModalClose}>
          <span>
            <TranslateComponent>
              Close
            </TranslateComponent>
          </span>
        </Button>
      </div>
    </>
  )
}

export default CompressorRunHoursAndStarts
