import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import automatedTests from "denali-translations/src/locales/en-US/automated-tests.json"
import properties from "denali-translations/src/locales/en-US/properties.json"
import exceptions from "denali-translations/src/locales/en-US/exception-details.json"
import buildings from "denali-translations/src/locales/en-US/command-center/buildings.json"
import reports from "denali-translations/src/locales/en-US/command-center/reports.json"
import common from "denali-translations/src/locales/en-US/command-center/common.json"
import performance from "denali-translations/src/locales/en-US/command-center/performance.json"
import adminSettings from "denali-translations/src/locales/en-US/command-center/admin-settings.json"
export const BASE_LANGUAGE = "en"
const resources = {
  en: {
    translation: {
      properties,
      exceptions,
      buildings,
      reports,
      common,
      adminSettings,
      performance,
      automatedTests
    }
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    saveMissingPlurals: false,
    fallbackLng: localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : BASE_LANGUAGE,
    returnEmptyString: false,
    debug: true,
    supportedLngs: [BASE_LANGUAGE],
    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false
    },
    // string or array of namespaces to load
    // ns: [
    //   "common",
    //   "buildings",
    //   "reports",
    // ],
    // defaultNS: "common",
    lng: BASE_LANGUAGE,
    keySeparator: "."
  })

export default i18n
