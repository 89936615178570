import moment from "moment"
import {
  CHILLER_CAPACITY_NOMINAL,
  CHILLER_ENTERED_SERVICE,
  CHILLER_LIFE,
  CHILLER_PROFILE,
  COMPRESSOR_RUN_HOURS,
  COMPRESSOR_RUN_HOURS_AND_STARTS,
  COMPRESSOR_STARTS_BEFORE,
  MISSING_TEXT,
  serviceDateFormat
} from "./constant"
import { isNumber } from "lodash"
import { validParentId } from "src/utils/analyticsHelper"
import { isValueAvailable } from "src/utils/CommonMethods"
import { UNIT_CONVERSION, UOM_LABELS } from "src/constants/unitConversion"

export const displayTextGenerator = (targetKey, value, uom = UOM_LABELS.IP) => {
  let displayText = ""
  if (targetKey === CHILLER_CAPACITY_NOMINAL) {
    displayText = value
      ? uom === UOM_LABELS.SI ? (value || "")?.toString() + " " + UOM_LABELS.KW : (value || "")?.toString() + " " + UOM_LABELS.TONS
      : MISSING_TEXT
  } else if (targetKey === CHILLER_ENTERED_SERVICE) {
    displayText = value
      ? moment(new Date(value))?.format(serviceDateFormat)
      : MISSING_TEXT
  } else if (targetKey === CHILLER_LIFE) {
    displayText = value ? (value || "") + " " + "Years" : MISSING_TEXT
  } else if (
    targetKey === COMPRESSOR_RUN_HOURS ||
    targetKey === COMPRESSOR_STARTS_BEFORE
  ) {
    displayText = isValueAvailable(value) ? value : MISSING_TEXT
  } else {
    displayText = MISSING_TEXT
  }
  return displayText
}

export const getChillerProperties = (
  chillerCharacteristic: any = [],
  equipmentDetails,
  uom = UOM_LABELS.IP
) => {
  const chillerCapacityNominal = chillerCharacteristic?.find(
    (obj: any) => obj?.targetKey === CHILLER_CAPACITY_NOMINAL
  )
  const chillerEnteredService = chillerCharacteristic?.find(
    (obj: any) => obj?.targetKey === CHILLER_ENTERED_SERVICE
  )
  const chillerLifeObj = chillerCharacteristic?.find(
    (obj: any) => obj?.targetKey === CHILLER_LIFE
  )

  const compressorRunHours = chillerCharacteristic?.find(
    (obj: any) => obj?.targetKey === COMPRESSOR_RUN_HOURS
  )
  const compressorStartsBefore = chillerCharacteristic?.find(
    (obj: any) => obj?.targetKey === COMPRESSOR_STARTS_BEFORE
  )

  const disableChillerProfileCharacteristics =
    !Boolean(chillerCapacityNominal) || !Boolean(chillerEnteredService)
  const disableChillerLifeCharacteristics =
    !Boolean(chillerLifeObj) || !Boolean(chillerEnteredService)
  const disableCompressorRunHoursCharacteristics =
    !Boolean(compressorRunHours) || !Boolean(compressorStartsBefore)

  const chillerCapacityNominalValue = isNumber(
    parseFloat(chillerCapacityNominal?.value)
  )
    ? uom === UOM_LABELS.SI ? Math.round(chillerCapacityNominal?.value * UNIT_CONVERSION.VALUE_BTU_TO_KW) : parseFloat(chillerCapacityNominal?.value)
    : null

  const chillerLifeObjValue = isNumber(parseFloat(chillerLifeObj?.value))
    ? parseFloat(chillerLifeObj?.value)
    : null
  const compressorRunHoursValue = isNumber(
    parseFloat(compressorRunHours?.value)
  )
    ? parseFloat(compressorRunHours?.value)
    : null
  const compressorStartsBeforeValue = isNumber(
    parseFloat(compressorStartsBefore?.value)
  )
    ? parseFloat(compressorStartsBefore?.value)
    : null
  const characteristicsProps = [
    {
      name: "Chiller Profile",
      value: CHILLER_PROFILE,
      updateDataHeading: "Enter Chiller Profile Data",
      info: {
        propKey: CHILLER_PROFILE,
        description:
          "section includes general information about the chiller, such as chiller name, capacity, serial number, model number, chiller entered service date, sales order number and TIS collection since date.",
        imageSrc: "/images/cpr/new-chiller-profile-img.png",
        dialogWidth: "992px",
        imageWidth: "925px",
        imageHeight: "156px"
      },
      otherProps: [
        {
          id: chillerCapacityNominal?.id,
          targetKey: CHILLER_CAPACITY_NOMINAL,
          label: "Chiller Capacity",
          value: chillerCapacityNominalValue,
          disabled: disableChillerProfileCharacteristics,
          details: chillerCapacityNominal
        },
        {
          id: chillerEnteredService?.id,
          targetKey: CHILLER_ENTERED_SERVICE,
          label: "Chiller Entered Service Date",
          value: chillerEnteredService?.value,
          disabled: disableChillerProfileCharacteristics,
          details: chillerEnteredService
        }
      ]
    },
    {
      name: "Compressor Run Hours and Starts",
      value: COMPRESSOR_RUN_HOURS_AND_STARTS,
      updateDataHeading: "Enter Compressor Run Hours and Starts",
      info: {
        propKey: COMPRESSOR_RUN_HOURS_AND_STARTS,
        description:
          "section shows data about the compressor’s run hours and starts for the selected month vs. the previous month and year along with the total run hours and starts over the total life of the equipment. To display properly, this chart requires that any “Comp Running” properties for the chiller are mapped properly.",
        imageSrc: "/images/cpr/new-chiller-run-hours-and-starts-img.png",
        dialogWidth: "992px",
        imageWidth: "920px",
        imageHeight: "302px"
      },
      otherProps: [
        {
          id: compressorRunHours?.id,
          targetKey: COMPRESSOR_RUN_HOURS,
          label: "Run Hours Before TIS Data Collection Start Date",
          value: compressorRunHoursValue,
          disabled: disableCompressorRunHoursCharacteristics,
          details: compressorRunHours
        },
        {
          id: compressorStartsBefore?.id,
          targetKey: COMPRESSOR_STARTS_BEFORE,
          label: "Starts Before TIS Data Collection Start Date",
          value: compressorStartsBeforeValue,
          disabled: disableCompressorRunHoursCharacteristics,
          details: compressorStartsBefore
        }
      ]
    },
    {
      name: "Chiller Life",
      value: CHILLER_LIFE,
      updateDataHeading: "Enter Chiller Life Data",
      disabled: !Boolean(chillerEnteredService?.value),
      info: {
        propKey: CHILLER_LIFE,
        description:
          "section shows a simple chart indicating the chiller’s current age within the ASHRAE life expectancy guidelines.",
        imageSrc: "/images/cpr/new-chiller-life-img.png",
        dialogWidth: "369px",
        imageWidth: "302px",
        imageHeight: "307px"
      },
      otherProps: [
        {
          id: chillerEnteredService?.id,
          targetKey: CHILLER_ENTERED_SERVICE,
          label: "Chiller Entered Service Date",
          value: chillerEnteredService?.value,
          isRequired: true,
          disabled: disableChillerLifeCharacteristics,
          details: chillerEnteredService
        },
        {
          id: chillerLifeObj?.id,
          targetKey: CHILLER_LIFE,
          label: "Chiller Life",
          value: chillerLifeObjValue,
          disabled: disableChillerLifeCharacteristics,
          details: chillerLifeObj
        }
      ]
    }
  ]
  const plantLoadAnalysis = [
    {
      name: "Plant Load Analysis",
      value: "PlantLoadAnalysis",
      info: {
        propKey: "PlantLoadAnalysis",
        description:
          "section allows the customer to see when the chiller plant is high or low during the selected month. For this section to display properly, the chiller must be part of a Chiller Plant and have a value entered in Equipment Setup > Characteristics for \"Chiller Capacity: Nominal.\"",
        imageSrc: "/images/cpr/new-plant-load-analysis-img.png",
        dialogWidth: "992px",
        imageWidth: "925px",
        imageHeight: "320px"
      },
      otherProps: [
        {
          checkboxName: "Include Cooling Degree Days",
          name: "Cooling Degree Days",
          info: {
            description:
              "A cooling degree day is every degree that the mean temperature is above 65 degrees during a day.",
            dialogWidth: "500px"
          },
          value: "IncludeCoolingDegreeDays"
        }
      ]
    }
  ]
  const chillerProperties = [
    {
      name: "Performance Efficiency",
      value: "PerformanceEfficiency",
      info: {
        propKey: "PerformanceEfficiency",
        description:
          "section includes a chart that averages the KW/Ton for each day and calculates the average KW/Ton for the selected month. This chart informs customers how efficiently their chiller is running. To display properly, this chart requires Power and Load data.",
        imageSrc: "/images/cpr/new-perf-efficiency-img.png",
        dialogWidth: "781px",
        imageWidth: "713px",
        imageHeight: "320px"
      }
    },
    {
      name: "Performance/Water Temp",
      value: "PerformanceWaterTemp",
      info: {
        propKey: "PerformanceWaterTemp",
        description:
          "section includes a chart that shows the daily average for the evaporator leaving temperature and the condenser leaving temperature along with the monthly average temperature of each. This chart informs the customer how efficiently their chiller is running. To display properly, this chart requires that the properties “Condenser Water Temperature Leaving” and “Chilled Water Temperature Leaving” are mapped correctly.",
        imageSrc: "/images/cpr/new-perf-water-temp-img.png",
        dialogWidth: "781px",
        imageWidth: "713px",
        imageHeight: "320px"
      }
    },
    {
      name: "Condenser Heat Transfer",
      value: "CondenserHeatTransfer",
      info: {
        propKey: "CondenserHeatTransfer",
        description:
          "section includes a chart that shows the daily average for the evaporator leaving temperature and the condenser leaving temperature along with the monthly average temperature of each. This chart informs the customer how efficiently their chiller is running. To display properly, this chart requires that the properties “Condenser Water Temperature Leaving” and “Chilled Water Temperature Leaving” are mapped correctly.",
        imageSrc: "/images/cpr/new-cond-heat-transfer-img.png",
        dialogWidth: "781px",
        imageWidth: "713px",
        imageHeight: "320px"
      }
    },
    {
      name: "Evaporator Heat Transfer",
      value: "EvaporatorHeatTransfer",
      info: {
        propKey: "EvaporatorHeatTransfer",
        description:
          "section includes a chart that uses Chiller Current Draw (%RLA) and Evaporator Approach Temperature (˚F or ˚C) which helps show increased performance of the evaporator after service. This chart will most likely illustrate a return to sub-optimal performance over time (approach temperature will likely increase).",
        imageSrc: "/images/cpr/new-evap-heat-transfer-img.png",
        dialogWidth: "781px",
        imageWidth: "713px",
        imageHeight: "320px"
      }
    },
    {
      name: "Load Profile",
      value: "LoadProfile",
      info: {
        propKey: "LoadProfile",
        description:
          "section includes a chart that informs the customer on how long the chiller operated in each load range. Current month vs. previous month data is shown. This will allow the customers to see whether the chiller is frequently operating in an inefficient load range.",
        imageSrc: "/images/cpr/new-load-prof-img.png",
        dialogWidth: "781px",
        imageWidth: "713px",
        imageHeight: "320px"
      }
    },
    {
      name: "Operational Data",
      value: "OperationalData",
      info: {
        propKey: "OperationalData",
        description:
          "section allows the customer to gauge chiller performance of industry standard measurements for the current month in different load ranges.",
        imageSrc: "/images/cpr/new-perf-assess-img.png",
        dialogWidth: "992px",
        imageWidth: "925px",
        imageHeight: "320px"
      }
    },
    {
      name: "Top Operating Modes",
      value: "TopOperatingModes",
      info: {
        propKey: "TopOperatingModes",
        description:
          "section shows what percentage of the time the chiller was in each operating mode state for the current month and previous month.",
        imageSrc: "/images/cpr/new-operating-mode-img.png",
        dialogWidth: "992px",
        imageWidth: "925px",
        imageHeight: "320px"
      }
    },
    {
      name: "Shutdowns",
      value: "Shutdowns",
      info: {
        propKey: "Shutdowns",
        description:
          "section shows how many shutdowns occurred in the selected month vs. the previous month and why the shutdown happened.",
        imageSrc: "/images/cpr/new-shutdowns-img.png",
        dialogWidth: "992px",
        imageWidth: "925px",
        imageHeight: "320px"
      }
    }
  ]

  const defaultSelectedProperties = [
    ...characteristicsProps
      ?.filter((obj) => !obj?.disabled)
      ?.map((obj) => obj?.value),
    ...chillerProperties?.map((obj) => obj?.value)
  ]
  const finalProps: any = [...characteristicsProps]

  if (validParentId(equipmentDetails)) {
    finalProps.push(...plantLoadAnalysis)
    plantLoadAnalysis?.forEach((obj) => {
      defaultSelectedProperties.push(obj?.value)
      const otherProps = obj?.otherProps || []
      otherProps?.forEach((obj) => {
        if (obj?.checkboxName) {
          defaultSelectedProperties.push(obj?.value)
        }
      })
    })
  }

  finalProps.push(...chillerProperties)

  const characteristicsValues: any = {}

  finalProps?.forEach((obj) => {
    const otherProps = (obj?.otherProps || [])?.filter(
      (obj) => !obj?.checkboxName
    )
    otherProps?.forEach((o) => {
      characteristicsValues[o?.targetKey] = o || {}
    })
  })
  return {
    finalProps: finalProps,
    defaultSelectedProperties: defaultSelectedProperties,
    characteristicsValues
  }
}

export const dateFormatter = (date: any) => {
  const dateObj = new Date(date)
  if (date) {
    const year = dateObj?.getFullYear()
    const month = String(dateObj?.getMonth() + 1).padStart(2, "0") // Months are zero-based
    const day = String(dateObj?.getDate()).padStart(2, "0")
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate // Output: 2018-03-15
  } else {
    return ""
  }
}
