import { useEffect, useRef } from "react"
import {
  isString,
  isNumber,
  isBoolean,
  isArray,
  isEmpty,
  isPlainObject
} from "lodash"
// import moment from "moment"
import { getMoment } from "./MomentUtils"
import { CHART_DATE_FORMAT } from "src/constants"
import { END_DATE, START_DATE } from "src/blitz/DateRange/constant"
import { v4 as uuidv4 } from "uuid"
import { getConfig } from "src/config"
import { COMMAND_CENTER_CHART_EXPORT_URL } from "src/constants/common"
import { Auth } from "aws-amplify"
import { useSelector } from "react-redux"
import { selectLanguage } from "src/redux/slicers/userData"
const moment = getMoment()

// ****** Flattens object with nested objects and arrays of any depth ***
export const flatterData = (rawData: any, prefix?: string, result?: any) => {
  prefix = prefix ? prefix : ""
  result = result ? result : {}

  if (isString(rawData) || isNumber(rawData) || isBoolean(rawData)) {
    result[prefix] = rawData
    return result
  }

  if (isArray(rawData) || isPlainObject(rawData)) {
    for (const i in rawData) {
      let pref = prefix
      if (isArray(rawData)) {
        pref = pref + `[${i}]`
      } else {
        if (isEmpty(prefix)) {
          pref = i
        } else {
          pref = prefix + "_" + i
        }
      }
      flatterData(rawData[i], pref, result)
    }
    return result
  }
  return result
}

//* Below hook is used to make proper setInterval in functional components since set interval will not be cleared properly*/
export const useInterval = (callback: any, delay: number) => {
  const savedCallback: any = useRef()
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}

//* Below hook is used to get previous props values in functional components */
export const usePrevious = (value: any) => {
  // The ref object is a generic container whose current property is mutable
  // and it can hold any value, similar to an instance property on a class
  const ref: any = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes or we can also try without any dependency
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export const SearchRegex = (text = "", searchText = "") => {
  return text?.toLowerCase()?.includes(searchText?.toLowerCase())
}

export const getActiveNav = (pathname, pageName) => pathname?.split("/").includes(pageName)
export const getActiveNavUrl = (pathname, pageName) => pathname === pageName

export const generateArray = (length, defaultValue) => {
  const sample = []
  for (let i = 0; i < length; i++) sample.push(defaultValue)
  return sample
}

// params  building

type BuidlingType = {
  address?: string
  city?: string
  state?: string
  postalCode?: string
  country?: string
}
export type DateRangeType = {
  start?: string | Date
  end?: string | Date
}

export const buildingAddress = (building: BuidlingType) => {
  const comma = ", "
  let addressStr = ""
  if (building?.address) addressStr += building?.address

  if (building?.city) addressStr += comma + building?.city

  if (building?.state) addressStr += comma + building?.state

  if (building?.postalCode) addressStr += comma + building?.postalCode

  if (building?.country) addressStr += comma + building?.country

  return addressStr
}

export const getPropByPath = (object: any, path: any, defaultValue: any) => {
  const _path = Array.isArray(path) ? path : path.split(".")
  if (object && _path.length)
    return getPropByPath(object[_path.shift()], _path, defaultValue)
  return object === undefined ? defaultValue : object
}

export const getDateDifferByDays = (
  date: any,
  type: string,
  days: number,
  format = "YYYY-MM-DD"
) => moment(date)?.[type](days, "days").format(format)

export const getMomentDateBasedOnTimeZone = (tz: string) => moment.tz(tz)

export const getDateFormat = (date: string | Date, format = "YYYY-MM-DD") =>
  moment(date).format(format)

// Use these Date methods with locale = 'en' to pass date in API payload
export const getDateFormatWithEnglishLocale = (date: string | Date, format = "YYYY-MM-DD") =>
  moment(date).locale("en").format(format)

export const getDateDifferByDaysWithEnglishLocale = (
  date: any,
  type: string,
  days: number,
  format = "YYYY-MM-DD"
) => moment(date)?.locale("en")[type](days, "days").format(format)

export const IsStringContainsOnlyNumber = (value: string) => /^\d+$/.test(value)

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str?.replace(/'/g, "\""))
  } catch (e) {
    return false
  }
  return true
}
// Return true if the string format is mm/dd/yy or m/d/yy
export const IsStringContainsDate = (value: string) =>
  /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{2}$/.test(value)

export const convertToCamelCaseId = (input: string) => {
  const regex = /\s+(\w)?/gi
  const output = input.toLowerCase().replace(regex, function (match, letter) {
    return letter.toUpperCase()
  })
  return output
}

export const handleDateRangeSubmit = (
  dateSubmitted: DateRangeType,
  baseURL,
  navigate
) => {
  const startDate = getDateFormat(
    dateSubmitted?.start,
    CHART_DATE_FORMAT.RANGE_DATE_FORMAT
  )
  const endDate = getDateFormat(
    dateSubmitted?.end,
    CHART_DATE_FORMAT.RANGE_DATE_FORMAT
  )
  const path = `${baseURL?.pathname}?${START_DATE}=${startDate}&${END_DATE}=${endDate}`
  navigate(path)
}
// For Downloading the Report
export const downloadFileUsingS3 = (data, name) => {
  const link = document.getElementById("download-file") as HTMLAnchorElement
  if (link != null) {
    link.href = data
    link.setAttribute("download", name)
    setTimeout(() => {
      link.click()
    })
  }
}

export const getDateDisplay = (date: string | Date, format = "dddd, LL") => moment.utc(date).format(format)
export const getDateTimeDisplay = (date: string | Date, format = "dddd, LL, h:mm A") => moment.utc(date).format(format)

export const getDateByDateString = (date: string | Date, timeZone: string) =>
  date ? (timeZone ? moment(date)?.tz(timeZone) : moment(date)) : null
export const currentDate = (timeZone?: string) =>
  timeZone ? moment().tz(timeZone) : moment()

export const buildCreateFindingTCURL = (
  traneConnectURL,
  serviceAdvisoriesList,
  selectedAdvisories,
  summary
) => {
  const {
    absoluteURL = "",
    findingsPath = "",
    findingParameters = {}
  } = traneConnectURL
  const equipment = serviceAdvisoriesList?.find(
    (obj) => obj?.id === selectedAdvisories[0]
  )
  const { externalLinks } = getConfig()
  const equipmentId = equipment?.equipmentId
  const sid = selectedAdvisories[0]
  const chartUrl = `${externalLinks[COMMAND_CENTER_CHART_EXPORT_URL]?.absoluteURL}/${findingParameters?.building}/${summary?.id}/${findingParameters?.equipmentId}/${equipmentId}/${findingParameters?.performance}?${findingParameters?.chartId}=${equipment.chartId}`
  const accountParameter = summary?.accountId
    ? `${findingParameters?.accountId}=${summary?.accountId}`
    : ""
  const buildingParameter = summary?.id
    ? `${findingParameters?.buildingId}=${summary?.id}`
    : ""
  const equipmentParameter = equipmentId
    ? `${findingParameters?.equipmentId}=${equipmentId}`
    : ""
  const sidParameter = sid ? `${findingParameters?.sid}=${sid}` : ""
  const chartLinkParameter = chartUrl
    ? `${findingParameters?.chartLink}=${encodeURIComponent(chartUrl)}`
    : ""
  let urlParams = ""
  const urlArray = [
    accountParameter,
    buildingParameter,
    equipmentParameter,
    sidParameter,
    chartLinkParameter
  ]
  urlArray?.forEach((item: string) => {
    if (Boolean(item)) {
      urlParams += `${item}&`
    }
  })
  const urlTC = `${absoluteURL}/${findingsPath}${Boolean(urlParams) ? "?" : ""
    }${urlParams?.slice(0, -1)}`
  return urlTC
}

export const buildCreateNoteTCURL = (
  traneConnectURL,
  serviceAdvisoriesList,
  selectedAdvisories,
  summary,
  equipmentSummary?,
  externalLinks?
) => {
  const {
    absoluteURL = "",
    findingsPath = "",
    findingParameters = {},
    pathParameters = {},
    additionalQuery = ""
  } = traneConnectURL
  const equipment = serviceAdvisoriesList?.find(
    (obj) => obj?.id === selectedAdvisories[0]
  )
  const equipmentId = equipment?.equipmentId
  const buildingParameter =
    findingParameters?.buildingId &&
      summary?.id &&
      findingParameters?.buildingId
      ? `${findingParameters?.buildingId}=${summary?.id}`
      : ""
  const accountParameter =
    summary?.accountId && findingParameters?.accountId
      ? `${findingParameters?.accountId}=${summary?.accountId}`
      : ""
  const equipmentParameter =
    equipmentSummary?.id && findingParameters?.selectedEquipmentId
      ? `${findingParameters?.selectedEquipmentId}=${equipmentSummary.id}`
      : ""
  const selectedequipmentParameter =
    equipmentId && findingParameters?.equipmentId
      ? `${findingParameters?.equipmentId}=${equipmentId}`
      : ""

  const sidParameter =
    Array.isArray(selectedAdvisories) && selectedAdvisories.length > 0
      ? `${findingParameters?.sid}=${selectedAdvisories[0]}`
      : ""

  const chartLinkParameter = findingParameters?.chartLink
    ? `${findingParameters?.chartLink}=${externalLinks[COMMAND_CENTER_CHART_EXPORT_URL]?.absoluteURL
    }/building/${summary?.id}/equipment/${equipmentId}/performance?chartId=${equipment?.chartId
    }&startDate=${moment(equipment?.worstException)
      .subtract(6, "days")
      .format("MM-DD-YYYY")}
  &endDate=${equipment?.worstException}`
    : ""
  const newFindingsPath =
    findingsPath +
    "/" +
    (pathParameters && (pathParameters?.buildingId ? summary?.id : ""))

  let urlParams = ""
  const urlArray = [
    accountParameter,
    buildingParameter,
    equipmentParameter,
    selectedequipmentParameter,
    sidParameter,
    chartLinkParameter
  ]
  if (additionalQuery !== "") {
    urlArray.push(additionalQuery)
  }
  urlArray?.forEach((item: string) => {
    if (Boolean(item)) {
      urlParams += `${item}&`
    }
  })
  const urlTC = `${absoluteURL}/${newFindingsPath}${Boolean(urlParams) ? "?" : "/"
    }${urlParams?.slice(0, -1)}`
  return urlTC
}

export const generateClientID = () => {
  const clientID = uuidv4()
  return clientID
}

export const isValueAvailable = (value: any) => {
  return value === 0 || value
}

const combineBuildings = (user: any) => {
  try {
    return user.bIds
  } catch {
    return []
  }
}

export const fectchAccessToken = async (buildingId: string) => {
  const updatedUser = await Auth.currentAuthenticatedUser()
  if (
    updatedUser?.signInUserSession?.idToken?.payload?.[
      "cognito:groups"
    ]?.includes("SuperAdmin")
  ) {
    return true
  } else {
    await Auth.updateUserAttributes(updatedUser, {
      "custom:accessControl": JSON.stringify({
        b: buildingId ? [buildingId] : undefined
      })
    })
    const { idToken }: any = await Auth.currentSession()
    const isSuperAdmin =
      idToken?.payload?.["cognito:groups"]?.includes("SuperAdmin")
    const allBuildings = combineBuildings(idToken?.payload)
    return allBuildings.includes(buildingId) || isSuperAdmin
  }
}

export const extractdata = (str) => {
  return JSON.parse(str.replaceAll("=", ":").replace("index", "\"index\"").replace("body", "\"body\"").replace("statusCode", "\"statusCode\""))
}

// Funtion to check if application is running on DEV environment
export const isDEV = () => {
  const config = getConfig()
  const environment = (config?.appEnvironment?.BUILD_ENV || "")?.toLowerCase()
  return environment === "dev" || environment === "development"
} 

export const LanguageCodeForIntl = {
  "pt-PT": "pt",
  "es": "pt",
  "es-MX": "pt"
}
//Handling Date Format By Locale Setting
export const getDateFormatByLocale = (OriginalFormat) => {
let FinalDateFormat = OriginalFormat;
let selectedLanguage = useSelector(selectLanguage);
selectedLanguage = selectedLanguage ? LanguageCodeForIntl[selectedLanguage] ? LanguageCodeForIntl[selectedLanguage] : selectedLanguage : "en";

if(selectedLanguage === "en")
  return OriginalFormat;

let lDateFormat = new Intl.DateTimeFormat(selectedLanguage, { month: "2-digit", day: "2-digit" }).formatToParts()
switch (OriginalFormat) {
  

    case "dddd, MMMM D, YYYY": {
       lDateFormat = new Intl.DateTimeFormat(selectedLanguage, {
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
      }).formatToParts();
   
      if (lDateFormat && lDateFormat.length) {
          const Part1 = lDateFormat[0].type === "weekday"? "dddd": lDateFormat[0].type === "month"? "MMMM": lDateFormat[0].type === "day"? "D" : "YYYY";
          const literal1 = lDateFormat[1]?.type === "literal"? lDateFormat[1].value: ", ";
          const Part2 = lDateFormat[2]?.type === "weekday" ? "dddd": lDateFormat[2]?.type === "month"? "MMMM": lDateFormat[2]?.type === "day"? "D" : "YYYY";
          const literal2 = lDateFormat[3]?.type === "literal"? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[3].value: " ";
          const Part3 = lDateFormat[4]?.type === "weekday" ? "dddd" : lDateFormat[4]?.type === "month" ? "MMMM": lDateFormat[4]?.type === "day"? "D": "YYYY";
          const literal3 = lDateFormat[5]?.type === "literal"? lDateFormat[5].value === " de " ? " [de] ": lDateFormat[5].value : ", ";
          const Part4 = lDateFormat[6]?.type === "weekday" ? "dddd" : lDateFormat[6]?.type === "month" ? "MMMM": lDateFormat[6]?.type === "day"? "D": "YYYY";
   
          FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + literal3 + Part4;
      } 
   
      return FinalDateFormat;
  }
  case "dddd MMMM D, YYYY h:mm A":{
      lDateFormat = new Intl.DateTimeFormat(selectedLanguage, {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
  }).formatToParts();
  if (lDateFormat && lDateFormat.length) {
    const Part1 = lDateFormat[0].type === "weekday"? "dddd": lDateFormat[0].type === "month"? "MMMM": lDateFormat[0].type === "day"? "D" : "YYYY";
    const literal1 = lDateFormat[1]?.type === "literal"? lDateFormat[1].value: ", ";
    const Part2 = lDateFormat[2]?.type === "weekday" ? "dddd": lDateFormat[2]?.type === "month"? "MMMM": lDateFormat[2]?.type === "day"? "D" : "YYYY";
    const literal2 = lDateFormat[3]?.type === "literal"? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[3].value: " ";
    const Part3 = lDateFormat[4]?.type === "weekday" ? "dddd" : lDateFormat[4]?.type === "month" ? "MMMM": lDateFormat[4]?.type === "day"? "D": "YYYY";
    const literal3 = lDateFormat[5]?.type === "literal"? lDateFormat[5].value === " de " ? " [de] ": lDateFormat[5].value : ", ";
    const Part4 = lDateFormat[6]?.type === "weekday" ? "dddd" : lDateFormat[6]?.type === "month" ? "MMMM": lDateFormat[6]?.type === "day"? "D": "YYYY";

    FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + literal3 + Part4 +" h:mm A";
  } 

  return FinalDateFormat;
}
      case "MMMM D, YYYY": {
         lDateFormat = new Intl.DateTimeFormat(selectedLanguage, {
          month: "long",
          day: "numeric",
          year: "numeric",
        }).formatToParts();
       
        if (lDateFormat && lDateFormat.length) {
          const Part1 = lDateFormat[0]?.type === "month" ? "MMMM" : lDateFormat[0]?.type === "day" ? "D" : "YYYY";
          const literal1 = lDateFormat[1]?.type === "literal" ? lDateFormat[1].value === " de " ? " [de] " : lDateFormat[1].value : " ";
          const Part2 = lDateFormat[2]?.type === "day" ? "D" : lDateFormat[2]?.type === "month" ? "MMMM" : "YYYY";
          const literal2 = lDateFormat[3]?.type === "literal" ? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[3].value : ", ";
          const Part3 = lDateFormat[4]?.type === "year" ? "YYYY" : lDateFormat[4]?.type === "month" ? "MMMM" : "D";
          const literal3 = lDateFormat[5]?.type === "literal" ? lDateFormat[5].value === " de " ? " [de] " : lDateFormat[5].value : "";
       
          FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + literal3;
        }
        return FinalDateFormat;
      }
      case "M/DD/YY": {
        const lDateFormat = new Intl.DateTimeFormat(selectedLanguage, {
            month: "numeric",
            day: "2-digit",
            year: "2-digit",
        }).formatToParts();
     
        if (lDateFormat && lDateFormat.length) {
            const Part1 = lDateFormat[0].type === "month" ? "M" : lDateFormat[0].type === "day" ? "DD" : "YY";
            const literal1 = lDateFormat[1].type === "literal" ? lDateFormat[1].value : "/";
            const Part2 = lDateFormat[2].type === "month" ? "M" : lDateFormat[2].type === "day" ? "DD" : "YY";
            const literal2 = lDateFormat[3].type === "literal" ? lDateFormat[3].value : "/";
            const Part3 = lDateFormat[4].type === "month" ? "M" : lDateFormat[4].type === "day" ? "DD" : "YY";
            const literal3 = lDateFormat[5]?.type === "literal" ? lDateFormat[5]?.value : "";
            FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + literal3;
        }
        return FinalDateFormat;
    }
    case "MM-DD-YYYY": {
      const lDateFormat = new Intl.DateTimeFormat(selectedLanguage, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).formatToParts();
     
      if (lDateFormat && lDateFormat.length) {
        const Part1 = lDateFormat[0].type === "month" ? "MM" : lDateFormat[0].type === "day" ? "DD" : "YYYY";
        const literal1 = lDateFormat[1]?.type === "literal" ? lDateFormat[1].value === " de " ? " [de] " : lDateFormat[1].value : "-";
        const Part2 = lDateFormat[2]?.type === "month" ? "MM" : lDateFormat[2]?.type === "day" ? "DD" : "YYYY";
        const literal2 = lDateFormat[3]?.type === "literal" ? lDateFormat[3].value === " de " ? " [de] " : lDateFormat[1].value : "-";
        const Part3 = lDateFormat[4]?.type === "month" ? "MM" : lDateFormat[4]?.type === "day" ? "DD" : "YYYY";
        const literal3 = lDateFormat[5]?.type === "literal" ? lDateFormat[5]?.value === " de " ? " [de] " : lDateFormat[5].value : "";
     
        FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + literal3;
      } 
   
      return FinalDateFormat;
  }
  case "MMMM D, YYYY h:mm A": {
    const lDateFormat = new Intl.DateTimeFormat(selectedLanguage, {
      month: "long",
      day: "numeric",
      year: "numeric"
    }).formatToParts(new Date());
 
    if (lDateFormat && lDateFormat.length) {
      const Part1 = lDateFormat[0].type === "month" ? "MMMM" : lDateFormat[0].type === "day" ? "D" : "YYYY";
      const literal1 = lDateFormat[1]?.type === "literal" ? lDateFormat[1].value : ", ";
      const Part2 = lDateFormat[2]?.type === "month" ? "MMMM" : lDateFormat[2]?.type === "day" ? "D" : "YYYY";
      const literal2 = lDateFormat[3]?.type === "literal" ? lDateFormat[3].value : ", ";
      const Part3 = lDateFormat[4]?.type === "month" ? "MMMM" : lDateFormat[4]?.type === "day" ? "D" : "YYYY";
      const literal3 = lDateFormat[5]?.type === "literal" ? lDateFormat[5].value : ", ";
    
     
        FinalDateFormat = Part1 + literal1 + Part2 + literal2 + Part3 + literal3  + " h:mm A";
    } 
 
    return FinalDateFormat;
} 
  default:
      return OriginalFormat
}
}

