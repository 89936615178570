import { node } from "prop-types"
import { useSelector } from "react-redux"
import { selectUserAccess } from "src/redux/slicers/homeSlice"


export const accessControlFunc = (props: { id: string | Array<string> | any, userAccess?: Map<any, any> | undefined }) => {
  const accessMap = props.userAccess ? props.userAccess : useSelector(selectUserAccess)
  if (Array.isArray(props.id)) {
    return Boolean(props.id.some(i => accessMap.has(i)))
  } else {
    return accessMap.has(props.id) ? true : false
  }
}

export const AccessControl: any = props => {
  const {
    children,
    id
  } = props
  const accessMap = useSelector(selectUserAccess)
  if (Array.isArray(id)) {
    return Boolean(id.some(i => accessMap.has(i))) ? <>{children}</> : <></>
  } else {
    return accessMap.has(id) ? <>{children}</> : <></>
  }
}

AccessControl.propTypes = {
  /** Node to appear inside of button element */
  children: node,
}

AccessControl.defaultProps = {

}
