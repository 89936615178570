import { TranslateComponent } from "src/common/translations"
import styles from "./Loading.module.scss"
import clsx from "clsx"

type LoadingProps = {
  progressMessage?: string
  cancelText?: string
  progress?: any
  handleCancel?: () => void
  showCancel?: boolean
  showProgress?: boolean
}

export default function LoadingSpinner({
  progressMessage = "",
  cancelText = "",
  progress = 10,
  handleCancel,
  showCancel = false,
  showProgress = false
}: LoadingProps) {
  return (
    <div className={styles.exportLoader}>
      <div
        className={clsx(styles.exportLoader, styles.exportLoaderBackdrop)}
      ></div>
      <div className={styles.exportLoaderWrapper}>
        <div className={styles.exportLoaderBody}>
          <div>
            <TranslateComponent>{progressMessage}</TranslateComponent>
            {showCancel && (
              <button
                onClick={() => handleCancel()}
                className={styles.cancelLink}
              >
                <TranslateComponent>{cancelText}</TranslateComponent>
              </button>
            )}
          </div>
          <div className={styles.progress}>
            <div
              className={styles.progressBar}
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          {showProgress && <div>{progress} %</div>}
        </div>
      </div>
    </div>
  )
}
