import {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
  useMemo,
  useState
} from "react"
import { ExceptionsWithSearch } from "./ExceptionsWithSearch"
import { t } from "src/translations/help"
import { useMutation, useQuery } from "src/hooks/APIHooks"
import { useDispatch, useSelector } from "react-redux"
import {
  getExceptionHistory,
  getExceptionsLoader,
  setExceptions,
  getFilter,
  setExceptionSearchText,
  setSelectedExceptions,
  setReportStatus,
  getSelectedExceptions,
  setIsValid,
  selectMultiBuildingsDialog,
  setMultiBuildingsDialog,
  clearSelectedBuilding,
  getReportStatus,
  getBuildingEquipmentTypes,
  getExceptionSearchText,
  setLoadMoreLimit,
  setMaxSelectedBuildings,
  selectAppliedBuildings
} from "src/redux/slicers/reportSlice"
import { SearchRegex } from "src/utils/CommonMethods"
import styles from "./ExceptionReport.module.scss"
import clsx from "clsx"
import { EmptyReport } from "../EmptyReport"
import {
  selectBuildingSummary,
  selectBuildingId
} from "src/redux/slicers/buildingSummarySlice"
import {
  generateEHRDataReport,
  fetchListAnalytics,
  fetchAnalytics,
  getEquipmentPropertiesByBuilding
} from "./../graphql"
import { ACTIONS } from "src/constants"
import { REPORT_STATE } from "../constants"
import moment from "moment"
import { BuidlingsSelection } from "./BuildingsSelection"
import { selectLanguage } from "src/redux/slicers/homeSlice"
import { fetchLocalConfigFile } from "src/graphql/queries/localConfig"
import { get, groupBy, isString, map, mapValues } from "lodash"
import { downloadFileUsingS3 } from "src/utils/CommonMethods"
import { getCircuitAndCompressorInstances } from "../../Charts/Helper/exceptions"
import { updateExceptions } from "./helper"
import { RECHECK_INTERVAL, RETRY_COUNT } from "src/constants/common"

const ALL = "all"

const ExceptionReport = forwardRef((props: any, ref: any) => {
  useImperativeHandle(ref, () => ({
    onClickGenerate: generateReportHandler
  }))
  const dispatch = useDispatch()
  const buildingId = useSelector(selectBuildingId)
  const [selectAllExcep, setSelectAllExce] = useState(false)
  const exceptionList = useSelector(getExceptionHistory)
  const {
    equipmentType: selectedEquipmentType,
    startDate,
    endDate
  } = useSelector(getFilter)
  const exceptionLoader = useSelector(getExceptionsLoader)
  const selectedExceptions = useSelector(getSelectedExceptions)
  const showMultiBuildingsDialog = useSelector(selectMultiBuildingsDialog)
  const { data: buildingSummary } = useSelector(selectBuildingSummary)
  const [reportInfo, setReportInfo] = useState(REPORT_STATE)
  const { cancelled, error } = useSelector(getReportStatus)
  const buildingEquipmentTypes = useSelector(getBuildingEquipmentTypes)
  const exceptionSearchText = useSelector(getExceptionSearchText)
  const { value: langDir } = useSelector(selectLanguage)
  const appliedBuildings = useSelector(selectAppliedBuildings)

  const { refetch: fetchExceptions } = useQuery(
    {
      query: fetchAnalytics,
      disableInitialLoad: true,
      dataPath: "data.getByAnalyticByEquipmentType",
      returnRequired: true,
    }
  )
  const { refetch: fetchEquipmentProperties } = useQuery({
    query: getEquipmentPropertiesByBuilding,
    disableInitialLoad: true,
    dataPath: "data.listEquipmentPropertiesByBuilding",
    returnRequired: true,
  })
  const { refetch: fetchListExceptions } = useQuery({
    query: fetchListAnalytics,
    disableInitialLoad: true,
    dataPath: "data.listAnalyticConfigs",
    returnRequired: true
  })

  useEffect(() => {
    setSelectAllExce(false)
  }, [selectedEquipmentType])

  useEffect(() => {
    const ayncFunction = async () => {
      if (selectedEquipmentType?.value !== "" && buildingId) {
        const equipmentType = selectedEquipmentType?.value === ALL ? "" : selectedEquipmentType?.value
        dispatch(setExceptions({ type: "Loading" }))
        if (equipmentType) {
          const [propertyResponse, exceptionResponse] = await Promise.all([fetchEquipmentProperties({ id: buildingId, equipmentType: equipmentType }), await fetchExceptions({ equipmentType: equipmentType })])
          if (propertyResponse?.error || exceptionResponse?.error) {
            dispatch(setExceptions({ type: "Failure" }))
          } else {
            const componentRelatedProperties = getCircuitAndCompressorInstances(propertyResponse?.data?.items || [])
            const exceptionData = updateExceptions(exceptionResponse?.data?.items || [], componentRelatedProperties, selectedEquipmentType)
            dispatch(setExceptions({ type: "Success", data: exceptionData }))
          }
        } else {
          const filterEquipmentTypes = buildingEquipmentTypes?.filter(
            (equipmentType) => equipmentType?.value !== ALL
          )
          const equipmentTypes = filterEquipmentTypes.map((equipmentType) => {
            return { equipmentType: { eq: equipmentType?.value } }
          })
          const variables = { filter: { or: equipmentTypes, isActive: { eq: 1 } } }
          const [propertyResponse, exceptionResponse] = await Promise.all([fetchEquipmentProperties({ id: buildingId }), fetchAllExceptions(variables)])
          const allExceptions: any = exceptionResponse || []
          const componentRelatedProperties = getCircuitAndCompressorInstances(propertyResponse?.data?.items || [])
          const exceptionData = updateExceptions(allExceptions, componentRelatedProperties, selectedEquipmentType, true)
          dispatch(setExceptions({ type: "Success", data: exceptionData }))
        }
      }
    }
    ayncFunction()
  }, [selectedEquipmentType, buildingId])

  const fetchAllExceptions = async (variables) => {
    dispatch(setExceptions({ type: "Loading" }))
    try {
      let all = []
      let nextToken = ""
      let count = 1
      do {
        const variablesNew = {
          ...variables
        }
        if (nextToken) {
          variablesNew.nextToken = nextToken
        }
        const res: any = await fetchListExceptions(variablesNew)
        if (!res?.error) {
          const response = get(res?.response, res?.dataPath, null)
          const items = response?.items || []
          nextToken = response?.nextToken
          all = all.concat(items)
        }
        count++
      } while (nextToken !== null && count <= 10)
      return all
    } catch (err) {
      dispatch(setExceptions({ type: "Failure" }))
    }
  }

  const isValid = useMemo(
    () => selectedExceptions?.length > 0,
    [selectedExceptions]
  )

  useEffect(() => {
    dispatch(setIsValid(isValid))
  }, [isValid])

  const onSearch = useCallback((value: string) => {
    dispatch(setExceptionSearchText(value))
  }, [])

  const filteredExceptions = useMemo(() => {
    if (exceptionSearchText) {
      return exceptionList.filter(({ exceptionName }) =>
        SearchRegex(exceptionName, exceptionSearchText)
      )
    } else return exceptionList
  }, [exceptionList, exceptionSearchText])

  const onAllExceptionSelect = useCallback(
    (checked: boolean) => {
      const updatedExceptions = checked ? filteredExceptions : []
      dispatch(setSelectedExceptions(updatedExceptions))
      setSelectAllExce((prev: boolean) => !prev)
    },
    [filteredExceptions]
  )

  const handleSelectException = useCallback(
    (checked: boolean, exception: any) => {
      if (isString(exception) && exception === "all-check") {
        onAllExceptionSelect(checked)
      } else {
        const updatedExceptions = checked
          ? [...selectedExceptions, exception]
          : selectedExceptions.filter(
            (obj: any) => obj?.exceptionName !== exception?.exceptionName
          )
        dispatch(setSelectedExceptions(updatedExceptions))
        const isSelected = updatedExceptions.length
          ? filteredExceptions?.length === updatedExceptions?.length
            ? true
            : null
          : false
        setSelectAllExce(isSelected)
      }
    },
    [selectedExceptions]
  )

  const config = {
    title: t("reports.selectSystemOrEquipment"),
    data: exceptionList,
    loading: exceptionLoader,
    handleChange: handleSelectException,
    selectedItems: selectedExceptions,
    onChangeSearch: onSearch,
    searchText: exceptionSearchText,
    isAllChecked: selectAllExcep,
    selectedEquipmentType: selectedEquipmentType?.value,
    translateValues: true
  }

  useEffect(() => {
    return () => {
      dispatch(setMultiBuildingsDialog(false))
      dispatch(clearSelectedBuilding())
    }
  }, [])

  const getLocalEHRConfigFile = async () => {
    const data = await fetchLocalConfigFile("configEHR.json")
    if (!data) {
      dispatch(setLoadMoreLimit(15))
      dispatch(setMaxSelectedBuildings(100))
    } else {
      dispatch(setLoadMoreLimit(data.loadMoreLimit))
      dispatch(setMaxSelectedBuildings(data.maxSelectedBuildings))
    }
  }

  useEffect(() => {
    getLocalEHRConfigFile()
  }, [])

  const { onSubmit: generateReportQuery } = useMutation({
    query: generateEHRDataReport,
    onSuccess: (data: any) => {
      if (data?.data?.generateEHRDataReport) {
        const { body } = JSON.parse(data?.data?.generateEHRDataReport)
        setReportInfo({
          url: body,
          retryCount: 0
        })
      }
    },
    onError: () => {
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  })

  // **** Below method is invoked by ref **** /
  const generateReportHandler = () => {
    if (isValid) {
      dispatch(setReportStatus(ACTIONS.LOADING))
      const formattedStartDate = moment(new Date(startDate)).locale("en").format(
        "YYYY-MM-DD"
      )
      const formattedEndDate = moment(new Date(endDate)).locale("en").format("YYYY-MM-DD")
      const equipType = {
        [selectedEquipmentType?.value !== ALL
          ? selectedEquipmentType?.value
          : selectedEquipmentType?.label]: selectedEquipmentType?.label
      }
      let buildingIds = {}
      if (appliedBuildings?.length >= 1) {
        buildingIds = appliedBuildings?.reduce((prevBuildings, building) => {
          prevBuildings[building?.id] = {}
          prevBuildings[building?.id] = building?.name
          return prevBuildings
        }, {})
      } else {
        buildingIds = {
          [buildingSummary?.id]: buildingSummary?.name
        }
      }
      const groupedExceptions = groupBy(
        selectedExceptions || [],
        "equipmentType"
      )
      const exceptionList = mapValues(groupedExceptions, (items) =>
        map(items, "exceptionKey")
      )
      generateReportQuery({
        requestBody: JSON.stringify({
          domainName: window.location.origin || "",
          buildingIds,
          exceptionList: exceptionList,
          equipType,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          langDir: langDir,
          report: "ehr"
        })
      })
    }
  }

  useEffect(() => {
    let timeout = null
    if (cancelled || !reportInfo.url || error) {
      clearTimeout(timeout)
    } else if (reportInfo.retryCount === 0) {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, 1000)
    } else {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, RECHECK_INTERVAL)
    }
    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout)
    }
  }, [cancelled, reportInfo])

  const checkReportIsFound = async () => {
    try {
      const response = await fetch(reportInfo.url)
      if (response?.status === 404) {
        setReportInfo({
          ...reportInfo,
          retryCount: reportInfo.retryCount + 1
        })
        if (reportInfo.retryCount + 1 >= RETRY_COUNT) {
          dispatch(setReportStatus(ACTIONS.ERROR))
        }
      } else if (response?.status === 200) {
        dispatch(setReportStatus(ACTIONS.COMPLETE))
        downloadFileUsingS3(reportInfo.url, buildingSummary?.name)
      } else {
        dispatch(setReportStatus(ACTIONS.ERROR))
      }
    } catch (e) {
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  }

  return (
    <>
      <div className={clsx(styles.exceptionReportWrapper)}>
        {selectedEquipmentType?.value !== "" ? (
          <>
            <ExceptionsWithSearch config={config} />
          </>
        ) : (
          <EmptyReport message={t("reports.selectreportOptionsabove")} />
        )}
      </div>

      {showMultiBuildingsDialog && <BuidlingsSelection />}
    </>
  )
})

ExceptionReport.displayName = "ExceptionReport"

export default ExceptionReport
