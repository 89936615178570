import { useEffect, useRef, useState } from "react"
import styles from "./Ai.module.scss"
import clsx from "clsx"
export const WeatherData = {
  weather: {
    "N": "North",
    "NNE":"North North East",
    "NE": "North East",
    "ENE": "East North East",
    "E": "East",
    "ESE": "East South East",
    "SE": "South East",
    "SSE": "South South East",
    "S": "South",
    "SSW": "South South West",
    "SW": "South West",
    "WSW": "West South West",
    "W": "West",
    "WNW": "West North West",
    "NW": "North West",
    "NNW": "North North West"
  }
  };
const HelpAIModal = ({variation, setVariation, loading, closeModal, messages, handleKeyPress, text, setText, theme}) => {
  const [openState, setOpenState] = useState(false)
  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current.scrollIntoView({ behavior: "smooth" });
  });

  return  <div
      onClick={() => setOpenState(!openState)}
      className={
      clsx(
          styles.aimodalcontainer,
      )}
    >
      <h1 className={clsx({[styles.darkbackground]: theme === "dark"})}>
        <div></div>
        <div>Trane Digital Support</div>      
        <span className={"icon icon-close"} onClick={() => closeModal()}></span>
      </h1>
      <div className={
      clsx(
          styles.aibody,
      )}>
        <div className={
              clsx(
                  styles.selectableTabContainer,
              )}>
          <div onClick={() => {
            if (!loading) {
              setVariation("generic")
            }
          }} className={
              clsx(
                  {[styles.selectedTab]: variation === "generic"},
              )}>
            Generic
          </div>
          <div onClick={() => {
            if (!loading) {
              setVariation("insight")
            }
          }} className={
              clsx(
                  {[styles.selectedTab]: variation === "insight"},
              )}>
            Insight
          </div>
        </div>
        <div className={
              clsx(
                  styles.messagescontainer,
              )}>
          {messages.filter(e => e.type === variation).map((e, i) => {
            return <div className={
                clsx(
                    styles.aimodalmessageline,
                    {[styles.usermessageline]: e.actor === "user"},
                )}
                key={i}
              >
              <div
                className={
                  clsx(
                      styles.aimodalmessage,
                      {[styles.usermessage]: e.actor === "user"},
                      {[styles.darkbackground]: e.actor === "user" && theme === "dark"}
                  )}
                >
                  {e.message}
              </div>
            </div>
          })}
          <div ref={messageEndRef} />
          {
            loading && <div className={
                clsx(
                    styles.aimodalmessageline,
                )}
              >
              <div
                className={
                  clsx(
                      styles.loadingmessage,
                      styles.aimodalmessage,
                  )}
                >
                  <span>...</span>
              </div>
            </div>
          }
        </div>
        <footer>
          <textarea onChange={(e) => {
            setText(e.target.value)
          }} 
          onKeyUp={handleKeyPress}
          value={text}
          placeholder="Type your question here..."
          />
            
        </footer>

      </div>
    </div>
}

export default HelpAIModal
