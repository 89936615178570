import {
  assignAttributeStateToLegends,
  assignAttributeStateToTLLegends,
  convertTimeToMinutes,
  generateLegend,
  getChartObject,
  getTimeIndex,
  intervalToMinutes,
  legendIDGenerator,
  legendName
} from "../common"
import moment from "moment"
import { isArray, isString, toNumber } from "lodash"
import {
  chartDateFormat,
  constantValues,
  reduxStoreTimeStampFormat,
  settingsReferenceLines
} from "../../constant"
import { TIMELINE_PROPERTY_TYPE, transformTimeLinechartNew } from "./timeLineChart"
import { generateExceptionBars } from "../Exceptions/index"
import { generateSuppressionBars } from "../Suppressions"
import { generateExceptionLegends } from "../Exceptions/helper"
import { generateSuppressionLegends } from "../Suppressions/helper"

export const REFERENCE_LINE_TYPE = "referenceLine"
export const LINE_TYPE = "line"
export const MEAN_LINE_TYPE = "mean"

export const transformLinechart = ({
  tz,
  startDate,
  endDate,
  intervalMinutes = "15m",
  inputData,
  chartInfo,
  equipmentList,
  buildingId,
  equipmentId,
  added,
  oldLegends,
  oldTimeLineLegends = null,
  assignedAttributes = [],
  legendAttributesCombination
}) => {
  const timestamps = []
  const calculatedMin = intervalToMinutes(intervalMinutes)
  const legends = {}
  let timeLineLegends = {}
  const isBuildingLevelEquipCharts = ["52", "53"]?.includes(chartInfo?.id)
  const equipmentType = chartInfo?.equipmentType
  const settings = inputData?.settings || {}
  const addedAxisList = added?.axisList || []
  const data = inputData?.data || {}
  const newData = {}

  const localTimeStamp = Boolean(tz)
    ? moment(
      moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
      "MM-DD-YYYY HH:mm"
    )
    : moment()
  const endTimestamp = moment(endDate, chartDateFormat).add(1, "days")
  let oneLoopCompleted = false
  for (const id in data) {
    const paramData = data[id]
    const equipmentName = equipmentList?.find((obj) => obj?.id === id)?.name
    const legendEquipmentType = equipmentList?.find((obj) => obj?.id === id)?.type
    const selectedEquipment = equipmentId === id
    const building = buildingId === id
    const properties = paramData?.properties

    for (const propertyKey in properties) {
      const legendId = legendIDGenerator(id, propertyKey)
      const property = properties[propertyKey]
      const propertyIndex = property?.index
      const propertyName = property?.name || ""

      let isAdded = false
      let axis = chartInfo?.axisConfig?.find((obj) =>
        getChartObject(obj, propertyKey, equipmentType)
      )
      const addedAxis = addedAxisList?.find((addedObj) => (addedObj?.added?.[legendEquipmentType] || [])?.includes(legendId))
      if (addedAxis) {
        axis = addedAxis
        isAdded = true
      }
      let legendObj: any = {}
      const name = legendName({ building, selectedEquipment, propertyName, equipmentName })
      if (axis?.axisName === "timeline") {
        legendObj = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, propertyKey, type: "timeline", oldLegends: oldTimeLineLegends, propertyType: TIMELINE_PROPERTY_TYPE })
        timeLineLegends[legendId] = legendObj
      } else {
        legendObj = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, type: LINE_TYPE, isAdded, legendEquipmentType, oldLegends })
        legends[legendId] = legendObj
      }

      let currentTimestamp = moment(startDate, chartDateFormat)
      let prevValue = null
      while (
        currentTimestamp.isSameOrBefore(endTimestamp) &&
        currentTimestamp.isSameOrBefore(localTimeStamp)
      ) {
        const dateKey = currentTimestamp.format("YYYY-MM-DD")
        const extractTime = currentTimestamp.format("HH:mm")
        const timetoMin = convertTimeToMinutes(extractTime)
        const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)

        const dateAvailable = paramData?.values?.[dateKey]

        let finalValue
        if (dateAvailable) {
          const valueArray = paramData?.values?.[dateKey]?.[timeIndexPosition]
          const valueArrayIsArray = isArray(valueArray)
          const arrayValues = valueArrayIsArray ? valueArray : []
          const value = arrayValues[propertyIndex]
          const isNull =
            constantValues?.includes(value) ||
            value?.toString()?.startsWith("_")
          const isTimeline = axis?.axisName === "timeline"

          const checkedValue =
            isNull || (isTimeline ? false : !isFinite(toNumber(value)))
              ? null
              : isTimeline
                ? value
                : parseFloat(value)

          if ((isString(value) && value?.length === 0) || !valueArrayIsArray) {
            finalValue = prevValue
          } else {
            finalValue = checkedValue
          }

          if (timeIndexPosition === 95) {
            prevValue = null
          } else {
            prevValue = finalValue
          }
        } else {
          finalValue = null
          prevValue = null
        }

        const dateVal = currentTimestamp.format(reduxStoreTimeStampFormat)

        if (!newData?.[legendId]) {
          newData[legendId] = {
            data: []
          }
        }
        newData[legendId]?.data.push(finalValue)
        if (!oneLoopCompleted) {
          timestamps.push(dateVal)
        }
        currentTimestamp = currentTimestamp.add(calculatedMin, "minutes")
      }
      oneLoopCompleted = true
    }
  }

  const noDataArray = Object.keys(legends)?.map((key) => {
    const noData = (newData?.[key]?.data || [])?.every(
      (e) => !Boolean(e) && e !== 0
    )
    return noData
  })

  const isNoData = noDataArray?.every((e) => Boolean(e))

  Object.keys(settings)?.forEach((key) => {
    const axis = chartInfo?.axisConfig?.find((obj) =>
      getChartObject(obj, key, equipmentType)
    )
    const value = settings?.[key]
    const legendId = legendIDGenerator("", key)
    const legendObj: any = generateLegend({ legendId, name: settingsReferenceLines(key, chartInfo?.title || ""), axisName: axis?.axisName, symbol: axis?.symbol, type: REFERENCE_LINE_TYPE, value, oldLegends })
    legends[key] = legendObj
  })

  assignAttributeStateToLegends(legends, oldLegends, assignedAttributes, legendAttributesCombination, isBuildingLevelEquipCharts)

  const axisList = chartInfo?.axisConfig?.filter(
    (obj) => obj?.axisName !== "timeline" && obj?.axisName !== "exceptions"
  )

  const finalAxisList = [...axisList]

  addedAxisList?.forEach((newObj) => {
    const axisAvailable = finalAxisList?.find(
      (obj) => obj?.axisName === newObj?.axisName
    )
    if (!axisAvailable) {
      finalAxisList.push(newObj)
    }
  })

  // Create/update Exception Legends
  const exceptionData = inputData?.exceptions?.data || {}
  const exceptionLegends = generateExceptionLegends({ exceptionData, chartInfo, equipmentList, selectedEquipID: equipmentId, oldTimeLineLegends })

  // Create/update Suppression Legends
  const suppressionData = inputData?.exceptions?.suppressed?.data || {}
  const suppressionLegends = generateSuppressionLegends({ suppressionData, chartInfo, oldTimeLineLegends })

  // Assign Colors to new time line legends of all kinds
  assignAttributeStateToTLLegends(timeLineLegends, exceptionLegends, suppressionLegends, assignedAttributes, legendAttributesCombination, oldTimeLineLegends)

  const { timeLineBars, totalLanes } = transformTimeLinechartNew(
    timeLineLegends,
    timestamps,
    newData
  )

  const exceptionSampling = inputData?.exceptions?.sampling || 15
  const {
    exceptionsBars,
    exceptionLanes
  } = generateExceptionBars(
    exceptionData,
    exceptionLegends,
    exceptionSampling,
    chartInfo,
    totalLanes,
    startDate,
    endTimestamp,
    localTimeStamp
  )

  const suppressed = inputData?.exceptions?.suppressed?.data && inputData?.exceptions?.suppressed?.data[equipmentId] || {}
  const { suppressionBars } =
    generateSuppressionBars(
      suppressed,
      suppressionLegends,
      exceptionLanes,
      startDate,
      endTimestamp.format("YYYY-MM-DD")
    )

  const bars = [
    ...timeLineBars,
    ...exceptionsBars,
    ...suppressionBars
  ]
  const isNoTimeLineData = bars?.every(
    (obj) => !Boolean(obj?.value) || obj?.value === "No Data"
  )
  timeLineLegends = {
    ...timeLineLegends,
    ...exceptionLegends,
    ...suppressionLegends
  }
  return {
    chartData: {
      data: isNoData ? {} : newData
    },
    chartProps: {
      legends: legends,
      axisList: finalAxisList,
      timeLineLegends: timeLineLegends,
      timeLineBars: isNoTimeLineData && isNoData ? [] : bars,
      timestamps: timestamps,
      noDataAvailable: isNoData && isNoTimeLineData ? true : false,
      isdataprocessed: true,
    }
  }
}
