import {
  Checkbox,
  ModalDialog,
  SearchInput,
  Spinner,
  Typography
} from "src/blitz"
import { useEffect, useMemo, useState } from "react"
import { t } from "src/translations/help"
import styles from "./BuildingsSelection.module.scss"
import {
  clearMultipleBuildingFilters,
  getAccountSelected,
  getBuildingList,
  getBuildingListSearchText,
  getIsMultipleBuildingLimitExceeded,
  selectAppliedBuildings,
  selectBuildingAction,
  selectExceptionHistory,
  setAppliedBuildings,
  setBuildingListSearchText,
  setMultiBuildingsDialog,
  setMultipleBuildingLimitExceeded,
  setBuildingList,
  getloadMoreLimit,
  selectMultiBuildingsDialog
} from "src/redux/slicers/reportSlice"
import { useDispatch, useSelector } from "react-redux"
import clsx from "clsx"
import AllAccounts from "../AllAccounts/AllAccounts"
import HighlightText from "src/blitz/HighLightText/HighLightText"
import InfiniteScroll from "react-infinite-scroll-component"
import { selectBuildingSummary } from "src/redux/slicers/buildingSummarySlice"
import { NAME_FIELD } from "src/components/buildingSummary/constants"
import { sortDataByFieldName } from "src/blitz/DataGridTable/helper"
import { differenceBy, uniqBy } from "lodash"
import {
  FetchBuildingsType
} from "../BuildingsSelection/types"
import { AccountSelected } from "src/redux/types/reportType"
import { API } from "aws-amplify"
import { searchBuildings } from "../../graphql"

let limitExceededTimer: any
const BuidlingsSelection = () => {
  const dispatch = useDispatch()
  const [applied, setApplied] = useState<string>("")
  const { selectedBuildings } = useSelector(selectExceptionHistory)
  const appliedBuildings = useSelector(selectAppliedBuildings)
  const showMultiBuildingsDialog = useSelector(selectMultiBuildingsDialog)
  const ASC = "asc"
  const {
    data: buildingsList,
    hasMore: hasMoreBuilding,
    loading: loadingBuilding,
    total: totalBuilding,
    nextToken: nextTokenBuildings
  } = useSelector(getBuildingList)
  const buildingListSearchText = useSelector(getBuildingListSearchText)
  const { data: buildingSummary } = useSelector(selectBuildingSummary)
  const accountSelected = useSelector(getAccountSelected)
  const IsMultipleBuildingLimitExceeded = useSelector(
    getIsMultipleBuildingLimitExceeded
  )
  const [loadRemaining, setLoadRemaining] = useState(false)
  const loadMoreLimit = useSelector(getloadMoreLimit)

  const [buildingListLoading, setBuildingListLoading] = useState<boolean>(false)

  const preSelectedBuilding = {
    id: buildingSummary?.id,
    name: buildingSummary?.name
  }

  const applyButtonHandle = (selectedBuildings) => {
    dispatch(setAppliedBuildings(selectedBuildings))
    const appliedMessage = t("reports.applied")
    setApplied(appliedMessage)
  }

  const closeReportModal = () => {
    dispatch(setMultiBuildingsDialog(false))
  }

  const {
    selectedBuildingsList = [],
    unSelectedBuildingsList = [],
    disableApply,
    selectAllDisable,
    showSelected,
    checkedIcon,
    selectedBuildingsListDis = []
  } = useMemo(() => {
    const { unSelectedBuildingsList } = buildingsList?.reduce(
      (buildings, filteredBuilding) => {
        if (!selectedBuildings.find((obj) => obj?.id === filteredBuilding.id))
          buildings.unSelectedBuildingsList.push(filteredBuilding)
        return buildings
      },
      {
        unSelectedBuildingsList: []
      }
    )
    const selectedBuildingsList = sortDataByFieldName(
      NAME_FIELD,
      ASC,
      selectedBuildings
    )
    const selectAllEnable =
      Boolean(accountSelected) || Boolean(buildingListSearchText)
    const selectAllDisable =
      !selectAllEnable || loadingBuilding || loadRemaining
    const checkedIcon =
      unSelectedBuildingsList?.length === 0
        ? true
        : selectedBuildingsList?.length === 0
        ? false
        : null

    const selectedBuildingsListDis = selectedBuildingsList?.filter((obj) =>
      obj?.name?.toLowerCase()?.includes(buildingListSearchText?.toLowerCase())
    )

    return {
      selectedBuildingsList,
      unSelectedBuildingsList,
      disableApply: selectedBuildings?.length < 1 || loadRemaining,
      selectAllDisable: selectAllDisable,
      showSelected: selectedBuildingsListDis?.length > 0,
      checkedIcon,
      selectedBuildingsListDis
    }
  }, [
    buildingsList,
    selectedBuildings,
    accountSelected,
    buildingListSearchText,
    totalBuilding,
    loadRemaining
  ])

  const limitExceededTimerHandler = () => {
    setApplied("")
    clearTimeout(limitExceededTimer)
    limitExceededTimer = null
    dispatch(setMultipleBuildingLimitExceeded(true))
    limitExceededTimer = setTimeout(() => {
      dispatch(setMultipleBuildingLimitExceeded(false))
    }, 5000)
  }

  const handleAllSelectClick = async () => {
    if (selectedBuildingsList?.length + totalBuilding > 100) {
      if (unSelectedBuildingsList?.length === 0) {
        dispatch(selectBuildingAction([]))
      } else {
        limitExceededTimerHandler()
      }
    } else {
      if (unSelectedBuildingsList?.length > 0) {
        if (buildingsList?.length === totalBuilding) {
          const updateSelectedBuildings = [
            ...selectedBuildings,
            ...buildingsList
          ]
          const sortedupdateSelectedBuildings = sortDataByFieldName(
            NAME_FIELD,
            ASC,
            updateSelectedBuildings
          )
          const duplicatesFiltered = uniqBy(sortedupdateSelectedBuildings, "id")
          dispatch(selectBuildingAction(duplicatesFiltered))
        } else {
          setLoadRemaining(true)
          await fetchBuildings(
            buildingListSearchText,
            true,
            accountSelected,
            true
          )
          setLoadRemaining(false)
        }
      } else {
        if (selectedBuildingsListDis?.length < selectedBuildingsList?.length) {
          const newListOfBuildings = differenceBy(
            uniqBy(selectedBuildingsList, "id"),
            selectedBuildingsListDis,
            "id"
          )
          dispatch(selectBuildingAction(newListOfBuildings))
        } else {
          dispatch(selectBuildingAction([]))
        }
      }
    }
    setApplied("")
  }

  const onBuildingSelection = (building, checked) => {
    if (selectedBuildings?.length < 100 || !checked) {
      const updateSelectedBuildings = checked
        ? [...selectedBuildings, building]
        : selectedBuildings.filter((obj) => obj?.id !== building?.id)
      dispatch(selectBuildingAction(updateSelectedBuildings))
      setApplied("")
      if (IsMultipleBuildingLimitExceeded) {
        dispatch(setMultipleBuildingLimitExceeded(false))
      }
    } else {
      limitExceededTimerHandler()
    }
  }

  const modalConfig = useMemo(
    () => ({
      modal: showMultiBuildingsDialog,
      heading: t("reports.editViewFacilities"),
      fullScreen: false,
      buttons: [
        {
          type: "button",
          variant: "blue",
          handleClick: () => applyButtonHandle(selectedBuildings),
          text: t("common.apply"),
          disabled: disableApply
        },
        {
          type: "button",
          variant: "dark",
          handleClick: closeReportModal,
          text: t("common.close")
        }
      ],
      handleClose: closeReportModal
    }),
    [showMultiBuildingsDialog, disableApply, selectedBuildings]
  )

  useEffect(() => {
    if (appliedBuildings?.length === 0) {
      dispatch(selectBuildingAction([preSelectedBuilding]))
      dispatch(setAppliedBuildings([preSelectedBuilding]))
    } else {
      dispatch(selectBuildingAction([...appliedBuildings]))
    }

    return () => {
      dispatch(clearMultipleBuildingFilters())
    }
  }, [])

  const fetchBuildings: FetchBuildingsType = async (
    searchText,
    loadMore,
    accountSelected: AccountSelected,
    removeLimit?
  ) => {
    try {
      if ((!loadMore && searchText) || !loadMore) {
        dispatch(setBuildingList({ type: "Loading" }))
      }
      const variables = {
        limit: null,
        filter: { isActive: { eq: 1 } },
        sort: [{ direction: ASC, field: NAME_FIELD }],
        nextToken: null
      }

      if (!removeLimit) {
        variables.limit = loadMoreLimit
      }

      if (loadMore) {
        variables.nextToken = nextTokenBuildings
        setBuildingListLoading(true)
      }
      if (searchText) {
        const subString = searchText.split(" ")
        const buildingFilter = subString?.map((sub) => ({
          accountId: { wildcard: "*" },
          name: { wildcard: `*${sub}*` }
        }))
        variables.filter = {
          ...variables.filter,
          ...{
            and: subString?.length >0 ? buildingFilter : buildingFilter[0]
          }
        }
      } else {
        variables.filter = {
          ...variables.filter,
          ...{
            and: [{
              accountId: { wildcard: "*" },
              name: { wildcard: "*" }
            }]
          }
        }
      }
      if (accountSelected) {
        const subString = searchText.split(" ")
        const accountFilter = subString?.map((sub) => ({
          accountId: { eq: `${accountSelected?.accountId}` },
          name: { wildcard: `*${sub}*` }
        }))
        variables.filter = {
          ...variables.filter,
          ...{
            and: subString?.length >0 ? accountFilter : accountFilter[0]
          }
        }
      }
      const apiData: any = await API.graphql({
        query: searchBuildings,
        variables
      })

      const searchBuildingsData = apiData?.data?.searchBuildings
      const results = variables?.nextToken
        ? [...buildingsList, ...(searchBuildingsData?.items || [])]
        : searchBuildingsData?.items || []

      if (removeLimit) {
        const updateSelectedBuildings = [
          ...selectedBuildings,
          ...buildingsList,
          ...(searchBuildingsData?.items || [])
        ]
        const sortedupdateSelectedBuildings = sortDataByFieldName(
          NAME_FIELD,
          ASC,
          updateSelectedBuildings
        )
        const duplicatesFiltered = uniqBy(sortedupdateSelectedBuildings, "id")
        dispatch(selectBuildingAction(duplicatesFiltered))
      }
      dispatch(
        setBuildingList({
          type: "Success",
          data: results,
          total: searchBuildingsData?.total,
          nextToken: searchBuildingsData?.nextToken,
          hasMore: results?.length < searchBuildingsData?.total
        })
      )

      setBuildingListLoading(false)
    } catch (e) {
      dispatch(
        setBuildingList({
          type: "Failure"
        })
      )
    }
  }

  useEffect(() => {
    fetchBuildings(buildingListSearchText, false, accountSelected)
  }, [buildingListSearchText, accountSelected])

  const onBuildingListInputChange = (value) => {
    dispatch(setBuildingListSearchText(value))
  }

  const dataExceededMessage = useMemo(
    () =>
      IsMultipleBuildingLimitExceeded &&
      "Selected Buildings cannot exceed over 100",
    [IsMultipleBuildingLimitExceeded]
  )

  return (
    <ModalDialog
      modalConfig={modalConfig}
      customClassName={styles.modalWrapper}
      titleClassName={styles.dialogHeader}
      bodyClassName={styles.dialogBody}
      footerClassName={styles.dialogFooter}
      showDataExceeded={dataExceededMessage}
      showCompleted={Boolean(applied)}
      completedMessage={applied}
    >
      <div>
        <div
          className={clsx(
            styles.accountsWrapper,
            "d-flex justify-content-between align-items-center"
          )}
        >
          <Typography styleType="p4" tagType="span">
            {t("reports.showFacilitiesFor")}
          </Typography>
         { <AllAccounts/> }
        </div>
        <div
          className={clsx(
            styles.searchWrapper,
            "d-flex justify-content-between align-items-center"
          )}
        >
          <Typography styleType="p4" tagType="span">
            {t("reports.showFacilityorFacilities")}
          </Typography>
          <SearchInput
            onInputChange={(value) => onBuildingListInputChange(value)}
            onInputClear={() => onBuildingListInputChange("")}
            searchText={buildingListSearchText}
          />
        </div>
      </div>
      <div className={styles.buildingsWrapper}>
        <div className={clsx(styles.selectAllWrapper, styles.border)}>
          <div className={styles.selectAllLeft}>
            <Checkbox
              name={"select-all-equipments"}
              label={t("common.selectandDeSelectAll")}
              checked={checkedIcon}
              onClick={handleAllSelectClick}
              disabled={selectAllDisable}
            />
            {loadRemaining && <Spinner loading={loadRemaining} />}
          </div>
          <Typography
            tagType="span"
            styleType="p4"
          >{`Selected Count: ${selectedBuildingsList?.length}`}</Typography>
        </div>
        <div id="facility-scroll-ehr" className={styles.optionsWrapperLone}>
          <InfiniteScroll
            dataLength={buildingsList.length}
            next={() =>
              fetchBuildings(buildingListSearchText, true, accountSelected)
            }
            hasMore={hasMoreBuilding}
            style={{ overflow: "hidden" }}
            loader={<Spinner loading={buildingListLoading} />}
            scrollableTarget="facility-scroll-ehr"
            inverse={false}
          >
            <div className={styles.optionsWrapper}>
              {showSelected && (
                <div className={clsx(styles.selectedWrapper, styles.border)}>
                  {selectedBuildingsListDis?.map((item: any, index: number) => {
                    const checkboxId = `${item?.id}-${index}`
                    return (
                      <Checkbox
                        key={checkboxId}
                        name={item?.id}
                        label={
                          <HighlightText
                            name={item?.label || item?.name}
                            searchText={buildingListSearchText}
                          />
                        }
                        checked={selectedBuildings.find(
                          (obj) => obj?.id === item?.id
                        )}
                        onChange={(e: any) => {
                          onBuildingSelection(item, e.target.checked)
                        }}
                      />
                    )
                  })}
                </div>
              )}
              <div className={styles.unSelectedWrapper}>
                {unSelectedBuildingsList?.map((item: any, index: number) => {
                  const checkboxId = `${item.id}-${index}`
                  return (
                    <Checkbox
                      key={checkboxId}
                      name={item.id}
                      label={
                        <HighlightText
                          name={item?.label || item?.name}
                          searchText={buildingListSearchText}
                        />
                      }
                      checked={selectedBuildings.find(
                        (obj) => obj?.id === item?.id
                      )}
                      onChange={(e: any) => {
                        onBuildingSelection(item, e.target.checked)
                      }}
                    />
                  )
                })}
                {loadingBuilding && <Spinner loading={loadingBuilding} />}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </ModalDialog>
  )
}

export default BuidlingsSelection
