import { ITableCell } from "../types"

const TableCell = (props: ITableCell) => {
  const {
    className = "",
    colSpan,
    rowSpan,
    children,
    style,
    title,
    testId = "",
    onClick = () => null,
  } = props

  return (
    <td
      title={title && title}
      style={{ ...style }}
      rowSpan={rowSpan && rowSpan}
      colSpan={colSpan && colSpan}
      className={className}
      data-testid={testId}
      onClick = {onClick}
    >
      {children}
    </td>
  )
}

export default TableCell
