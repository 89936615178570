export const getAISuggestion = /* GraphQL */ `
  query getAISuggestion(
    $prompt: String,
    $type: String,
    $buildingId: String
  ) {
    getAISuggestion(
      prompt: $prompt
      type: $type
      buildingId: $buildingId
    ) 
  }
`