import { legendIDGenerator } from "../common"
import { generateSuppressionsTimestampObjects } from "./helper"

export const generateSuppressionBars = (
  suppressionsData: any = {},
  suppressionLegends: any = {},
  totalLanes = 0,
  startDate,
  endDate
) => {
  const suppressionBars = []
  let lane = totalLanes
  Object.keys(suppressionsData)?.forEach((suppressionName) => {
    const suppressionArray = suppressionsData?.[suppressionName]
    const legendId = legendIDGenerator("", suppressionName)
    const legend = suppressionLegends?.[legendId]
    const otherProps = {
      color: legend?.color,
      id: legend?.id,
      lane: lane,
      name: legend?.name,
      propertyName: legend?.propertyName,
      show: legend?.show,
      visible: legend?.visible
    }

    const suppressionTimeLineBars = generateSuppressionsTimestampObjects(
      suppressionArray,
      startDate,
      endDate,
      otherProps
    )

    suppressionBars.push(...suppressionTimeLineBars)
    lane++
  })

  return {
    suppressionBars
  }
}
