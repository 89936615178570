import { Dropdown } from "react-bootstrap"
import { useMemo } from "react"
import styles from "./AllAccounts.module.scss"
import dropdownStyles from "src/blitz/MultiLevelDropdown/MultiLevelDropdown.module.scss"
import { SearchInput, Spinner } from "src/blitz"
import clsx from "clsx"
import { ALL_ACCOUNTS,BUILDINGS_ACCOUNTS_LIMIT } from "../constant"
import HighlightText from "src/blitz/HighLightText/HighLightText"
import {
  getAccountList,
  getAccountListSearchText,
  getAccountSelected,
  setAccountListSearchText,
  setAccountSelected,
  setAccountList,
  getloadMoreLimit,
} from "src/redux/slicers/reportSlice"
import { useDispatch, useSelector } from "react-redux"
import { isString } from "lodash"
import InfiniteScroll from "react-infinite-scroll-component"
import {useEffect,useState} from "react"
import {
  FetchAccountsType
} from "../BuildingsSelection/types"
import { searchBuildings } from "../../graphql"
import { API } from "aws-amplify"
import { uniqBy } from "lodash"
const AllAccounts = () => {

  const dispatch = useDispatch()
  const {
    data: accountsList,
    hasMore: hasMoreAccount,
    loading: loadingAccount,
    nextToken: nextTokenAccounts
  } = useSelector(getAccountList)
  const accountListSearchText = useSelector(getAccountListSearchText)
  const accountSelected = useSelector(getAccountSelected)
  const loadMoreLimit = useSelector(getloadMoreLimit)
  const [accountListLoading, setAccountListLoading] = useState<boolean>(false)
  const [accountsCall, setAccountsCall] = useState<boolean>(false)
  const ASC = "asc"
  const onAccountListInputChange = (value) => {
    dispatch(setAccountListSearchText(value))
  }

  const handleAccountChange = (value) => {
    if (isString(value) && value === ALL_ACCOUNTS) {
      dispatch(setAccountSelected(null))
    } else {
      dispatch(setAccountSelected(value))
    }
  }

  const showAllAccounts = useMemo(
    () =>
      ALL_ACCOUNTS?.toLowerCase()?.includes(
        accountListSearchText?.toLowerCase()
      ),
    [accountListSearchText]
  )

  const fetchAccounts: FetchAccountsType = async (
    searchText,
    loadMore = false
  ) => {
    try {
      if ((!loadMore && searchText) || !loadMore) {
        dispatch(setAccountList({ type: "Loading" }))
      }
      
      const variables = {
        limit: BUILDINGS_ACCOUNTS_LIMIT,
        filter: { isActive: { eq: 1 } },
        sort: [{ direction: ASC, field: "accountName" }],
        nextToken: null
      }

      if (loadMore) {
        variables.nextToken = nextTokenAccounts
        setAccountListLoading(true)
      }
      if (searchText) {
        const subString = searchText.split(" ")
        const accountNameFilter = subString?.map((sub) => ({
          accountId: { wildcard: "*" },
          accountName: { wildcard: `*${sub}*` }
        }))
        
        variables.filter = {
          ...variables.filter,
          ...{and : subString?.length >0 ? accountNameFilter : accountNameFilter[0] },

        }
      }else {
        variables.filter = {
          ...variables.filter,
          ...{and : [{accountId: { wildcard: "*" },accountName: { wildcard: "*" }}]}
        }
      }
    
      const apiData: any = await API.graphql({
        query: searchBuildings,
        variables
      })

      const searchAccountsData = apiData?.data?.searchBuildings
      const uniqueAccountsData = uniqBy(searchAccountsData?.items,"accountId")
      const results = variables?.nextToken
        ? [...accountsList, ...(uniqueAccountsData || [])]
        : uniqueAccountsData || []
      
      dispatch(
        setAccountList({
          type: "Success",
          data: results,
          total: searchAccountsData?.total,
          nextToken: searchAccountsData?.nextToken,
          hasMore: results?.length < searchAccountsData?.total
        })
      )

      setAccountListLoading(false)
    } catch (e) {
      dispatch(
        setAccountList({
          type: "Failure"
        })
      )
    }
  }

  useEffect(() => {
    if(accountsCall){
      fetchAccounts(accountListSearchText)
    }
  }, [accountListSearchText,accountsCall])

  

  return (
    <Dropdown autoClose align={"end"} className={dropdownStyles.dropdown} onClick = {()=>setAccountsCall(true)}>
      <Dropdown.Toggle
        className={clsx(styles.ddToggle, dropdownStyles.dropdownTtoggle)} 
      >
        {accountSelected?.accountName || ALL_ACCOUNTS}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className={clsx(dropdownStyles.dropdownMenu, styles.dropdownMenu)}
      >
        <div className={styles.menuWrapper}>
          <div className={styles.searchBox}>
            <SearchInput
              onInputChange={(value) => onAccountListInputChange(value)}
              onInputClear={() => onAccountListInputChange("")}
              searchText={accountListSearchText}
            />
          </div>
          <div id="account-scroll-ehr" className={styles.infiniteWrapper}>
            <InfiniteScroll
              dataLength={accountsList?.length}
              next={() => fetchAccounts(accountListSearchText, true)}
              hasMore={hasMoreAccount}
              style={{ overflow: "hidden" }}
              loader={<Spinner loading={accountListLoading} />}
              scrollableTarget="account-scroll-ehr"
              inverse={false}
            >
              {showAllAccounts && !loadingAccount && (
                <Dropdown.Item
                  onClick={() => handleAccountChange(ALL_ACCOUNTS)}
                  className={dropdownStyles.dropdownItem}
                >
                  <HighlightText
                    name={ALL_ACCOUNTS}
                    searchText={accountListSearchText}
                  />
                </Dropdown.Item>
              )}
              {accountsList?.map((menu, index) => {
                return (
                  <Dropdown.Item
                    onClick={() => handleAccountChange(menu)}
                    className={dropdownStyles.dropdownItem}
                    key={index}
                  >
                    <HighlightText
                      name={menu.accountName}
                      searchText={accountListSearchText}
                    />
                  </Dropdown.Item>
                )
              })}
              {loadingAccount && <Spinner loading={loadingAccount} />}
            </InfiniteScroll>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default AllAccounts
