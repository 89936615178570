import MainLayout from "./MainLayout"
import { Amplify, Auth } from "aws-amplify"
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions"
import { Authenticator } from "@aws-amplify/ui-react"
import config from "../aws-exports"
import { useEffect, useState } from "react"

const WrapperLayout = ({ errorMessage }) => {

  const [authUpdated, setAuthUpdated] = useState(false);

  useEffect(() => {
    Amplify.configure(config)
    Amplify.configure({
      API: {
        graphql_headers: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: session.getIdToken().getJwtToken()
          }
        }
      }
    })
    Amplify.addPluggable(new AmazonAIPredictionsProvider())
    setAuthUpdated(true)
  }, [])
  
  return (<>
    {authUpdated && <Authenticator.Provider>
      <MainLayout errorMessage={errorMessage} />
    </Authenticator.Provider>
    }</>)
}


export default WrapperLayout;