import { t } from "src/translations/help"
import { ModalDialog, Typography } from "src/blitz"
import styles from "./ReportErrorMessage.module.scss"
const ReportErrorMessage = (props: any) => {
  const { modalConfig } = props

  return (
    <ModalDialog
      modalConfig={modalConfig}
      footerClassName={styles.errorPopupFooter}
    >
      <div className={styles.errorMessageBody}>
        <Typography tagType="p" styleType="p4">
          {t("reports.errorModal.confirmRequestTextHint")}
        </Typography>
        <Typography tagType="p" styleType="p4">
          {t("reports.errorModal.loadErrorText")}
        </Typography>
        <Typography tagType="p" styleType="p4">
          <>
            {t("reports.errorModal.techinicalDetails")} :{" "}
            {t("reports.errorModal.errorMessage")}
          </>
        </Typography>
      </div>
    </ModalDialog>
  )
}
export default ReportErrorMessage
