export const UNIT_CONVERSION = {
    MPH_TO_MPS: 0.44704,
    PRESSURE_INHG_TO_HPA: 33.8639,
    VISIBILITY_MI_TO_KM: 1.60934,
    FLOORAREA_FT2_TO_M2: 0.092903,
    TEMPERATURE_F_TO_C: 5 / 9,
    VALUE_BTU_TO_KW: 3.5168525
}

export const UOM_LABELS = {
    HPA: "hPa",
    INCHES: "inches",
    SI: "SI",
    IP: "IP",
    KM: "km",
    MILES: "Miles",
    C: "C",
    F: "F",
    METER_PER_SECOND: "m/s",
    MILES_PER_HOUR: "MPH",
    KW: "kW",
    TONS: "Tons",
    SQMT: "m²",
    SQFT: "ft²",
}