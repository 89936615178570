import * as d3 from "d3"

// Node Element Shape Constants
export const DOT_SHAPE = "dot"
export const SQUARE_SHAPE = "square"
export const TRIANGLE_SHAPE = "triangle"
export const XS_SHAPE = "xs"

// Line type constants
export const SOLID_LINE = "solid"
export const DASHED_LINE = "dashed"
export const DOTTED_LINE = "dotted"
export const DASH_DOTTED_LINE = "dashDotted"
export const LONG_DASH_LINE = "longDash"

// Avaiable size constants
export const DEFAULT_SIZE = "1"
export const LEVEL_ONE = "2"
export const LEVEL_TWO = "3"
export const LEVEL_THREE = "4"
export const NONE = "none"

//ShapeClassnames
export const SHAPE_SPAN_CLASS = {
  [DOT_SHAPE]: "icon-chart-marker-icons_circle",
  [TRIANGLE_SHAPE]: "icon-chart-marker-icons_triangle",
  [SQUARE_SHAPE]: "icon-chart-marker-icons_square",
  [XS_SHAPE]: "icon-chart-marker-icons_shape_x",
  [NONE]: ""
}

// D3 Shape imports
export const shapesObject = {
  [DOT_SHAPE]: {
    shape: d3.symbolCircle
  },
  [SQUARE_SHAPE]: {
    shape: d3.symbolSquare
  },
  [TRIANGLE_SHAPE]: {
    shape: d3.symbolTriangle
  },
  [XS_SHAPE]: {
    shape: d3.symbolCross
  }
}

// Node Element Shape size values
export const shapeSizeObject = {
  [DEFAULT_SIZE]: 30,
  [LEVEL_ONE]: 90,
  [LEVEL_TWO]: 120,
  [LEVEL_THREE]: 150
}

// Line Element thickness values
export const lineSizeObject = {
  [DEFAULT_SIZE]: 1,
  [LEVEL_ONE]: 3,
  [LEVEL_TWO]: 6,
  [LEVEL_THREE]: 9
}

//Pallete Colors in Color picker
export const PALLETTE = [
  "#61AE34",
  "#FFF10B",
  "#D52B1E",
  "#00B9E4",
  "#FB9A09",
  "#DAFFA7",
  "#FFF787",
  "#FEA7AA",
  "#CFD5E8",
  "#FFEEC1",
  "#B7FF5B",
  "#E1D47B",
  "#FD575D",
  "#6C97BF",
  "#FEDE87",
  "#89C506",
  "#AB9A1F",
  "#E10018",
  "#326089",
  "#FECF54"
]

export const APPROVED_COLOR_PALLETTE = [
  "#9debff",//Teal/500
  "#7ac54f",//Green 400
  "#ff8b11",// Orange 500
  "#ff3e38",//Red 500
  "#e99cff", //Purple 500
  "#fff13b", //Yellow 500
  "#377080",//Teal/700
  "#207311", //Green/700
  "#b75207", //Orange/700
  "#b71d31", //Red/700
  "#9d4fb3", //Purple/600
  "#c5a400", //Yellow/600
  "#daf9ff",//Teal/300
  "#a4e275", //Green/300
  "#ffc770", //Orange/300
  "#ff9f87",//Red/300
  "#f9daff",//Purple/300
  "#fffbb3"//Yellow/300
]

export const LINE_TYPES = [
  SOLID_LINE,
  DASHED_LINE,
  DOTTED_LINE,
  DASH_DOTTED_LINE,
  LONG_DASH_LINE,
]

export const SHAPES_TYPES = [
  DOT_SHAPE,
  SQUARE_SHAPE,
  TRIANGLE_SHAPE,
  XS_SHAPE
]

// Chart Categories
export const Line_CHART_CATEGORY = "Line"
export const LineWithBinaryStates_CHART_CATEGORY = "LineWithBinaryStates"
export const Pie_CHART_CATEGORY = "Pie"
export const LineWithControlRangeAndMean_CHART_CATEGORY =
  "LineWithControlRangeAndMean"
export const Scatter_CHART_CATEGORY = "Scatter"
export const ScatterWithLimits_CHART_CATEGORY = "ScatterWithLimits"
export const ScatterWithPerformanceCurve_CHART_CATEGORY =
  "ScatterWithPerformanceCurve"
export const Pareto_CHART_CATEGORY = "Pareto"
export const StackedBar_CHART_CATEGORY = "StackedBar"
export const EXCEPTION_TYPE_LEGEND = "exception"
export const TIMELINE_TYPE_LEGEND = "timeline"

//Messages
export const MINMAX_ERROR_MESSAGE =
  "Min value must be less than or equal to Max value."
export const MINMAX_WARNING_MESSAGE =
  "All available values might not present in the selected range. please press restore default button to reset."
export const RESTORE_DEFAULTS = "Restore Defaults"

// NumberRegexValidator
export const isValidNumber = (value) => {
  const regex = /^-?[0-9]*\.?[0-9]*$/
  if (regex.test(value)) {
    return true
  }
  return false
}
