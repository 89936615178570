import { useCallback, useMemo, useState } from "react"
import styles from "./Characteristics.module.scss"
import { Button, Typography } from "src/blitz"
import DateRangePicker from "src/blitz/DateRange/DateRangePicker"
import { DATE, ONE_DAY } from "src/blitz/DateRange/constant"
import moment from "moment"
import {
  CHILLER_ENTERED_SERVICE,
  CHILLER_LIFE,
  serviceDateFormat,
  uploadServiceDateFormat
} from "../constant"
import clsx from "clsx"
import { dateFormatter } from "../helper"
import { TranslateComponent } from "src/common/translations"

const ChillerLife = (props) => {
  const {
    modalCharacteristics,
    handleUpdateModalClose = () => null,
    handleSave = () => null
  } = props
  const [openCalendar, setOpenCalendar] = useState(false)
  const [chillerLife, setChillerLife] = useState(
    modalCharacteristics?.[CHILLER_LIFE]?.value
  )
  const [selectedDate, setSelectedDate] = useState({
    start: modalCharacteristics?.[CHILLER_ENTERED_SERVICE]?.value
      ? new Date(modalCharacteristics?.[CHILLER_ENTERED_SERVICE]?.value)
      : ""
  })
  const [serviceDate, setServiceDate] = useState(
    modalCharacteristics?.[CHILLER_ENTERED_SERVICE]?.value
  )
  const [calendarView, setCalendarView] = useState({
    start: DATE,
    end: DATE
  }) // date, month, year

  const handleChange = useCallback((e) => {
    const value = e?.target?.value
    setChillerLife(value)
  }, [])

  const handleDateSubmit = () => {
    if (selectedDate?.start) {
      const date = dateFormatter(selectedDate?.start)
      const formatted = moment(date)?.format(uploadServiceDateFormat)
      setServiceDate(formatted)
    }
  }

  const disableSave = useMemo(() => {
    const isSameChillerLife =
      chillerLife === modalCharacteristics?.[CHILLER_LIFE]?.value
    const isSameDate = moment(serviceDate).isSame(
      moment(new Date(modalCharacteristics?.[CHILLER_ENTERED_SERVICE]?.value))
    )
    const isNull = !Boolean(chillerLife) || !Boolean(serviceDate)
    return (isSameChillerLife && isSameDate) || isNull
  }, [modalCharacteristics, serviceDate, chillerLife])

  const onSaveClick = () => {
    const savedObject: any = {
      current: modalCharacteristics,
      updated: {}
    }
    const characteristics = modalCharacteristics || {}
    Object.keys(characteristics)?.forEach((targetKey) => {
      const crnt = modalCharacteristics?.[targetKey] || {}
      let crntValue = ""
      let uptdValue: any = ""
      let passValue: any = ""
      if (targetKey === CHILLER_LIFE) {
        crntValue = crnt?.value
        uptdValue = chillerLife
        passValue = chillerLife
      }
      if (targetKey === CHILLER_ENTERED_SERVICE) {
        crntValue = dateFormatter(crnt?.value)
        uptdValue = dateFormatter(serviceDate)
        passValue = moment(uptdValue)?.format(uploadServiceDateFormat)
      }
      if (!Boolean(uptdValue) && uptdValue !== 0) {
        return
      } else {
        if (crntValue !== uptdValue) {
          savedObject.updated[targetKey] = {
            ...crnt,
            value: passValue
          }
        }
      }
    })
    handleSave(savedObject)
  }

  return (
    <>
      <div className={clsx(styles?.wrapper, styles?.chillerProfileWrapper)}>
        <div className={styles?.inputFieldWrapper}>
          <Typography tagType="div" styleType="p4" className={styles?.label}>
            Chiller Entered Service Date:
          </Typography>
          <div className={styles?.dateField}>
            <DateRangePicker
              openCalendar={openCalendar}
              setOpenCalendar={setOpenCalendar}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              calendarView={calendarView}
              setCalendarView={setCalendarView}
              pickerCategory={ONE_DAY}
              maxEndDateProps={new Date()}
              submitHandler={handleDateSubmit}
            />
            {serviceDate && (
              <Typography tagType="div" styleType="p4">
                {serviceDate
                  ? moment(new Date(serviceDate))?.format(serviceDateFormat)
                  : ""}
              </Typography>
            )}
          </div>
        </div>
        <div className={styles?.inputFieldWrapper}>
          <Typography tagType="div" styleType="p4" className={styles?.label}>
            Chiller Life:*
          </Typography>
          <input
            type="number"
            id="chillerLife"
            name="Chiller Life"
            value={chillerLife}
            onChange={(e) => handleChange(e)}
          ></input>
        </div>
        <div className={clsx(styles?.unselectable, styles?.italic)}>
          <TranslateComponent>*The</TranslateComponent>
          {" "}
          <a
            className={clsx(styles?.redirection, styles?.italic)}
            target="_blank"
            rel="noreferrer"
            href="http://weblegacy.ashrae.org/publicdatabase/system_service_life.asp?selected_system_type=2"
          >
            <TranslateComponent>ASHRAE tool</TranslateComponent>
          </a>{" "}
          <TranslateComponent>
            can provide assistance determining the life of a chiller.
          </TranslateComponent>
        </div>
      </div>
      <div className={styles?.footer}>
        <Button
          type="button"
          variant="blue"
          onClick={onSaveClick}
          disabled={disableSave}
        >
          <span>
            <TranslateComponent>
              Save
            </TranslateComponent>
          </span>
        </Button>
        <Button type="button" onClick={handleUpdateModalClose}>
          <span>
            <TranslateComponent>
              Close
            </TranslateComponent>
          </span>
        </Button>
      </div>
    </>
  )
}

export default ChillerLife
