import { useState, useEffect } from "react";
import { getTranslation } from "../redux/slicers/translationSlice";
import { useAppDispatch } from "../redux/Store";
import { useSelector } from "react-redux";
import { selectLanguage } from "src/redux/slicers/userData";
import store from "../redux/Store";

const translateCache: Record<string, Record<string, string>> = { "ROI": { "en": "ROI", "fr": "ROI 2" } };

// Custom hook for translation
export const translate = (text: string) => {
  const dispatch = useAppDispatch();
  const selectedLanguage = useSelector(selectLanguage);
  const [translatedText, setTranslatedText] = useState<string>(text);

  useEffect(() => {
    const fetchTranslation = async () => {
      if (text in translateCache) {
        setTranslatedText(translateCache[text][selectedLanguage]);
        return;
      }
      const response = await fetchTranslate(text, selectedLanguage);
      setTranslatedText(response);
    };

    fetchTranslation();
  }, [dispatch, text, selectedLanguage]);

  return translatedText;
};



const ongoingRequests = {};

export const fetchTranslate = async (text, language = "") => {
  if (!text || language === "en") {
    return text;
  }

  // Check if the translation is already cached
  if (translateCache[text] && translateCache[text][language]) {
    return translateCache[text][language];
  }

  // Check if there is an ongoing request for the same text
  if (ongoingRequests[text]) {
    return ongoingRequests[text];
  }

  // If no cached or ongoing request, make a new translation request
  const dispatch = store.dispatch;

  // Store the promise in the ongoingRequests object
  ongoingRequests[text] = dispatch<any>(getTranslation(text)).then((result) => {
    const translatedText = result?.payload || text;

    // Remove the promise from ongoingRequests after it resolves
    delete ongoingRequests[text];

    return translatedText;
  });

  return ongoingRequests[text];
};

// Functional component, can be used like <TranslateComponent>text</TranslateComponent>
export const TranslateComponent = props => {
  const translatedText = translate(props.children);
  return <>{translatedText}</>;
};

export default translate;
