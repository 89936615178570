import { translations } from "./setup"

export const t = (stringPath = "") => {
  if (!stringPath) {
    return ""
  } else {
    return getValueByPath(translations, stringPath)
  }
}

export const getValueByPath = (object = translations, path = "") => {
  const objectName = path?.split(".")?.[0]
  const translationsObject = object?.[objectName]

  if (!translationsObject) return ""
  else {
    return path.split(".").reduce((acc, key) => {
      if (acc && typeof acc === "object" && key in acc) {
        return acc[key]
      }
      return ""
    }, object)
  }
}
