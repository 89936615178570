import * as d3 from "d3"
import moment from "moment"

export const drawAxis = (ref, axis) => {
  d3.select(ref).call(axis)
}

export const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && typeof o2 === "object" && Object?.keys(o1)?.length > 0
    ? Object?.keys(o1)?.length === Object?.keys(o2)?.length &&
    Object?.keys(o1)?.every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2

export const arraysEqual = (a1, a2) =>
  a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]))

export const isInfinityRange = (range) => {
  return range && range?.length === 2 &&
    (
      range[0] === -Infinity || range[0] === Infinity || range[0] === null ||
      range[1] === -Infinity || range[1] === Infinity || range[1] === null
    )
}

export const removeChartFilter = (obj, chartClientId) =>
  obj?.chartClientId !== chartClientId
export const chartFilterEquals = (obj, chartClientId) =>
  obj?.chartClientId === chartClientId

export const timeXAxisLabel = (x) => {
  const date = moment(new Date(x))
  const isMidNight = date.hour() === 0 && date.minutes() === 0
  return isMidNight ? date.format("DD MMM") : date.format("hh A")
}

export const hasAttribute = (obj = {}, attr) => {
  return obj.hasOwnProperty && obj.hasOwnProperty(attr) && typeof obj?.[attr] !== "function"
}

export const getTextDimensions = (
  symbol = "",
  fontSize = 12,
  fontFamily = "Lato, sans-serif"
) => {
  // Create a temporary SVG element
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg")
  svg.setAttribute("xmlns", "http://www.w3.org/2000/svg")
  document.body.appendChild(svg)

  // Create a text element
  const textElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "text"
  )

  fontSize && textElement.setAttribute("font-size", `${fontSize}`)
  fontFamily && textElement.setAttribute("font-family", fontFamily)
  textElement.textContent = symbol

  // Append text element to SVG
  svg.appendChild(textElement)

  // Get text dimensions
  const bbox = textElement.getBBox()
  const width = bbox.width
  const height = bbox.height

  // Remove SVG from the body
  document.body.removeChild(svg)

  return { width: width, height: height }
}

// Number formatter
export const nFormatter = (num, digits = 1) => {
  const isNegative = num < 0
  num = Math.abs(num) // Convert negative number to positive for formatting
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ]
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/
  const lookupsFiltered = lookup?.filter((item) => num >= item.value)
  const item = lookupsFiltered?.[lookupsFiltered?.length - 1]

  if (item) {
    const formattedNum = (num / item?.value)
      ?.toFixed(digits)
      ?.replace(regexp, "")
    return isNegative
      ? "-" + formattedNum.concat(item?.symbol)
      : formattedNum.concat(item?.symbol)
  } else {
    return "0"
  }
}