import {
  SecondaryNav,
  LeftPanel,
  SearchInput,
  RightPanel,
  Typography,
  Spinner
} from "src/blitz"
import styles from "./ChillerPerformanceReport.module.scss"
import clsx from "clsx"
import { useState } from "react"
import {
  getChillerCharacteristics,
  getSelectedChillerProperties,
  getSelectedEquipments,
  setSelectedChillerProperties
} from "src/redux/slicers/reportSlice"
import { useSelector, useDispatch } from "react-redux"
import PropertiesList from "./PropertiesList"
import { TranslateComponent } from "src/common/translations"
import { prop, props } from "ramda"

const ChillerProperties = (props) => {
  const { systemOfMeasure, loading } = props
  const [searchText, setSearchText] = useState("")
  const dispatch = useDispatch()
  const selectedProperties = useSelector(getSelectedChillerProperties)
  const selectedEquipment = useSelector(getSelectedEquipments)
  const chillerCharacteristics = useSelector(getChillerCharacteristics)
  const handleSearch = (value: string) => {
    setSearchText(value)
  }
  const showEmptyMessage =
    !selectedEquipment || Object?.keys(selectedEquipment)?.length === 0

  const handleChangeProperties = (property, otherProps = []) => {
    if (selectedProperties?.includes(property)) {
      const otherPropsCheckBoxes =
        otherProps
          ?.filter((obj) => obj?.checkboxName)
          ?.map((obj) => obj?.value) || []
      const newProps = selectedProperties?.filter(
        (prop) => prop !== property && !otherPropsCheckBoxes?.includes(prop)
      )
      dispatch(setSelectedChillerProperties(newProps))
    } else
      dispatch(setSelectedChillerProperties([...selectedProperties, property]))
  }

  return (
    <div className={clsx("col-sm", styles.reportListWrapper)}>
      <div className={clsx(styles.reportPanel)}>
        <SecondaryNav withBorder>
          <LeftPanel loading={loading}>
            <TranslateComponent>
              Select Report Section
            </TranslateComponent>
          </LeftPanel>
          <RightPanel loading={loading}>
            <SearchInput
              onInputChange={handleSearch}
              onInputClear={() => setSearchText("")}
              searchText={searchText}
            />
          </RightPanel>
        </SecondaryNav>
        <div className={styles.panelList}>
          {showEmptyMessage ? (
            <Typography
              tagType="div"
              styleType="p1"
              color="graphite"
              className={styles.emptyMessage}
              fontType="italicFont"
            >
              Select your report options to the left.
            </Typography>
          ) : (
            <div className={styles.panelListInner}>
              <div className={styles.reportListHeader}>
                <Typography
                  tagType="div"
                  styleType="p4"
                  className={styles.yellowText}
                >
                  Yellow text
                </Typography>
                <Typography tagType="div" styleType="p4" color="graphite">
                  indicates data is missing that could make the report more
                  useful or allow sections to be included in the report
                </Typography>
              </div>
              <div
                className={clsx(styles.reportListBody, {
                  [styles.reportListBodyLoading]:
                    chillerCharacteristics?.loading
                })}
              >
                {chillerCharacteristics?.loading ? (
                  <Spinner loading={chillerCharacteristics?.loading} />
                ) : (
                  <PropertiesList
                    chillerCharacteristics={chillerCharacteristics}
                    selectedProperties={selectedProperties}
                    searchText={searchText}
                    handleChangeProperties={handleChangeProperties}
                    systemOfMeasure={systemOfMeasure}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChillerProperties
