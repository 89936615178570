import moment from "moment"

export const MISSING_TEXT = "Missing"
export const serviceDateFormat = "MMMM D, YYYY"
export const uploadServiceDateFormat = "YYYY-MM-DDTHH:mm:ss.SS[Z]"

// Characeteristics target Keys
export const CHILLER_CAPACITY_NOMINAL = "ChillerCapacityNominal"
export const CHILLER_ENTERED_SERVICE = "ChillerEnteredService"

export const COMPRESSOR_RUN_HOURS = "RunHoursBeforeTISDataCollectionStartDate"
export const COMPRESSOR_STARTS_BEFORE = "StartsBeforeTISDataCollectionStartDate"
// Characeteristics target Keys

export const CHILLER_LIFE = "ChillerLife"

// CPR Properties target Keys
export const CHILLER_PROFILE = "ChillerProfile"
export const COMPRESSOR_RUN_HOURS_AND_STARTS = "CompressorRunHoursAndStarts"
