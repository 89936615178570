import styles from "./Spinner.module.scss"
import clsx from "clsx"
import { TranslateComponent } from "src/common/translations"

const Spinner = ({
  loading = false,
  completed = false,
  message = "Completed",
  error = false,
  errorMessage = "Something went wrong",
  dataExceeded = false,
  className = ""
}: any) => {
  if (loading) {
    return (
      <div className={clsx(styles.wrapper, className)}>
        <span className={clsx("icon-spinner3", styles.spinner)}></span>
      </div>
    )
  } else if (error) {
    return (
      <div className={styles.loaderError}>
        <span>{errorMessage}</span>
        <span className="icon-warning" title={errorMessage}></span>
      </div>
    )
  } else if (completed) {
    return (
      <div className={styles.loaderComplete}>
        <span>
          <TranslateComponent>
            {message}
          </TranslateComponent>
        </span>
        <span className="enlarged">&#x2713;</span>
      </div>
    )
  } else if (dataExceeded) {
    return (
      <div className={styles.loaderDataExceeded}>
        <span>{<TranslateComponent>Data Limit Exceeded</TranslateComponent>}</span>
        <span className="icon-info" title={dataExceeded}></span>
      </div>
    )
  } else {
    return null
  }
}

export default Spinner
