import { ADD } from "./constant"
import moment from "moment"

export const getCalculatedDay = (
  calendarType,
  pickerCategory,
  date = new Date()
) => {
  const rangeNum = pickerCategory - 1
  const calculatedDate = new Date(date.getTime())
  calculatedDate.setDate(
    calendarType === ADD ? date.getDate() + rangeNum : date.getDate() - rangeNum
  )
  return new Date(
    calculatedDate.getFullYear(),
    calculatedDate.getMonth(),
    calculatedDate.getDate()
  )
}

// To check -->
// If enetered date is invalid,
// If query start and end date parameters are available!
export const checkInValidDate = (dateParam) => {
  const date = new Date(dateParam).toString()
  return date?.toLowerCase() === "invalid date" || dateParam === null
}

export const getDateDifference = (endDate: string, startDate: string) => {
  // const oneDay = 24 * 60 * 60 * 1000;
  // return Math.abs((endDate.getTime() - startDate.getTime()) / (oneDay));
  const sDate = moment(startDate, "MM/DD/YYYY")
  const eDate = moment(endDate, "MM/DD/YYYY")
  return eDate.diff(sDate, "days")
}

export const displayDateFormatter = (date, showDay = false) => {
  if (date) {
    return (
      (showDay ? date?.toLocaleString("default", { weekday: "long" }) + ", " : "") +
      date?.toLocaleString("default", { month: "long" }) +
      " " +
      date?.getDate() +
      ", " +
      date?.getFullYear()
    )
  } else {
    return ""
  }
}

export const getMinutesDifference = (startDate, endDate) => {
  const start: any = new Date(startDate)
  const end: any = new Date(endDate)
  start?.setHours && start?.setHours(0, 0, 0, 0)
  end?.setHours && end?.setHours(0, 0, 0, 0)
  end?.setDate && end.setDate(end.getDate() + 1)
  return (Math.floor(Math.abs(end - start) / 60000))
}