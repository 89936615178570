const SUPPORTED_LANGUAGES: any = [
  { key: "en", value: "English" },
  { key: "nl", value: "Dutch" }, //Dutch
  { key: "fr", value: "French" }, // French
  { key: "fr-CA", value: "French(Canada)" }, // French (Canada)
  { key: "de", value: "German" }, // German
  { key: "hu", value: "Hungarian" }, //Hungarian
  { key: "it", value: "Italian" }, //Italian
  { key: "pt", value: "Portuguese(Brazil)" }, //Portuguese (Brazil)
  { key: "pt-PT", value: "Portuguese(Portugal)" }, //Portuguese (Portugal)
  { key: "es", value: "Spanish" } //Spanish
];

export default SUPPORTED_LANGUAGES

