import clsx from "clsx"
import { Fragment } from "react"
import { useNavigate } from "react-router-dom"
import Checkbox from "src/blitz/Checkbox/Checkbox"
import Typography, { ILoaderProps } from "../Typography"
import styles from "./BuildingTile.module.scss"
import ConnectivityModal from "./ConnectivityModal"
import SkeletonLoader from "../Skelton/Skelton"
import { selectSearchText } from "src/redux/slicers/homeSlice"
import { useSelector } from "react-redux"
import OfferingInfo from "./OfferingInfo"
import React from "react"
import { removeSpaceInString } from "src/components/buildings/helper"

type BuildingTileType = {
  building?: any
  selectBuilding?: any
  selectedBuildings?: any
  loading?: boolean
}

const loaderProps: ILoaderProps = { theme: "white", width: 200, loading: false }

function BuildingTile({
  building,
  selectBuilding,
  selectedBuildings,
  loading = false
}: BuildingTileType) {
  const { id } = building
  if (!id && !loading) return null
  const navigation = useNavigate()
  const navigate = () => {
    const URL = loading ? "#" : `/building/${id}/summary`
    navigation(URL)
  }

  return (
    <li key={id}>
      <div onClick={navigate}>
        <TileHeader
          building={building}
          selectBuilding={selectBuilding}
          selectedBuildings={selectedBuildings}
          loading={loading}
          index={id}
        />
      </div>
      <div className={styles?.bottomPanel}>
        <SkeletonLoader
          loading={loading}
          width={280}
          theme="white"
          className={styles.loader}
        >
          <div className={clsx(styles?.bottomPanel__item)}>
            <ConnectivityModal
              building={building}
              status={building?.connectionStatus}
              type="Connectivity"
              testId={`facility-dashboard-tile-connectivity-${id}`}
            />
          </div>
          <div className={styles?.divider}></div>
          <div className={styles?.bottomPanel__item}>
            <ConnectivityModal
              status={building?.remoteAccess}
              building={building}
              type="Remote Access"
              testId={`facility-dashboard-tile-remote-access-${id}`}
            />
          </div>
          {building?.["critical"] && (
            <Fragment>
              <div className={styles?.divider}></div>
              <div className={styles?.bottomPanel__item}>
                <ConnectivityModal
                  type="Critical"
                  status={""}
                  building={{}}
                  testId={`facility-dashboard-tile-critical-${id}`}
                />
              </div>
            </Fragment>
          )}
          {building?.information && (
            <Fragment>
              <div className={styles?.divider}></div>
              <div className={styles?.bottomPanel__item}>
                <ConnectivityModal
                  type="Information"
                  status={""}
                  building={{}}
                  testId={`facility-dashboard-tile-information-${id}`}
                />
              </div>
            </Fragment>
          )}
          {building?.service && (
            <Fragment>
              <div className={styles?.divider}></div>
              <div className={styles?.bottomPanel__item}>
                <ConnectivityModal
                  type="Service Required"
                  status={""}
                  building={{}}
                  testId={`facility-dashboard-tile-service-required-${id}`}
                />
              </div>
            </Fragment>
          )}
          <div className={styles?.rightAligned}></div>
          <OfferingInfo
            buildingName={building?.name}
            offerings={building?.offerings?.items}
            devices={building?.deviceConnectivity}
            testId={"facility-dashboard-tile-offering-info-" + building?.id}
          />
        </SkeletonLoader>
      </div>
    </li>
  )
}

function TileHeader({
  loading,
  building,
  selectedBuildings,
  selectBuilding,
  index
}) {
  const searchText = removeSpaceInString(useSelector(selectSearchText))
  return (
    <Fragment>
      <div className={clsx(styles.title, styles[building.connectionStatus])}>
        <SkeletonLoader width={300} theme="light-dark" loading={loading}>
          <Fragment>
            <Checkbox
              className={styles.checkbox}
              checked={selectedBuildings.includes(building?.id)}
              onClick={(e: any) => e.stopPropagation()}
              onChange={(e: any) => {
                selectBuilding(building?.id, e?.target?.checked)
              }}
              testId={`facility-dashboard-tile-checkbox-field-${index}`}
            />
            <Typography
              tagType="span"
              styleType="p4"
              title={building?.name}
              data-testid="facility-dashboard-tile-title"
              highLightedText={searchText}
              textToHighlight={building?.name}
            >
              {building?.name}
            </Typography>
          </Fragment>
        </SkeletonLoader>
      </div>
      <div className={styles?.facilityInfo}>
        <Typography
          tagType="p"
          styleType="p4"
          data-testid="facility-dashboard-tile-account-name-label"
          loaderProps={{ ...loaderProps, loading, width: 280 }}
          highLightedText={searchText}
          textToHighlight={
            building?.accountName ? building?.accountName : "N/A"
          }
        >
          {building?.accountName ? building?.accountName : "N/A"}
        </Typography>
        <Typography
          tagType="p"
          styleType="p4"
          data-testid="facility-dashboard-tile-address1-label"
          loaderProps={{ ...loaderProps, loading, width: 280 }}
          highLightedText={searchText}
          textToHighlight={building?.address}
        >
          {building?.address}
        </Typography>
        <Typography
          tagType="p"
          styleType="p4"
          data-testid="facility-dashboard-tile-address2-label"
          loaderProps={{ ...loaderProps, loading, width: 280 }}
          highLightedText={searchText}
          textToHighlight={`${building?.address2 ? building?.address2 : ""} ${building?.city ? building?.city : ""
            } ${building?.state ? building?.state : ""}  ${building?.postalCode ? building?.postalCode : ""
            } ${building?.country ? building?.country : ""}`}
        >
          <>
            {building?.address2} {building?.city} {building?.state}{" "}
            {building?.postalCode} {building?.country}
          </>
        </Typography>
      </div>
    </Fragment>
  )
}
export default React.memo(BuildingTile)
