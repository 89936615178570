import React, { useMemo } from "react"
import Highlighter from "react-highlight-words"
import { Checkbox } from "src/blitz"
import styles from "./ExceptionsWithSearch.module.scss"
import { SecondaryNav, LeftPanel, SearchInput, RightPanel } from "src/blitz"
import clsx from "clsx"
import { t } from "src/translations/help"
import { All } from "../constant"
import { searchEntities } from "src/utils/SearchUtils"
import translate from "src/common/translations"

// To be moved to utilz
const HighlightText = ({ name, searchText, translateValues }) => {
  return (
    <Highlighter
      highlightClassName={styles.searchHighlight}
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={translateValues ? translate(name) : name}
    />
  )
}

const ExceptionsWithSearch = ({ config }) => {
  const {
    title,
    data,
    loading,
    handleChange,
    selectedItems,
    onChangeSearch,
    searchText,
    isAllChecked,
    translateValues = false,
    selectedEquipmentType
  } = config

  const rows = useMemo(
    () => searchEntities(searchText, data, ["exceptionName"]),
    [searchText, data]
  )

  return (
    <div className={clsx("row g-0", styles.reportListContainer)}>
      <div className={clsx("col-sm", styles.reportListWrapper)}>
        <div className={clsx(styles.reportPanel)}>
          <SecondaryNav withBorder>
            <LeftPanel loading={loading}>{translate(title)}</LeftPanel>
            <RightPanel loading={loading}>
              <SearchInput
                onInputChange={onChangeSearch}
                onInputClear={() => onChangeSearch("")}
                searchText={searchText}
                testId="reports-Exceptions-history-equipment-search-input"
              />
            </RightPanel>
          </SecondaryNav>
          <div className={styles.panelList}>
            <div className={styles.panelListInner}>
              <div className={styles.reportListHeader}>
                <Checkbox
                  name={"select-all-equipments"}
                  label={t("common.selectandDeSelectAll")}
                  checked={isAllChecked}
                  testId="reports-Exceptions-history-equipment-select-all-checkbox"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e?.target?.checked, "all-check")
                  }
                />
              </div>
              <div className={styles.reportListBody}>
                {rows?.map((item: any, index: number) => {
                  const checkboxId = `${item.id}-${index}`
                  return (
                    <Checkbox
                      key={checkboxId}
                      testId={`reports-Exceptions-history-equipment-select-checkbox${index}`}
                      name={item.id}
                      label={
                        <HighlightText
                          name={`${item?.exceptionName}`}
                          searchText={searchText}
                          translateValues={translateValues}
                        />
                      }
                      onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(ev.target.checked, item, "checkbox")
                      }}
                      checked={selectedItems?.find(
                        (obj) => obj?.exceptionName === item.exceptionName
                      )}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExceptionsWithSearch
