import { sortDataByFieldName } from "src/blitz/DataGridTable/helper"
import { EquipmentProperties } from "./ExceptionReport/types"
import { DIOfferings, DIOfferingsMappedToEquipmentTypes } from "./constants"
import { ENABLING_DI_REPORT_OFFERINGS } from "src/components/buildingSummary/constants"
import { reportOfferingCodeCheck } from "src/components/buildingSummary/helper"
import { intersection, uniq } from "lodash"

const VAV_BOXES = ["VAS"]
const LoadValves = ["DL"]
const groupTypes = {
  "VAV-BOX": VAV_BOXES,
  LoadValve: LoadValves
}

export const frameEquipmentsTree = (data: any, flat: boolean): any => {
  if (data.length === 0) return []
  const {
    activeEquipments = [],
    familyTypes = [],
    rootEquipments = []
  } = data?.reduce(
    (acc, item: any) => {
      if (item?.isActive) {
        acc.activeEquipments.push(item)
        const familyType = `${item?.type}:${item?.family}`
        if (!acc.familyTypes.includes(familyType))
          acc.familyTypes.push(familyType)
        if (VAV_BOXES.includes(item.type) && item?.isParent) {
          acc.rootEquipments["VAV-BOX"].push(item)
        } else if (
          LoadValves.includes(item?.type) &&
          item.parentId !== "NA" &&
          item?.isParent
        ) {
          acc.rootEquipments["LoadValve"].push(item)
        }
      }
      return acc
    },
    {
      activeEquipments: [],
      familyTypes: [],
      rootEquipments: {
        LoadValve: [],
        "VAV-BOX": []
      }
    }
  )
  const parents = familyTypes?.map((familyType: any) => {
    const [type, family] = familyType.split(":")
    if (!flat && ["VAV-BOX", "LoadValve"].includes(type)) {
      const types =
        type === "VAV-BOX" ? ["VAV-BOX", "VAS"] : ["LoadValve", "DL"]
      const equipmentList = activeEquipments?.filter((item: any) =>
        types.includes(item.type)
      )
      const parents = rootEquipments[type]?.filter((item: any) =>
        groupTypes[type].includes(item?.type)
      )
      return {
        title: family,
        value: type,
        type: "text",
        options: getChildOptions(parents, equipmentList, familyType)
      }
    } else {
      const equipmentList = activeEquipments?.filter(
        (item: any) => item.type === type
      )
      return {
        title: family,
        value: type,
        type: "button",
        sub: equipmentList?.length ? equipmentList : undefined
      }
    }
  })
  return sortDataByFieldName("title", "Asc", parents)
}

const getChildOptions = (
  parents: any,
  equipmentList: any,
  familyType: string
) => {
  const [parentEquipmentType, parentEquipmentFamily] = familyType.split(":")
  const typeName =
    parentEquipmentType === "LoadValve"
      ? parentEquipmentFamily + "s"
      : parentEquipmentFamily + "es"
  const { equipmentsWithParents = [], standAlones = [] } =
    equipmentList?.reduce(
      (acc, item: any) => {
        if ((item?.parentId && item?.parentId !== "NA") || item?.isParent)
          acc.equipmentsWithParents.push(item)
        else acc.standAlones.push(item)
        return acc
      },
      {
        equipmentsWithParents: [],
        standAlones: []
      }
    )
  const childrens = parents?.map((item: any) => {
    const parentId = item?.id
    const groupType =
      parentEquipmentType === "VAV-BOX" && item?.type === "VAS"
        ? "VAV-BOX"
        : parentEquipmentType === "LoadValve" && item?.type === "DL"
          ? "LoadValve"
          : item?.type
    return {
      title: `${typeName}: ${item?.name}`,
      value: groupType,
      type: "button",
      sub: equipmentsWithParents?.filter(
        (child: any) => child.parentId === parentId
      )
    }
  })
  if (standAlones?.length) {
    childrens.push({
      title: `${typeName}: Standalone`,
      value: parentEquipmentType,
      type: "button",
      sub: standAlones
    })
  }
  const childResult = sortDataByFieldName("title", "Asc", childrens)
  return childResult
}

export const transformChillerProperties = (
  propertyArray: EquipmentProperties
) => {
  const componentPropertyArray = propertyArray?.filter(
    ({ targetKey, value }) =>
      targetKey?.toLowerCase()?.includes("compressorcountckt") &&
      typeof value === "string" &&
      value?.length > 0
  )
  const filteredArray = Array?.from(
    new Set(componentPropertyArray?.map((item) => item?.targetKey))
  ).map((targetKey) => ({
    targetKey,
    value: Math?.max(
      ...componentPropertyArray
        ?.filter((item) => item?.targetKey === targetKey)
        ?.map((item) => parseInt(item?.value))
    )
  }))
  const circuitInstances =
    filteredArray?.length > 1
      ? filteredArray?.map(
        ({ targetKey }) => `Ckt${targetKey?.slice(targetKey?.length - 1)}`
      )
      : filteredArray?.length == 1 ? ["Ckt"] : []
  const compressorInstances = []

  if (
    (filteredArray?.length === 1 && filteredArray[0]?.value <= 1)
  ) {
    compressorInstances?.push("Comp")
  } else if (filteredArray?.length !== 0) {
    filteredArray.forEach((item) => {
      const { targetKey, value } = item
      for (let i = 1; i <= value; i++) {
        const suffix = String?.fromCharCode(i + 96)?.toUpperCase()
        compressorInstances?.push(
          `${targetKey?.slice(targetKey?.length - 1)}${suffix}`
        )
      }
    })
  }
  const condenserType = propertyArray
    ?.filter(({ targetKey }) => targetKey === "CondenserType")?.[0]
    ?.value?.trim()
    ?.replace(/\s/g, "")
  const compressorType = propertyArray
    ?.filter(({ targetKey }) => targetKey === "CompressorType")?.[0]
    ?.value?.trim()
    ?.replace(/\s/g, "")

  return {
    instance: (condenserType && compressorType) ? [
      "Chiller",
      ...circuitInstances,
      ...compressorInstances
    ] : ["Chiller"],
    compCondType:
      condenserType && compressorType
        ? `${condenserType}#${compressorType}`
        : ""
  }
}

export const offeringDIAvailableEquipmentTypes = (
  offerings: any[],
) => {
  if (!offerings || offerings?.length === 0) return [];

  const offeringsCodes = uniq(offerings?.filter((obj) => !obj?.disableAutomatedTestResults).map(obj => obj.code)) || []
  const commonOfferings = intersection(DIOfferings, offeringsCodes)
  const DIOfferingsNames = offerings?.filter((item: any) => item?.code?.startsWith("DI-") && !item?.disableAutomatedTestResults)
  const availableEquipmentTypes = []
  DIOfferingsNames?.forEach((offering: any) => {
    if (DIOfferingsMappedToEquipmentTypes[offering?.code]) {
      availableEquipmentTypes?.push(DIOfferingsMappedToEquipmentTypes[offering?.code])
    }
  })
  if (commonOfferings.length > 0) {
    return uniq([...availableEquipmentTypes, "Chiller", "AHU"])
  }
  return [...availableEquipmentTypes]
}

export const DI_TAB_V2_ALLOWED_EQUIP_TYPES = [];
export const DI_TAB_ALLOWED_EQUIP_TYPES = ["VRF-System", "VRF-IDU", "VRF-ODU"];
export const decideShowDITab = (offerings = [], equipmentType = "", isV2 = false) => {
  return offeringDIAvailableEquipmentTypes(offerings)?.includes(equipmentType) || (isV2 ? DI_TAB_V2_ALLOWED_EQUIP_TYPES?.includes(equipmentType) : DI_TAB_ALLOWED_EQUIP_TYPES?.includes(equipmentType))
}

export const iSDiReportDisabled = (equipmentType, buildingSummary) => {

  if (intersection(DI_TAB_ALLOWED_EQUIP_TYPES, equipmentType).length > 0) {
    return false
  }

  return intersection(offeringDIAvailableEquipmentTypes(buildingSummary?.offerings), equipmentType).length > 0
    ? reportOfferingCodeCheck(
      [...ENABLING_DI_REPORT_OFFERINGS, ...DIOfferings],
      buildingSummary?.offerings,
      buildingSummary
    )
    : true
}