import { createSelector } from "@reduxjs/toolkit"
import { END_DATE, START_DATE } from "src/blitz/DateRange/constant"
import { tranformChartData, updateAxisList } from "src/components/shared/Charts/Helper/common"
import {
  CHART_EQUIPMENT_PROPERTIES_LIST_ALL,
  CHART_TYPE,
  SELECTED_TYPE_EQUIPMENT,
} from "src/components/shared/Charts/constant"
import { SUPPRESSED_PROPERTY_TYPE } from "src/components/shared/Charts/Helper/Suppressions/constants"

// selector to check and return that given chart has all filters selected i.e facility, equipment, chart, date range
export const isRequiredChartPropertiesSelected = createSelector(
  (state: any) => state.performance.chartsFilter,
  (state: any) => state.performance.summaryInfo,
  (state: any, obj: any) => obj,
  (chartsFilter: any, summaryInfo: any, obj: any) => {
    const chartClientId = obj?.chartClientId
    const isBuildingPerformance = obj?.isBuildingPerformance

    if (!chartsFilter || !summaryInfo) return false

    const chartFilter = (chartsFilter || {})[chartClientId]
    const selectedType = chartFilter[SELECTED_TYPE_EQUIPMENT]

    const isBuildingSelected = !!summaryInfo?.data?.id

    const isEquipmentOrFacilitySelected = !!(isBuildingPerformance
      ? selectedType?.value
      : selectedType?.value?.id)

    const isChartSelected = !!chartFilter[CHART_TYPE]

    const isDateRangeSelected =
      !!chartFilter[START_DATE] && !!chartFilter[END_DATE]

    return (
      isBuildingSelected &&
      isEquipmentOrFacilitySelected &&
      isChartSelected &&
      isDateRangeSelected
    )
  }
)

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ //

// Process Performance Chart Data

export const processPerformanceChartData = (
  state,
  chartClientId,
  chartProps,
  type,
  assignedAttributes,
  legendAttributesCombination
) => {
  const added = JSON.parse(
    JSON.stringify(state.chartsFilter?.[chartClientId]?.added || {})
  )
  const chartURLState = state?.chartURLState
  const chartFilter = state.chartsFilter[chartClientId]
  const oldLegends = state.chartsProps[chartClientId]?.legends || {}
  const oldTimeLineLegends =
    state.chartsProps[chartClientId]?.timeLineLegends || {}
  const chartPropsNew = {
    ...chartProps,
    added: added,
    assignedAttributes,
    legendAttributesCombination,
    oldLegends,
    oldTimeLineLegends: oldTimeLineLegends
  }
  const transformedData = tranformChartData(type, chartPropsNew)
  let transformedProps = transformedData?.chartProps || {}

  const newLegends = transformedProps?.legends || {}
  const newTimeLineLegends = transformedProps?.timeLineLegends || {}

  let newAxisList = transformedData?.chartProps?.axisList || []
  const oldAxisList = state.chartsProps[chartClientId]?.axisList || []

  newAxisList = updateAxisList(transformedData, newAxisList, type, oldAxisList)

  if (chartURLState && chartFilter?.initialChartStateUpdate) {
    const chartURLStateLegends = chartURLState?.[chartClientId]?.legends || []
    const chartURLStateAxes = chartURLState?.[chartClientId]?.axis || []
    if (
      typeof newLegends === "object" &&
      Object?.keys(newLegends || {})?.length > 0 &&
      chartURLStateLegends?.length > 0
    ) {
      Object?.keys(newLegends)?.forEach((newLegendKey) => {
        const urlLegend = chartURLStateLegends?.find(
          (obj) => obj?.id === newLegendKey
        )
        if (urlLegend) {
          newLegends[newLegendKey] = {
            ...newLegends[newLegendKey],
            ...(urlLegend || {})
          }
        }
      })
      Object?.keys(newTimeLineLegends)?.forEach((newLegendKey) => {
        const urlLegend = chartURLStateLegends?.find(
          (obj) => obj?.id === newLegendKey
        )
        if (urlLegend) {
          newTimeLineLegends[newLegendKey] = {
            ...newTimeLineLegends[newLegendKey],
            ...(urlLegend || {})
          }
        }
      })
      transformedProps.legends = newLegends
      transformedProps.timeLineLegends = newTimeLineLegends
    }
    if (chartURLStateAxes?.length > 0) {
      newAxisList = newAxisList?.map((axis) => {
        const urlAxis = chartURLStateAxes?.find(
          (obj) => obj?.axisName === axis?.axisName
        )
        const newAxis: any = { ...axis }
        if (urlAxis?.range) {
          newAxis.range = urlAxis?.range
        }

        if (urlAxis?.isRangeEdited) {
          newAxis.isRangeEdited = true
        }
        return newAxis
      })
      chartURLStateAxes?.forEach((axis) => {
        const axisAvailable = newAxisList?.find(
          (obj) => obj?.axisName === axis?.axisName
        )
        if (!axisAvailable) {
          newAxisList.push(axis)
        }
      })
    }
    newAxisList = newAxisList.map((axis) => {
      const AxisLegends = Object.values(transformedProps.legends).filter(
        (legend: any) => legend.axisName === axis?.axisName
      )
      if (AxisLegends.every((legend: any) => !legend.show)) {
        return { ...axis, hideAxis: true }
      } else {
        return { ...axis, hideAxis: false }
      }
    })
  }

  transformedProps = {
    ...transformedProps,
    axisList: newAxisList
  }

  if (
    typeof oldTimeLineLegends === "object" &&
    typeof newTimeLineLegends === "object" &&
    Object?.keys(newTimeLineLegends)?.length > 0
  ) {
    Object?.keys(newTimeLineLegends)?.forEach((newLegendKey) => {
      if (
        newTimeLineLegends?.[newLegendKey]?.propertyType ===
        SUPPRESSED_PROPERTY_TYPE
      ) {
        newTimeLineLegends[newLegendKey] = {
          ...newTimeLineLegends[newLegendKey],
          visible:
            oldTimeLineLegends?.[newLegendKey]?.visible ||
            state?.chartsFilter?.[chartClientId]?.suppressionToggleIndicator
        }
      }
    })
    transformedProps.timeLineLegends = newTimeLineLegends
  }

  // storing default settings in chartProps
  if (transformedProps?.defaultSettingData) {
    transformedProps.defaultSettingData = chartProps?.defaultSettingData
  }
  return {
    transformedProps: transformedProps || {},
    transformedData: transformedData?.chartData || {}
  }
}

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ //

// Process Pareto chart Data

export const processParetoChartData = (
  type,
  chartProps,
  listAnalyticConfigs,
  state,
  chartClientId,
  isHeadLess,
  bc
) => {
  const chartPropsNew = {
    ...chartProps,
    listAnalyticConfigs: listAnalyticConfigs,
    equipmentProperties:
      state?.chartsFilter?.[chartClientId]?.[
      CHART_EQUIPMENT_PROPERTIES_LIST_ALL
      ],
    isHeadLess,
    bc
  }
  const transformedData = tranformChartData(type, chartPropsNew)
  const transformedProps = transformedData?.chartProps || {}
  // Retaining the legend properties within date changes of same chart
  const oldLegends = state.chartsProps[chartClientId]?.legends || {}
  const newLegends = transformedProps?.legends || {}
  if (
    typeof oldLegends === "object" &&
    typeof newLegends === "object" &&
    Object?.keys(newLegends)?.length > 0
  ) {
    Object?.keys(newLegends)?.forEach((newLegendKey) => {
      if (oldLegends[newLegendKey]) {
        newLegends[newLegendKey] = {
          ...newLegends[newLegendKey],
          show: oldLegends[newLegendKey]?.show,
          color: oldLegends[newLegendKey]?.color,
          shape: oldLegends[newLegendKey]?.shape,
          strokeWidth: oldLegends[newLegendKey]?.strokeWidth,
          shapeSize: oldLegends[newLegendKey]?.shapeSize,
          lineType: oldLegends[newLegendKey]?.lineType
        }
      }
    })
    transformedProps.legends = newLegends
  }
  return {
    transformedProps: transformedProps || {},
    transformedData: transformedData?.chartData || {}
  }
}
