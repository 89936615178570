import clsx from "clsx"
import { useState } from "react"
import { ASC, DESC } from "src/blitz/DataGridTable/constants"
import ResizeHandle from "../Resize/ResizeHandle"
import { ITableHeadCell } from "../types"
import headCellStyles from "./TableHeadCell.module.scss"
import FilterModal from "src/blitz/DataGridTable/FilterModal/FilterModal"
import translate from "src/common/translations"

const TableHeadCell = (props: ITableHeadCell) => {
  const {
    className = "",
    colSpan,
    rowSpan,
    children,
    style = {},
    title,
    onClick = () => null,
    handleFilterChange = () => null,
    getFilterAggregates = () => null,
    sortable = false,
    activeSort,
    sortOrder,
    settingField,
    testId = "",
    resizable = false,
    onColumnResize = () => null,
    resizableProps = null,
    columnObj = null,
    currentFilter = null,
    aggregateData = [],
    aggregatesLoading = false,
    handleRemoveFilter,
    width = "",
    loading = false,
    clientFilter = null
  } = props

  const [colWidth, setColWidth] = useState(width)

  const content = () => {
    if (settingField || sortable) {
      return (
        <div className="d-flex justify-content-between align-items-center">
          <div onClick={onClick} className={headCellStyles.contentWrapper}>
            {children}
          </div>
          <div
            className={clsx(
              "d-flex justify-content-between align-items-center",
              headCellStyles.optionWrapper
            )}
          >
            <div
              onClick={onClick}
              className={clsx(headCellStyles.sort, {
                [headCellStyles.sortIcon]: sortable,
                [headCellStyles.sortAsc]: activeSort
                  ? sortOrder === ASC
                  : false,
                [headCellStyles.sortDesc]: activeSort
                  ? sortOrder === DESC
                  : false
              })}
            ></div>
            {columnObj && (
              <FilterModal
                clientFilter={clientFilter}
                aggregatesLoading={aggregatesLoading}
                aggregateData={aggregateData}
                columnObj={columnObj}
                handleSortingChange={onClick}
                handleFilterChange={handleFilterChange}
                currentFilter={currentFilter}
                handleRemoveFilter={handleRemoveFilter}
                getFilterAggregates={getFilterAggregates}
                sortOrder={sortOrder}
                activeSort={activeSort}
                disabled={loading}
              />
            )}
          </div>
        </div>
      )
    } else {
      return <div onClick={onClick}>{children}</div>
    }
  }

  const onResize = (event) => {
    if (!resizableProps || !resizableProps.isCustomWidthReq) {
      setColWidth(event.width)
    }

    onColumnResize(event)
  }

  return (
    <th
      className={clsx(className, {
        [headCellStyles.sortTH]: sortable
      })}
      title={title && translate(title)}
      style={{ width: colWidth, ...style }}
      rowSpan={rowSpan && rowSpan}
      colSpan={colSpan && colSpan}
      data-testid={testId}
    >
      {content()}
      {resizable ? (
        <ResizeHandle
          columnName={title}
          resizableProps={resizableProps}
          onResize={onResize}
        />
      ) : null}
    </th>
  )
}

export default TableHeadCell
