import { ModalDialog, Spinner, Typography } from "src/blitz"
import styles from "./AdvisorySuggestion.module.scss"
import { CAUSES_TEXT, NOTES_TEXT } from "src/components/buildingSummary/constants"
import { translate, TranslateComponent } from "src/common/translations"
const ServiceAdvisorySuggestion = (props: any) => {
  const { suggestions, testName = "", suggestionModalConfig, loading = false } = props
  return (
    <ModalDialog
      modalConfig={suggestionModalConfig}
      bodyClassName={styles.suggestionBody}
      customClassName={styles.customModalContent}
      footerClassName={styles.suggestionFooter}
    >
      <div className={styles.suggestionContent}>
        <Spinner loading={loading} className={styles.loadingContent} />
        {!loading && <div className={styles.suggestionlist}>
          <Typography
            className={styles.namePargraph}
            tagType="p"
            styleType="p4"
            fontType="boldFont"
          >
            <TranslateComponent>{testName}</TranslateComponent>
          </Typography>
          <Typography
            tagType="p"
            styleType="p4"
            className={styles.suggestionDescription}
          >
            <TranslateComponent>{suggestions?.description}</TranslateComponent>
          </Typography>
          {suggestions?.notes && <Typography
            tagType="p"
            styleType="p4"
            className={styles.suggestionNotes}
          >{NOTES_TEXT + (suggestions?.notes)}</Typography>}
          {suggestions?.causes && <Typography
            tagType="p"
            styleType="p4"
            fontType="boldFont"
            className={styles.nameHeading}
          >
            <TranslateComponent>{CAUSES_TEXT}</TranslateComponent>
          </Typography>}
          <SuggestionData data={suggestions?.causes} />
        </div>}

      </div>
    </ModalDialog>
  )
}
const SuggestionData = ({ data }) => {
  if (!data) return null
  return (
    <ul>
      {data?.map((item: any, index: number) => (
        <li key={index} className={styles?.subCausesListStyle}>
          <TranslateComponent>{item?.name}</TranslateComponent>
          <SuggestionData data={item?.causes} />
        </li>
      ))}
    </ul>
  )
}

export default ServiceAdvisorySuggestion
