export const hasAbsoluteAncestor = (element) => {
  // Check if the element or any of its ancestors has position: absolute
  while (element) {
    const computedStyle = window.getComputedStyle(element)
    if (computedStyle.getPropertyValue("position") === "absolute") {
      return true
    }
    element = element.parentElement
  }
  return false
}
