import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setApplicationErrors } from "src/redux/slicers/applicationSlice";
import styles from "./ItemNotFound.module.scss";

const ItemNotFound = (props) => {
    const { id } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleOnClick = (e) => {
        e.preventDefault();
        dispatch(setApplicationErrors({ errorID: id }))
        navigate("/")
    }
    return <div>
        The page you are looking for is not available. The location of the
        item may have changed. If you have used a bookmark or shared
        hyperlink for navigation, select the page from the left sidebar and
        bookmark the page location or <span className={styles.link} onClick={handleOnClick}>click here </span> to go to
        Home page.
    </div>
}

export default ItemNotFound

export const itemNotFoundTitle = "Item Not Found"