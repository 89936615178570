import React, { ReactEventHandler, useEffect } from "react"
import ReactDOM from "react-dom"
import { Button, Spinner, Typography } from "src/blitz"
import style from "./ModalDialog.module.scss"
import clsx from "clsx"
import { ITypographyFontType, ITypographyStyleType } from "../Typography/types"
import { TranslateComponent } from "src/common/translations"

type ButtonType = {
  text: string
  handleClick: ReactEventHandler
  type: string
  variant?: any
  disabled?: boolean
  btnClassName?: string
  btnSize?: any
}

type ModalDialogProps = {
  children?: React.ReactNode
  rootClassName?: any
  customStyles?: any
  customClassName?: string
  titleClassName?: string
  titleFontName?: ITypographyFontType
  titleFontType?: ITypographyStyleType
  bodyClassName?: string
  footerClassName?: string
  showSpinner?: boolean
  showCompleted?: boolean
  showError?: boolean
  showDataExceeded?: boolean | string
  completedMessage?: string
  showAppliedMsg?: boolean
  modalConfig: {
    heading: string
    isShowCloseIcon?: boolean
    isAlert?: boolean
    fullScreen?: boolean
    halfScreen?: boolean
    modal: boolean
    buttons?: ButtonType[]
    handleClose?: () => void
  }
  error?: boolean
  errorMessage?: string
}

const ModalDialog = ({
  children,
  customStyles,
  customClassName = "",
  titleClassName = "",
  bodyClassName = "",
  footerClassName = "",
  titleFontName = "oswaldFont",
  titleFontType = "p2",
  modalConfig,
  showSpinner = false,
  showCompleted = false,
  showDataExceeded = false,
  completedMessage = "",
  error = false,
  errorMessage = "",
  showAppliedMsg = false
}: ModalDialogProps) => {
  const {
    heading,
    isAlert = false,
    buttons = [],
    fullScreen = false,
    halfScreen = false,
    isShowCloseIcon = true,
    modal = false,
    handleClose
  } = modalConfig
  useEffect(() => {
    document.body.style.overflow = modal ? "hidden" : "unset"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [modal])
  if (!modal) return
  const isFooter = buttons.length > 0
  return ReactDOM.createPortal(
    <div className={style.modalRoot}>
      <div className={style.modalContainer}>
        <div
          style={customStyles}
          className={clsx(
            `${style.modalWrapper} ${style.fade} ${customClassName}`,
            {
              [style.in]: modal,
              [style.halfScreen]: halfScreen,
              [style.fullScreen]: fullScreen
            }
          )}
        >
          <div className={clsx(style.modalHeader, titleClassName)}>
            {isAlert ? (
              <Typography tagType="span" styleType="p2">
                Alert
              </Typography>
            ) : (
              <Typography
                tagType="h3"
                fontType={titleFontName}
                styleType={titleFontType}
                translateText={true}
              >
                {heading}
              </Typography>
            )}
            {isShowCloseIcon && (
              <button
                className={`${style.modalClose} icon-close`}
                onClick={() => handleClose()}
              />
            )}
          </div>
          <div className={clsx(style.modalContent, bodyClassName)}>
            {typeof children === "string"?  <TranslateComponent>{children}</TranslateComponent> :children}
          </div>
          {isFooter && (
            <div className={clsx(style.modalFooter, footerClassName)}>
              <>
                {showAppliedMsg && (
                  <span className={style.appliedIocnStyles}>
                    <span className={style.footermodalClose}><TranslateComponent>Applied</TranslateComponent></span>
                    <span
                      className={`${style.appliedIocnStyles} icon icon-checkmark`}
                    ></span>
                  </span>
                )}
                <Spinner
                  loading={showSpinner}
                  completed={showCompleted}
                  message={completedMessage}
                  dataExceeded={showDataExceeded}
                  error={error}
                  errorMessage={errorMessage}
                />
                {buttons?.map((button: ButtonType, key) => {
                  const {
                    handleClick,
                    disabled = false,
                    btnClassName = "",
                    variant,
                    text = "",
                    btnSize
                  } = button
                  return (
                    <Button
                      key={key}
                      type="button"
                      onClick={(e) => {
                        handleClick(e)
                      }}
                      variant={variant}
                      disabled={disabled}
                      className={btnClassName}
                      btnSize={btnSize}
                      testId={(heading + "-" + text + "-button")
                        ?.replaceAll(" ", "-")
                        ?.toLowerCase()}
                    >
                      <span>
                        <TranslateComponent>
                          {text}
                        </TranslateComponent>
                      </span>
                    </Button>
                  )
                })}
              </>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${style.modalBackdrop} ${style.fade} ${modal ? [style.in] : ""
          }`}
      ></div>
    </div>,
    document.body
  )
}

export default ModalDialog
