import {
  SecondaryNav,
  LeftPanel,
  SearchInput,
  RightPanel,
} from "src/blitz"
import styles from "./DigitalInspectionReport.module.scss"
import clsx from "clsx"
import { useState } from "react"
import {TranslateComponent} from "src/common/translations"


const DIReportSelectionSection = ({ loading }: any) => {
  const [searchText, setSearchText] = useState("")
  const handleSearch = (value: string) => {
    setSearchText(value)
  }


  return (
    <div className={clsx("col-sm", styles.reportListWrapper)}>
      <div className={clsx(styles.reportPanel)}>
        <SecondaryNav withBorder>
          <LeftPanel loading={loading}>
            <TranslateComponent>Select Report Section</TranslateComponent>
          </LeftPanel>
          <RightPanel loading={loading}>
            <SearchInput
              onInputChange={handleSearch}
              onInputClear={() => setSearchText("")}
              searchText={searchText}
            />
          </RightPanel>
        </SecondaryNav>
        {/* {if the report section exists for DIR will be added here} */}
        <div className={styles.panelList}></div>
      </div>
    </div>
  )
}

export default DIReportSelectionSection
