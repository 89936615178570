import { useImperativeHandle, forwardRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  getFilter,
  getIsValid,
  getReportStatus,
  getSelectedChillerProperties,
  getSelectedEquipments,
  setIsValid,
  setReportStatus
} from "src/redux/slicers/reportSlice"
import styles from "./ChillerPerformanceReport.module.scss"
import clsx from "clsx"
import { ACTIONS } from "src/constants"
import EquipmentList from "./EquipmentList"
import ChillerProperties from "./ChillerProperties"
import moment from "moment"
import { useMutation } from "src/hooks/APIHooks"
import { generateEquipmentPerformanceReport } from "../graphql"
import { REPORT_STATE } from "../constants"
import { downloadFileUsingS3 } from "src/utils/CommonMethods"
import { selectBuildingSummary } from "src/redux/slicers/buildingSummarySlice"
import { RECHECK_INTERVAL, RETRY_COUNT } from "src/constants/common"

const ChillerReport = forwardRef((props: any, ref: any) => {
  const { selectedLanguage, selectedMetric } = props
  useImperativeHandle(ref, () => ({
    onClickGenerate: generateReportHandler
  }))

  const dispatch = useDispatch()
  const { data: buildingSummary } = useSelector(selectBuildingSummary)
  const selectedEquipments: any = useSelector(getSelectedEquipments)
  const selectedProperties = useSelector(getSelectedChillerProperties)
  const { cancelled, error } = useSelector(getReportStatus)
  const [reportInfo, setReportInfo] = useState(REPORT_STATE)
  const { startDate, endDate, language, systemOfMeasure } =
    useSelector(getFilter)
  const isValid = useSelector(getIsValid)
  useEffect(() => {
    dispatch(
      setIsValid(
        Object?.keys(selectedEquipments)?.length > 0 &&
        selectedProperties?.length > 0
      )
    )
  }, [selectedEquipments, selectedProperties])
  useEffect(() => {
    let timeout = null
    if (cancelled || !reportInfo.url || error) {
      clearTimeout(timeout)
    } else if (reportInfo.retryCount === 0) {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, 0)
    } else {
      timeout = setTimeout(() => {
        checkReportIsFound()
      }, RECHECK_INTERVAL)
    }
    return () => {
      // clears timeout before running the new effect
      clearTimeout(timeout)
    }
  }, [cancelled, reportInfo])

  const checkReportIsFound = async () => {
    try {
      const response = await fetch(reportInfo.url)
      if (response?.status === 404) {
        setReportInfo({
          ...reportInfo,
          retryCount: reportInfo.retryCount + 1
        })
        if (reportInfo.retryCount + 1 >= RETRY_COUNT) {
          dispatch(setReportStatus(ACTIONS.ERROR))
        }
      } else if (response?.status === 200) {
        dispatch(setReportStatus(ACTIONS.COMPLETE))
        const fileName =
          buildingSummary?.name +
          "_" +
          moment(new Date(startDate)).format("MM-DD-YYYY") +
          "_" +
          selectedEquipments?.name +
          "_Chiller_Peformance"
        downloadFileUsingS3(reportInfo.url, fileName)
      } else {
        dispatch(setReportStatus(ACTIONS.ERROR))
      }
    } catch (e) {
      console.log(e)
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  }

  const { onSubmit: generateReportQuery } = useMutation({
    query: generateEquipmentPerformanceReport,
    onSuccess: (data: any) => {
      if (data?.data?.generateEquipmentPerformanceReport) {
        const { body } = JSON.parse(
          data?.data?.generateEquipmentPerformanceReport
        )
        console.log(JSON.parse(data?.data?.generateEquipmentPerformanceReport))
        setReportInfo({
          url: body,
          retryCount: 0
        })
      }
    },
    onError: () => {
      dispatch(setReportStatus(ACTIONS.ERROR))
    }
  })

  // **** Below method is invoked by ref **** /
  const generateReportHandler = () => {
    if (isValid) {
      dispatch(setReportStatus(ACTIONS.LOADING))
      const formattedStartDate = moment(new Date(startDate)).locale("en").format(
        "YYYY-MM-DD"
      )
      const formattedEndDate = moment(new Date(endDate)).locale("en").format("YYYY-MM-DD")
      const currentDate = moment(new Date(buildingSummary?.localTime)).locale("en").format(
        "YYYY-MM-DD"
      )
      generateReportQuery({
        requestBody: JSON.stringify({
          sections: selectedProperties,
          bId: buildingSummary?.id,
          bName: buildingSummary?.name,
          location: buildingSummary?.address,
          postalCode: buildingSummary?.postalCode,
          city: buildingSummary?.city,
          state: buildingSummary?.state,
          eId: selectedEquipments?.id,
          eName: selectedEquipments?.name,
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          current_date: currentDate,
          systemOfMeasure: selectedMetric?.metricCode,
          language: selectedLanguage?.langCode,
          report: "cpr"
        })
      })
    }
  }

  return (
    <div className={clsx(styles.chillerReportWrapper)}>
      <EquipmentList />
      <ChillerProperties
        systemOfMeasure={selectedMetric?.metricCode ? selectedMetric?.metricCode : systemOfMeasure} />
    </div>
  )
})

ChillerReport.displayName = "ChillerReport"
export default ChillerReport
