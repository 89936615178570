import { Link } from "react-router-dom"
import styles from "./Tabs.module.scss"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { trackEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import {TranslateComponent} from "src/common/translations"


const Tabs = ({ navData = [], getUpdatedNav = (data: any) => data }) => {
  const location = useLocation()
  const handlechange = (index: number) => {
    const updatedNav = navData.map((item, i) => {
      item.active = i === index
      return item
    })
    setNavs(updatedNav)
    getUpdatedNav(updatedNav)
  }
  const [navs, setNavs] = useState([])
  useEffect(() => {
    const updatedNav = navData.map((item) => {
      if (item?.link) {
        item.active = location.pathname?.split("/")?.includes(item?.pathname)
      }
      return item
    })
    setNavs(updatedNav)
  }, [navData, location.pathname])

  return (
    <ul className={`nav ${styles.navPills}`}>
      {navs?.map((data: any, navIndex) => {
        return (
          <li
            key={navIndex}
            className={clsx({
              [styles.active]: data?.active,
              [styles.disabled]: data?.disabled
            })}
          >
            <Link to={(data?.link) ? `${data?.link}` : "#"} data-testid={data?.testId} onClick={() => {
              handlechange(navIndex)
            }
            }>
              <span >
                <TranslateComponent>{data.name}</TranslateComponent>
                {data?.showCount && <span>{" (" + data?.count + ") "}</span>}
                {data?.icon && (
                  <span className={`btn-icon-right ${data?.icon}`}></span>
                )}
              </span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Tabs
