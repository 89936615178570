import { PRIMARY_OFFERINGS } from "../common/primary-offerings"
import { PRIMARY_OFFERING } from "../common/properties"
import { ADDITIONAL_PROPERTIES_TO_SEND, DEFAULT_PROPERTIES_TO_SEND_BY_EVENT } from "../common"

export const USER_EVENTS = {
	LOGIN : {
		categoryName: "Login",
		events: {
			USER_LOGIN: "User Login",
		}
	},
	EQUIPMENT_SUMMARY: {
		categoryName: "Equipment Summary",
		events: {
			VIEW_EQUIPMENT_SUMMARY: "View Equipment Summary",
			VIEW_EQUIPMENT_SERVICE_ADVISORY: "View Equipment Service Advisory List",
			VIEW_EQUIPMENT_DIGITAL_INSPECTION: "View Equipment Digital Inspection",
			VIEW_EQUIPMENT_PERFORMANCE: "View Equipment Performance",
			EQUIPMENT_NAVIGATE_TO_AUTOMATED_TEST: "Equipment Navigate to Automated Test",
		}
	},
	BUILDING_SUMMARY: {
		categoryName: "Building Summary",
		events: {
			VIEW_BUILDING_SUMMARY: "View Building Summary",
			VIEW_BUILDING_TILES: "View Building Tiles",
			NAVIGATE_ADMIN_SETTINGS: "Navigate to Admin Settings",
			CLICK_SUPPORT_FEEDBACK: "Click Support/Feedback",
			CLICK_LOGOUT: "Click Logout",
			SET_VIEW_DEFAULT: "Set View as Default",
			CLICK_MANAGE_MY_VIEWS: "Click Manage My Views",
			CLICK_SAVE_CURRENT_VIEW: "Click Save Current View",
			CLICK_CREATE_NEW_VIEW: "Click Create New View",
			CLICK_DELETE_VIEW: "Click Delete View",
			CLICK_EDIT_VIEW: "Click Edit View",
			SAVE_VIEW: "Save View",
			DELETE_VIEW: "Delete View",
			CHANGE_USER_VIEW: "Change User View",
			CHANGE_VIEW: "Change View",
			NAVIGATE_BUILDING: "Navigate to Building",
			TABLE_NAVIGATE_EQUIPMENT: "Table Navigate to Equipment",
		}
	},
	SERVICE_ADVISORY: {
		categoryName: "Service Advisory",
		events: {
			VIEW_SERVICE_ADVISORY: "View Service Advisory List",
			CREATE_FINDING_SERVICE: "Create Finding From Service Advisory",
			SET_STATUS_PROGRESS: "Set Status to In Progress",
			SET_STATUS_CLOSE: "Set Status to Close",
			SERVICE_ADVISORY_EVENT: "View Service Advisory Event Log",
		}
	},
	PERFORMANCE_CHART: {
		categoryName: "Performance Chart",
		events: {
			VIEW_PERFORMANCE: "View Performance",	
			CHANGE_TIME_PERIOD: "Change Time Period",
			CHANGE_CHART_TYPE: "Change Chart Type",
			CHANGE_SELECTED_EQUIPMENT: "Change Selected Equipment",
			CLICK_CHART_PRINT_EXPORT_OPTIONS: "Click Chart Print/Export Options",
			CLICK_CREATE_FINDING_FROM_CHART: "Click Create Finding From Chart",
			CREATE_FINDING_FROM_CHART: "Create Finding From Chart",
			EXPORT_CHART_IMAGE: "Export Chart as Image",
			EXPORT_CHART_DATA: "Export Chart as Data",
			PRINT_CHART: "Print Chart",
			COPY_CHART_URL: "Copy Chart URL",
			ADD_COMPARISON_CHART: "Add Comparison Chart",
			CLICK_ADD_PROPERTY: "Click Add Property",
			CLICK_EDIT_PROPERTY: "Click Edit Chart Property",
			ADD_PROPERTY: "Add Property",
			REMOVE_ALL_PROPERTIES: "Remove All Added Properties",
			CLICK_FORMAT_CHART: "Click Format Chart",
			FORMAT_CHART: "Format Chart",
		}
	},
	TRANECONNECT_REDIRECT: {
		categoryName: "TraneConnect Redirect",
		events: {
			VIEW_NOTES: "Redirect to View Notes",
			CREATE_NOTE: "Redirect to Create Note",
			AUTOMATED_TEST_SETTINGS: "Redirect to Automated Test Settings",
			AUTOMATED_TEST_SUPPRESSION: "Redirect to Automated Test Suppression",
			BUILDING_SETUP: "Redirect to Building Setup",
			EQUIPMENT_SETUP: "Redirect to Equipment Setup",
			REMOTE_ACCESS: "Redirect to Remote Access",
		}
	},
	REPORTS: {
		categoryName: "Reports",
		events: {
			CLICK_ALARMS_EVENTS: "Click Alarms and Events Report",
			CLICK_CHILLER_PERFORMANCE: "Click Chiller Performance Report",
			CLICK_DIGITAL_INSPECTION: "Click Digital Inspection Report",
			CLICK_EXCEPTION_HISTORY: "Click Exception History Report",
			CLICK_FACILITY_DETAILS: "Click Facility Details Report",
			CLICK_RAW_DATA: "Click Raw Data Report",
			CREATE_EXCEPTION_HISTORY_REPORT: "Create Exception History Report",
			CREATE_CHILLER_PERFORMANCE_REPORT: "Create Chiller Performance Report",
			CREATE_DIGITAL_INSPECTION_REPORT: "Create Digital Inspection Report",
			CREATE_RAW_DATA_REPORT: "Create Raw Data Report",
			CREATE_ALARMS_REPORT: "Create Alarms and Events Report",
			CANCEL_GENERATE_REPORT: "Cancel Generate Report",
		}
	}
}

export const PROPERTIES_TO_SEND_BY_EVENT = {
	//[USER_EVENTS.EQUIPMENT_SUMMARY.events.VIEW_EQUIPMENT_SUMMARY_LIST_PAGE]: { ...DEFAULT_PROPERTIES_TO_SEND_BY_EVENT, [PRIMARY_OFFERING]: PRIMARY_OFFERINGS.IS, ...ADDITIONAL_PROPERTIES_TO_SEND },
}
