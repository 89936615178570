export const getEquipmentSummaryById = /* GrapghQL */ `
  query GetEquipmentBuildingInfo($equipmentId: ID!) {
    getEquipment(id: $equipmentId) {
      id
      parentId
      name
      uiType
      type
      family
      controllerType
      imageUrl
      uri
      collectedDataTime
      modelName
      device {
        serial
      }
      building {
        id
        name
        address
        city
        state
        postalCode
        accountId
        floorArea
        tz
        lat
        long
        offeringCodes
        accountName
        offerings {
        items {
          name
          gracePeriod
          endDate
          startDate
          code
          status
          isActive
        }
      }
      }
    }
  }
`
export const equipmentWeatherInfo = /* GraphQL */ `
  query getWeatherInfo($buildingId: ID!) {
    listWeatherTimeSeriesData(
      buildingId: $buildingId
      limit: 1
      sortDirection: DESC
    ) {
      items {
        buildingId
        dateTime
        data
      }
    }
  }
`
export const searchAnalyticResults = /* GraphQL */ `
  query listAnalyticResultsByEquipmentAndTestDate(
    $equipmentId: ID!
    $startDate: String
    $endDate: String
  ) {
    listAnalyticResultsByEquipmentAndTestDate(
      equipmentId: $equipmentId
      testDate: { between: [$startDate, $endDate] }
    ) {
      items {
        id
        testDate
        piSummary
        sdAts
      }
    }
  }
`
export const listNotesByEquipment = /* GraphQL */ `
  query listNotesByEquipment($id: ID!) {
    listNotesByEquipment(equipmentId: $id) {
      items {
        id
      }
    }
  }
`
export const equipmentServiceAdvisoriesTotal = /* GraphQL */ `
  query GetequipmentServiceAdvisoriesTotal(
    $buildingId: ID!
    $equipmentId: ID!
  ) {
    searchAnalyticServiceAdvisories(
      aggregates: {
        field: equipmentId
        name: "Advisories per Equipment"
        type: terms
      }
      filter: {
        buildingId: { eq: $buildingId }
        equipmentId: { eq: $equipmentId }
      }
    ) {
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`
export const searchBuildingServiceAdvisoriesByEquipment = /* GraphQL */ `
  query searchBuildingServiceAdvisoriesByEquipment(
    $direction: SearchableSortDirection
    $field: SearchableAnalyticServiceAdvisorySortableFields
    $limit: Int
    $filter: SearchableAnalyticServiceAdvisoryFilterInput
    $nextToken: String
  ) {
    searchAnalyticServiceAdvisories(
      aggregates: {
        field: equipmentId
        name: "Advisories per Equipment"
        type: terms
      }
      filter: $filter
      sort: { direction: $direction, field: $field }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        daysFailed
        exceptionName
        equipmentName
        latestException
        testValue
        equipmentId
        status
        worstException
        firstException
        id
        chartId
        impactComfort
        impactEnergy
        impactReliability
        equipment {
          name
        }
        suggestion {
          suggestion
          testName
        }
      }
      aggregateItems {
        result {
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              doc_count
              key
            }
          }
        }
      }
      nextToken
    }
  }
`

export const searchBuildingServiceAdvisoriesAggregatesByEquipment = /* GraphQL */ `
  query searchBuildingServiceAdvisories(
    $filter: SearchableAnalyticServiceAdvisoryFilterInput
    $limit: Int
    $aggregates: [SearchableAnalyticServiceAdvisoryAggregationInput]
  ) {
    searchAnalyticServiceAdvisories(
      limit: $limit
      filter: $filter
      aggregates: $aggregates
    ) {
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            __typename
            buckets {
              key
            }
          }
        }
      }
    }
  }
`

export const downloadEquipMentServiceAdvisoriesReport = /* GraphQL */ `
  mutation generateEHRDataReport($requestBody: AWSJSON) {
    generateEHRDataReport(requestBody: $requestBody)
  }
`

export const getXmlFileNames = /* GraphQL */ `
  query getXmlFileNames(
    $buildingId: String
    $deviceSerial: String
    $fileType: String
    $startDate: AWSDate
  ) {
    downloadIngestionInputFiles(
      deviceSerial: $deviceSerial
      fileType: $fileType
      startDate: $startDate
      buildingId: $buildingId
    )
  }
`
export const getXmlData = /* GraphQL */ `
  query getXmlData(
    $buildingId: String
    $deviceSerial: String
    $startDate: AWSDate
    $fileNames: [String]
  ) {
    downloadIngestionInputFiles(
      deviceSerial: $deviceSerial
      startDate: $startDate
      buildingId: $buildingId
      fileNames: $fileNames
    )
  }
`
export const getEquipmentProperties = /* GraphQL */ `
  query GetEquipmentProperties($equipmentId: ID!) {
    getEquipment(id: $equipmentId) {
      equipmentProperties(propertyType: { eq: "Property" }) {
        items {
          sourceKey
          targetKey
          sourceUri
          tisDisplayName
          sourceDisplayName
          sourceMeasureType
          sourceMeasureUnit
          sourceDeviceId
          sourceDataGroup
          propertyType
        }
      }
    }
  }
`
export const getMappedEquipmentProperties = /* GraphQL */ `
  query GetEquipmentProperties($equipmentId: ID!) {
    getEquipment(id: $equipmentId) {
      equipmentProperties {
        items {
          sourceKey
          targetKey
          sourceUri
          tisDisplayName
          sourceDisplayName
          sourceMeasureType
          sourceMeasureUnit
          sourceDeviceId
          sourceDataGroup
          propertyType
        }
      }
    }
  }
`

export const EquipmentDataQualityHandler = /* GraphQL */ `
  query EquipmentDataQualityHandler(
    $buildingId: String!
    $equipmentId: String!
    $date: String
  ) {
    getEquipmentDataQuality(
      buildingId: $buildingId
      equipmentId: $equipmentId
      date: $date
    )
  }
`

export const getPropertyMappingByEquipmentID = /* GraphQL */ `
  query GET_DATA_CHARACTERISTICS($id: ID!) {
    getPropertyMappingByEquipmentID(
      equipmentId: $id
      propertyType: { eq: "Characteristic" }
      limit: 1000
    ) {
      items {
        value
        targetKey
      }
    }
  }
`
export const fetchListAnalytics = /* GraphQL */ `
  query listAnalyticConfigs(
    $filter: ModelAnalyticConfigFilterInput
    $limit: Int
  ) {
    listAnalyticConfigs(filter: $filter, limit: $limit) {
      items {
        equipmentType
        exceptionInputs
      }
    }
  }
`

export const get_DI_Report_Details = /* GraphQL */ `
mutation getDIReportDetails($requestBody: AWSJSON) {
  generateEquipmentInspectionReport(requestBody: $requestBody)
}
`
export const getDeviceSerialDataByBuilding = /* GraphQL */ `
query GetBuilding($id: ID!) {
  getBuilding(id: $id) {
    devices {
      items {
        serial
        name
        type
        isActive
      }
    }
  }
}`
