import { CUSTOM_RANGE, FOUR_WEEKS, ONE_DAY, ONE_MONTH, ONE_WEEK } from "src/blitz/DateRange/constant"
import { t } from "src/translations/help"

export const MAX_DATA_CELLS_QUANTITY = 2500000
export const DATA_INTERVAL_MINUTES = 15
export const RAW_DATA = "raw-data"
export const EXCEPTION_REPORT = "exception-history"
export const CHILLER_REPORT = "chiller-history"
export const ALARMS_AND_EVENTS_REPORT = "alarms-and-events"
export const DIGITAL_INSPECTION_REPORT = "digital-inspection"
export const DIGITAL_INSPECTION_REPORT_V2 = "digital-inspection-v2"

export const SELECT_ALL = [
  {
    title: "All",
    value: "all",
    type: "button"
  }
]

export const OPTION_ONE_MONTH = {
  title: "1 Month",
  value: ONE_MONTH,
  type: "button"
}
export const OPTION_ONE_DAY = {
  title: t("common.oneDay"),
  value: ONE_DAY,
  type: "button"
}
export const OPTION_ONE_WEEK = {
  title: t("common.oneWeek"),
  value: ONE_WEEK,
  type: "button"
}

export const OPTION_FOUR_WEEKS = {
  title: t("common.fourWeeks"),
  value: FOUR_WEEKS,
  type: "button"
}

export const OPTION_CUSTOM = {
  title: t("common.customRange"),
  value: CUSTOM_RANGE,
  type: "button"
}


export const defaultTimePeriodOptions = [
  OPTION_ONE_DAY,
  OPTION_ONE_WEEK,
  OPTION_FOUR_WEEKS,
  OPTION_CUSTOM
]

export const REPORT_STATE = {
  url: "",
  retryCount: 0
}

export const IsMultipleSingleFormat = "mxlsx"
export const NONE = "None"
export const CHARACTERISTIC = "Characteristic"
export const LOAD_VALVE = "LoadValve"
export const VAV_BOX = "VAV-BOX"

export const STANDALONE = "Standlone"

export const LANG_ENGLISH = "en"
export const LANG_FRENCH = "fr-CA"
export const IMPERIAL_MEASURE = "IP"
export const METRIC_MEASURE = "SI"
export const CHILLER = "Chiller"

export const OTHER_LANG_CODE: any = {
  BG: "bg",
  HR: "hr",
  CS: "cs",
  DA: "da",
  NL: "nl",
  ET: "et",
  FI: "fi",
  FR: "fr",
  FR_CA: "fr-CA",
  DE: "de",
  EL: "el",
  HU: "hu",
  IT: "it",
  LV: "lv",
  LT: "lt",
  NO: "no",
  PL: "pl",
  PT: "pt",
  PT_PT: "pt-PT",
  RO: "ro",
  RU: "ru",
  SK: "sk",
  SL: "sl",
  ES: "es",
  ES_MX: "es-MX",
  SV: "sv",
  TR: "tr"
}

export const DIOfferingsMappedToEquipmentTypes = {
  "DI-CH": "Chiller",
  "DI-AHU": "AHU",
}

export const DIOfferings = ["CAP", "BP"]

export const reportTimePeriodOptions = {
  [CHILLER_REPORT]: [OPTION_ONE_MONTH],
  [DIGITAL_INSPECTION_REPORT]: [
    OPTION_ONE_WEEK,
    OPTION_FOUR_WEEKS,
    OPTION_CUSTOM
  ],
  [RAW_DATA]: defaultTimePeriodOptions,
  [EXCEPTION_REPORT]: defaultTimePeriodOptions,
  [ALARMS_AND_EVENTS_REPORT]: defaultTimePeriodOptions
}

export const systemOfMeasureOptions: any = [
  {
    title: "Imperial(IP)",
    value: IMPERIAL_MEASURE,
    type: "button"
  },
  {
    title: "Metric(SI)",
    value: METRIC_MEASURE,
    type: "button"
  }
]

export const languageOptions: any = [
  {
    title: "English",
    value: LANG_ENGLISH,
    type: "button"
  },
  // {
  //   title: "Arabic",
  //   value: "ar",
  //   type: "button"
  // },
  {
    title: "Bulgarian",
    value: OTHER_LANG_CODE.BG,
    type: "button"
  },
  {
    title: "Croatian",
    value: OTHER_LANG_CODE.HR,
    type: "button"
  },
  {
    title: "Czech",
    value: OTHER_LANG_CODE.CS,
    type: "button"
  },
  {
    title: "Danish",
    value: OTHER_LANG_CODE.DA,
    type: "button"
  },
  {
    title: "Dutch", //"Nederlands", 
    value: OTHER_LANG_CODE.NL,
    type: "button"
  },
  {
    title: "Estonian",
    value: OTHER_LANG_CODE.ET,
    type: "button"
  },
  {
    title: "Finnish",
    value: OTHER_LANG_CODE.FI,
    type: "button"
  },
  {
    title: "French", //"Français", 
    value: OTHER_LANG_CODE.FR,
    type: "button"
  },
  {
    title: "French (Canada)", //"Français(Canada)",
    value: OTHER_LANG_CODE.FR_CA,
    type: "button"
  },
  {
    title: "German", //"Deutsch", 
    value: OTHER_LANG_CODE.DE,
    type: "button"
  },
  {
    title: "Greek",
    value: OTHER_LANG_CODE.EL,
    type: "button"
  },
  // {
  //   title: "Hebrew", //עברית
  //   value: "he",
  //   type: "button"
  // },
  {
    title: "Hungarian", //"Magyar", 
    value: OTHER_LANG_CODE.HU,
    type: "button"
  },
  {
    title: "Italian", //"Italiana",
    value: OTHER_LANG_CODE.IT,
    type: "button"
  },
  {
    title: "Latvian",
    value: OTHER_LANG_CODE.LV,
    type: "button"
  },
  {
    title: "Lithuanian",
    value: OTHER_LANG_CODE.LT,
    type: "button"
  },
  {
    title: "Norwegian",
    value: OTHER_LANG_CODE.NO,
    type: "button"
  },
  {
    title: "Polish",
    value: OTHER_LANG_CODE.PL,
    type: "button"
  },
  {
    title: "Portuguese (Brazil)", //"Português(Brasil)", 
    value: OTHER_LANG_CODE.PT,
    type: "button"
  },
  {
    title: "Portuguese (Portugal)", //"Português(Portugal)", 
    value: OTHER_LANG_CODE.PT_PT,
    type: "button"
  },
  {
    title: "Romanian",
    value: OTHER_LANG_CODE.RO,
    type: "button"
  },
  {
    title: "Russian",
    value: OTHER_LANG_CODE.RU,
    type: "button"
  },
  {
    title: "Slovak",
    value: OTHER_LANG_CODE.SK,
    type: "button"
  },
  {
    title: "Slovenian",
    value: OTHER_LANG_CODE.SL,
    type: "button"
  },
  {
    title: "Spanish", //"Española", 
    value: OTHER_LANG_CODE.ES,
    type: "button"
  },
  {
    title: "Spanish (Mexico)", //"Español (México)", 
    value: OTHER_LANG_CODE.ES_MX,
    type: "button"
  },
  {
    title: "Swedish",
    value: OTHER_LANG_CODE.SV,
    type: "button"
  },
  {
    title: "Turkish",
    value: OTHER_LANG_CODE.TR,
    type: "button"
  }
]
