import clsx from "clsx"
import { Fragment } from "react"
import { v4 as uuidv4 } from "uuid"
import Typography from "../Typography"
import styles from "./RadioGroup.module.scss"
import { HighLightText } from "./../"
import { isArray } from "lodash"
import { TranslateComponent } from "src/common/translations"

type RadioOption = {
  disabled?: boolean
  label: string
  name?: string
  radioId?: string
  value?: string
  radioClassName?: string
  testId?: string
  iconClassName?: string
  translateCurrentValueOff?:boolean
}
type RadioOptions = {
  radioGroupClassName?: string
  options: RadioOption[]
  layout?: "row" | "column"
  selected: any
  handleChange: any
  highLightText?: string
  translateCurrentValueOff?:boolean
}
const RadioGroup = ({
  options,
  layout,
  selected,
  handleChange,
  radioGroupClassName = "",
  highLightText = "",
  translateCurrentValueOff=false
}: RadioOptions) => {
  return (
    <Fragment>
      <div
        className={clsx(radioGroupClassName, {
          ["d-inline-flex"]: layout === "row",
          ["d-flex"]: layout === "column",
          [styles.radioColumn]: layout === "column"
        })}
      >
        {options?.map((option: RadioOption, key) => {
          const id = uuidv4()
          const {
            disabled = false,
            label = "",
            name,
            radioId = id,
            value,
            radioClassName = "",
            testId = "",
            iconClassName = ""
          } = option

          const radioLabel =translateCurrentValueOff? label || name : <TranslateComponent>{label || name}</TranslateComponent>
          return (
            <div key={key} className={clsx(styles.radioButton, radioClassName)}>
              <input
                type="radio"
                name={name}
                value={value}
                id={radioId}
                disabled={disabled}
                checked={isArray(selected) ? selected.some(item => item?.id === value) : selected === value}
                onClick={() => handleChange(value)}
                data-testid={testId}
              />
              <Typography tagType="label" styleType="p4" htmlFor={radioId}>
                <>
                  <span className="drop-shadow"></span>
                  {highLightText?.length > 0 ? (
                    <div className={styles.highlightWrapper}>
                      <HighLightText
                        name={radioLabel}
                        searchText={highLightText}
                        translateLabel={true}
                      /></div>
                  ) : (
                    radioLabel
                  )}
                </>
              </Typography>
              <Typography tagType="label" styleType="p1" className={styles?.radioIconSize} >
                <span className={iconClassName}></span>
              </Typography>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export default RadioGroup
