import { isArray, isNumber, isString, toInteger, toNumber } from "lodash"
import {
  DEFAULT_SIZE,
  DOT_SHAPE,
  LEVEL_ONE,
  SOLID_LINE
} from "src/blitz/Charts/constants"
import {
  chartDateFormat,
  constantValues,
  editableProperties,
  reduxStoreTimeStampFormat,
} from "../../constant"
import {
  assignAttributeStateToLegends,
  assignAttributeStateToTLLegends,
  convertTimeToMinutes,
  generateLegend,
  getChartObject,
  getTimeIndex,
  intervalToMinutes,
  legendIDGenerator,
  legendName
} from "../common"
import moment from "moment"
import { TIMELINE_PROPERTY_TYPE, transformTimeLinechartNew } from "./timeLineChart"
import { LINE_TYPE, MEAN_LINE_TYPE } from "./lineChart"
import { generateExceptionBars } from "../Exceptions/index"
import { generateExceptionLegends } from "../Exceptions/helper"
import { generateSuppressionLegends } from "../Suppressions/helper"
import { generateSuppressionBars } from "../Suppressions"

export const MEAN_OF_ALL_ID = "meanOfAll"
export const transformLineWithControlRangeAndMeanchart = ({
  tz,
  startDate,
  endDate,
  intervalMinutes = "15m",
  inputData,
  chartInfo,
  equipmentList,
  equipmentId,
  added,
  oldLegends,
  oldTimeLineLegends = null,
  assignedAttributes = [],
  legendAttributesCombination
}) => {
  const defaultBoundaryRange = {
    above: 95,
    upper: 65,
    low: 0
  }
  const timestamps = []
  const calculatedMin = intervalToMinutes(intervalMinutes)
  const data = inputData?.data || {}
  const equipmentType = chartInfo?.equipmentType
  const settings = inputData?.settings || {}
  const addedAxisList = added?.axisList || []
  const legends = {}
  let timeLineLegends = {}
  const additionalInformation = {}
  const newData = {}

  const localTimeStamp = Boolean(tz)
    ? moment(
      moment.tz(moment(), tz).format("MM-DD-YYYY HH:mm"),
      "MM-DD-YYYY HH:mm"
    )
    : moment()
  const endTimestamp = moment(endDate, chartDateFormat).add(1, "days")
  let oneLoopCompleted = false
  for (const id in data) {
    const paramData = data[id]
    const equipmentName = equipmentList?.find((obj) => obj?.id === id)?.name
    const selectedEquipment = equipmentId === id
    const properties = paramData?.properties
    const legendEquipmentType = equipmentList?.find((obj) => obj?.id === id)?.type

    for (const propertyKey in properties) {
      const property = properties[propertyKey]
      const propertyIndex = property?.index
      const propertyName = property?.name || ""

      let isAdded = false
      let axis = chartInfo?.axisConfig?.find((obj) =>
        getChartObject(obj, propertyKey, equipmentType)
      )
      const legendId = legendIDGenerator(id, propertyKey)
      const addedAxis = addedAxisList?.find((addedObj) => (addedObj?.added?.[legendEquipmentType] || [])?.includes(legendId))
      if (addedAxis) {
        axis = addedAxis
        isAdded = true
      }
      let legendObj: any = {}

      if (axis?.axisName === "timeline") {
        const name = legendName({ selectedEquipment, propertyName, equipmentName })
        legendObj = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, propertyKey, type: "timeline", oldLegends: oldTimeLineLegends, propertyType: TIMELINE_PROPERTY_TYPE })
        timeLineLegends[legendId] = legendObj
      } else {
        const name = legendName({ selectedEquipment, propertyName, equipmentName }, [isAdded ? true : false, true])
        legendObj = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, type: LINE_TYPE, isAdded, legendEquipmentType, oldLegends })
        legends[legendId] = legendObj
      }
      let currentTimestamp = moment(startDate, chartDateFormat)

      let prevValue = null
      while (
        currentTimestamp.isSameOrBefore(endTimestamp) &&
        currentTimestamp.isSameOrBefore(localTimeStamp)
      ) {
        const dateKey = currentTimestamp.format("YYYY-MM-DD")
        const extractTime = currentTimestamp.format("HH:mm")
        const timetoMin = convertTimeToMinutes(extractTime)
        const timeIndexPosition = getTimeIndex(timetoMin, calculatedMin)

        const dateAvailable = paramData?.values?.[dateKey]

        let finalValue
        if (dateAvailable) {
          const valueArray = paramData?.values?.[dateKey]?.[timeIndexPosition]
          const valueArrayIsArray = isArray(valueArray)
          const arrayValues = valueArrayIsArray ? valueArray : []
          const value = arrayValues[propertyIndex]

          const isNull =
            constantValues?.includes(value) ||
            value?.toString()?.startsWith("_")
          const isTimeline = axis?.axisName === "timeline"

          const checkedValue =
            isNull || (isTimeline ? false : !isFinite(toNumber(value)))
              ? null
              : isTimeline
                ? value
                : parseFloat(value)

          if ((isString(value) && value?.length === 0) || !valueArrayIsArray) {
            finalValue = prevValue
          } else {
            finalValue = checkedValue
          }

          if (timeIndexPosition === 95) {
            prevValue = null
          } else {
            prevValue = finalValue
          }
        } else {
          finalValue = null
          prevValue = null
        }
        const dateVal = currentTimestamp.format(reduxStoreTimeStampFormat)

        if (!newData?.[legendId]) {
          newData[legendId] = {
            data: []
          }
        }
        newData[legendId]?.data.push(finalValue)
        if (!oneLoopCompleted) {
          timestamps.push(dateVal)
        }
        currentTimestamp = currentTimestamp.add(calculatedMin, "minutes")
      }
      oneLoopCompleted = true
    }
  }

  const mean = []
  const arrayOfArrays = []
  Object.keys(legends)?.forEach((key) => {
    const legend = legends?.[key]
    if (legend?.type === "line" && !legend?.isAdded) {
      const data = newData?.[legend?.id]?.data || [];
      arrayOfArrays.push(data);
    }
  })

  const paramCount = arrayOfArrays?.length
  const numOfColumns = arrayOfArrays?.[0]?.length
  for (let j = 0; j < numOfColumns; j++) {
    let columnSum = 0
    for (let i = 0; i < paramCount; i++) {
      if (isNumber(arrayOfArrays?.[i]?.[j])) {
        columnSum += arrayOfArrays?.[i]?.[j]
      }
    }
    mean[j] = isFinite(toNumber(columnSum)) ? Math.round(columnSum / paramCount) : ""
  }

  const noDataArray = Object.keys(legends)?.map((key) => {
    const noData = (newData?.[key]?.data || [])?.every(
      (e) => !Boolean(e) && e !== 0
    )
    return noData
  })

  const isNoData = noDataArray?.every((e) => Boolean(e))

  assignAttributeStateToLegends(legends, oldLegends, assignedAttributes, legendAttributesCombination)

  const axisList = chartInfo?.axisConfig?.filter(
    (obj) => obj?.axisName !== "timeline" && obj?.axisName !== "exceptions"
  )

  const finalAxisList = [...axisList]

  addedAxisList?.forEach((newObj) => {
    const axisAvailable = finalAxisList?.find(
      (obj) => obj?.axisName === newObj?.axisName
    )
    if (!axisAvailable) {
      finalAxisList.push(newObj)
    }
  })

  // Create/update Exception Legends
  const exceptionData = inputData?.exceptions?.data || {}
  const exceptionLegends = generateExceptionLegends({ exceptionData, chartInfo, equipmentList, selectedEquipID: equipmentId, oldTimeLineLegends })

  // Create/update Suppression Legends
  const suppressionData = inputData?.exceptions?.suppressed?.data || {}
  const suppressionLegends = generateSuppressionLegends({ suppressionData, chartInfo, oldTimeLineLegends })

  // Assign Colors to new time line legends of all kinds
  assignAttributeStateToTLLegends(timeLineLegends, exceptionLegends, suppressionLegends, assignedAttributes, legendAttributesCombination, oldTimeLineLegends)


  const { timeLineBars, totalLanes } = transformTimeLinechartNew(
    timeLineLegends,
    timestamps,
    newData
  )

  const exceptionSampling = inputData?.exceptions?.sampling || 15
  const {
    exceptionsBars,
    exceptionLanes
  } = generateExceptionBars(
    exceptionData,
    exceptionLegends,
    exceptionSampling,
    chartInfo,
    totalLanes,
    startDate,
    endTimestamp,
    localTimeStamp
  )

  const suppressed = inputData?.exceptions?.suppressed?.data && inputData?.exceptions?.suppressed?.data[equipmentId] || {}
  const { suppressionBars } =
    generateSuppressionBars(
      suppressed,
      suppressionLegends,
      exceptionLanes,
      startDate,
      endTimestamp.format("YYYY-MM-DD")
    )

  const bars = [
    ...timeLineBars,
    ...exceptionsBars,
    ...suppressionBars
  ]
  const isNoTimeLineData = bars?.every(
    (obj) => !Boolean(obj?.value) || obj?.value === "No Data"
  )

  timeLineLegends = {
    ...timeLineLegends,
    ...exceptionLegends,
    ...suppressionLegends
  }

  const meanLegendObj = {
    id: MEAN_OF_ALL_ID,
    name: "Mean of All",
    color: "yellow",
    axisName: "y1",
    shapeSize: LEVEL_ONE,
    lineType: SOLID_LINE,
    shape: DOT_SHAPE,
    strokeWidth: LEVEL_ONE,
    show: true,
    visible: true,
    type: MEAN_LINE_TYPE,
    defaultSettings: {}
  }

  const keys = Object.keys(meanLegendObj) || []
  editableProperties?.forEach((attribute: string) => {
    if (keys?.includes(attribute)) {
      meanLegendObj.defaultSettings[attribute] = meanLegendObj?.[attribute]
    }
  })
  legends[meanLegendObj?.id] = meanLegendObj

  newData[MEAN_OF_ALL_ID] = {
    data: mean
  }

  const propKeys = Object.keys(settings)
  if (propKeys?.length > 0) {
    additionalInformation["settings"] = {}
    let boundaryIndex = 0
    do {
      const key = propKeys[boundaryIndex - 1]?.toLowerCase()
      const initialIndex = boundaryIndex === 0
      const isLow = key?.includes("low")
      const settingsKey = initialIndex ? "low" : isLow ? "upper" : "above"
      const value = isNumber(toInteger(settings[propKeys[boundaryIndex - 1]]))
        ? settings[propKeys[boundaryIndex - 1]]
        : defaultBoundaryRange[settingsKey]
      const settingsValue = initialIndex ? 0 : value
      additionalInformation["settings"][settingsKey] = toNumber(settingsValue)
      const legendObj = {
        id: initialIndex ? "low" : key,
        name: initialIndex
          ? "Lower Control Range"
          : isLow
            ? "Upper Control Range"
            : "Above Control Range",
        color: initialIndex ? "#87b1e1" : isLow ? "#67d25b" : "#ff001a",
        settingsKey: settingsKey,
        axisName: "y1",
        shapeSize: DEFAULT_SIZE,
        lineType: SOLID_LINE,
        shape: DOT_SHAPE,
        strokeWidth: LEVEL_ONE,
        show: true,
        visible: true,
        type: "boundary",
        boundaryPosition: initialIndex ? 0 : isLow ? 1 : 2,
        defaultSettings: {}
      }
      const keys = Object.keys(legendObj) || []
      editableProperties?.forEach((attribute: string) => {
        if (keys?.includes(attribute)) {
          legendObj.defaultSettings[attribute] = legendObj?.[attribute]
        }
      })
      legends[legendObj?.id] = legendObj
      boundaryIndex++
    } while (boundaryIndex <= propKeys?.length)
  } else {
    additionalInformation["settings"] = defaultBoundaryRange
  }

  additionalInformation["timeLineBars"] =
    isNoTimeLineData && isNoData ? [] : bars
  additionalInformation["timeLineLegends"] = timeLineLegends
  additionalInformation["timestamps"] = isNoData ? [] : timestamps


  return {
    chartData: {
      data: isNoData ? {} : newData
    },
    chartProps: {
      legends,
      axisList: finalAxisList,
      toggleSwitch: true,
      noDataAvailable: isNoData && isNoTimeLineData ? true : false,
      isdataprocessed: true,
      ...additionalInformation
    }
  }
}
