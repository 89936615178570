import React, { useState } from "react"
import style from "./SearchFilter.module.scss"
import { Button } from "src/blitz"
import { IDefaultSearchFilter } from "./types"

const SearchFilter = (props: IDefaultSearchFilter) => {
  const {
    searchByLabel = "",
    onChangeInput,
    onClickSearch = () => null,
    defaultInputValue = "",
    className = "",
    searching = false
  } = props
  //   const [selectedType, selectType] = useState(defaultSearchType)
  const [searchInput, setInputValue] = useState(defaultInputValue)

  const onChangeSearchInput = (value: string) => {
    setInputValue(value)
    onChangeInput && onChangeInput(value)
  }

  return (
    <div className={`${style.searchFilter} ${className}`}>
      <p>{searchByLabel ? searchByLabel : "Search By Name"}</p>
      <div className={style.inputSearchContainer}>
        <input
          type="text"
          value={searchInput}
          onChange={(e: any) => onChangeSearchInput(e.target.value)}
        />
        <div
          title="Clear Search"
          className={style.inputClearSearch}
          onClick={() => onChangeSearchInput("")}
        >
          <span className="clear icon icon-critical"></span>
        </div>
      </div>
      <Button
        type="button"
        onClick={() => onClickSearch(searchInput)}
        hoverVariant="dark"
        btnSize="mini"
        className={style.searchFilterButton}
      >
        {searchInput && searching ? "Searching..." : "Search"}
      </Button>
    </div>
  )
}

export default SearchFilter
