import amplitudeJs from "amplitude-js";
import { amplitudeKey } from "./aws-exports";
import store from "src/redux/Store.ts"
import { PROPERTIES_TO_SEND_BY_EVENT } from "src/amplitude-categories"

const API_KEY = amplitudeKey 

const amplitude = amplitudeJs.getInstance()

amplitude.init(API_KEY, null, {
    // optional configuration options
      includeUtm: true,
      includeGclid: true,
      includeReferrer: true,
    });
    const identifyEvent = (data) =>
    {
        const userDataToSend = {
            "user id": store?.getState()?.userData?.userInfo?.data?.id,
            "user type": store?.getState()?.userData?.userInfo?.data?.type?.name,
            "role names": store?.getState()?.userData?.roles,
            "client id": "Command Center",
            "job title": store?.getState()?.userData?.userInfo?.data?.jobTitle,
            "primary sales office" : store?.getState()?.userData?.userInfo?.data?.primarySalesOfficeId,
            "version": process.env?.REACT_APP_AWS_JOB_ID
        }  
        amplitude.setUserProperties(data ? data : userDataToSend)
        amplitude.setUserId(store?.getState()?.appData?.userInfo?.data?.id)
    }
    
    const trackEvent = (event, options = {}) => {
        const userDataToSend = {
            /*userId: store?.getState()?.appData?.userInfo?.data?.id,
            userTypeId: store?.getState()?.appData?.userInfo?.data?.userTypeId,
            userTypeName: store?.getState()?.appData?.userInfo?.data?.type?.name,
            userRoles: store?.getState()?.appData?.roles*/
            "client id": "Command Center",
        }
        const mergedProperties = {...PROPERTIES_TO_SEND_BY_EVENT[event] || {}, ...userDataToSend}
        //const mergedProperties = {}
        amplitude.logEvent(event, {
            ...Object
                .keys(mergedProperties || {})
                .reduce((result, key) => typeof mergedProperties[key] === "function"
                    ? { ...result, [key]: mergedProperties[key]() }
                    : { ...result, [key]: mergedProperties[key] },
                    {}),
            ...options
        })
    }
    
    export { amplitude, trackEvent, identifyEvent };