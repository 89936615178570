import { environmentHelper } from "./helper"
import config from "../aws-exports"

export const getConfig = () => {
  let environmentSpecificParams = {}
  Object.keys(environmentHelper).forEach((key) => {
    environmentSpecificParams = {
      ...environmentSpecificParams,
      ...environmentHelper[key].call(config || {})
    }
  })
  return {
    ...(config || {}),
    externalLinks: { ...environmentSpecificParams }
  }
}
