import { useState, useEffect } from "react"
import { API } from "aws-amplify"
import { get } from "lodash"
// import { getPropByPath } from "src/utils/CommonMethods"
interface IUseQuery {
  query: any
  variables?: any
  onSuccess?: any
  onError?: any
  dataPath?: string
  disableInitialLoad?: boolean
  errorPolicy?: "none" | "all" | "ignore"
  isHeadLess?: boolean
  returnRequired?: boolean
}
interface IUseMutation {
  query: any
  onSuccess?: any
  onError?: any
  dataPath?: string
  throwException?: boolean
  requireReturnValue?: boolean
}

export const useQuery = ({
  query,
  variables,
  onSuccess,
  onError,
  dataPath,
  disableInitialLoad = false,
  errorPolicy = "none",
  isHeadLess = false,
  returnRequired = false
}: IUseQuery) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [responseTime, setResponseTime] = useState(null)

  useEffect(() => {
    if (!disableInitialLoad) {
      fetchData(variables)
    }
  }, [disableInitialLoad])

  const fetchData = async (variablesParam?: any): Promise<any> => {
    let response: any = {}
    let apiData: any = {}
    let data: any
    let errorOccured: any = false
    const startTime: any = new Date()
    setResponseTime(null)
    setLoading(true)
    setError(null)
    try {
      const payload: any = {
        query,
        variables: variablesParam ? variablesParam : variables
      }
      if (!isHeadLess) {
        payload.authMode = "AMAZON_COGNITO_USER_POOLS"
      }
      apiData = await API.graphql(payload)
      response = apiData
      if (apiData && !apiData.errors) {
        if (dataPath?.length) {
          apiData = get(apiData, dataPath, null)
          // apiData = getPropByPath(apiData, dataPath, null)
        }
        setData(apiData)
        data = apiData
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(apiData)
        }
      } else {
        redirectToLogin(apiData?.errors)
        setData(null)
        data = null
        setError(apiData.errors)
        if (onError && typeof onError === "function") {
          onError(error)
        }
        errorOccured = true
      }
    } catch (errorData) {
      if (errorData.data) {
        if (errorPolicy === "all") {
          let apiData = errorData
          if (dataPath?.length) {
            // apiData = getPropByPath(apiData, dataPath, null)
            apiData = get(apiData, dataPath, null)
            setData(apiData)
            data = apiData
          } else {
            setData(apiData.data)
            data = apiData.data
          }
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess(apiData)
          }
        } else if (errorPolicy === "ignore") {
          let apiData = errorData
          if (dataPath?.length) {
            // apiData = getPropByPath(apiData, dataPath, null)
            apiData = get(apiData, dataPath, null)
            setData(apiData)
            data = apiData
          } else {
            setData(apiData.data)
            data = apiData.data
          }
          if (onSuccess && typeof onSuccess === "function") {
            onSuccess(apiData)
          }
        } else {
          setData(null)
          data = null
          setError(errorData)
          if (onError && typeof onError === "function") {
            onError(errorData)
          }
          errorOccured = true
        }
      } else {
        setData(null)
        data = null
        setError(errorData)
        if (onError && typeof onError === "function") {
          onError(errorData)
        }
        errorOccured = true
      }
    }
    setLoading(false)
    const endTime: any = new Date()
    const timeDiff: any = endTime - startTime
    setResponseTime(timeDiff)
    if (returnRequired) {
      return {
        response: response,
        dataPath: dataPath,
        data: data,
        error: errorOccured
      }
    }
  }

  return {
    data,
    error,
    loading,
    refetch: fetchData,
    setError: setError,
    setData: setData,
    responseTime
  }
}

export const useMutation = ({
  query,
  onSuccess,
  onError,
  dataPath,
  throwException = false,
  requireReturnValue = false
}: IUseMutation) => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [responseTime, setResponseTime] = useState(null)

  const updateData = async (
    variablesParam: any,
    extraParamsPass: any = null
  ) => {
    let apiData: any = {}
    const startTime: any = new Date()
    setLoading(true)
    setError(null)
    try {
      apiData = await API.graphql({
        query,
        variables: variablesParam
      })
      setLoading(false)
      const endTime: any = new Date()
      const timeDiff: any = endTime - startTime
      setResponseTime(timeDiff)
      if (apiData && !apiData.errors) {
        if (dataPath?.length) {
          apiData = get(apiData, dataPath, null)
        }
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess(apiData, extraParamsPass)
          if (requireReturnValue) {
            return {
              apiData,
              extraParamsPass
            }
          }
        }
      } else {
        redirectToLogin(apiData?.errors)
        setError(apiData.errors)
        if (onError && typeof onError === "function") {
          onError(error, extraParamsPass)
        }
        if (throwException) throw error
      }
    } catch (error) {
      setLoading(false)
      const endTime: any = new Date()
      const timeDiff: any = endTime - startTime
      setResponseTime(timeDiff)
      setError(error)
      if (onError && typeof onError === "function") {
        onError(error, extraParamsPass)
      }
      if (throwException) throw error
      console.log("error has occurred", error)
    }
  }

  return {
    error,
    loading,
    onSubmit: updateData,
    setError: setError,
    responseTime
  }
}
const redirectToLogin = (errors: any) => {
  const isExpired =
    errors?.[0]?.errorType === "UnauthorizedException" ||
    errors?.[0]?.message === "Token has expired"
  if (isExpired) window.location.reload()
}
