import { OFFERING_STATUS } from "src/components/buildingSummary/constants";
import { t } from "src/translations/help"
import { getDateByDateString, currentDate } from "src/utils/CommonMethods"


const OFFERING_STATUS_TO_DISPLAY = [OFFERING_STATUS.APPROVED, OFFERING_STATUS.EXPIRED];

export const checkIsOfferingValid = (date: string | Date, tz: string) =>
    date ? currentDate(tz).isBefore(getDateByDateString(date, tz)) : true


export const getExpiredStatus = (offerings: any, tz: string) => {
    const { endDate, gracePeriod, status, isActive } = offerings
    const expiredDays = currentDate(tz).diff(getDateByDateString(endDate, tz), "days")
    return {
        status: status,
        isExpired: status === OFFERING_STATUS.EXPIRED,
        remainingDaysNumberInGracePeriod: gracePeriod - expiredDays,
        disableAutomatedTestResults: isActive !== 1
    }
}

export const getFilteredOfferings = (offerings = []) => {
    return offerings.filter(offering => OFFERING_STATUS_TO_DISPLAY.includes(offering.status))
}

export const showGracePeriod = (offering) => {
    return offering?.isExpired
        ? offering?.remainingDaysNumberInGracePeriod >= 0 ? offering?.remainingDaysNumberInGracePeriod : t("common.na")
        : t("common.na")
}

