import moment from "moment"
import { registerLocale, setDefaultLocale } from "react-datepicker"

const LangugeShortForms = {
    "fr-CA": "fr",
    "pt-PT": "pt"
}

export const setMomentLocale = async (selectedLanguage) => {
    let lang = selectedLanguage;
    //set userPreferred Language in intial Pageload
    if (selectedLanguage === "en") {
        moment.locale(selectedLanguage)
    } else {
        lang = LangugeShortForms[selectedLanguage] ? LangugeShortForms[selectedLanguage] : selectedLanguage
        await import(`moment/locale/${lang}`)
        const selectedDateLocale = await import(`date-fns/locale/${lang}`)
        registerLocale(`${lang}`, selectedDateLocale.default)
    }
    setDefaultLocale(`${lang}`)
    moment.locale(lang)
}

export const getMoment = () => moment;
