/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:05b0a725-5e90-4a8b-b3bd-f6c4f0afdcc0",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_xJnIZt9c4",
  aws_user_pools_web_client_id: "2tkrbdi4t6bj0b4emsna16b7hh",
  oauth: {
    domain: "denali-deveu.auth.eu-central-1.amazoncognito.com",
    scope: ["openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: window.location.origin,
    redirectSignOut: `https://corpextdev.b2clogin.com/corpextdev.onmicrosoft.com/B2C_1A_OIDC_USERINFO_DENALI/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`,
    responseType: "code",
    client_id: "522f643d-5757-4085-b88d-c23004a8f0dd" //TODO: we need a production client id for federation
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint:
    "https://75jnvv76mfd6jn5oid6vw4c5ui.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  language: "it",
  appEnvironment: {
    BUILD_ENV: "deveu",
    DEFAULT_SUBCRIBER_FOR_BUILDING: "vsi-test@service.traneconnect.com",
    externalLinks: {
      traneConnectBaseURL: "https://dev.connect.tis.trane.eu",
      commandCenterBaseURL: "https://dev.tcc.tis.trane.tu",
      commandCenterGen4BaseURL: "https://gen4.tcc.tis-dev.trane.com/"
    }
  },
  predictions: {
    convert: {
      translateText: {
        region: "eu-central-1",
        proxy: false,
        defaults: {
          sourceLanguage: "en",
          targetLanguage: "zh"
        }
      },
      speechGenerator: {
        region: "eu-central-1",
        proxy: false,
        defaults: {
          VoiceId: "Ivy",
          LanguageCode: "en-US"
        }
      },
      transcription: {
        region: "eu-central-1",
        proxy: false,
        defaults: {
          language: "en-US"
        }
      }
    },
    identify: {
      identifyText: {
        proxy: false,
        region: "eu-central-1",
        defaults: {
          format: "PLAIN"
        }
      },
      identifyEntities: {
        proxy: false,
        region: "eu-central-1",
        celebrityDetectionEnabled: true,
        defaults: {
          collectionId: "identifyEntities8b89c648-test",
          maxEntities: 50
        }
      },
      identifyLabels: {
        proxy: false,
        region: "eu-central-1",
        defaults: {
          type: "LABELS"
        }
      }
    },
    interpret: {
      interpretText: {
        region: "eu-central-1",
        proxy: false,
        defaults: {
          type: "ALL"
        }
      }
    }   
  },
  "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "search_indices": {
                "items": [
                    "denalilocator-eudev"
                ],
                "default": "denalilocator-eudev"
            },
            "maps": {
                "items": {
                    "denalimap-eudev": {
                        "style": "VectorEsriNavigation"
                    }
                },
                "default": "denalimap-eudev"
            }
        }
  }
}

export const amplitudeKey = "310d2b075694a4c3588727d46c057f22"
export const zendeskWidgetKey = "b31edde2-47a3-49a9-9bb9-30b343b73f9c"
export const staticFilesPath = "https://denali-staticfiles-rs42xyaee5e55navukocfivplm-eudev-eu-central-1.s3.amazonaws.com"

export default awsmobile