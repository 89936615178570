export const ACTIVE = "Active"
export const SUPPRESSED = "Suppressed"

export const NAME_FIELD = "analyticName"
export const TEST_STATUS_FIELD = "testStatus"
export const TRANSLATED_ANALYTIC_NAME = "translatedAnalyticName"

export const testStatusArray = [
  { id: ACTIVE, label: ACTIVE, value: ACTIVE },
  { id: SUPPRESSED, label: SUPPRESSED, value: SUPPRESSED }
]
