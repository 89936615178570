import moment from "moment"
import { reduxStoreTimeStampFormat } from "../../constant"
import { isObject } from "lodash"
import { generateLegend, legendIDGenerator, legendName } from "../common"

export const EXCEPTION_PROPERTY_TYPE = "exceptionPropertyType"
export const generateExceptionsTimestampObjects = (
  timestamps: string[],
  interval: string,
  sampling: number,
  cStartDate: any,
  cEndDate: any,
  buildingLocalTime: any,
  otherProps: any = {}
) => {
  const correctedEndDate =
    moment(new Date(buildingLocalTime)).isSameOrBefore(
      moment(new Date(cEndDate))
    ) && buildingLocalTime
      ? moment(new Date(buildingLocalTime)).format(reduxStoreTimeStampFormat)
      : moment(new Date(cEndDate)).format(reduxStoreTimeStampFormat)
  const intervalMinutes = parseInterval(interval)
  const samplingMinutes = sampling
  const timestampObjects: { startTimestamp: string; endTimestamp: string }[] =
    []
  let timeLineBars = []
  if (timestamps?.length > 0) {
    timestamps.forEach((timestamp) => {
      const timestampDate = moment(timestamp, reduxStoreTimeStampFormat)
      const endTimestamp = moment(timestampDate).add(samplingMinutes, "minutes")
      const startTimestamp = moment(endTimestamp).subtract(
        intervalMinutes,
        "minutes"
      )
      const timestampObject = {
        startTimestamp: startTimestamp.format(reduxStoreTimeStampFormat),
        endTimestamp: endTimestamp.format(reduxStoreTimeStampFormat),
        ...otherProps,
        value: true
      }

      timestampObjects.push(timestampObject)
    })

    // Sort timestamp objects based on startTimestamp
    timestampObjects.sort((a, b) =>
      moment(a.startTimestamp).diff(moment(b.startTimestamp))
    )

    // Fill in the gaps
    const filledTimestampObjects: {
      startTimestamp: string
      endTimestamp: string
    }[] = []

    const firstObjectStartDate = moment(timestampObjects[0].startTimestamp)
    if (
      firstObjectStartDate.diff(moment(cStartDate, reduxStoreTimeStampFormat)) >
      0
    ) {
      filledTimestampObjects.push({
        startTimestamp: cStartDate,
        endTimestamp: timestampObjects?.[0]?.startTimestamp,
        ...otherProps,
        color: "#525252",
        value: ""
      })
    }

    for (let i = 0; i < timestampObjects.length - 1; i++) {
      const currentObject = timestampObjects?.[i]
      const nextObject = timestampObjects?.[i + 1]

      const currentEndDate = moment(currentObject.endTimestamp)
      const nextStartDate = moment(nextObject.startTimestamp)

      if (nextStartDate.diff(currentEndDate) > 0) {
        // There is a gap, fill it
        const gapStartDate = moment(currentEndDate)
        const gapEndDate = moment(nextStartDate)

        filledTimestampObjects.push({
          startTimestamp: gapStartDate.format(reduxStoreTimeStampFormat),
          endTimestamp: gapEndDate.format(reduxStoreTimeStampFormat),
          ...otherProps,
          color: "#525252",
          value: ""
        })
      }
    }

    const lastObjectEndDate = moment(
      timestampObjects[timestampObjects.length - 1].endTimestamp
    )
    if (
      moment(correctedEndDate, reduxStoreTimeStampFormat).diff(
        lastObjectEndDate
      ) > 0
    ) {
      filledTimestampObjects.push({
        startTimestamp: lastObjectEndDate.format(reduxStoreTimeStampFormat),
        endTimestamp: correctedEndDate,
        ...otherProps,
        color: "#525252",
        value: ""
      })
    }

    timeLineBars = [...filledTimestampObjects, ...timestampObjects]
    timeLineBars.sort((a, b) =>
      moment(a.startTimestamp).diff(moment(b.startTimestamp))
    )
  } else {
    timeLineBars.push({
      startTimestamp: moment(cStartDate).format(reduxStoreTimeStampFormat),
      endTimestamp: correctedEndDate,
      ...otherProps,
      color: "#525252",
      value: "No Data"
    })
  }

  return timeLineBars
}

export const parseInterval = (interval: string): number => {
  const timeValue = parseInt(interval?.slice(0, -1))
  const timeUnit = interval?.slice(-1)

  if (timeUnit === "m") {
    return timeValue
  } else if (timeUnit === "h") {
    return timeValue * 60
  } else if (timeUnit === "d") {
    return timeValue * 24 * 60
  } else {
    throw new Error("Invalid interval format")
  }
}

export const generateExceptionLegends = (props: any) => {
  const { exceptionData = {}, chartInfo = {}, equipmentList = [], selectedEquipID = "", oldTimeLineLegends } = props;
  const exceptionLegends: any = {}
  if (isObject(exceptionData)) {
    Object.keys(exceptionData)?.forEach((equipmentID: string) => {
      const equipExceptionObject = exceptionData?.[equipmentID] || {};
      const equipmentName = equipmentList?.find(equip => equip?.id === equipmentID)?.name || ""
      const selectedEquipment = equipmentID === selectedEquipID

      Object.keys(equipExceptionObject)?.forEach((exceptionName: string) => {
        const exceptionArray = equipExceptionObject?.[exceptionName]
        const exceptionsAvailable = (exceptionArray || [])?.length > 0
        const axis = chartInfo?.axisConfig?.find(
          (obj) => obj?.axisName?.toLowerCase() === "exceptions"
        )
        const legendId = legendIDGenerator(equipmentID, exceptionName)
        const name = legendName({ selectedEquipment, propertyName: exceptionName, equipmentName })
        const legendObj: any = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, type: "timeline", propertyKey: exceptionName, oldLegends: oldTimeLineLegends, batch: exceptionsAvailable, show: exceptionsAvailable, propertyType: EXCEPTION_PROPERTY_TYPE })
        exceptionLegends[legendId] = legendObj
      })
    })
  }
  return exceptionLegends
}