import { useMemo } from "react"
import "react-datepicker/dist/react-datepicker.css"
import styles from "./DateRangePicker.module.scss"
import "./DateRangePicker.scss"
import CalendarComponent from "./CalendarComponent"
import {
  DATE,
  END,
  MONTH,
  RANGE,
  START,
  SUBTRACT,
  YEAR,
  ONE_DAY,
  ADD,
  CUSTOM_RANGE,
  ONE_MONTH
} from "./constant"
import { getCalculatedDay, displayDateFormatter } from "./helper"
import clsx from "clsx"
import CheckOutsideClick from "../CheckOutsideClick/CheckOutsideClick"
import { Button } from "src/blitz"
import moment from "moment"
import { isFunction } from "lodash"
import { TranslateComponent } from "src/common/translations"
const DateRangePicker = (props) => {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const {
    openCalendar,
    selectedDate,
    calendarView,
    setSelectedDate,
    setCalendarView,
    pickerCategory,
    submitHandler = () => null,
    setOpenCalendar = () => null,
    maxStartDateProps,
    maxEndDateProps,
    minStartDateProps = undefined,
    minEndDateProps = undefined,
    useCalendarOnly,
    testId = "",
    handleCustomDateSelection = undefined
  } = props

  const currentDate = new Date()

  const handleDateSelection = (
    date,
    calendarType: string,
    newCalendarView: string
  ) => {
    if (newCalendarView === DATE) {
      if (isFunction(handleCustomDateSelection)) {
        handleCustomDateSelection(calendarType, date, pickerCategory)
      } else {
        setSelectedDate((prvsVal) => ({
          start:
            calendarType === START
              ? date
              : pickerCategory === ONE_DAY
                ? prvsVal?.start
                : pickerCategory === CUSTOM_RANGE
                  ? date < prvsVal?.start
                    ? date
                    : prvsVal?.start
                  : getCalculatedDay(SUBTRACT, pickerCategory, date),
          end:
            calendarType === END
              ? date
              : pickerCategory === ONE_DAY
                ? date
                : pickerCategory === CUSTOM_RANGE
                  ? date > prvsVal?.end
                    ? date
                    : prvsVal?.end
                  : getCalculatedDay(ADD, pickerCategory, date)
        }))
      }

    } else if (newCalendarView === MONTH) {
      if (pickerCategory === ONE_MONTH) {
        const monthEndDate = moment(date).endOf("month").toDate()
        setSelectedDate(() => ({
          start: moment(date).startOf("month").toDate(),
          end: moment(monthEndDate)?.isAfter(maxEndDateProps) ? maxEndDateProps : monthEndDate
        }))
      } else {
        setCalendarView((obj) => ({
          ...obj,
          [calendarType]: DATE
        }))
      }

    } else if (newCalendarView === YEAR) {
      setCalendarView((obj) => ({
        ...obj,
        [calendarType]: MONTH
      }))
    }
  }

  const handleHeaderButtonClick = (
    calendarType: string,
    newCalendarView: string
  ) => {
    setCalendarView((obj) => ({
      ...obj,
      [calendarType]:
        newCalendarView === DATE
          ? MONTH
          : newCalendarView === MONTH
            ? YEAR
            : obj[calendarType]
    }))
  }

  const handleSubmit = () => {
    setOpenCalendar(false)
    submitHandler(selectedDate)
  }

  const startDateD = useMemo(
    () => displayDateFormatter(selectedDate?.start),
    [selectedDate?.start]
  )
  const endDateD = useMemo(
    () => displayDateFormatter(selectedDate?.end),
    [selectedDate?.end]
  )

  const selectedRange = useMemo(() => {
    return pickerCategory === START
      ? startDateD
      : pickerCategory === END
        ? endDateD
        : pickerCategory === RANGE && startDateD + " - " + endDateD
  }, [pickerCategory, startDateD, endDateD])

  const maxEndDate = useMemo(
    () => (maxEndDateProps ? maxEndDateProps : currentDate),
    [maxEndDateProps, currentDate]
  )
  const maxStartDate = useMemo(
    () =>
      maxStartDateProps
        ? maxStartDateProps
        : pickerCategory === ONE_DAY || pickerCategory === CUSTOM_RANGE || pickerCategory === ONE_MONTH
          ? currentDate
          : getCalculatedDay(SUBTRACT, pickerCategory, maxEndDate),
    [
      maxStartDateProps,
      pickerCategory,
      SUBTRACT,
      CUSTOM_RANGE,
      currentDate,
      ONE_DAY
    ]
  )

  const positionClass = useMemo(
    () =>
      pickerCategory === ONE_DAY || pickerCategory === ONE_MONTH
        ? styles.positionOneDay
        : styles.positionNotOneDay,
    [pickerCategory]
  )

  return (
    <CheckOutsideClick
      disable={useCalendarOnly}
      handleOutsideClick={() => setOpenCalendar(false)}
    >
      <div className="position-relative">
        {!useCalendarOnly && (
          <Button
            type="button"
            btnSize="mini"
            variant="dark"
            className={styles.calendarBtn}
            onClick={() => setOpenCalendar((calendar) => !calendar)}
            testId={testId}
          >
            <span className="icon icon-uniE005"></span>
          </Button>
        )}
        {openCalendar && (
          <div
            className={clsx({
              [styles.calendarComponentWrapper]: !useCalendarOnly,
              [styles.positionNotOneDay]: !useCalendarOnly,
              [positionClass]: !useCalendarOnly
            })}
          >
            <div
              className={clsx({
                [styles.dateRangeWrapper]: !useCalendarOnly,
                [styles.drwOneDay]: (pickerCategory === ONE_DAY || pickerCategory === ONE_MONTH)
              })}
            >
              {!useCalendarOnly && (
                <div className={styles.dateRangeWrapperArrow}></div>
              )}
              <div className={styles.dateRangeCalendarsWrapper}>
                <CalendarComponent
                  type={START}
                  calenderType={calendarView?.start}
                  calendarClassName={`${styles.reactDatepicker} ${styles.rdpStartCalendar} rdp-start-calendar`}
                  legendClassName={styles.rdpCtlStartDate}
                  handleDateSelection={handleDateSelection}
                  handleHeaderButtonClick={handleHeaderButtonClick}
                  date={selectedDate?.start}
                  title="Start Date"
                  maxDate={maxStartDate}
                  minDate={minStartDateProps}
                />
                {pickerCategory !== ONE_DAY && pickerCategory !== ONE_MONTH && (
                  <CalendarComponent
                    type={END}
                    calenderType={calendarView?.end}
                    calendarClassName={`${styles.reactDatepicker} ${styles.rdpEndCalendar} rdp-end-calendar`}
                    legendClassName={styles.rdpCtlEndDate}
                    handleDateSelection={handleDateSelection}
                    handleHeaderButtonClick={handleHeaderButtonClick}
                    date={selectedDate?.end}
                    title="End Date"
                    maxDate={maxEndDate}
                    minDate={minEndDateProps}
                  />
                )}
              </div>
              {!useCalendarOnly && (
                <div className={styles.reactDatepickerActionsWrapper}>
                  <p className={styles.rdpActionsSelectedRangeText}>
                    {selectedRange}
                  </p>
                  <button
                    className={styles.rdpActionsSubmitButton}
                    onClick={handleSubmit}
                    data-testid="date-range-picker-submit-button-click"
                  >
                    <TranslateComponent>
                      Submit
                    </TranslateComponent>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        {props.children}
      </div>
    </CheckOutsideClick>
  )
}

export default DateRangePicker
