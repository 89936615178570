import { createSlice } from "@reduxjs/toolkit"
import { ACTIONS } from "src/constants"
const initialReportStatus = {
  error: false,
  completed: false
}
export const buildingSummarySlice = createSlice({
  name: "building",
  initialState: {
    buildingId: null,
    dateRange: {
      startDate: null,
      endDate: null
    },
    searchText: "",
    buildingSummary: {
      loading: true,
      data: null,
      error: false
    },
    buildingWeatherInfo: {
      loading: false,
      data: null,
      error: false
    },
    analyticConfig: {
      loading: false,
      data: null,
      error: false
    },
    serviceAdvisories: {
      //searchText: "",
      loading: true,
      data: [],
      hasMore: false,
      nextToken: null,
      error: false
    },
    serviceAdvisoriesAggregates: {
      loading: true,
      data: [],
      error: false
    },
    listNotesByBuildingTotal: {
      loading: true,
      data: null,
      error: false
    },
    serviceAdvisoriesTotal: {
      loading: true,
      data: null,
      error: false
    },
    serviceAdvisoriesFilter: {},
    automatedTests: {
      loading: true,
      partialLoad: false,
      data: [],
      responseTime: 0,
      error: false
    },
    equipmentList: {
      id: null,
      loading: true,
      data: null,
      error: false
    },
    equipmentTypesInBuilding: {},
    generateServiceAdviosriesReport: false,
    status: initialReportStatus,
    selectedAdvisories: [],
    updatedAdvisories: []
  },
  reducers: {
    setBuildingId: (state, action) => {
      state.buildingId = action.payload
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setBuildingSummary: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.buildingSummary = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.buildingSummary = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.buildingSummary = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setWeatherInfo: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.buildingWeatherInfo = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.buildingWeatherInfo = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.buildingWeatherInfo = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setAnalyticConfig: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.analyticConfig = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.analyticConfig = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.analyticConfig = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setlistNotesByBuildingTotal: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.listNotesByBuildingTotal = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.listNotesByBuildingTotal = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.listNotesByBuildingTotal = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setServiceAdvisoriesTotal: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.serviceAdvisoriesTotal = {
            loading: true,
            data: null,
            error: false
          }
          break
        case "Success":
          state.serviceAdvisoriesTotal = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.serviceAdvisoriesTotal = {
            loading: false,
            data: null,
            error: true
          }
          break
      }
    },
    setAutomatedTests: (state, action) => {
      const {
        type,
        data = [],
        responseTime = 0,
        partialLoad = false,
        loading = false
      } = action.payload
      switch (type) {
        case "Loading":
          state.automatedTests = {
            loading,
            partialLoad,
            data: state?.automatedTests?.data,
            responseTime: 0,
            error: false
          }
          break
        case "Success":
          state.automatedTests = {
            loading,
            partialLoad,
            data,
            responseTime,
            error: false
          }
          break
        case "Failure":
          state.automatedTests = {
            loading,
            partialLoad,
            data: [],
            responseTime: 0,
            error: true
          }
          break
      }
    },
    setEquipmentList: (state, action) => {
      const { type, data = [], id = null } = action.payload
      switch (type) {
        case "Loading":
          state.equipmentList = {
            id,
            loading: true,
            data,
            error: false
          }
          break
        case "Success":
          state.equipmentList = {
            id,
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.equipmentList = {
            id,
            loading: false,
            data: [],
            error: true
          }
          break
      }
    },
    setServiceAdvisories: (state, action) => {
      const {
        type = "Sucesss",
        data = [],
        nextToken = null,
        hasMore = false,
        loadMore = false
      } = action.payload
      switch (type) {
        case "Loading":
          state.serviceAdvisories = {
            loading: true,
            data: loadMore ? state.serviceAdvisories.data : [],
            nextToken,
            hasMore: false,
            error: undefined
          }
          break
        case "Success":
          state.serviceAdvisories = {
            loading: false,
            data,
            nextToken,
            hasMore,
            error: false
          }
          break
        case "Failure":
          state.serviceAdvisories = {
            loading: false,
            data,
            nextToken,
            hasMore: false,
            error: true
          }
          break
      }
    },
    setServiceAdvisoriesAggregates: (state, action) => {
      const { type = "Sucesss", data = [] } = action.payload
      switch (type) {
        case "Loading":
          state.serviceAdvisoriesAggregates = {
            loading: true,
            data: [],
            error: undefined
          }
          break
        case "Success":
          state.serviceAdvisoriesAggregates = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.serviceAdvisoriesAggregates = {
            loading: false,
            data,
            error: true
          }
          break
      }
    },
    setServiceAdvisoriesFilter: (state, action) => {
      state.serviceAdvisoriesFilter = { ...action.payload }
    },
    setGenerateServiceAdviosriesReport: (state, action) => {
      state.generateServiceAdviosriesReport = action.payload
    },
    setReportStatus: (state, { payload }) => {
      switch (payload) {
        case ACTIONS.COMPLETE:
          state.status = {
            ...initialReportStatus,
            completed: true
          }
          break
        case ACTIONS.ERROR:
          state.status = {
            ...initialReportStatus,
            error: true
          }
          break
        case ACTIONS.RESET:
          state.status = {
            ...initialReportStatus
          }
          break
      }
      return state
    },
    setSelectedAdvisories: (state, action) => {
      state.selectedAdvisories = action.payload
    },
    setUpdatedAdvisories: (state, action) => {
      state.updatedAdvisories = action.payload
    },
    // slicer to filter and store given equipment are available in equiment list
    // This state is used in other places show / hide functioanlity based on the availability of equipment
    updateExplicitEquipmentTypesAreAvailableOrNot: (state, action) => {
      const { equipments = [] } = action.payload as {
        equipments: any[]
      }
      const availableEquipmentTypes: any = {}
      equipments?.forEach((obj: any) => {
        if (!availableEquipmentTypes?.[obj?.type]) {
          availableEquipmentTypes[obj?.type] = true
        }
      })
      state.equipmentTypesInBuilding = availableEquipmentTypes
    }
  }
})

export const {
  setAutomatedTests,
  setEquipmentList,
  setSearchText,
  setBuildingSummary,
  setServiceAdvisories,
  setServiceAdvisoriesAggregates,
  setlistNotesByBuildingTotal,
  setServiceAdvisoriesTotal,
  setBuildingId,
  setServiceAdvisoriesFilter,
  setGenerateServiceAdviosriesReport,
  setReportStatus,
  setSelectedAdvisories,
  setUpdatedAdvisories,
  setWeatherInfo,
  updateExplicitEquipmentTypesAreAvailableOrNot,
  setAnalyticConfig,
} = buildingSummarySlice.actions

export const selectAutomatedTests = (state: any) =>
  state.building.automatedTests
export const selectEquipmentList = (state: any) => state.building.equipmentList
export const selectBuildingSummary = (state: any) =>
  state.building.buildingSummary
export const selectBuildingWeatherInfo = (state: any) =>
  state.building.buildingWeatherInfo
export const selectAnalyticConfig = (state: any) =>
  state.building.analyticConfig
export const selectBuildingReports = (state: any) => state.building.reports
export const selectSearchText = (state: any) => state.building.searchText
export const selectBuildingId = (state: any) => state.building.buildingId
export const selectServiceAdvisories = (state: any) =>
  state.building.serviceAdvisories
export const selectServiceAdvisoriesAggregates = (state: any) =>
  state.building.serviceAdvisoriesAggregates
export const selectServiceAdvisoriesTotal = (state: any) =>
  state.building.serviceAdvisoriesTotal
export const selectNoteBuildingTotal = (state: any) =>
  state.building.listNotesByBuildingTotal
export const getGenerateServiceAdviosriesReport = (state: any) =>
  state.building.generateServiceAdviosriesReport
export const getReportStatus = (state: any) => state.building.status
export const selectServiceAdvisoriesFilter = (state: any) =>
  state.building.serviceAdvisoriesFilter
export const selectSelectedServiceAdvisories = (state: any) =>
  state.building.selectedAdvisories
export const selectUpdatedServiceAdvisories = (state: any) =>
  state.building.updatedAdvisories

export const selectEquipmentTypes = (state: any) => state.building.equipmentTypesInBuilding
export const selectExplicitEquipmentTypes = (
  state: any,
  equipmentType: string
): undefined | boolean => state.building.equipmentTypesInBuilding[equipmentType]
