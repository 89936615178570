import { useMemo, useState } from "react"
import ShortName, {
  IShortNameVariant,
  IShortType
} from "src/blitz/ShortNames/ShortName"
import styles from "./BuildingTile.module.scss"
import clsx from "clsx"
import { getFilteredOfferings } from "src/utils/OfferingUtils"
import { TranslateComponent } from "src/common/translations"

export type offeringItem = {
  code?: string
  startDate?: string
  endDate?: string
  gracePeriod?: number
  name?: string
  isExpired?: boolean
  remainingDaysNumberInGracePeriod?: number
  varient?: string
  shortName?: string
  overlay?: boolean,
  status: string
}
type OfferingProps = {
  offerings?: offeringItem[]
  devices?: string[]
  showAll?: boolean
  testId?: string
  buildingName?: string
}

const ITEMS_TO_SHOW = 3
const addVariant = (
  shortNames: any,
  variant: IShortNameVariant,
  overlay: boolean
) =>
  shortNames?.map((offering: any) => ({
    ...offering,
    shortName: offering.code || offering,
    variant: offering.isExpired ? "red" : variant,
    overlay
  }))
const OfferingModal = ({
  buildingName = "",
  offerings = [],
  devices = [],
  showAll = false,
  testId = ""
}: OfferingProps) => {
  const {
    items,
    overflow,
    totalItems,
    showMoreText = ""
  } = useMemo(() => {
    const filteredOfferings = offerings ? getFilteredOfferings(offerings) : []
    const offeringsInList = addVariant(filteredOfferings, "gray", true)
    const devicesInList = devices ? addVariant(devices, "green", false) : []
    const totalItems = [...offeringsInList, ...devicesInList]
    const overflow = showAll ? false : totalItems?.length > 4
    const hiddenItems = overflow
      ? (totalItems?.length - ITEMS_TO_SHOW)?.toString()
      : ""
    return {
      totalItems,
      items: overflow ? totalItems?.slice(0, ITEMS_TO_SHOW) : totalItems,
      showMoreText: "View {0} more"?.replace("{0}", hiddenItems),
      overflow
    }
  }, [offerings, devices])
  const [showMore, setShowMore] = useState<boolean>(false)
  const showMoreHandler = () => {
    setShowMore((showMore) => !showMore)
  }
  const getOffering = (code) =>
    offerings?.filter((offering) => offering.code === code)[0]

  return (
    <>
      {showAll ? (
        <>
          {(showMore ? totalItems : items)?.map(
            (item: IShortType, index: number) => (
              <ShortName
                buildingName={buildingName}
                key={item?.shortName}
                shortName={item?.shortName}
                variant={item?.variant}
                overlay={item?.overlay}
                item={getOffering(item.shortName)}
                testId={testId}
                name={item?.name}
              />
            )
          )}{" "}
        </>
      ) : (
        <div
          className={clsx(styles.offerInfoWrapper, {
            [styles.expandedOfferingInfo]: showMore
          })}
        >
          <div style={{ maxWidth: overflow && !showMore && "90px" }}>
            {(showMore ? totalItems : items)?.map(
              (item: IShortType, index: number) => (
                <ShortName
                  buildingName={buildingName}
                  key={item?.shortName}
                  shortName={item?.shortName}
                  variant={item?.variant}
                  overlay={item?.overlay}
                  item={getOffering(item.shortName)}
                  testId={testId}
                  name={item?.name}
                />
              )
            )}
          </div>
          {overflow && (
            <button className={styles.showMore} onClick={showMoreHandler}>
              {<TranslateComponent>{showMore ? "Close " : showMoreText}</TranslateComponent>}
            </button>
          )}
        </div>
      )}
    </>
  )
}
export default OfferingModal
