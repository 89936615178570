import { ASC } from "../shared/Charts/constant"

export const buildTableFormat = (equipments: any[], propertyName: string) => {
  if (!equipments || !propertyName) return []
  const items = equipments?.reduce((accumulatedItems, equipment: any) => {
    const equipmentName = equipment?.name
    const advisories = equipment[propertyName]?.items?.map((params: any) => {
      params.equipmentName = equipmentName
      return params
    })
    accumulatedItems = [...accumulatedItems, ...advisories]
    return accumulatedItems
  }, [])
  return items || []
}

export const sortDataByFieldName = (
  sortField: string,
  sortOrder: string,
  data: any,
  columnData?: any,
  secondarySortField?: string
) => {
  if (!sortField || !data) return
  const isDateField = columnData?.find(
    (columnObj: any) => columnObj?.id === sortField
  )?.date
  const sorted = [...data].sort((a, b) => {
    if (isDateField) {
      const dateA = new Date(a).getTime()
      const dateB = new Date(b).getTime()
      if (sortOrder === ASC) {
        return (dateA || -Infinity) - (dateB || -Infinity)
      } else {
        return (dateB || -Infinity) - (dateA || -Infinity)
      }
    } else {
      let sortVal = getSortValue(a, b, sortField)

      //If both values are same then compare with secondary sort field.
      if (
        secondarySortField &&
        sortField !== secondarySortField &&
        sortVal == 0
      ) {
        sortVal = getSortValue(a, b, secondarySortField)
      }

      return sortVal * (sortOrder === ASC ? 1 : -1)
    }
  })
  return sorted
}


function getSortValue(a: any, b: any, sortField: string) {
  const aValue =
    a[sortField] === null || a[sortField] === undefined ? "" : a[sortField]
  const bValue =
    b[sortField] === null || b[sortField] === undefined ? "" : b[sortField]
  const sortVal = aValue?.toString()?.localeCompare(bValue?.toString(), "en", {
    numeric: true
  })

  return sortVal
}


export const filterArrayBySearchString = (arr, searchString: string) => {
  const lowerSearchString = searchString?.toLowerCase()
  return arr.filter((obj) => {
    return Object.values(obj).some((value) => {
      const stringValue = String(value).toLowerCase()
      return stringValue?.includes(lowerSearchString)
    })
  })
}

export const getChartId = (exceptionInput, equipmentProperties) => {
  const chart = exceptionInput.chart
  if (typeof chart === "string") {
    return ""
  }
  for (const key in chart) {
    if (key === "def_id") {
      return chart[key]
    } else {
      const targetChart = chart[key]
      const item = equipmentProperties.find((item) => {
        return item.targetKey === key
      })
      for (const key in targetChart) {
        if (key === "any" || key === "or") {
          if (
            targetChart[key]?.conditions?.includes(
              item?.value == null ? "Null" : item.value
            )
          ) {
            return targetChart[key]?.id
          } else {
            return targetChart?.def_id
          }
        }
      }
    }
  }
}

export const isChartIdExists = (equipMentTypeAnalitics = [], equipMentProperties: any, columnItem: string) => {
  const exceptionInput = equipMentTypeAnalitics.find((input) => {
    return columnItem && input.uiATName.replace(/XX/g, "") === columnItem?.replace(/\d[A-Z]?\s+/g, "")
  })
  if (exceptionInput) {
    return getChartId(exceptionInput, equipMentProperties)
  } else {
    return ""
  }
}