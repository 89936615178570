export const NAME_FIELD = "name"
export const FAMILY_FIELD = "family"
export const NO_TAG = "NoTag"
export const AUTOMATED_TESTS = "Automated Tests"
export const VAV_AIR_SYS = "Variable Air System"
export const AIR_HANDLER = "Air Handler"
export const STAND_ALONE = "StandAlone"
export const ROOT = "Root"
export const NO_DATA_KEY = "No data"
export const SERVICE_ADVISORY_LIMIT = 50
export const SERVICE_ADVISORY_AGGREGATE_LIMIT = 1000
export const GRACE_PERIOD_LIMIT = 60
export const APPLY = "Apply"
export const DONE = "Done"
export const REMOVE = "Remove"
export const DATATYPE_NUMBER = "number"
export const DATATYPE_STRING = "string"
export const DATATYPE_DATE = "date"
export const TYPE_MIN = "min"
export const TYPE_MAX = "max"
export const TYPE_TEXT = "terms"
export const EQUIPMENT = "Equipment"
export const Building = "Building"
export const STATUS = "status"
export const CLOSED = "closed"
export const NO_SUGGESTION_DATA = "Suggestion Data Not Available"
export const CAUSES_TEXT = "Most likely causes are :"
export const NOTES_TEXT = "Note :"
export const ENABLING_EHR_REPORT_OFFERINGS = ["CAP", "DI-CH", "DI-AHU", "BP"]
export const ENABLING_CPR_REPORT_OFFERINGS = [
  "CAP",
  "DI",
  "BP",
  "Chiller",
  "DI-CH"
]
export const ENABLING_DI_REPORT_OFFERINGS = ["DI-CH", "DI-AHU"]
export const ENABLING_ALARM_EVENTS_REPORT__OFFERINGS = ["CAP", "BP", "DI-CH"]
export const ENABLING_SERVICE_ADVISORY__REPORT_OFFERINGS = [
  "CAP",
  "DI-CH",
  "BP"
]

export const REPORT_STATE = {
  url: "",
  retryCount: 0
}
export const OFFERINGS = {
  "BP": "BP",
  "EP": "EP",
  "CAP": "CAP",
  "DI-CH": "DI-CH",
  "DI-AHU": "DI-AHU"
}

export const OFFERING_STATUS = {
  APPROVED: "Approved",
  EXPIRED: "Expired"
}

export const SERVICE_ADVISORY_AGGREGATES = [
  {
    type: TYPE_TEXT,
    name: DATATYPE_STRING + ":equipmentName:" + TYPE_TEXT,
    field: "equipmentId"
  },
  {
    type: TYPE_TEXT,
    name: DATATYPE_STRING + ":exceptionName:" + TYPE_TEXT,
    field: "exceptionName"
  },
  {
    type: TYPE_MIN,
    field: "daysFailed",
    name: DATATYPE_NUMBER + ":daysFailed:" + TYPE_MIN
  },
  {
    type: TYPE_MAX,
    field: "daysFailed",
    name: DATATYPE_NUMBER + ":daysFailed:" + TYPE_MAX
  },
  {
    type: TYPE_MIN,
    field: "firstException",
    name: DATATYPE_DATE + ":firstException:" + TYPE_MIN
  },
  {
    type: TYPE_MAX,
    field: "firstException",
    name: DATATYPE_DATE + ":firstException:" + TYPE_MAX
  },
  {
    type: TYPE_MIN,
    field: "latestException",
    name: DATATYPE_DATE + ":latestException:" + TYPE_MIN
  },
  {
    type: TYPE_MAX,
    field: "latestException",
    name: DATATYPE_DATE + ":latestException:" + TYPE_MAX
  },
  {
    type: TYPE_MIN,
    field: "worstException",
    name: DATATYPE_DATE + ":worstException:" + TYPE_MIN
  },
  {
    type: TYPE_MAX,
    field: "worstException",
    name: DATATYPE_DATE + ":worstException:" + TYPE_MAX
  },
  {
    type: TYPE_TEXT,
    name: DATATYPE_STRING + ":status:" + TYPE_TEXT,
    field: "status"
  },
  {
    type: TYPE_TEXT,
    name: DATATYPE_STRING + ":impactComfort:" + TYPE_TEXT,
    field: "impactComfort"
  },
  {
    type: TYPE_TEXT,
    name: DATATYPE_STRING + ":impactEnergy:" + TYPE_TEXT,
    field: "impactEnergy"
  },
  {
    type: TYPE_TEXT,
    name: DATATYPE_STRING + ":impactReliability:" + TYPE_TEXT,
    field: "impactReliability"
  }
]
export const BUILDING_AGGREGATES = [
  {
    type: TYPE_TEXT,
    name: "facilityName",
    field: "name"
  },
  {
    type: TYPE_TEXT,
    name: "account",
    field: "accountName"
  },
  {
    type: TYPE_TEXT,
    name: "facilityAddress",
    field: "address"
  },
  {
    type: TYPE_TEXT,
    name: "city",
    field: "city"
  },
  {
    type: TYPE_TEXT,
    name: "state",
    field: "state"
  },
  {
    type: TYPE_TEXT,
    name: "postalCode",
    field: "postalCode"
  },
  {
    type: TYPE_TEXT,
    name: "country",
    field: "country"
  },
  {
    type: TYPE_TEXT,
    name: "connectionStatus",
    field: "connectionStatus"
  },
  {
    type: TYPE_TEXT,
    name: "remoteAccessStatus",
    field: "remoteAccessStatus"
  },
  {
    type: TYPE_TEXT,
    name: "deviceConnectivity",
    field: "deviceConnectivity"
  },
  {
    type: TYPE_TEXT,
    name: "offeringCodes",
    field: "offeringCodes"
  },
  {
    type: TYPE_TEXT,
    name: "salesOfficeCode",
    field: "salesOfficeCode"
  },
]

export const CHECKED_OFFERINGS = {
  BP: "BP",
  CAP: "CAP",
  "DI-CH": "DI-CH",
  "DI-AHU": "DI-AHU",
  "DI-VRF" : "DI-VRF"
}

export const EXCLUDED_OFFERINGS = {
  AN: "AN",
  AM: "AM",
  GF: "GF",
  TS: "TS",
  TC: "TC",
  RA: "RA"
}
