import { useEffect, useState } from "react"
import { cloneDeep } from "lodash"
import { Outlet, useSearchParams } from "react-router-dom"
import { Amplify } from "aws-amplify"
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions"
import config from "../aws-exports"
import { selectIsHeadLess, setBCChart, setIsHeadLess } from "src/redux/slicers/performanceSlice"
import { useDispatch, useSelector } from "react-redux"
import { CHART_BC_PARAM } from "src/components/shared/Charts/constant"
import { setSelectedLanguage } from "src/redux/slicers/userData"
import { setFilter } from "src/redux/slicers/reportSlice"
import { setMomentLocale } from "src/utils/MomentUtils"

const PerformanceReportLayout = () => {

  const [authUpdated, setAuthUpdated] = useState(false);
  const [searchParams] = useSearchParams()
  const authToken = searchParams.get("token")
  const dispatch = useDispatch();
  const isHeadLess = useSelector(selectIsHeadLess);
  const bc = searchParams.get(CHART_BC_PARAM)
  const lang = searchParams.get("lang") //Reading user selected language, which was passed in URL
  const uom = searchParams.get("uom") //Reading UOM, which was passed in URL

  useEffect(() => {
    setMomentLocale(lang)
    const newConfig = cloneDeep(config);
    newConfig.aws_appsync_authenticationType = "null";
    Amplify.configure(newConfig)
    if (authToken) {
      Amplify.configure({
        API: {
          graphql_headers: async () => {
            return {
              Authorization: authToken
            }
          }
        }
      })
      Amplify.addPluggable(new AmazonAIPredictionsProvider());
      setAuthUpdated(true)
    }
    dispatch(setIsHeadLess(true));
    dispatch(setBCChart(bc))
    // Updating language in store with language retrieved from URL
    dispatch(setSelectedLanguage(lang))
    if (uom) {
      // Updating UOM in store with language retrieved from URL
      dispatch(setFilter({
        systemOfMeasure: uom.toUpperCase()
      }))
    }
  }, [authToken])

  return (<>
    {(authUpdated && isHeadLess) && <Outlet />}
  </>)
}

export default PerformanceReportLayout