import { useSelector } from "react-redux"
import { selectApplicationErrors } from "src/redux/slicers/applicationSlice"
import ErrorModal from "./ErrorModal"

const Error = () => {
    const applicationErrors = useSelector(selectApplicationErrors)

    return (<>
        {applicationErrors?.map((obj) => {
            return <ErrorModal key={obj?.id} heading={obj.heading} errorMessage={obj?.errorMessage} id={obj?.id} shouldNotExpire={obj?.shouldNotExpire} ErrorBody={obj?.ErrorBody} />
        })}

    </>)

}

export default Error