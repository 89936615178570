import clsx from "clsx"
import { ITableContainer } from "../types"

const TableContainer = (props: ITableContainer) => {
  const { className = "", children, style = {} } = props

  return (
    <div style={{ ...style }} className={clsx(className)}>
      {children}
    </div>
  )
}

export default TableContainer
