import React, { useMemo, useEffect, useRef } from "react"
import style from "./SearchInput.module.scss"
import clsx from "clsx"
import { debounce } from "lodash"
import { IDefaultSearchInput } from "./types"

export default function SearchInput(props: IDefaultSearchInput) {
  const {
    searchText = "",
    onInputChange,
    onInputClear,
    testId = "",
    isDisabled = false
  } = props
  const inputField = useRef(null)
  const handleChange = (e) => {
    onInputChange(e.target.value)
  }
  const debouncedInput = useMemo(() => {
    return debounce(handleChange, 500)
  }, [])

  useEffect(() => {
    return () => {
      debouncedInput.cancel()
    }
  })

  useEffect(() => {
    inputField.current.value = searchText
  }, [searchText])

  const clearSearchText = () => {
    onInputClear()
    inputField.current.value = ""
    inputField.current.focus()
  }

  const inputActive = Boolean(inputField?.current?.value) || Boolean(searchText)

  return (
    <div className={`${style.searchContainer} d-inline-flex )`}>
      <div className={clsx(style.searchWrapper)}>
        <div className={clsx(style.iconSearch, style.icon)}></div>
        <input
          type="text"
          placeholder=""
          className={clsx({
            [style.inputActive]: inputActive
          })}
          ref={inputField}
          onChange={debouncedInput}
          data-testid={testId}
          disabled={isDisabled}
        />
        <div
          onClick={clearSearchText}
          data-testid="search-input-text-clear-click"
          className={clsx(style.clearInput, style.iconClear, style.icon)}
        ></div>
      </div>
    </div>
  )
}
