import { createSlice } from "@reduxjs/toolkit"
import { ReportType } from "../types/reportType"
import { CUSTOM_RANGE, TODAY_DATE_ISO } from "src/blitz/DateRange/constant"
import { ACTIONS } from "src/constants"
import {
  CHILLER_REPORT,
  DIGITAL_INSPECTION_REPORT,
  DIGITAL_INSPECTION_REPORT_V2,
  IMPERIAL_MEASURE,
  LANG_ENGLISH,
  reportTimePeriodOptions
} from "src/components/shared/ReportDialog/constants"
import { CURRENT_FETCH } from "src/components/shared/Charts/constant"
const initialReportStatus = {
  loading: false,
  error: false,
  cancelled: false,
  completed: false
}

const initialState: ReportType = {
  showReportDialog: false,
  showReportDialogV2: false,
  reportType: {
    title: "",
    value: ""
  },
  rawData: {
    equipments: {
      loading: false,
      data: [],
      error: false,
      selectedItems: [],
      equipmentSearchText: "",
      viewFormat: "xlsx"
    },
    properties: {
      loading: false,
      data: [],
      error: false,
      selectedItems: [],
      propertySearchText: "",
      includeWeatherData: false,
      includeOutofRange: true,
      includeNonStandard: false
    }
  },
  exceptionHistory: {
    loading: false,
    data: [],
    error: false,
    selectedItems: [],
    exceptionSearchText: "",
    // Exception report related
    showMultiBuildingsDialog: false,
    buildingList: {
      loading: false,
      data: [],
      error: false,
      total: 0,
      nextToken: null,
      hasMore: false
    },
    accountList: {
      loading: false,
      data: [],
      total: 0,
      error: false,
      nextToken: null,
      hasMore: false
    },
    buildingListSearchText: "",
    accountListSearchText: "",
    accountSelected: null,
    selectedBuildings: [],
    appliedBuildings: [],
    multipleBuildingLimitExceeded: false,
    loadMoreLimit: 15,
    maxSelectedBuildings: 100
    // Exception report related
  },
  chiller: {
    properties: {
      loading: false,
      data: [],
      error: false
    },
    selectedProperties: [],
    characteristics: {
      loading: false,
      data: [],
      error: false,
      equipmentId: null,
      equipmentDetails: null
    }
  },
  digitalInspection: {
    reportDetails: {
      loading: false,
      data: "",
      error: false
    }
  },
  filterData: {
    equipmentType: {
      label: "",
      value: ""
    },
    // reportType: "raw-data",
    timePeriod: CUSTOM_RANGE,
    startDate: TODAY_DATE_ISO,
    endDate: TODAY_DATE_ISO,
    language: LANG_ENGLISH,
    systemOfMeasure: IMPERIAL_MEASURE
  },
  isValid: false,
  isDataExceeded: false,
  status: initialReportStatus,
  equipmentTypes: [],
  addChartPeoperties: []
}

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportDialog: (state, action) => {
      state.showReportDialog = action.payload
    },
    setAppliedBuildings: (state, action) => {
      state.exceptionHistory.appliedBuildings = action.payload
    },
    setMultiBuildingsDialog: (state, action) => {
      state.exceptionHistory.showMultiBuildingsDialog = action.payload
    },
    setReportCheck: (state, action) => {
      state.rawData.properties = {
        ...state.rawData.properties,
        ...action.payload
      }
    },
    setReportFormat: (state, action) => {
      state.rawData.equipments.viewFormat = action.payload
    },
    setEquipmentSearchText: (state, action) => {
      state.rawData.equipments.equipmentSearchText = action.payload.trim()
    },
    setPropertySearchText: (state, action) => {
      state.rawData.properties.propertySearchText = action.payload.trim()
    },
    setExceptionSearchText: (state, action) => {
      state.exceptionHistory.exceptionSearchText = action.payload.trim()
    },
    setSelectedEquipments: (state, action) => {
      state.rawData.equipments.selectedItems = action.payload
    },
    setSelectedProperties: (state, action) => {
      state.rawData.properties.selectedItems = action.payload
    },
    setSelectedExceptions: (state, action) => {
      state.exceptionHistory.selectedItems = action.payload
    },
    resetReportsReducer: (state) => {
      state.rawData = initialState.rawData
      state.filterData = initialState.filterData
      state.status = initialReportStatus
    },
    setFilter: (state, action) => {
      state.filterData = { ...state.filterData, ...action.payload }
    },
    setIsValid: (state, action) => {
      state.isValid = action.payload
    },
    setIsDataExceeded: (state, action) => {
      state.isDataExceeded = action.payload
    },
    setRawDataEquipments: (state, action) => {
      const { type, data = {} } = action.payload
      switch (type) {
        case "Loading":
          state.rawData.equipments = {
            ...state.rawData.equipments,
            loading: true,
            data: [],
            error: false,
            selectedItems: []
          }
          break
        case "Success":
          state.rawData.equipments = {
            ...state.rawData.equipments,
            loading: false,
            data,
            error: false,
            selectedItems: []
          }
          break
        case "Failure":
          state.rawData.equipments = {
            ...state.rawData.equipments,
            loading: false,
            data: [],
            error: true,
            selectedItems: []
          }
          break
      }
    },
    setRawDataProperties: (state, action) => {
      const { type, data = [], selectedItems = [] } = action.payload
      switch (type) {
        case "Loading":
          state.rawData.properties = {
            ...state.rawData.properties,
            loading: true,
            data: [],
            error: false,
            selectedItems: []
          }
          break
        case "Success":
          state.rawData.properties = {
            ...state.rawData.properties,
            loading: false,
            data,
            error: false,
            selectedItems: selectedItems
          }
          break
        case "Failure":
          state.rawData.properties = {
            ...state.rawData.properties,
            loading: false,
            data: [],
            error: true,
            selectedItems: []
          }
          break
      }
    },
    setExceptions: (state, action) => {
      const { type, data = [], selectedItems = [] } = action.payload
      switch (type) {
        case "Loading":
          state.exceptionHistory = {
            ...state.exceptionHistory,
            loading: true,
            data: [],
            error: false,
            selectedItems: []
          }
          break
        case "Success":
          state.exceptionHistory = {
            ...state.exceptionHistory,
            loading: false,
            data,
            error: false,
            selectedItems: selectedItems
          }
          break
        case "Failure":
          state.exceptionHistory = {
            ...state.exceptionHistory,
            loading: false,
            data: [],
            error: true,
            selectedItems: []
          }
          break
      }
    },
    setReportType: (state, action) => {
      const filterData = {
        equipmentType: {
          label: "",
          value: ""
        },
        timePeriod: state?.filterData?.timePeriod,
        startDate: state?.filterData?.startDate,
        endDate: state?.filterData?.endDate,
        language: state?.filterData?.language,
        systemOfMeasure: state?.filterData?.systemOfMeasure
      }
      if (state.reportType?.value === CHILLER_REPORT) {
        filterData.timePeriod = CUSTOM_RANGE
      }

      if (!action.payload?.switch && action.payload?.reportType?.value !== CHILLER_REPORT) {
        const timePeriodOptions = (reportTimePeriodOptions?.[action.payload?.reportType?.value] || [])?.find((obj) => obj?.value == action.payload?.timePeriod)
        filterData.timePeriod = timePeriodOptions ? (action.payload?.timePeriod == 0 || action.payload?.timePeriod ? action.payload?.timePeriod : CUSTOM_RANGE) : CUSTOM_RANGE
      }
      if (action.payload?.reportType?.value === DIGITAL_INSPECTION_REPORT_V2) {
        state = {
          ...initialState,
          filterData: filterData,
          showReportDialogV2: true,
          reportType: action.payload?.reportType
        }
        return state
      }
      state = {
        ...initialState,
        filterData: filterData,
        showReportDialog: true,
        reportType: action.payload?.reportType
      }
      return state
    },
    setReportStatus: (state, { payload }) => {
      switch (payload) {
        case ACTIONS.LOADING:
          state.status = {
            ...initialReportStatus,
            loading: true
          }
          break
        case ACTIONS.COMPLETE:
          state.status = {
            ...initialReportStatus,
            completed: true
          }
          break
        case ACTIONS.CANCELLED:
          state.status = {
            ...initialReportStatus,
            cancelled: true
          }
          break
        case ACTIONS.ERROR:
          state.status = {
            ...initialReportStatus,
            error: true
          }
          break
        case ACTIONS.RESET:
          state.status = {
            ...initialReportStatus
          }
          break
      }
      return state
    },
    setBuildingList: (state, action) => {
      const {
        type,
        data = [],
        total = 0,
        hasMore = false,
        nextToken = null
      } = action.payload
      switch (type) {
        case "Loading":
          state.exceptionHistory.buildingList = {
            ...state.exceptionHistory.buildingList,
            loading: true,
            data,
            total,
            hasMore,
            nextToken
          }
          break
        case "Success":
          state.exceptionHistory.buildingList = {
            ...state.exceptionHistory.buildingList,
            loading: false,
            data,
            total,
            error: false,
            nextToken,
            hasMore
          }
          break
        case "Failure":
          state.exceptionHistory.buildingList = {
            ...state.exceptionHistory.buildingList,
            loading: false,
            data: null,
            total: 0,
            error: true,
            nextToken,
            hasMore
          }
          break
      }
    },
    setBuildingListSearchText: (state, action) => {
      state.exceptionHistory.buildingListSearchText = action.payload.trim()
    },
    setAccountList: (state, action) => {
      const {
        type,
        data = [],
        total = 0,
        hasMore = false,
        nextToken = null
      } = action.payload
      switch (type) {
        case "Loading":
          state.exceptionHistory.accountList = {
            ...state.exceptionHistory.accountList,
            loading: true,
            data,
            total,
            hasMore,
            nextToken
          }
          break
        case "Success":
          state.exceptionHistory.accountList = {
            ...state.exceptionHistory.accountList,
            loading: false,
            data,
            total,
            error: false,
            nextToken,
            hasMore
          }
          break
        case "Failure":
          state.exceptionHistory.accountList = {
            ...state.exceptionHistory.accountList,
            loading: false,
            data: null,
            total: 0,
            error: true,
            nextToken,
            hasMore
          }
          break
      }
    },
    setAccountListSearchText: (state, action) => {
      state.exceptionHistory.accountListSearchText = action.payload.trim()
    },
    setAccountSelected: (state, action) => {
      state.exceptionHistory.accountSelected = action.payload
    },
    selectBuildingAction: (state, action) => {
      state.exceptionHistory.selectedBuildings = action.payload
    },
    setBuildingEquipmentTypes: (state, action) => {
      state.equipmentTypes = action.payload
    },
    clearSelectedBuilding: (state) => {
      state.exceptionHistory.selectedBuildings = []
    },
    setMultipleBuildingLimitExceeded: (state, action) => {
      state.exceptionHistory.multipleBuildingLimitExceeded = action?.payload
    },
    setLoadMoreLimit: (state, action) => {
      state.exceptionHistory.loadMoreLimit = action?.payload
    },
    setMaxSelectedBuildings: (state, action) => {
      state.exceptionHistory.maxSelectedBuildings = action?.payload
    },
    clearMultipleBuildingFilters: (state) => {
      state.exceptionHistory.buildingListSearchText = ""
      state.exceptionHistory.accountListSearchText = ""
      state.exceptionHistory.accountSelected = null
    },
    setAddProperties: (state, action) => {
      state.addChartPeoperties = action.payload
    },
    setChillerProperties: (state, action) => {
      const { type, data = [] } = action.payload
      switch (type) {
        case "Loading":
          state.chiller.properties = {
            loading: true,
            data: [],
            error: false
          }
          break
        case "Success":
          state.chiller.properties = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.chiller.properties = {
            loading: false,
            data: [],
            error: true
          }
          break
      }
    },
    setDIReportDetails: (state, action) => {
      const { type, data = "" } = action.payload
      switch (type) {
        case "Loading":
          state.digitalInspection.reportDetails = {
            loading: true,
            data: "",
            error: false
          }
          break
        case "Success":
          state.digitalInspection.reportDetails = {
            loading: false,
            data,
            error: false
          }
          break
        case "Failure":
          state.digitalInspection.reportDetails = {
            loading: false,
            data: "",
            error: true
          }
          break
      }
    },
    setSelectedChillerProperties: (state, action) => {
      state.chiller.selectedProperties = action.payload
    },
    setChillerCharacteristics: (state, action) => {
      const {
        type,
        data = [],
        equipmentId,
        equipmentDetails,
        currentFetch
      } = action.payload
      switch (type) {
        case "Loading":
          state.chiller.characteristics = {
            ...state.chiller.characteristics,
            loading: true,
            data: [],
            error: false,
            currentFetch: currentFetch
          }
          break
        case "Success":
          if (state.chiller.characteristics?.[CURRENT_FETCH] === currentFetch) {
            state.chiller.characteristics = {
              loading: false,
              data,
              error: false,
              equipmentId: equipmentId,
              equipmentDetails
            }
          }
          break
        case "Failure":
          state.chiller.characteristics = {
            ...state.chiller.characteristics,
            loading: false,
            data: [],
            error: true
          }
          break
      }
    }
  }
})

export const {
  setReportDialog,
  setEquipmentSearchText,
  setPropertySearchText,
  setReportCheck,
  setReportFormat,
  setSelectedExceptions,
  setRawDataEquipments,
  setRawDataProperties,
  setSelectedEquipments,
  setSelectedProperties,
  resetReportsReducer,
  setBuildingListSearchText,
  setFilter,
  setExceptions,
  setIsValid,
  setIsDataExceeded,
  setReportType,
  setReportStatus,
  setBuildingList,
  setMultiBuildingsDialog,
  selectBuildingAction,
  clearSelectedBuilding,
  setAppliedBuildings,
  setBuildingEquipmentTypes,
  setExceptionSearchText,
  setAccountList,
  setAccountListSearchText,
  setAccountSelected,
  setMultipleBuildingLimitExceeded,
  setLoadMoreLimit,
  setMaxSelectedBuildings,
  clearMultipleBuildingFilters,
  setAddProperties,
  setChillerProperties,
  setSelectedChillerProperties,
  setChillerCharacteristics,
  setDIReportDetails
} = reportSlice.actions

export const selectReportDialog = (state: any) => state.reports.showReportDialog
export const selectReportDialogV2 = (state: any) => state.reports.showReportDialogV2

export const selectAppliedBuildings = (state: any) =>
  state.reports.exceptionHistory.appliedBuildings
export const selectExceptionHistory = (state: any) =>
  state.reports.exceptionHistory
export const getSelectedEquipments = (state: any) =>
  state.reports.rawData.equipments.selectedItems
export const getSelectedProperties = (state: any) =>
  state.reports.rawData.properties.selectedItems
export const getAddProperties = (state: any) => state.reports.addChartPeoperties
export const getEquipmentLoader = (state: any) =>
  state.reports.rawData.equipments.loading
export const getPropertiesLoader = (state: any) =>
  state.reports.rawData.properties.loading
export const getEquipments = (state: any) =>
  state.reports.rawData.equipments.data
export const getProperties = (state: any) =>
  state.reports.rawData.properties.data
export const getFilter = (state: any) => ({ ...state.reports.filterData })
export const getIsValid = (state: any) => state.reports.isValid
export const getIsDataExceeded = (state: any) => state.reports.isDataExceeded
export const getReportType = (state: any) => state.reports.reportType
export const getReportStatus = (state: any) => state.reports.status
/* Exeption report start */
export const getSelectedExceptions = (state: any) =>
  state.reports.exceptionHistory.selectedItems
export const getExceptionsLoader = (state: any) =>
  state.reports.exceptionHistory.loading
export const getExceptionHistory = (state: any) =>
  state.reports.exceptionHistory.data
export const selectMultiBuildingsDialog = (state: any) =>
  state.reports.exceptionHistory.showMultiBuildingsDialog
export const getBuildingList = (state: any) =>
  state.reports.exceptionHistory.buildingList
export const getBuildingEquipmentTypes = (state: any) =>
  state.reports.equipmentTypes
export const getExceptionSearchText = (state: any) =>
  state.reports.exceptionHistory.exceptionSearchText
export const getBuildingListSearchText = (state: any) =>
  state.reports.exceptionHistory.buildingListSearchText
export const getAccountList = (state: any) =>
  state.reports.exceptionHistory.accountList
export const getAccountListSearchText = (state: any) =>
  state.reports.exceptionHistory.accountListSearchText
export const getAccountSelected = (state: any) =>
  state.reports.exceptionHistory.accountSelected
export const getIsMultipleBuildingLimitExceeded = (state: any) =>
  state.reports.exceptionHistory.multipleBuildingLimitExceeded
export const getloadMoreLimit = (state: any) =>
  state.reports.exceptionHistory.loadMoreLimit
export const getmaxSelectedBuildings = (state: any) =>
  state.reports.exceptionHistory.maxSelectedBuildings
/* Exeption report end */

/* Chiller Report Start*/

export const getSelectedChillerProperties = (state: any) =>
  state.reports.chiller.selectedProperties
export const getChillerProperties = (state: any) =>
  state.reports.chiller.properties.data
export const getChillerCharacteristics = (state: any) =>
  state.reports.chiller.characteristics
/* Chiller Report End*/

/* Digital Inspection Report Start*/
export const getDIReportDetails = (state: any) =>
  state.reports.digitalInspection.reportDetails
/* Digital Inspection Report End*/

export const getFileFormatDetails = (state: any) => {
  return {
    equipmentSearchText: state.reports.rawData.equipments.equipmentSearchText,
    propertySearchText: state.reports.rawData.properties.propertySearchText,
    exceptionSearchText: state.reports.exceptionHistory.exceptionSearchText,
    viewFormat: state.reports.rawData.equipments.viewFormat,
    includeWeatherData: state.reports.rawData.properties.includeWeatherData,
    includeOutofRange: state.reports.rawData.properties.includeOutofRange,
    includeNonStandard: state.reports.rawData.properties.includeNonStandard
  }
}
