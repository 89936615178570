export const DATE = "date"
export const MONTH = "month"
export const YEAR = "year"

export const START = "start"
export const END = "end"

export const ADD = "add"
export const SUBTRACT = "subtract"

export const INDIVIDUAL = "individual"
export const RANGE = "range"

export const ONE_DAY = 0
export const ONE_WEEK = 7
export const TWO_WEEKS = 14
export const FOUR_WEEKS = 28
export const ONE_MONTH = 31
export const CUSTOM_RANGE = "custom"

export const START_DATE = "startDate"
export const END_DATE = "endDate"

export const NONE = "None"
export const TODAY_DATE_ISO = new Date().toISOString()
