import moment from "moment"
import momentTZ from "moment-timezone"
import { sortDataByFieldName } from "src/blitz/DataGridTable/helper"

export const TIME_LIMIT = 12
export const TILE_DATE_FORMAT = "MM/DD/YY hh:mm A"
export const CONNECTED = "Connected"
export const NOT_CONNECTED = "Not Connected"
export const NOT_CONNECTED_STATUS = "not-connected"
export const NA = "N/A"
export const AVAILABLE = "Available"
export const NOT_AVAILABLE = "Not Available"
export const CONNECTIVITY = "Connectivity"
export const REMOTE = "Remote Access"
export const NOT_SUPPORTED = "Not Supported"
export const DEVICE_TYPE_NIAGARA = "NAX"
export const DEVICE_TYPE_ENSEMBLE = "ES"

export const remoteAccessSortOrder = {
  "Not Available": 0,
  Available: 1,
  "Not Supported": 2
}

export const connectivitySortOrder = {
  "Not Connected": 0,
  Connected: 1,
  "N/A": 2
}

export const availableOptions = {
  "not-connected": "Not Connected",
  connected: "Connected",
  "partially-connected": "Partially Connected",
  "not-available": "Not Available",
  available: "Available",
  "partially-available": "Partially Available"
}

export const getTimeStringByTZ = (
  timeStringUTC: string,
  tz: string,
  dateFormat: string
) => {
  return momentTZ(timeStringUTC).tz(tz).format(dateFormat)
}

export const getTimeFormatted = (
  timeString: string,
  format: string,
  tz: string,
) => {
  if (tz) {
    return momentTZ(timeString).tz(tz)?.format(format)
  } else {
    let localTS: any = timeString || "";
    if (timeString?.endsWith("Z")) {
      localTS = localTS?.split("")?.slice(0, timeString?.length - 2)?.join("")
    }
    return moment(localTS).format(format)
  }
}

export const getTimeStringWithoutTZ = (
  timeString: string,
  dateFormat = "dddd, MMMM DD , YYYY, h:mm A"
) => {
  //Replace Z with empty quotes to remove timezone notation.
  const weatherUpdatedAt = (timeString || "").replace("Z", "")
  return moment(weatherUpdatedAt).format(dateFormat)
}

export const getTimeStringWithoutTZDuplicate = (
  timeString: string,
  dateFormat = "dddd, MMMM DD , YYYY, h:mm A"
) => {
  //Replace Z with empty quotes to remove timezone notation.
  const weatherUpdatedAt = (timeString || "").replace("Z", "")
  return moment(weatherUpdatedAt).locale("en").format(dateFormat)
}

export const getConnectionStatus = (
  deviceConnection: any,
  buildingDetails: any
) => {
  const connectionTime = deviceConnection?.connectionTimeUTC
  const { connectionToleranceInMins, tz } = buildingDetails
  const currentTime = momentTZ()
  const deviceConnectionTime = momentTZ(connectionTime).tz(tz)
  const timeDiffer = momentTZ
    .duration(currentTime.diff(deviceConnectionTime))
    .asHours()
  const timeLimit = connectionToleranceInMins
    ? Math.round(connectionToleranceInMins / 60)
    : TIME_LIMIT
  if (timeDiffer < timeLimit) {
    return CONNECTED
  } else {
    return NOT_CONNECTED
  }
}

export const groupByTypeWithSortOrder = (
  sortArray: any,
  sortPreferenceKey: string,
  sortPreference: any,
  sortField: string,
  sortOrder: string
) => {
  const initialValue = {}
  sortArray
    .sort(
      (a, b) =>
        sortPreference[a[sortPreferenceKey]] -
        sortPreference[b[sortPreferenceKey]]
    )
    .reduce((acc, cval) => {
      const key = cval[sortPreferenceKey]
      acc[key] = [...(acc[key] || []), cval]
      return acc
    }, initialValue)
  let filteredArray = []
  Object.keys(initialValue).map((key) => {
    filteredArray = [
      ...filteredArray,
      ...sortDataByFieldName(sortField, sortOrder, initialValue[key])
    ]
  })
  return filteredArray
}
