import { useEffect, useState } from "react"
import { selectSession } from "src/redux/slicers/sessionSlice"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Loader } from "src/Loadable"
import ErrorMessage from "./ErrorMessage/ErrorMessage"
import { useTranslation } from "react-i18next"
import { Auth } from "aws-amplify"
import { fectchAccessToken } from "src/utils/CommonMethods"

const SecurityCheck = ({ children }: any) => {
  const { buildingId } = useParams()
  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(false)
  const session = useSelector(selectSession)
  if (!buildingId) return
  const { t } = useTranslation()
  const combineBuildings = (user: any) => {
    try {
      return user.bIds
    } catch {
      return []
    }
  }
  const validateUser = async () => {
    setLoading(true)
    if (!session.error) {
      const  authenticated = await fectchAccessToken(buildingId)
      setAuthenticated(authenticated)
      // const updatedUser = await Auth.currentAuthenticatedUser()
      // if (updatedUser?.signInUserSession?.idToken?.payload?.["cognito:groups"]?.includes("SuperAdmin")) {
      //   setAuthenticated(true)
      // }
      // else {
      //   await Auth.updateUserAttributes(updatedUser, {
      //   "custom:accessControl": JSON.stringify({
      //     b: buildingId ? [buildingId] : undefined,
      //   })
      // })
      // const { idToken }: any = await Auth.currentSession()
      // const isSuperAdmin = idToken?.payload?.["cognito:groups"]?.includes("SuperAdmin")
      // const allBuildings = combineBuildings(idToken?.payload)
      // setAuthenticated(allBuildings.includes(buildingId) || isSuperAdmin)
      // }
    } else setAuthenticated(false)
    setLoading(false)
  }

  useEffect(() => {
    validateUser()
  }, [session])
  if (loading) return <Loader />
  if (!authenticated) return <ErrorMessage title={t("common.noAccess")} />
  return <>{children}</>
}
export default SecurityCheck
