import { createSlice } from "@reduxjs/toolkit"
import { SessionTypes } from "../types/sessionTypes"
import { TStore } from "../Store"
import { Auth } from "aws-amplify"
const initialState: SessionTypes = {
  data: null,
  loading: true,
  error: false,
  token: null,
  groups: []
}

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action) => {
      const { type, data = {}, token, groups = [] } = action.payload
      switch (type) {
        case "Loading":
          state = {
            loading: true,
            token: null,
            data: null,
            error: false,
            groups: []
          }
          break
        case "Success":
          state = {
            loading: false,
            data,
            token,
            error: false,
            groups
          }
          break
        case "Failure":
          state = {
            loading: false,
            token: null,
            data: null,
            error: true,
            groups: []
          }
          break
      }
      return state
    }
  }
})

export const { setSession } = sessionSlice.actions

export const selectSession = (state: TStore) => state.session

export const fetchSession = () => {
  return async (dispatch: any) => {
    dispatch(setSession({ type: "Loading" }))
    try {
      const { idToken }: any = await Auth.currentSession()
      const user = idToken.payload
      const groups = user["cognito:groups"] || []
      dispatch(
        setSession({
          type: "Success",
          data: idToken.payload,
          token: idToken.jwtToken,
          groups: groups
        })
      )
    } catch {
      dispatch(setSession({ type: "Failure" }))
    }
  }
}
