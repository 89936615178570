import Highlighter from "react-highlight-words"
import styles from "./HighLightText.module.scss"
import translate from "src/common/translations"

const HighlightText = ({ name, searchText, translateLabel = false }) => {
  return (
    <Highlighter
      highlightClassName={styles.searchHighlight}
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={translateLabel ? translate(name) : name}
    />
  )
}

export default HighlightText
