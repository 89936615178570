import clsx from "clsx"
import { ITableHeader } from "../types"
import styles from "./TableHeader.module.scss"

const TableHeader = (props: ITableHeader) => {
  const { className = "", children, style = {} } = props

  return (
    <thead style={{ ...style }} className={clsx(styles.tableHeader, className)}>
      {children}
    </thead>
  )
}

export default TableHeader
