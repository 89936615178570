import React, { useState } from "react"
import styles from "./ShortName.module.scss"
import clsx from "clsx"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import { getDateDisplay } from "src/utils/CommonMethods"
import { t } from "src/translations/help"
import { showGracePeriod } from "src/utils/OfferingUtils"
import { TranslateComponent } from "src/common/translations"

export type offeringItem = {
  code?: string
  startDate?: string
  endDate?: string
  gracePeriod?: number
  name?: string
  isExpired?: boolean,
  status: string,
  remainingDaysNumberInGracePeriod?: number
}

export type IShortType = {
  shortName: string
  variant: IShortNameVariant
  overlay: boolean
  item: offeringItem
  testId?: string
  name?: string
  buildingName?: string
}
export type IShortNameVariant = "green" | "gray" | "red"
const ShortName = (props: IShortType) => {
  const { shortName, variant, overlay, item, testId = "", name, buildingName } = props
  if (!shortName) return null
  const [show, setShow] = useState(false)
  const popover = (
    <Popover
      show={show}
      className={`${styles.offeringPopover} `}
      data-testid={testId}
    >
      <Popover.Header className={styles.popoverHeader}>
        <span
          title={t("common.close")}
          onClick={() => setShow(!show)}
          className={`home-legend-icon icon-close ${styles.closeIcon}`}
        ></span>
        <div className={styles.headerTitle}>
          <strong>
            {buildingName} : <TranslateComponent>{name}</TranslateComponent>
          </strong>
        </div>
      </Popover.Header>
      <Popover.Body className={styles.popoverBody}>
        <div>
          <strong>{<TranslateComponent>Start Date</TranslateComponent>}</strong>:&nbsp;{" "}
          {item?.startDate ? getDateDisplay(item?.startDate) : "N/A"}
        </div>
        <div>
          <strong>{<TranslateComponent>Expiration Date</TranslateComponent>}</strong>:&nbsp;{" "}
          <span className={clsx({ [styles.expired]: item?.isExpired })}>
            {item?.endDate ? getDateDisplay(item?.endDate) : "N/A"}
          </span>
        </div>
        <div>
          <strong>{<TranslateComponent>Offering Status</TranslateComponent>}</strong>: &nbsp;
          <span className={clsx({ [styles.expired]: item?.isExpired })}>
            <TranslateComponent>{item?.status}</TranslateComponent>
          </span>
        </div>
        <div>
          <strong>{<TranslateComponent>Grace Period Days Remaining</TranslateComponent>}</strong>:&nbsp;{" "}
          <span className={clsx({ [styles.expired]: item?.isExpired })}>
            {" "}
            {showGracePeriod(item)}
          </span>
        </div>
      </Popover.Body>
    </Popover>
  )

  const OverlayPopover = () => (
    <>
      {overlay ? (
        <OverlayTrigger
          rootClose
          trigger={"click"}
          placement="bottom"
          overlay={popover}
          flip
          delay={20}
        >
          <span
            className={clsx(
              styles.shortname,
              styles["shortname-div-" + variant]
            )}
          >
            {shortName}
          </span>
        </OverlayTrigger>
      ) : (
        <span
          className={clsx(styles.shortname, styles["shortname-div-" + variant])}
        >
          {shortName}
        </span>
      )}
    </>
  )
  return <OverlayPopover />
}
export default ShortName
