import { isObject } from "lodash"
import moment from "moment"
import { generateLegend, legendIDGenerator, legendName } from "../common"
import { SUPPRESSED_PROPERTY_TYPE } from "./constants"

export const generateSuppressionsTimestampObjects = (
  timestamps,
  startDate,
  endDate,
  otherProps: any = {}
) => {
  const sortedDates = timestamps
    .map((ts) => moment.utc(ts))
    .sort((a, b) => a - b)

  const result = []
  const currentRange: any = {}

  let index = 0
  for (
    let date = moment.utc(startDate);
    date.isBefore(moment.utc(endDate).add(1, "days"));
    date.add(1, "days")
  ) {
    if (index === 0) {
      currentRange.startTimestamp = date.clone()
    }
    index++
    const isDateInTimestamps = sortedDates.some((d) => d.isSame(date, "day"))
    const startDateAvailable = sortedDates.some((d) =>
      d.isSame(currentRange.startTimestamp, "day")
    )
    if (!isDateInTimestamps) {
      if (startDateAvailable) {
        currentRange.endTimestamp = date.clone()
      }
      if (date.isSame(moment.utc(endDate))) {
        currentRange.endTimestamp = date.clone()
      }
    } else {
      if (!startDateAvailable) {
        currentRange.endTimestamp = date.clone()
      }
    }
    if (currentRange?.startTimestamp && currentRange?.endTimestamp) {
      const startDateAvailable = sortedDates.some((d) =>
        d.isSame(currentRange.startTimestamp, "day")
      )
      const obj = {
        startTimestamp:
          currentRange?.startTimestamp?.format("YYYY-MM-DD") + "T00:00:00",
        endTimestamp:
          currentRange?.endTimestamp?.format("YYYY-MM-DD") + "T00:00:00",
        ...(otherProps || {}),
        value: startDateAvailable ? "Yes" : "No Data",
        color: startDateAvailable ? otherProps?.color : "#565656"
      }
      result.push(obj)
      currentRange.startTimestamp = date.clone()
      delete currentRange.endTimestamp
    }
  }
  return result
}

export const generateSuppressionLegends = (props: any) => {
  const { suppressionData = {}, chartInfo = {}, oldTimeLineLegends } = props;
  const legends: any = {}
  if (isObject(suppressionData)) {
    Object.keys(suppressionData)?.forEach((suppressionName: string) => {
      const axis = chartInfo?.axisConfig?.find(
        (obj) => obj?.axisName?.toLowerCase() === "exceptions"
      )
      const legendId = legendIDGenerator("", suppressionName)
      const name = legendName({ propertyName: `Suppression: ${suppressionName || ""}` }, [true, false])
      const legendObj: any = generateLegend({ legendId, name, axisName: axis?.axisName, symbol: axis?.symbol, propertyKey: suppressionName, type: "timeline", oldLegends: oldTimeLineLegends, batch: true, propertyType: SUPPRESSED_PROPERTY_TYPE })
      legends[legendId] = legendObj
    })
  }
  return legends
}