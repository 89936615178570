export const UPDATE_USER_SESSION = `
mutation UPDATE_USER_SESSION {
  updateUserSession
}
`

export const UPDATE_OWN_SELF = /* GraphQL */ `
  mutation UPDATE_OWN_SELF($requestBody: AWSJSON!) {
    mutateOwnUser(requestBody: $requestBody)
  }
`