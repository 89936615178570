import { t } from "src/translations/help"
import { ModalDialog, Typography } from "src/blitz"
import styles from "./ErrorModal.module.scss"
import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { setApplicationErrors } from "src/redux/slicers/applicationSlice"

const ErrorModal = (props: any) => {
    const { id, heading, errorMessage, shouldNotExpire, ErrorBody } = props
    const dispatch = useDispatch()

    const errorModalConfig = useMemo(
        () => ({
            modal: true,
            heading: heading,
            isShowCloseIcon: false,
            buttons: [
                {
                    type: "button",
                    variant: "dark",
                    handleClick: () => dispatch(setApplicationErrors({ errorID: id })),
                    text: t("common.close")
                }
            ]
        }),
        [heading, id, errorMessage]
    )

    useEffect(() => {
        if (!shouldNotExpire) {
            setTimeout(() => {
                dispatch(setApplicationErrors({ errorID: id }))
            }, 20000)
        }
    }, [])

    return (
        <ModalDialog
            modalConfig={errorModalConfig}
            footerClassName={styles.errorPopupFooter}
        >
            <div className={styles.errorMessageBody}>
                {ErrorBody ? <ErrorBody id={id} /> : <></>}
                <Typography tagType="p" styleType="p4">
                    {errorMessage}
                </Typography>
            </div>
        </ModalDialog>
    )
}
export default ErrorModal
