import clsx from "clsx"
import { useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import { DATE, MONTH, YEAR } from "./constant"
import styles from "./DateRangePicker.module.scss"
import { getDateFormat } from "src/utils/CommonMethods"
import translate, { TranslateComponent } from "src/common/translations"

const CalendarComponent = (props) => {
  const {
    type,
    calenderType,
    calendarClassName,
    legendClassName,
    handleDateSelection,
    handleHeaderButtonClick,
    date,
    title,
    maxDate,
    minDate = undefined
  } = props

  const [yearRange, setYearRange] = useState([])
  const [change, setChange] = useState(false)

  useEffect(() => {
    if (calenderType === YEAR) {
      const years = []
      document
        .querySelectorAll(".react-datepicker__year-text")
        ?.forEach((node) => {
          years.push(node?.innerHTML)
        })

      setYearRange(years)
    }
  }, [calenderType, change])

  const minYear = useMemo(() => Math.min(...yearRange), [yearRange])
  const maxYear = useMemo(() => Math.max(...yearRange), [yearRange])

  const renderDayContents = (day, date) => {
    return <span data-testid={getDateFormat(date)}>{day}</span>
  }

  return (
    <div className={`${styles.calendarWrapper} ${styles.rdpCalendarTitl}`}>
      <div className={styles.rdpCalendarTitle}>
        <div
          className={clsx(
            `${styles.rdpCalendarTitleLegend} ${legendClassName}`
          )}
        ></div>
        <p className={styles.rdpCalendarTitleTitle}>
          <TranslateComponent>
            {title}
          </TranslateComponent>
        </p>
      </div>
      <DatePicker
        peekNextMonth
        calendarClassName={calendarClassName}
        showMonthYearPicker={calenderType === MONTH}
        showYearPicker={calenderType === YEAR}
        maxDate={maxDate}
        minDate={minDate}
        inline
        yearItemNumber={12}
        selected={date}
        onChange={(date) => handleDateSelection(date, type, calenderType)}
        renderDayContents={renderDayContents}
        renderCustomHeader={(prps) => {
          const {
            date,
            changeYear,
            decreaseMonth,
            decreaseYear,
            increaseYear,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          } = prps
          const monthText = date.toLocaleString("default", {
            month: "long"
          })

          const yearText = date.getFullYear()
          const yearRange = minYear + " - " + maxYear
          const buttonText =
            calenderType === DATE
              ? monthText + " " + yearText
              : calenderType === MONTH
                ? yearText
                : calenderType === YEAR && yearRange

          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1px"
              }}
            >
              <button
                className={styles.reactDatepickerCustomHeaderButton}
                onClick={
                  calenderType === DATE
                    ? decreaseMonth
                    : calenderType === MONTH
                      ? decreaseYear
                      : calenderType === YEAR &&
                      (() => {
                        setChange((val) => !val)
                        changeYear(minYear - 12)
                      })
                }
                disabled={prevMonthButtonDisabled}
              >
                &#9664;
              </button>
              <button
                className={`${styles.reactDatepickerCustomHeaderButton} ${styles.rdpChbMiddle}`}
                onClick={() => handleHeaderButtonClick(type, calenderType)}
                disabled={calenderType === YEAR}
              >
                <strong>  {buttonText === yearText ? buttonText
                  :
                  <TranslateComponent>{buttonText}</TranslateComponent>
                }</strong>
              </button>
              <button
                className={styles.reactDatepickerCustomHeaderButton}
                onClick={
                  calenderType === DATE
                    ? increaseMonth
                    : calenderType === MONTH
                      ? increaseYear
                      : calenderType === YEAR &&
                      (() => {
                        setChange((val) => !val)
                        changeYear(minYear + 12)
                      })
                }
                disabled={nextMonthButtonDisabled}
              >
                &#9654;
              </button>
            </div>
          )
        }}
      />
    </div>
  )
}

export default CalendarComponent
