/**
 *
 * @param sortField
 * @param sortOrder
 * @param data
 * @returns
 */

import { DATATYPE_NUMBER } from "src/components/buildingSummary/constants"
import { ASC } from "./constants"

export const sortDataByFieldName = (
  sortField: string,
  sortOrder: string,
  data: any,
  columnData?: any,
  secondarySortField?: string
) => {
  if (!sortField || !data) return
  const isDateField = columnData?.find(
    (columnObj: any) => columnObj?.id === sortField
  )?.date
  const isNumField = columnData?.find((columnObj: any) => columnObj?.id === sortField)?.dataType === DATATYPE_NUMBER
  const sorted = [...data].sort((a: any, b: any) => {
    if (isDateField) {
      const dateA = new Date(a[sortField]).getTime()
      const dateB = new Date(b[sortField]).getTime()
      if (sortOrder === ASC) {
        return (dateA || -Infinity) - (dateB || -Infinity)
      } else {
        return (dateB || -Infinity) - (dateA || -Infinity)
      }
    } else {
      let sortVal = getSortValue(a, b, sortField, isNumField)

      //If both values are same then compare with secondary sort field.
      if (
        secondarySortField &&
        sortField !== secondarySortField &&
        sortVal == 0
      ) {
        sortVal = getSortValue(a, b, secondarySortField, isNumField)
      }

      return sortVal * (sortOrder === ASC ? 1 : -1)
    }
  })
  return sorted
}



export const sortByAlphanumericSpecialChars = (
  sortField: string,
  sortOrder: string,
  data: any
) => {
  if (!sortField || !data) return
  const sorted = [...data].sort((a, b) => {
    const aValue = a[sortField],
      bValue = b[sortField]
    if (aValue === null || aValue === undefined || bValue === null || bValue === undefined) return 0
    const sortVal = aValue.localeCompare(bValue, "en", { numeric: true })
    return sortVal * (sortOrder === ASC ? 1 : -1)
  })
  return sorted
}

/**
 * Returns the sort value.
 * @param a - first object
 * @param b - second object
 * @param sortField - sort field name
 * @returns
 */
function getSortValue(a: any, b: any, sortField: string, isNumField: boolean) {
  const aValue =
    a[sortField] === null || a[sortField] === undefined ? (isNumField ? Infinity: "") : a[sortField]
  const bValue =
    b[sortField] === null || b[sortField] === undefined ? (isNumField ? Infinity: "") : b[sortField]
  const sortVal = aValue?.toString()?.localeCompare(bValue?.toString(), "en", {
    numeric: true
  })

  return sortVal
}
