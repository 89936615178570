import { useEffect, useMemo, useState } from "react"
import { t } from "src/translations/help"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Outlet } from "react-router-dom"
import { Header } from "src/blitz"
import { useSelector, useDispatch } from "react-redux"
import {
  selectUserAccess,
  setBreadcrumbs,
  setHeaderTitle,
  setUserAccess
} from "src/redux/slicers/homeSlice"
import { selectBuildingSummary } from "src/redux/slicers/buildingSummarySlice"
import { selectEquipmentSummary } from "src/redux/slicers/equipmentSummarySlice"
import { fetchSession } from "src/redux/slicers/sessionSlice"
import { Auth } from "aws-amplify"
import { useAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css"
import "react-loading-skeleton/dist/skeleton.css"
import "src/App.scss"
import { Hub } from "aws-amplify"
import { ErrorMessage } from "src/components/shared"
import { Typography, Button } from "src/blitz"
import "./styles.scss"
import { useQuery, useMutation } from "src/hooks/APIHooks"
import { GetUIAccessToken } from "src/graphql/queries"
import { accessControlFunc } from "src/components/accessControl"
import { zendeskHelper } from "./zendesk-helper"
import { trackEvent, identifyEvent } from "src/amplitude.js"
import { USER_EVENTS } from "src/amplitude-categories"
import store from "src/redux/Store"
import {
  selectUserInfo,
  setUserInfo,
  setUserRoles
} from "src/redux/slicers/userData"
import { GET_USER_BY_ID } from "src/graphql/queries/userQueries"
import { parseUserData } from "src/parseUserData"
import { UPDATE_OWN_SELF, UPDATE_USER_SESSION } from "src/graphql/queries/userMutations"
import { isEmpty } from "lodash"
import { FAILURE, LOADING, SUCCESS } from "src/components/shared/Charts/constant"
import { setFilter } from "src/redux/slicers/reportSlice"
import { GET_EULA_VERSION } from "src/graphql/queries/s3Query"
import { staticFilesPath } from "../aws-exports.js"
import { getConfig } from "src/config"
import { COMMAND_CENTER_GEN_4_REDIRECTION } from "src/constants/common"
import SUPPORTED_LANGUAGES from "src/blitz/Header/LanguagePicker"
import { setSelectedLanguage } from "src/redux/slicers/userData"
import { clearTranslations } from "src/redux/slicers/translationSlice"
import { setMomentLocale } from "src/utils/MomentUtils"
import { TranslateComponent } from "src/common/translations"
import common from "src/translations/en/common.json"
import ItemNotFound, { itemNotFoundTitle } from "src/components/shared/ErrorModal/Errors/ItemNotFound"
import { setApplicationErrors } from "src/redux/slicers/applicationSlice"
import { validate as isValidUUID } from "uuid"
import { decodeURLSearchParams } from "src/components/shared/Charts/Helper/common"


const MainLayout = ({ errorMessage }) => {
  const [errorMessageState, setErrorMessageState] = useState(errorMessage)
  const [userSignedInState, setUserSignedInState] = useState("checking")
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch: any = useDispatch()
  const { buildingId, equipmentId } = useParams()
  const [page, setPage] = useState("loading")
  const [firstSignIn, setFirstSignIn] = useState(false)
  const [initializedApp, setInitializedApp] = useState(false)
  const { externalLinks } = getConfig()

  const { onSubmit: updateOwnUser } = useMutation({
    query: UPDATE_OWN_SELF,
    dataPath: "data",
    onSuccess: (data) => {
      refetch({ id: user.attributes["custom:userId"] })
    }
  })

  const { onSubmit: refreshUserSession } =
    useMutation({
      query: UPDATE_USER_SESSION,
      onSuccess: async (res) => {
        if (res?.["data"]?.updateUserSession) {
          const cognitoUser = await Auth.currentAuthenticatedUser()
          const currentSession = await Auth.currentSession()
          await cognitoUser.refreshSession(
            currentSession["refreshToken"],
            async (err, session) => {
              if (!err) {
                setInitializedApp(true)
                setFirstSignIn(false)
              } else {
                console.log(err)
              }
            }
          )
        } else {
          setPage("error")
          setErrorMessageState("Unable to update user's access. Please try again or contact support.")
        }
      }
    })

  Hub.listen("auth", (data) => {
    if (data.payload.event === "customState_failure") {
      setErrorMessageState(data.payload.data.message)
      setPage("error")
    } else if (data.payload.event === "signIn_failure") {
      setPage("error")
    } else if (data.payload.event === "signIn") {
      setFirstSignIn(true)
    } else if (data.payload.event === "customOAuthState") {
      navigate(data.payload.data)
    } else if (data.payload.event === "updateUserAttributes_failure") {
      Auth.signOut()
    }
  })

  // TODO: We need to handle prod environment:
  const customProvider = "b2c"

  useEffect(() => {
    const authFunc = async () => {
      await Auth.currentSession().catch((err) => {
        if (err === "No current user") {
          setUserSignedInState("not signed in")
        }
      })
    }
    authFunc()
  }, [])

  useEffect(() => {
    if (userSignedInState === "not signed in" && page === "loading") {
      if (!errorMessageState) {
        Auth.federatedSignIn({
          customProvider: customProvider,
          customState: `${window.location.pathname}${window.location.search}`
        })
      } else {
        setPage("error")
      }
    }
  }, [userSignedInState])

  const { loading: loadingSummary } = useSelector(selectBuildingSummary)
  const { loading: loadingEquipmentSummary } = useSelector(
    selectEquipmentSummary
  )
  const { breadcrumbs, headerTitle } = useSelector((store: any) => store.home)
  const { user, signOut } = useAuthenticator()

  const userInfo = useSelector(selectUserInfo)
  const { data: userData, refetch } = useQuery({
    query: GET_USER_BY_ID,
    disableInitialLoad: true,
    onSuccess: (rawUserInfo) => {
      const data = parseUserData(rawUserInfo)
      if (isEmpty(data)) {
        setPage("error")
        setErrorMessageState(`No Command Center Account Found.
        We were unable to log you into Command Center because there is no Command Center account associated with the email you provided. Please contact your Trane Account Manager for further assistance.`)
      } else {
        dispatch(
          setUserInfo({
            data
          })
        )
      }
    },
    onError: (err) => {
      setPage("error")
      console.log("Error: ", err)
    },

    errorPolicy: "ignore",
    dataPath: "data.getUser"
  })

  const { data: eulaData } =
    useQuery({
      query: GET_EULA_VERSION,
      variables: { body: JSON.stringify({ prefix: "cc/EULA/" }) },
      dataPath: "data.getLatestFile"
    })

  const { refetch: userAccessRefetch } = useQuery({
    query: GetUIAccessToken,
    disableInitialLoad: true,
    onError: () => {
      dispatch(setUserAccess({ type: FAILURE }))
    },
    onSuccess: (userAccessInfo) => {
      const parsedData = JSON.parse(JSON.parse(userAccessInfo))
      if (!parsedData?.body) {
        setPage("error")
      } else {
        const parsedBody = JSON.parse(parsedData.body)
        const items = parsedBody?.data?.listUserAccessesByUserId?.items || []
        const UIPermMap = new Map([])
        const ListOfRoles = []

        for (let i = 0; i < items.length; i++) {
          if (ListOfRoles.indexOf(items[i].role?.name) === -1) {
            ListOfRoles.push(items[i].role?.name)
          }
          for (let j = 0; j < items[i].role.permissions.items.length; j++) {
            if (
              !UIPermMap.has(items[i].role.permissions.items[j].permission.name)
            ) {
              UIPermMap.set(
                items[i].role.permissions.items[j].permission.name.replace(
                  "[resource]",
                  items[i].resource
                ),
                true
              )
            }
          }
        }
        dispatch(setUserAccess({ type: SUCCESS, data: UIPermMap }))
        dispatch(setUserRoles(ListOfRoles))
      }
    },
    errorPolicy: "ignore",
    dataPath: "data.getUIAccessTokens"
  })

  useEffect(() => {
    const callUserRefetch = async () => {
      if (user && !userInfo) {
        refetch({ id: user.attributes["custom:userId"] })
      }
    }
    callUserRefetch()
    if (user && initializedApp) {
      dispatch(setUserAccess({ type: LOADING }))
      userAccessRefetch()
    }
  }, [user, initializedApp])

  useEffect(() => {
    if (!userInfo?.id) return

    // Prefill the user details on the Zendesk Request/feeback support Bot
    zendeskHelper.identify(
      userInfo.firstName ?? "",
      userInfo.lastName ?? "",
      userInfo.email ?? ""
    )
  }, [userInfo])



  const ErrorBodyComponent = () => (
    <div className={"error-body-wrapper"}>
      <Typography tagType="p">
        {errorMessageState
          ? errorMessageState
          : "We were unable to log you into Command Center because there is no Command Center account associated with the email you provided. Please contact your Trane Account Manager for further assistance."}
      </Typography>
      <br />
      <Typography tagType="p">
        <>
          <Button
            type="button"
            variant="transparent"
            onClick={() => {
              signOut()
            }}
          >
            <Typography tagType="span" styleType="p3" fontType="normalFont">
              Logout
            </Typography>
          </Button>{" "}
        </>
      </Typography>
    </div>
  )

  const TermsAndConditionsBodyComponent = () => (
    <div className={"error-body-wrapper"}>
      <Typography tagType="p">
        {<>In order to continue, you must accept and agree to be bound by the Trane Connect <a href={`${staticFilesPath}/${JSON.parse(JSON.parse(eulaData).body).Key}`} target="_blank" rel="noreferrer">End-User License Agreement Terms and Conditions</a>.</>}
      </Typography>
      <br />
      <Typography tagType="p">
        <>
          <Button
            type="button"
            variant="transparent"
            onClick={() => {
              const EULAData = JSON.parse(JSON.parse(eulaData).body)
              const profileSettings = { ...userData.profileSettings ? JSON.parse(userData.profileSettings) : {} }
              profileSettings.cc = { ...userData.profileSettings?.cc || {} }
              profileSettings.cc.EULA = {
                file: EULAData.Key,
                date: EULAData.LastModified,
                dateSigned: new Date().valueOf()
              }
              const data = { ...userData, profileSettings: { ...profileSettings } }
              updateOwnUser({ requestBody: JSON.stringify(data) })
              setPage("main")
            }}
          >
            <Typography tagType="span" styleType="p3" fontType="normalFont">
              Accept
            </Typography>
          </Button>{" "}
          <Button
            type="button"
            variant="transparent"
            onClick={() => {
              signOut()
            }}
          >
            <Typography tagType="span" styleType="p3" fontType="normalFont">
              Decline
            </Typography>
          </Button>{" "}
        </>
      </Typography>
    </div>
  )

  const userName = user?.attributes?.name || ""
  const loadingData = useMemo(
    () =>
      location.pathname.includes("equipment")
        ? loadingEquipmentSummary
        : location.pathname.includes("building") && location.pathname.includes("summary") && loadingSummary,
    [loadingSummary, loadingEquipmentSummary]
  )
  useEffect(() => {
    if (location?.pathname === "/buildings") {
      dispatch(setBreadcrumbs([]))
      dispatch(setHeaderTitle("default"))
    }
  }, [location])

  useEffect(() => {
    dispatch(fetchSession())
  }, [])

  useEffect(() => {
    const uDataFunc = async () => {
      if (userData && eulaData) {
        try {
          if (userData.profileSettings) {
            const EULAData = JSON.parse(JSON.parse(eulaData).body)
            const profileSettings = JSON.parse(userData.profileSettings)
            if (profileSettings?.cc?.EULA && profileSettings.cc.EULA.file === EULAData.Key && profileSettings.cc.EULA.date === EULAData.LastModified) {
              const userDataToSend = {
                "user id": userData?.id ? userData?.id : store?.getState()?.userData?.userInfo?.data?.id,
                "user type": userData?.type?.name ? userData?.type?.name : store?.getState()?.userData?.userInfo?.data?.type?.name,
                "role names": store?.getState()?.userData?.roles,
                "client id": "Command Center",
                "job title": userData?.jobTitle ? userData?.jobTitle : store?.getState()?.userData?.userInfo?.data?.jobTitle,
                "primary sales office": userData?.primarySalesOfficeId ? userData?.primarySalesOfficeId : store?.getState()?.userData?.userInfo?.data?.primarySalesOfficeId,
                "version": process.env?.REACT_APP_AWS_JOB_ID
              }
              identifyEvent(userDataToSend)
              const profileSettings = store?.getState()?.userData?.userInfo?.data?.profileSettings
              const lang = JSON.parse(profileSettings)?.language;
              if (lang) {
                await languageChange(lang)
              }
              if (firstSignIn) {
                await refreshUserSession({})
                const userDataToSend = {
                  "user id": userData?.id ? userData?.id : store?.getState()?.userData?.userInfo?.data?.id,
                  "user type": userData?.type?.name ? userData?.type?.name : store?.getState()?.userData?.userInfo?.data?.type?.name,
                  "role names": store?.getState()?.userData?.roles,
                  "client id": "Command Center",
                  "job title": userData?.jobTitle ? userData?.jobTitle : store?.getState()?.userData?.userInfo?.data?.jobTitle,
                  "primary sales office": userData?.primarySalesOfficeId ? userData?.primarySalesOfficeId : store?.getState()?.userData?.userInfo?.data?.primarySalesOfficeId,
                  "version": process.env?.REACT_APP_AWS_JOB_ID
                }
                identifyEvent(userDataToSend)
                trackEvent(USER_EVENTS.LOGIN.events.USER_LOGIN)
              } else {
                setInitializedApp(true)
              }
            } else {
              setPage("termsAndConditions")
            }
          } else {
            setPage("termsAndConditions")
          }
        } catch (e) {
          console.log(e)
        }
      }

    }
    uDataFunc()
  }, [userData, eulaData])

  useEffect(() => {
    if (store?.getState()?.userData?.roles.length) {
      const userDataToSend = {
        "user id": store?.getState()?.userData?.userInfo?.data?.id,
        "user type": store?.getState()?.userData?.userInfo?.data?.type?.name,
        "role names": store?.getState()?.userData?.roles,
        "client id": "Command Center",
        "job title": store?.getState()?.userData?.userInfo?.data?.jobTitle,
        "primary sales office": store?.getState()?.userData?.userInfo?.data?.primarySalesOfficeId,
        "version": process.env?.REACT_APP_AWS_JOB_ID
      }
      identifyEvent(userDataToSend)
    }
  }, [store?.getState()?.userData?.roles])

  const userAccess = useSelector(selectUserAccess)

  const languageChange = async (lang) => {
    const userData = store?.getState()?.userData
    const profileSettings = JSON.parse(userData?.userInfo?.data?.profileSettings)
    const UOM = profileSettings?.unitOfMeasurement === "im"
      ? "IP"
      : profileSettings?.unitOfMeasurement === "si"
        ? "SI"
        : "IP";

    // Update User selected language as preferred language in User profile
    if (profileSettings?.language !== lang) {
      const data = { ...userData, profileSettings: { ...profileSettings, language: lang } }
      updateOwnUser({ requestBody: JSON.stringify(data) })
    }
    await setMomentLocale(lang)
    dispatch(clearTranslations())
    dispatch(setSelectedLanguage(lang))
    dispatch(setFilter({
      language: lang,
      systemOfMeasure: UOM
    }))
  }

  const menus = [
    accessControlFunc({ id: "cc.pages.adminSettings", userAccess })
      ? {
        title: <TranslateComponent>{common.adminSettings}</TranslateComponent>,
        type: "link",
        link: "/admin-settings/permissions/groups",
        handler: () => { trackEvent(USER_EVENTS.BUILDING_SUMMARY.events.NAVIGATE_ADMIN_SETTINGS) }
      }
      : undefined,
    {
      title: t("common.language"),
      type: "text",
      options: SUPPORTED_LANGUAGES
        .map(lang => ({
          title: lang.value,
          customTranslationOn: true,
          type: "button",
          handler: () => {
            languageChange(lang.key);
          }
        })),
    },
    {
      title: t("common.support_feedback"),
      type: "button",
      handler: () => {
        zendeskHelper.openWidget()
        trackEvent(USER_EVENTS.BUILDING_SUMMARY.events.CLICK_SUPPORT_FEEDBACK)
      }
    },
    {
      title: t("common.terms_of_use"),
      type: "button",
      handler: () => {
        const link = document.createElement("a");
        link.download = "EndUserLicenseAgreement.pdf";
        link.target = "_blank";
        link.href = `${staticFilesPath}/${JSON.parse(JSON.parse(eulaData).body).Key}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    {
      title: t("common.logout"), type: "button", handler: () => {
        signOut()
        trackEvent(USER_EVENTS.BUILDING_SUMMARY.events.CLICK_LOGOUT)
      }
    },
    { type: "divider", disabled: false },
    accessControlFunc({ id: "tc.header.gen4link", userAccess }) ? {
      title: t("common.commandCenterGen4"), type: "button", handler: () => {
        window.open(externalLinks[COMMAND_CENTER_GEN_4_REDIRECTION]?.absoluteURL, "_blank")
        trackEvent(USER_EVENTS.BUILDING_SUMMARY.events.CLICK_COMMAND_CENTER_GEN4)
      }
    } : undefined,
    {
      title: `Trane ${new Date()?.getFullYear()} | Build ID ${process.env?.REACT_APP_AWS_JOB_ID
        ? process.env?.REACT_APP_AWS_JOB_ID.replace(/^0+/, "")
        : ""
        }`,
      type: "text",
      icon: "icon-copy-rght",
      disabled: true,
      iconLeft: true
    },
    {
      title: `Version: ${process.env?.REACT_APP_AWS_COMMIT_ID
        ? process.env?.REACT_APP_AWS_COMMIT_ID?.substring(0, 7)
        : ""
        }`,
      type: "text",
      disabled: true
    }
  ].filter((e) => e)
  const title = useMemo(() => {
    let title = headerTitle
    if (headerTitle === "default") {
      title = t("common.commandCenter")
    }
    return title
  }, [headerTitle])

  useEffect(() => {
    const pathName = location?.pathname
    const isBuildingLevel = pathName?.includes("building") && !pathName?.includes("equipment") && !pathName?.includes("buildings")
    const isEquipmentLevel = pathName?.includes("building") && pathName?.includes("equipment")

    const equipmentIds = decodeURLSearchParams(equipmentId)
    const everyEquipValidUUID = equipmentIds?.every((id) => isValidUUID(id))
    if ((isBuildingLevel && buildingId && !isValidUUID(buildingId)) || (isEquipmentLevel && ((buildingId && !isValidUUID(buildingId)) || (equipmentId && !(everyEquipValidUUID))))) {
      dispatch(setApplicationErrors({ heading: itemNotFoundTitle, ErrorBody: ItemNotFound, shouldNotExpire: true }))
    }

  }, [location?.pathname])

  return page === "termsAndConditions" ? (
    <ErrorMessage
      title={"Terms & Conditions"}
      BodyComponent={TermsAndConditionsBodyComponent}
    />
  ) : page !== "error" ? (
    <>
      <Header
        userName={userName}
        options={menus}
        breadcrumbs={breadcrumbs}
        headerTitle={title}
        loading={loadingData}
      />
      {initializedApp ? <Outlet /> : <></>}
    </>
  ) : (
    <ErrorMessage
      title={errorMessageState ? "ERROR" : "No Command Center Account Found"}
      BodyComponent={ErrorBodyComponent}
    />
  )
}

export default MainLayout
