import * as ReactDOMClient from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import store from "src/redux/Store"
import { Provider } from "react-redux"
import QueryProvider from "./providers/QueryProvider"

// errorMessage is needed for if a user is disabled via cognito and not via user dynamo fields
const errorMessage = new URLSearchParams(window.location.search).get(
  "error_description"
)

const root = ReactDOMClient.createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
  <QueryProvider>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <App errorMessage={errorMessage} />
      </BrowserRouter>
    </Provider>
  </QueryProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
