import Highlighter from "react-highlight-words"
import { Checkbox, RadioGroup } from "src/blitz"
import styles from "./ReportList.module.scss"
import translate from "src/common/translations"
// To be moved to utilz
const HighlightText = ({ name, searchText, translateValues }) => {
  return (
    <Highlighter
      highlightClassName={styles.searchHighlight}
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={translateValues ? translate(name) : name}
    />
  )
}

const ListWithSearch = ({
  data = [],
  selectedItems = [],
  handleChange,
  listType,
  searchText,
  translateValues = true
}) => {
  return (
    <div>
      {listType === "radio" ? (
        <div className="col-sm">
          <RadioGroup
            radioGroupClassName={styles.multiSingleSelection}
            options={data}
            layout="column"
            handleChange={(selectedVal: any) =>
              handleChange({ target: { checked: true } }, selectedVal, "radio")
            }
            selected={selectedItems?.length ? selectedItems[0] : false}
          />
        </div>
      ) : (
        data?.map((item: any, index: number) => {
          const checkboxId = `${item.id}-${index}`
          return (
            <Checkbox
              key={checkboxId}
              name={item.id}
              label={
                <HighlightText
                  name={item?.label || item?.name}
                  searchText={searchText}
                  translateValues={translateValues}
                />
              }
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(ev.target.checked, item.id, "checkbox")
              }}
              textColor={item?.textColor || "default"}
              checked={selectedItems?.includes(item.id)}
              disabled={item?.disabled}
            />
          )
        })
      )}
    </div>
  )
}

export default ListWithSearch
