export const STACKEDBAR_SORT_BY_PERCENT = "sortByPercent"
export const STACKEDBAR_SORT_BY_NAME = "sortByName"

export const stackedBarSortOptions = [
  {
    label: "Sort boxes by % time in each range",
    name: "Sort boxes by % time in each range",
    value: STACKEDBAR_SORT_BY_PERCENT
  },
  {
    label: "Sort boxes alphanumerically",
    name: "Sort boxes alphanumerically",
    value: STACKEDBAR_SORT_BY_NAME
  }
]
